import { useEffect, useRef, useState } from 'react'
import { app, env } from 'config'

export const useGoogleTagManagerInit = () => {
  const snippetAppended = useRef<boolean>(false)
  const [dataLayerInitialized, setDataLayerInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (dataLayerInitialized) {
      return
    }
    /*
      Third-party scripts can initialize dataLayer too,
      so we should not overwrite
    */
    window.dataLayer = window.dataLayer || []
    setDataLayerInitialized(true)
  }, [dataLayerInitialized])

  useEffect(() => {
    if (!app.analyticsEnabled) {
      return
    }

    if (!dataLayerInitialized || snippetAppended.current) {
      return
    }

    window.dataLayer!.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    })

    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${env.REACT_APP_GTM_CONTAINER_ID}`
    document.head.appendChild(script)

    snippetAppended.current = true
  }, [dataLayerInitialized])

  return { initialized: dataLayerInitialized }
}
