export const OTHER = 'other'
export const NOT_ELIGIBLE_PLANS = {
  'Medicare Advantage': 'medicare_advantage',
  Medicare: 'medicare',
  Other: OTHER
}

export const SELECTED_INSURANCE_PLAN_KEY = 'selected_insurance_plan'
export const INELIGIBLE_INSURANCE_PLAN_SELECTED = 'ineligible_insurance_plan_selected'

export const FROM_PODCAST = 'podcast-general'
export const HEARD_ABOUT_US = [
  {
    label: 'Facebook',
    value: 'facebook-general'
  },
  {
    label: 'From a friend, family member or coworker',
    value: 'from-friend-family-coworker'
  },
  {
    label: 'From an influencer',
    value: 'from-influencer'
  },
  {
    label: 'From a mobile app',
    value: 'from-mobile-app'
  },
  {
    label: 'Reddit',
    value: 'reddit'
  },
  {
    label: 'Snapchat',
    value: 'snapchat'
  },
  {
    label: 'Youtube',
    value: 'youtube-general'
  },
  {
    label: 'TikTok',
    value: 'tiktok-general'
  },
  {
    label: 'Google or other search engine',
    value: 'google-or-search'
  },
  {
    label: 'Podcast',
    value: FROM_PODCAST
  },
  {
    label: 'From a healthcare professional',
    value: 'from-healthcare-pro'
  },
  {
    label: 'Instagram',
    value: 'instagram-general'
  },
  {
    label: 'Press / Magazine',
    value: 'press-magazine'
  },
  { label: 'Other', value: OTHER }
]
