import { useCallback } from 'react'
import { getVideoCallData } from 'screens/VideoCalls/utils'
import getUserGeolocation from 'utils/getUserGeolocation'
import { useCurrentUserQuery } from 'types'

export const useGoogleTagManagerTrack = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const primaryProductKey = currentUser?.ongoingCoreSubscription?.primaryProduct.key || ''

  const track = useCallback(
    (event: string, properties?: object) => {
      if (!window.dataLayer) {
        return
      }

      const videoCallData = getVideoCallData()
      const userGeolocation = getUserGeolocation()

      window.dataLayer.push({
        event,
        primaryProductKey,
        ...videoCallData,
        ...userGeolocation,
        ...properties
      })
    },
    [primaryProductKey]
  )

  return track
}
