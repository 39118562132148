import { useCallback, useRef, useState } from 'react'
import { AppEnv, env } from 'config/env'
import { SearchParams } from 'location.types'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSearchParams } from 'react-router-dom'
import { RECAPTCHA_TIMEOUT } from 'utils/constants'

const siteKey = env.REACT_APP_RECAPTCHA_SITE_KEY
const isDevelopmentEnv = env.REACT_APP_ENV === AppEnv.Development
const isStagingEnv = env.REACT_APP_ENV === AppEnv.Staging
const FORCE_ALLOW_CAPTCHA_ITEM_KEY = 'forceAllowCaptcha'

const isForceAllowCaptcha = (searchParams: URLSearchParams) =>
  env.REACT_APP_ENV === AppEnv.Staging &&
  searchParams.get(SearchParams.ForceAllowCaptcha) === 'true'

export const useRecaptcha = () => {
  const [searchParams] = useSearchParams()
  const [forceAllowCaptcha, setForceAllowCaptcha] = useState(
    isStagingEnv && localStorage.getItem(FORCE_ALLOW_CAPTCHA_ITEM_KEY) === 'true'
  )

  const captureForceAllowCaptcha = () => {
    if (isForceAllowCaptcha(searchParams) && !forceAllowCaptcha) {
      localStorage.setItem(FORCE_ALLOW_CAPTCHA_ITEM_KEY, 'true')
      setForceAllowCaptcha(true)
    }
  }

  const shouldLoadCaptcha = !isDevelopmentEnv && !forceAllowCaptcha

  const tokenRefreshTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const handleRecaptcha = useCallback((token: string | null) => {
    setCaptchaToken(token || '')

    const refreshCaptcha = () => {
      if (recaptchaRef.current && token) {
        recaptchaRef.current.reset()
        setCaptchaToken('')
      }
    }

    if (tokenRefreshTimeoutRef.current) {
      clearTimeout(tokenRefreshTimeoutRef.current)
    }

    tokenRefreshTimeoutRef.current = setTimeout(refreshCaptcha, RECAPTCHA_TIMEOUT)
  }, [])

  return {
    forceAllowCaptcha,
    shouldLoadCaptcha,
    captureForceAllowCaptcha,
    handleRecaptcha,
    setCaptchaToken,
    captchaToken,
    recaptchaRef,
    siteKey
  }
}
