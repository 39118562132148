import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded date */
  ISO8601Date: any
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any
  /** Represents untyped JSON */
  JSON: any
  /** Represents a numeric value that can be either Integer, Float, or BigDecimal */
  Numeric: number
  Upload: any
}

/** Autogenerated return type of ActivatePrescriptionDexcomAction. */
export type ActivatePrescriptionDexcomActionPayload = {
  __typename?: 'ActivatePrescriptionDexcomActionPayload'
  success: Scalars['Boolean']
}

export type ActivationData = {
  calibrationInfo?: InputMaybe<CalibrationInfo>
  maxLifeInMinutes?: InputMaybe<Scalars['Int']>
  model: Scalars['String']
  serialNumber: Scalars['String']
  startTime?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
}

export type Activity = {
  __typename?: 'Activity'
  allDay: Scalars['Boolean']
  autoGenerated: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  description: Scalars['String']
  endedAt: Scalars['ISO8601DateTime']
  externalSource?: Maybe<Scalars['String']>
  favorite: Scalars['Boolean']
  glucose: EntryGlucose
  id: Scalars['ID']
  length: Scalars['Int']
  occurredAt: Scalars['ISO8601DateTime']
  startedAt: Scalars['ISO8601DateTime']
  statistics: EntryStatistics
  status?: Maybe<Scalars['String']>
  title: Scalars['String']
  type: Scalars['String']
}

export type ActivityCollection = {
  __typename?: 'ActivityCollection'
  activities: Array<Activity>
}

export type ActivityFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export type AddonLimit = {
  __typename?: 'AddonLimit'
  description?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  productId: Scalars['ID']
  purchasedAmount: Scalars['Int']
}

export type Address = {
  __typename?: 'Address'
  city: Scalars['String']
  country?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  state?: Maybe<AddressState>
  street: Scalars['String']
  street2: Scalars['String']
  zipCode: Scalars['String']
}

export enum AddressCountries {
  Ca = 'CA',
  Gb = 'GB',
  Us = 'US'
}

export enum AddressState {
  Aa = 'AA',
  Ab = 'AB',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Bc = 'BC',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Mb = 'MB',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nb = 'NB',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nl = 'NL',
  Nm = 'NM',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  On = 'ON',
  Or = 'OR',
  Other = 'Other',
  Pa = 'PA',
  Pe = 'PE',
  Pr = 'PR',
  Pw = 'PW',
  Qc = 'QC',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Sk = 'SK',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Yt = 'YT'
}

export type AllowEarlyExitBehavior = {
  __typename?: 'AllowEarlyExitBehavior'
  followupSurvey?: Maybe<SurveysConfigKind>
}

export type Answer = {
  __typename?: 'Answer'
  /** @deprecated Use followups instead */
  followup?: Maybe<Followup>
  followups: Array<Followup>
  inlineAlert?: Maybe<InlineAlert>
  label: Scalars['String']
  value: Scalars['String']
}

export type AnswersGroup = {
  __typename?: 'AnswersGroup'
  answers: Array<Answer>
  answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
}

export type Appointment = {
  __typename?: 'Appointment'
  actualDuration?: Maybe<Scalars['Int']>
  addToGoogleCalendarLink?: Maybe<Scalars['String']>
  agenda?: Maybe<Scalars['String']>
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  date?: Maybe<Scalars['String']>
  externalVideochatUrl?: Maybe<Scalars['String']>
  getHealthieId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  insurancePolicy?: Maybe<InsurancePolicy>
  meetingAt?: Maybe<Scalars['ISO8601DateTime']>
  meetingStatus?: Maybe<InsuranceBerryStreetAppointmentMeetingStatus>
  nutritionistName?: Maybe<Scalars['String']>
  recurrent: Scalars['Boolean']
  status: InsuranceBerryStreetAppointmentStatus
  title?: Maybe<Scalars['String']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']>
  user: User
}

export type AppointmentCollection = {
  __typename?: 'AppointmentCollection'
  appointments: Array<Appointment>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type AppointmentDynamicFilter = {
  field: AppointmentDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum AppointmentDynamicFilterField {
  MeetingAt = 'meeting_at',
  MeetingStatus = 'meeting_status',
  Status = 'status'
}

export type AppointmentDynamicFilterItem = {
  items: Array<AppointmentDynamicFilter>
  operator: DynamicFilterOperator
}

export enum AppointmentEhrSortField {
  MeetingAt = 'meeting_at'
}

export type AppointmentSort = {
  direction: SortDirection
  field: AppointmentSortField
}

export enum AppointmentSortField {
  MeetingAt = 'meeting_at'
}

export type AppointmentType = {
  __typename?: 'AppointmentType'
  duration: Scalars['String']
  key: InsuranceBerryStreetAppointmentType
  title: Scalars['String']
}

export type ApprovalRequest = {
  __typename?: 'ApprovalRequest'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  kind: UserApprovalRequestKind
  state: UserApprovalRequestState
  user: User
}

export type Availability = {
  __typename?: 'Availability'
  appointmentKind: EhrAppointmentKind
  dayOfWeek: Scalars['Int']
  rangeEndAt: Scalars['ISO8601DateTime']
  rangeStartAt: Scalars['ISO8601DateTime']
}

export type AvailableDates = {
  __typename?: 'AvailableDates'
  dates: Array<Scalars['ISO8601Date']>
}

export type AvailableDatesWithSlots = {
  __typename?: 'AvailableDatesWithSlots'
  coachProfiles?: Maybe<Array<CoachProfile>>
  dates: Array<Date>
}

export type AvailableSlots = {
  __typename?: 'AvailableSlots'
  slots: Array<Slot>
}

export type Behavior = {
  __typename?: 'Behavior'
  allowEarlyExit: Scalars['Boolean']
  allowMultipleSubmissions: Scalars['Boolean']
  redirectOnOutcome: Scalars['Boolean']
  showAlertOnClose: Scalars['Boolean']
  showProgressIndicator: Scalars['Boolean']
  trackResponses: Scalars['Boolean']
  warnBeforeStopping: Scalars['Boolean']
}

export enum BillingProduct {
  AutoCancelNutrisenseMonthly = 'auto_cancel_nutrisense_monthly',
  BundledFixedMember = 'bundled_fixed_member',
  BundledFixedMemberBuyNowPayLater = 'bundled_fixed_member_buy_now_pay_later',
  CancellationFee = 'cancellation_fee',
  Cgm = 'cgm',
  CompoundedSemaglutide = 'compounded_semaglutide',
  CompoundedSemaglutideWithSupport = 'compounded_semaglutide_with_support',
  DexcomCgm = 'dexcom_cgm',
  DexcomPatch = 'dexcom_patch',
  DmDietitian_4Video_30Min = 'dm_dietitian_4_video_30_min',
  DmDietitianVideo_30Min = 'dm_dietitian_video_30_min',
  DmMealPlan = 'dm_meal_plan',
  DmQGlucoseCompare = 'dm_q_glucose_compare',
  DmQGlucoseEvent = 'dm_q_glucose_event',
  DmQGlucoseFasting = 'dm_q_glucose_fasting',
  DmQGlucoseMetrics = 'dm_q_glucose_metrics',
  DmQGlucoseTrends = 'dm_q_glucose_trends',
  DmSupplementReview = 'dm_supplement_review',
  DmWeeklyGoals = 'dm_weekly_goals',
  DowngradeFee = 'downgrade_fee',
  DowngradeFee_6To_3Cycle_1 = 'downgrade_fee_6_to_3_cycle_1',
  DowngradeFee_6To_3Cycle_2 = 'downgrade_fee_6_to_3_cycle_2',
  DowngradeFee_12To_3Cycle_1 = 'downgrade_fee_12_to_3_cycle_1',
  DowngradeFee_12To_3Cycle_2 = 'downgrade_fee_12_to_3_cycle_2',
  DowngradeFee_12To_6Cycle_1 = 'downgrade_fee_12_to_6_cycle_1',
  DowngradeFee_12To_6Cycle_2 = 'downgrade_fee_12_to_6_cycle_2',
  DowngradeFee_12To_6Cycle_3 = 'downgrade_fee_12_to_6_cycle_3',
  DowngradeFee_12To_6Cycle_4 = 'downgrade_fee_12_to_6_cycle_4',
  DowngradeFee_12To_6Cycle_5 = 'downgrade_fee_12_to_6_cycle_5',
  FreeCgmReplacement = 'free_cgm_replacement',
  FreeSoftwareOnlyDppAnnualMembership = 'free_software_only_dpp_annual_membership',
  FreeSoftwareOnlyMedicalAnnualMembership = 'free_software_only_medical_annual_membership',
  FreeSoftwareOnlyOwnSensorAnnualMembership = 'free_software_only_own_sensor_annual_membership',
  FreeSoftwareOnlyVideoCallsAnnualMembership = 'free_software_only_video_calls_annual_membership',
  Influencer_1 = 'influencer_1',
  Influencer_2 = 'influencer_2',
  MedicalConsultAddon = 'medical_consult_addon',
  MedicalConsultLateCancellationFee = 'medical_consult_late_cancellation_fee',
  MedicalConsultLateRescheduleFee = 'medical_consult_late_reschedule_fee',
  MedicalConsultNoShowFee = 'medical_consult_no_show_fee',
  NonRecurring_1_16Rd = 'non_recurring_1_16_rd',
  NonRecurring_1_32Rd = 'non_recurring_1_32_rd',
  NonRecurring_1NoRd = 'non_recurring_1_no_rd',
  NonRecurring_2_32Rd = 'non_recurring_2_32_rd',
  NonRecurring_2Cgm_1Call = 'non_recurring_2_cgm_1_call',
  NonRecurring_2NoRd = 'non_recurring_2_no_rd',
  NonTaxablePrepaidNutrisenseMonthly_1 = 'non_taxable_prepaid_nutrisense_monthly_1',
  NonTaxablePrepaidNutrisenseMonthly_3 = 'non_taxable_prepaid_nutrisense_monthly_3',
  NonTaxablePrepaidNutrisenseMonthly_6 = 'non_taxable_prepaid_nutrisense_monthly_6',
  NonTaxablePrepaidNutrisenseMonthly_12 = 'non_taxable_prepaid_nutrisense_monthly_12',
  NutrisenseDietitian = 'nutrisense_dietitian',
  NutrisenseDietitianLimited = 'nutrisense_dietitian_limited',
  NutrisenseMonthly = 'nutrisense_monthly',
  NutrisenseMonthly_3 = 'nutrisense_monthly_3',
  NutrisenseMonthly_6 = 'nutrisense_monthly_6',
  NutrisenseMonthly_6BuyNowPayLater = 'nutrisense_monthly_6_buy_now_pay_later',
  NutrisenseMonthly_12 = 'nutrisense_monthly_12',
  NutrisenseMonthly_12BuyNowPayLater = 'nutrisense_monthly_12_buy_now_pay_later',
  NutrisensePeriodic_1_3 = 'nutrisense_periodic_1_3',
  NutrisensePeriodic_1_6 = 'nutrisense_periodic_1_6',
  NutrisensePeriodic_2_3 = 'nutrisense_periodic_2_3',
  NutrisensePeriodic_2_6 = 'nutrisense_periodic_2_6',
  NutrisenseSoftwareOnly = 'nutrisense_software_only',
  NutritionCoachFocused = 'nutrition_coach_focused',
  PaidCgmReplacement = 'paid_cgm_replacement',
  PaidDexcomPatch = 'paid_dexcom_patch',
  PaidPatch = 'paid_patch',
  PaidReshipCgmReplacement = 'paid_reship_cgm_replacement',
  Patch = 'patch',
  PrepaidNutrisenseMonthly_1 = 'prepaid_nutrisense_monthly_1',
  PrepaidNutrisenseMonthly_3 = 'prepaid_nutrisense_monthly_3',
  PrepaidNutrisenseMonthly_6 = 'prepaid_nutrisense_monthly_6',
  PrepaidNutrisenseMonthly_12 = 'prepaid_nutrisense_monthly_12',
  ReshipCgmReplacement = 'reship_cgm_replacement',
  ShippingFee = 'shipping_fee',
  SoftwareOnlyAnnualMembership = 'software_only_annual_membership',
  SoftwareOnlyOwnSensorAnnualMembership = 'software_only_own_sensor_annual_membership',
  SoftwareOnlyOwnSensorMonthlyMembership = 'software_only_own_sensor_monthly_membership',
  SupplementMg_2 = 'supplement_mg_2',
  SupplementMg_6 = 'supplement_mg_6',
  SupplementMvt_2 = 'supplement_mvt_2',
  SupplementMvt_6 = 'supplement_mvt_6',
  SupplementQ10_2 = 'supplement_q10_2',
  SupplementQ10_6 = 'supplement_q10_6',
  Tax = 'tax',
  Unknown = 'unknown',
  UpsellNutrisenseMonthly = 'upsell_nutrisense_monthly',
  UpsellNutrisenseMonthly_3 = 'upsell_nutrisense_monthly_3',
  UpsellNutrisenseMonthly_6 = 'upsell_nutrisense_monthly_6',
  VideoCallLateCancellationFee = 'video_call_late_cancellation_fee',
  VideoCallLateRescheduleFee = 'video_call_late_reschedule_fee',
  VideoCallNoShowFee = 'video_call_no_show_fee',
  WelcomeKit = 'welcome_kit'
}

export enum BillingProductCategory {
  ChurnRecoveryLearnedEverythingNeeded = 'churn_recovery_learned_everything_needed',
  ChurnRecoveryNotUsedOften = 'churn_recovery_not_used_often',
  ChurnRecoveryReachedHealthGoal = 'churn_recovery_reached_health_goal',
  ChurnRecoveryTooExpensive = 'churn_recovery_too_expensive',
  ChurnRecoveryUpsellAdhesiveSkinIrritation = 'churn_recovery_upsell_adhesive_skin_irritation',
  ChurnRecoveryUpsellDidNotLikeProduct = 'churn_recovery_upsell_did_not_like_product',
  ChurnRecoveryUpsellDidNotLikeSensor = 'churn_recovery_upsell_did_not_like_sensor',
  ChurnRecoveryUpsellLearnedEverythingNeeded = 'churn_recovery_upsell_learned_everything_needed',
  ChurnRecoveryUpsellNotUsedOften = 'churn_recovery_upsell_not_used_often',
  ChurnRecoveryUpsellOther = 'churn_recovery_upsell_other',
  ChurnRecoveryUpsellReachedHealthGoal = 'churn_recovery_upsell_reached_health_goal',
  ChurnRecoveryUpsellServiceNotValuable = 'churn_recovery_upsell_service_not_valuable',
  ChurnRecoveryUpsellTooExpensive = 'churn_recovery_upsell_too_expensive',
  Fee = 'fee',
  FreeTrial = 'free_trial',
  Glp = 'glp',
  Marketplace = 'marketplace',
  NutritionistHub = 'nutritionist_hub',
  Onboarding = 'onboarding'
}

export enum BillingProductGroupKind {
  Commitment = 'commitment',
  Dropdown = 'dropdown'
}

export type BiteAi = {
  __typename?: 'BiteAi'
  dailyValues: Array<KeyValuePairFloat>
  units: Array<BiteAiKeyValuePairString>
}

export type BiteAiKeyValuePairString = {
  __typename?: 'BiteAiKeyValuePairString'
  key: Scalars['String']
  value: Scalars['String']
}

export type BloodPressureMeasurement = {
  __typename?: 'BloodPressureMeasurement'
  diastolic: Scalars['Float']
  systolic: Scalars['Float']
}

export type BluetoothGlucoseData = {
  glucoseRaw: Scalars['Int']
  minutesSinceStart: Scalars['Int']
}

export type BluetoothScanData = {
  history: Array<BluetoothGlucoseData>
  serialNumber: Scalars['String']
}

export type CalendarDate = {
  __typename?: 'CalendarDate'
  date: Scalars['ISO8601Date']
  score?: Maybe<Scalars['Int']>
}

export type CalibrationInfo = {
  i1: Scalars['Float']
  i2: Scalars['Float']
  i3: Scalars['Float']
  i4: Scalars['Float']
  i5: Scalars['Float']
  i6: Scalars['Float']
}

export type Charge = {
  __typename?: 'Charge'
  amount: Scalars['Float']
  amountRefunded: Scalars['Float']
  created: Scalars['ISO8601DateTime']
  disputeStripeId?: Maybe<Scalars['String']>
  disputed: Scalars['Boolean']
  failureMessage?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invoice?: Maybe<Invoice>
  paid: Scalars['Boolean']
  providerPaymentMethodId?: Maybe<Scalars['String']>
  refundable: Scalars['Boolean']
  refunded: Scalars['Boolean']
  status: Scalars['String']
  stripeId: Scalars['String']
  user: User
}

export type Chart = {
  __typename?: 'Chart'
  description: Scalars['String']
  meta: ChartMeta
  range: ChartRange
  title: Scalars['String']
  type: Scalars['String']
  values: Array<ValuePair>
  xAxis: Scalars['String']
  yAxis: Scalars['String']
}

export type ChartData = {
  __typename?: 'ChartData'
  data: Array<ValuePair>
  key: Scalars['String']
  range?: Maybe<ChartRange>
  section: Scalars['String']
}

export type ChartFilter = {
  endDate: Scalars['String']
  period: Period
  startDate: Scalars['String']
  types: Array<KeyValueStringArray>
}

export type ChartMeta = {
  __typename?: 'ChartMeta'
  key?: Maybe<Scalars['String']>
  section?: Maybe<Scalars['String']>
  tag?: Maybe<Scalars['String']>
}

export type ChartRange = {
  __typename?: 'ChartRange'
  goal?: Maybe<Scalars['Float']>
  goalMax?: Maybe<Scalars['Float']>
  goalMin?: Maybe<Scalars['Float']>
  max?: Maybe<Scalars['Float']>
  min?: Maybe<Scalars['Float']>
  tickCount?: Maybe<Scalars['Int']>
}

export type Charts = {
  __typename?: 'Charts'
  charts: Array<Chart>
}

export type ChartsConfig = {
  __typename?: 'ChartsConfig'
  filters: ChartsFilters
}

export type ChartsData = {
  __typename?: 'ChartsData'
  values?: Maybe<Array<ChartData>>
}

export type ChartsFilters = {
  __typename?: 'ChartsFilters'
  types: Array<KeyValuePairStringArray>
}

export type ChatConversation = {
  __typename?: 'ChatConversation'
  assignee?: Maybe<User>
  candidateRead: Scalars['Boolean']
  id: Scalars['ID']
  intercomBackfillState?: Maybe<ChatConversationIntercomBackfillState>
  intercomCreatedAt: Scalars['ISO8601DateTime']
  intercomId: Scalars['String']
  kind: ChatConversationKind
  lastMessage?: Maybe<ChatConversationMessage>
  lastNote?: Maybe<ChatConversationNote>
  lastUserMessage?: Maybe<ChatConversationMessage>
  read: Scalars['Boolean']
  state: ChatConversationState
  user: User
}

export enum ChatConversationIntercomBackfillState {
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending'
}

export enum ChatConversationKind {
  Coach = 'coach',
  Support = 'support'
}

export type ChatConversationMessage = {
  __typename?: 'ChatConversationMessage'
  id: Scalars['ID']
  sender?: Maybe<User>
  senderKind: ChatConversationMessageSenderKind
  sentAt: Scalars['ISO8601DateTime']
  value: Scalars['String']
}

export type ChatConversationMessageCollection = {
  __typename?: 'ChatConversationMessageCollection'
  messages: Array<WebMembersChatConversationMessage>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export enum ChatConversationMessageSenderKind {
  Automated = 'automated',
  Unknown = 'unknown',
  User = 'user'
}

export type ChatConversationNote = {
  __typename?: 'ChatConversationNote'
  createdAt: Scalars['ISO8601DateTime']
  createdBy: User
  id: Scalars['ID']
  value: Scalars['String']
}

export enum ChatConversationState {
  Closed = 'closed',
  Open = 'open',
  Snoozed = 'snoozed'
}

export type Chatbot = {
  __typename?: 'Chatbot'
  lastShownCutoffAt: Scalars['ISO8601DateTime']
  responseTimeoutInSeconds: Scalars['Int']
}

export type ChecklistItem = {
  __typename?: 'ChecklistItem'
  completed: Scalars['Boolean']
  deepLink: Scalars['String']
  key: UserChecklistItem
  title: Scalars['String']
}

export type ChecklistItemCollection = {
  __typename?: 'ChecklistItemCollection'
  checklistItems: Array<ChecklistItem>
}

export type CheckoutOption = {
  __typename?: 'CheckoutOption'
  kind: SubscriptionCheckoutOptionKind
}

export type ClientConfig = {
  __typename?: 'ClientConfig'
  activityTypes: Array<Scalars['String']>
  appointmentKinds: Array<ClientConfigEhrAppointmentKind>
  appointmentTypes: Array<AppointmentType>
  biteAi: BiteAi
  caProvinces: Array<State>
  charts: ChartsConfig
  chatbot: Chatbot
  customIngredients: CustomIngredients
  dailyActions: Array<DailyActionKind>
  dayTabs: Array<KeyValuePairString>
  dexcomClientId: Scalars['String']
  dexcomSteloClientId: Scalars['String']
  education: Education
  events: Events
  featureFlags: Array<FeatureFlag>
  goalMetricOptions: Array<GoalMetricOption>
  goalOptions: Array<GoalOption>
  googleFit: GoogleFit
  healthData: HealthDataConfig
  healthKit: HealthKit
  healthMetrics: Array<HealthMetricType>
  healthSettings: HealthSettings
  /** @deprecated history is replaced by events in mobile app v3.7.2 */
  history: History
  insights: Config
  insuranceKinds: InsuranceKinds
  journal: JournalConfig
  mealTypes: Array<Scalars['String']>
  meals: Meals
  measurementTypes: Array<Scalars['String']>
  measurements: Array<MeasurementConfig>
  onboarding: Onboarding
  privacyCenterUrl: Scalars['String']
  questionnaireUrl: Scalars['String']
  referral: Referral
  reminders: Array<ReminderKind>
  remindersSettings: RemindersSettings
  researchFaqs: ResearchFaqs
  score: Score
  sensorSettings: SensorSettings
  settings: Settings
  signup: Signup
  stats: StatsConfig
  tickets: Tickets
  timeZones: Array<Scalars['String']>
  tutorials: Array<Tutorials>
  usStates: Array<State>
}

export type ClientConfigEhrAppointmentKind = {
  __typename?: 'ClientConfigEhrAppointmentKind'
  duration: Scalars['String']
  key: EhrAppointmentKind
  title: Scalars['String']
}

export type Coach = {
  __typename?: 'Coach'
  active: Scalars['Boolean']
  bio?: Maybe<Scalars['String']>
  coachId: Scalars['ID']
  credentials?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['ISO8601Date']>
  fullName: Scalars['String']
  getHealthieId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  kind: EhrAppointmentProviderKind
  manager?: Maybe<User>
  outOfOffice: Scalars['Boolean']
  photo?: Maybe<Scalars['String']>
  priorityChangedAt?: Maybe<Scalars['ISO8601Date']>
  schedulingPriority?: Maybe<Scalars['Int']>
  specialties: Array<EhrCoachSpecialtyDef>
  startDate?: Maybe<Scalars['ISO8601Date']>
  stateLicences: Array<Scalars['String']>
  updatedAt: Scalars['ISO8601Date']
}

export type CoachAssignment = {
  __typename?: 'CoachAssignment'
  appointment?: Maybe<Appointment>
  assignedAt?: Maybe<Scalars['ISO8601DateTime']>
  canSendEngagementMessage: Scalars['Boolean']
  coach?: Maybe<User>
  createdAt: Scalars['ISO8601DateTime']
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']>
  dietitianMenu: Scalars['Boolean']
  id: Scalars['ID']
  kind?: Maybe<CoachAssignmentKind>
  last: Scalars['Boolean']
  lastCoachMessageDate?: Maybe<Scalars['ISO8601DateTime']>
  state: CoachAssignmentState
  supportType: CoachAssignmentSupportType
  user: User
}

export type CoachAssignmentCollection = {
  __typename?: 'CoachAssignmentCollection'
  coachAssignments: Array<CoachAssignment>
}

export type CoachAssignmentInfo = {
  __typename?: 'CoachAssignmentInfo'
  activeAssignmentsCount: Scalars['Int']
  coachId: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['ISO8601DateTime']
}

export enum CoachAssignmentKind {
  AnnualMembership = 'annual_membership',
  CoachingPlan = 'coaching_plan',
  Fixed_8Weeks = 'fixed_8_weeks',
  Influencer = 'influencer',
  NoCoreSubscription = 'no_core_subscription',
  NonRecurring = 'non_recurring',
  Recurring_12Weeks = 'recurring_12_weeks',
  Regular = 'regular'
}

export enum CoachAssignmentState {
  Active = 'active',
  Inactive = 'inactive',
  Paused = 'paused',
  Pending = 'pending'
}

export enum CoachAssignmentSupportType {
  AsyncChat = 'async_chat',
  MealPlan = 'meal_plan',
  SupplementReview = 'supplement_review',
  VideoCall = 'video_call',
  VideoChat = 'video_chat'
}

export type CoachProfile = {
  __typename?: 'CoachProfile'
  /** @deprecated Use `state` instead */
  active: Scalars['Boolean']
  bio?: Maybe<Scalars['String']>
  coachId: Scalars['ID']
  credentials?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['ISO8601Date']>
  fullName: Scalars['String']
  getHealthieId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  manager?: Maybe<User>
  outOfOffice: Scalars['Boolean']
  photo?: Maybe<Scalars['String']>
  premium: Scalars['Boolean']
  priorityChangedAt?: Maybe<Scalars['ISO8601Date']>
  schedulingPriority?: Maybe<Scalars['Int']>
  specialties: Array<EhrCoachSpecialtyDef>
  startDate?: Maybe<Scalars['ISO8601Date']>
  state: EhrCoachState
  stateLicences: Array<Scalars['String']>
  updatedAt: Scalars['ISO8601Date']
}

export type Cohort = {
  __typename?: 'Cohort'
  id: Scalars['ID']
  instructor?: Maybe<CoachProfile>
  name: Scalars['String']
  sessions: Array<CohortSession>
  state: CoursesCohortState
}

export type CohortCollection = {
  __typename?: 'CohortCollection'
  cohorts: Array<Cohort>
}

export type CohortSession = {
  __typename?: 'CohortSession'
  attendance?: Maybe<CohortSessionAttendance>
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  joinUrl?: Maybe<Scalars['String']>
  kind: CoursesCohortSessionKind
  name: Scalars['String']
  position: Scalars['Int']
  scheduledAt: Scalars['ISO8601DateTime']
  scheduledEndAt: Scalars['ISO8601DateTime']
}

export type CohortSessionAttendance = {
  __typename?: 'CohortSessionAttendance'
  id: Scalars['ID']
  rsvp: CoursesCohortSessionAttendanceRsvp
  state: CoursesCohortSessionAttendanceState
}

export type CompletedScreen = {
  __typename?: 'CompletedScreen'
  footnote?: Maybe<Scalars['String']>
  header: Scalars['String']
  icon: UiIcon
  /** @deprecated use intro_markdown */
  intro?: Maybe<Scalars['String']>
  introMarkdown?: Maybe<Scalars['String']>
  primaryActionText?: Maybe<Scalars['String']>
  secondaryActionLink?: Maybe<Link>
  title?: Maybe<Scalars['String']>
}

export type Config = {
  __typename?: 'Config'
  filters: StatsFilters
  statistics: Array<Statistics>
}

export type ConfigUnit = {
  __typename?: 'ConfigUnit'
  precision: Scalars['Int']
  ranges: Array<RangeUnit>
  units: Scalars['String']
}

export enum CoursesCohortKind {
  DpWebinar = 'dp_webinar',
  Dpp2024 = 'dpp2024'
}

export enum CoursesCohortSessionAttendanceRsvp {
  Awaiting = 'awaiting',
  Confirmed = 'confirmed',
  Declined = 'declined'
}

export enum CoursesCohortSessionAttendanceState {
  Attended = 'attended',
  Excluded = 'excluded',
  Pending = 'pending',
  Unattended = 'unattended'
}

export enum CoursesCohortSessionKind {
  DpWebinarModule1 = 'dp_webinar_module1',
  Dpp2024Module1 = 'dpp2024_module1',
  Dpp2024Module2 = 'dpp2024_module2',
  Dpp2024Module3 = 'dpp2024_module3',
  Dpp2024Module4 = 'dpp2024_module4',
  Dpp2024Module5 = 'dpp2024_module5',
  Dpp2024Module6 = 'dpp2024_module6',
  Dpp2024Module7 = 'dpp2024_module7',
  Dpp2024Module8 = 'dpp2024_module8',
  Dpp2024Module9 = 'dpp2024_module9',
  Dpp2024Module10 = 'dpp2024_module10',
  Dpp2024Module11 = 'dpp2024_module11',
  Dpp2024Module12 = 'dpp2024_module12',
  Dpp2024Module13 = 'dpp2024_module13',
  Dpp2024Module14 = 'dpp2024_module14',
  Dpp2024Module15 = 'dpp2024_module15',
  Dpp2024Module16 = 'dpp2024_module16',
  Dpp2024Module17 = 'dpp2024_module17',
  Dpp2024Module18 = 'dpp2024_module18',
  Dpp2024Module19 = 'dpp2024_module19',
  Dpp2024Module20 = 'dpp2024_module20',
  Dpp2024Module21 = 'dpp2024_module21',
  Dpp2024Module22 = 'dpp2024_module22',
  Dpp2024Module23 = 'dpp2024_module23',
  Dpp2024Module24 = 'dpp2024_module24',
  Dpp2024Module25 = 'dpp2024_module25',
  Dpp2024Module26 = 'dpp2024_module26'
}

export enum CoursesCohortState {
  Draft = 'draft',
  Live = 'live',
  Stopped = 'stopped'
}

/** Autogenerated return type of CreateAction. */
export type CreateActionPayload = {
  __typename?: 'CreateActionPayload'
  success: Scalars['Boolean']
}

export type CreatedOneTimePayment = {
  __typename?: 'CreatedOneTimePayment'
  invoice: Invoice
}

export type CreatedSubscription = {
  __typename?: 'CreatedSubscription'
  clientSecret?: Maybe<Scalars['String']>
  providerSubscriptionId: Scalars['String']
  subscription: Subscription
}

export type CustomIngredients = {
  __typename?: 'CustomIngredients'
  macros: Array<Macro>
}

export type DailyAction = {
  __typename?: 'DailyAction'
  autocomplete: Scalars['Boolean']
  category: UserDailyActionCategory
  completionsCount?: Maybe<Scalars['Int']>
  createdAt: Scalars['ISO8601DateTime']
  description: Scalars['String']
  icon: UiIcon
  id: Scalars['ID']
  kind: UserDailyActionKind
  progress: Scalars['Int']
  statKey?: Maybe<Scalars['String']>
  status: UserDailyActionStatus
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type DailyActionCollection = {
  __typename?: 'DailyActionCollection'
  completionCounts: Array<DailyActionCompletionsCount>
  dailyActions: Array<DailyAction>
}

export type DailyActionCompletionsCount = {
  __typename?: 'DailyActionCompletionsCount'
  completionCount: Scalars['Int']
  dailyActionId: Scalars['Int']
}

export type DailyActionKind = {
  __typename?: 'DailyActionKind'
  autocomplete: Scalars['Boolean']
  category: UserDailyActionCategory
  description: Scalars['String']
  key: Scalars['String']
  title: Scalars['String']
}

export type DailyMeasurementsSourcePriority = {
  __typename?: 'DailyMeasurementsSourcePriority'
  sources: Array<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type DailyScore = {
  __typename?: 'DailyScore'
  score?: Maybe<Scalars['Int']>
  scoreMean?: Maybe<Scalars['Int']>
  scorePeak?: Maybe<Scalars['Int']>
  scoreStdDev?: Maybe<Scalars['Int']>
  scoreTimeOutsideRange?: Maybe<Scalars['Int']>
}

export type Date = {
  __typename?: 'Date'
  date: Scalars['ISO8601Date']
  slots: Array<Slot>
}

export type DateFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export type Dates = {
  __typename?: 'Dates'
  dates: Array<Scalars['ISO8601Date']>
}

export type DatesWithSlots = {
  __typename?: 'DatesWithSlots'
  dates: Array<EhrDate>
  providers: Array<EhrProviderKind>
}

export type Delimiter = {
  __typename?: 'Delimiter'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
}

export enum DexcomConnectionKind {
  Research = 'research',
  Stelo = 'stelo'
}

export enum DexcomConnectionStatus {
  Active = 'active',
  Invalidated = 'invalidated'
}

export type DexcomConnectionType = {
  __typename?: 'DexcomConnectionType'
  accessToken: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  dexcomUserId?: Maybe<Scalars['String']>
  lastSyncedAt?: Maybe<Scalars['ISO8601DateTime']>
  status: DexcomConnectionStatus
}

export enum DexcomResearchStatus {
  AcceptedSensor = 'accepted_sensor',
  CheckoutAcceptedSensor = 'checkout_accepted_sensor',
  CheckoutDeclinedSensor = 'checkout_declined_sensor',
  ConsentAccepted = 'consent_accepted',
  ConsentWithdrawn = 'consent_withdrawn',
  ConsentWithdrawnAdmin = 'consent_withdrawn_admin',
  DeclinedResearchConsent = 'declined_research_consent',
  DeclinedSensor = 'declined_sensor',
  Enrolled = 'enrolled',
  EnteredEligiblePlan = 'entered_eligible_plan',
  MedicalHistoryAccepted = 'medical_history_accepted',
  MedicalHistoryRejected = 'medical_history_rejected',
  NotInEligiblePlan = 'not_in_eligible_plan',
  Participating = 'participating'
}

export type DirectUpload = {
  __typename?: 'DirectUpload'
  headers: Array<KeyValuePairString>
  signedId: Scalars['String']
  url: Scalars['String']
}

export type Doctor = {
  __typename?: 'Doctor'
  avatar?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  credentials?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  getHealthieId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  kind: EhrAppointmentProviderKind
  lastName?: Maybe<Scalars['String']>
  state: EhrDoctorState
}

export type DoctorAssignment = {
  __typename?: 'DoctorAssignment'
  doctor: Doctor
  id: Scalars['ID']
  state: EhrDoctorAssignmentState
}

export type DppHealthDataRecordChecklist = {
  __typename?: 'DppHealthDataRecordChecklist'
  requiresPhotoUpload: Scalars['Boolean']
  state: DppHealthDataRecordChecklistState
}

export enum DppHealthDataRecordChecklistState {
  Done = 'done',
  Optional = 'optional',
  Pending = 'pending',
  Required = 'required'
}

export enum DppHealthDataRecordKind {
  HbA1c = 'hb_a1c',
  PhysicalActivity = 'physical_activity',
  Weight = 'weight'
}

export type DppHealthDataRecordsChecklist = {
  __typename?: 'DppHealthDataRecordsChecklist'
  baselineChecklist: DppHealthDataRecordChecklist
  hbA1cChecklist: DppHealthDataRecordChecklist
  physicalActivityChecklist: DppHealthDataRecordChecklist
  weightChecklist: DppHealthDataRecordChecklist
}

export enum DynamicFilterItemOperation {
  Blank = 'blank',
  Cont = 'cont',
  ContAll = 'cont_all',
  ContAny = 'cont_any',
  DoesNotMatch = 'does_not_match',
  DoesNotMatchAll = 'does_not_match_all',
  DoesNotMatchAny = 'does_not_match_any',
  End = 'end',
  EndAll = 'end_all',
  EndAny = 'end_any',
  Eq = 'eq',
  Gt = 'gt',
  GtAll = 'gt_all',
  GtAny = 'gt_any',
  Gteq = 'gteq',
  GteqAll = 'gteq_all',
  GteqAny = 'gteq_any',
  ICont = 'i_cont',
  IContAll = 'i_cont_all',
  IContAny = 'i_cont_any',
  In = 'in',
  IsNull = 'is_null',
  Lt = 'lt',
  LtAll = 'lt_all',
  LtAny = 'lt_any',
  Lteq = 'lteq',
  LteqAll = 'lteq_all',
  LteqAny = 'lteq_any',
  Matches = 'matches',
  MatchesAll = 'matches_all',
  MatchesAny = 'matches_any',
  NotCont = 'not_cont',
  NotContAll = 'not_cont_all',
  NotContAny = 'not_cont_any',
  NotEnd = 'not_end',
  NotEndAll = 'not_end_all',
  NotEndAny = 'not_end_any',
  NotEq = 'not_eq',
  NotEqAll = 'not_eq_all',
  NotICont = 'not_i_cont',
  NotIContAll = 'not_i_cont_all',
  NotIContAny = 'not_i_cont_any',
  NotIn = 'not_in',
  NotNull = 'not_null',
  NotStart = 'not_start',
  NotStartAll = 'not_start_all',
  NotStartAny = 'not_start_any',
  Present = 'present',
  Start = 'start',
  StartAll = 'start_all',
  StartAny = 'start_any'
}

export enum DynamicFilterOperator {
  And = 'and',
  Or = 'or'
}

export type Education = {
  __typename?: 'Education'
  collections: Array<EducationCollection>
}

export type EducationArticle = {
  __typename?: 'EducationArticle'
  author: Scalars['String']
  imageUrl: Scalars['String']
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
}

export type EducationCollection = {
  __typename?: 'EducationCollection'
  articles: Array<EducationArticle>
  imageUrl: Scalars['String']
  subtitle: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
}

export type EffectiveDailyMeasurement = {
  __typename?: 'EffectiveDailyMeasurement'
  availableMeasurements: Array<Measurement>
  date: Scalars['ISO8601Date']
  defaultSource?: Maybe<HealthDataMeasurementSource>
  selectedSource?: Maybe<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type EhrAppointment = {
  __typename?: 'EhrAppointment'
  addToGcalLink?: Maybe<Scalars['String']>
  attendances: Array<EhrAppointmentAttendance>
  category: EhrAppointmentCategory
  coachRating?: Maybe<Scalars['Int']>
  getHealthieId?: Maybe<Scalars['ID']>
  getHealthieInstanceKind: EhrGetHealthieInstanceKind
  id: Scalars['ID']
  joinUrl?: Maybe<Scalars['String']>
  kind: EhrAppointmentKind
  lastState: EhrAppointmentState
  meetingAt?: Maybe<Scalars['ISO8601DateTime']>
  providerName?: Maybe<Scalars['String']>
  providers: Array<EhrProvider>
  recurrent: Scalars['Boolean']
  startUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']>
}

export type EhrAppointmentAttendance = {
  __typename?: 'EhrAppointmentAttendance'
  agenda?: Maybe<Scalars['String']>
  chargeKind: EhrAppointmentAttendanceChargeKind
  id: Scalars['ID']
  insurancePolicy?: Maybe<InsurancePolicy>
  state: EhrAppointmentAttendanceState
  user: User
}

export enum EhrAppointmentAttendanceChargeKind {
  BsFree = 'bs_free',
  BsInsuranceCovered = 'bs_insurance_covered',
  BsPaid = 'bs_paid',
  NsPaid = 'ns_paid'
}

export enum EhrAppointmentAttendanceState {
  Attended = 'attended',
  Pending = 'pending',
  Unattended = 'unattended'
}

export enum EhrAppointmentCategory {
  Coaching = 'coaching',
  Dpp = 'dpp',
  GroupMnt = 'group_mnt',
  Medical = 'medical'
}

export type EhrAppointmentCollection = {
  __typename?: 'EhrAppointmentCollection'
  appointments: Array<EhrAppointment>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export enum EhrAppointmentKind {
  DpWebinarSession = 'dp_webinar_session',
  DppSession = 'dpp_session',
  Followup = 'followup',
  Followup_30 = 'followup_30',
  Followup_45 = 'followup_45',
  Followup_90 = 'followup_90',
  GlpSession = 'glp_session',
  GlpSession_10 = 'glp_session_10',
  Initial = 'initial',
  Initial_60 = 'initial_60',
  NoneCovered = 'none_covered'
}

export enum EhrAppointmentProviderKind {
  Coach = 'coach',
  Doctor = 'doctor'
}

export enum EhrAppointmentProviderState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum EhrAppointmentReason {
  BillingSideEffect = 'billing_side_effect',
  CoachesBillableMissingData = 'coaches_billable_missing_data',
  Console = 'console',
  GetHealthie = 'get_healthie',
  GlpIntakeSurveyRestarted = 'glp_intake_survey_restarted',
  InsuranceDomainMigration = 'insurance_domain_migration',
  InsuranceSideEffect = 'insurance_side_effect',
  OpsAccountRequest = 'ops_account_request',
  PaymentMethodMissing = 'payment_method_missing',
  RecurrenceDelete = 'recurrence_delete',
  RecurrenceUpsert = 'recurrence_upsert',
  StaleFreePending = 'stale_free_pending',
  UpcomingAppointmentRefresh = 'upcoming_appointment_refresh',
  User = 'user'
}

export type EhrAppointmentRecurrence = {
  __typename?: 'EhrAppointmentRecurrence'
  cadence: EhrAppointmentRecurrenceCadence
  days: Array<EhrAppointmentRecurrenceDay>
  id: Scalars['ID']
  numberOfCalls: Scalars['Int']
  timeSlots?: Maybe<Array<Scalars['ISO8601DateTime']>>
}

export enum EhrAppointmentRecurrenceCadence {
  BiWeekly = 'bi_weekly',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export enum EhrAppointmentRecurrenceDay {
  All = 'all',
  Friday = 'friday',
  Monday = 'monday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type EhrAppointmentSort = {
  direction: SortDirection
  field: AppointmentEhrSortField
}

export type EhrAppointmentState = {
  __typename?: 'EhrAppointmentState'
  appointmentId: Scalars['ID']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  kind: EhrAppointmentStateKind
  reason: EhrAppointmentReason
}

export enum EhrAppointmentStateKind {
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  InReview = 'in_review',
  LateCancellation = 'late_cancellation',
  LateReschedule = 'late_reschedule',
  NoShow = 'no_show',
  Occurred = 'occurred',
  PendingReschedule = 'pending_reschedule',
  PendingSchedule = 'pending_schedule',
  Rescheduled = 'rescheduled',
  Scheduled = 'scheduled'
}

export type EhrAvailability = {
  __typename?: 'EhrAvailability'
  appointmentType: InsuranceBerryStreetAppointmentType
  dayOfWeek: Scalars['Int']
  rangeEndAt: Scalars['ISO8601DateTime']
  rangeStartAt: Scalars['ISO8601DateTime']
}

export enum EhrChartNoteKind {
  CheckIn = 'check_in',
  CheckInAddendum = 'check_in_addendum',
  MedicalIntakeForm = 'medical_intake_form',
  RenewalIntakeForm = 'renewal_intake_form'
}

export enum EhrCoachSpecialty {
  AutoimmuneConditions = 'autoimmune_conditions',
  Bariatric = 'bariatric',
  Cancer = 'cancer',
  ChronicFatigue = 'chronic_fatigue',
  EatingDisorders = 'eating_disorders',
  Fertility = 'fertility',
  FoodAllergies = 'food_allergies',
  GutHealth = 'gut_health',
  HealthOptimization = 'health_optimization',
  HealthyAging = 'healthy_aging',
  HeartDisease = 'heart_disease',
  MensHealth = 'mens_health',
  MindfulEating = 'mindful_eating',
  PrediabetesDiabetes = 'prediabetes_diabetes',
  PrenatalNutrition = 'prenatal_nutrition',
  SportsPerformance = 'sports_performance',
  ThyroidDisorders = 'thyroid_disorders',
  WeightConcerns = 'weight_concerns',
  WomensHealth = 'womens_health'
}

export type EhrCoachSpecialtyDef = {
  __typename?: 'EhrCoachSpecialtyDef'
  key: EhrCoachSpecialty
  name: Scalars['String']
}

export enum EhrCoachState {
  Active = 'active',
  Inactive = 'inactive'
}

export type EhrDate = {
  __typename?: 'EhrDate'
  date: Scalars['ISO8601Date']
  slots: Array<EhrSlot>
}

export enum EhrDoctorAssignmentState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum EhrDoctorState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum EhrGetHealthieInstanceKind {
  BerryStreet = 'berry_street',
  Nutrisense = 'nutrisense'
}

export type EhrProvider = {
  __typename?: 'EhrProvider'
  appointment: EhrAppointment
  coach?: Maybe<Coach>
  doctor?: Maybe<Doctor>
  id: Scalars['ID']
  kind: EhrAppointmentProviderKind
  state: EhrAppointmentProviderState
}

export type EhrProviderKind = Coach | Doctor

export type EhrSlot = {
  __typename?: 'EhrSlot'
  providerId: Scalars['ID']
  providerUserId?: Maybe<Scalars['ID']>
  time: Scalars['ISO8601DateTime']
}

export type EntryGlucose = {
  __typename?: 'EntryGlucose'
  healthyRange: Array<Scalars['Float']>
  range: Array<Scalars['Float']>
  values: Array<Measurement>
}

export type EntryStatistics = {
  __typename?: 'EntryStatistics'
  aucScore?: Maybe<Scalars['Float']>
  glucose2hrDelta?: Maybe<Scalars['Float']>
  glucoseDelta?: Maybe<Scalars['Float']>
  glucoseMax?: Maybe<Scalars['String']>
  glucoseMin?: Maybe<Scalars['String']>
  glucoseScore?: Maybe<Scalars['Int']>
  glucoseScore2hrDelta?: Maybe<Scalars['Int']>
  glucoseScoreAuc?: Maybe<Scalars['Int']>
  glucoseScoreDelta?: Maybe<Scalars['Int']>
  glucoseScorePeak?: Maybe<Scalars['Int']>
}

export type EstimatedTax = {
  __typename?: 'EstimatedTax'
  estimatedTax: Scalars['Float']
}

export type Event = Activity | JournalEntry | Meal | Measurement | Note

export type EventCollection = {
  __typename?: 'EventCollection'
  events: Array<Event>
}

export type Events = {
  __typename?: 'Events'
  filters: EventsFilters
}

export type EventsData = {
  __typename?: 'EventsData'
  events: EventCollection
}

export type EventsFilters = {
  __typename?: 'EventsFilters'
  order: Array<Scalars['String']>
  orderBy: Array<Scalars['String']>
  types: Array<KeyValuePairStringArray>
}

export type ExpandableModule = {
  __typename?: 'ExpandableModule'
  contentId: Scalars['ID']
  id: Scalars['ID']
  lessons?: Maybe<Array<Lesson>>
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<Progress>
}

export type ExpandableProgram = {
  __typename?: 'ExpandableProgram'
  contentId: Scalars['ID']
  id: Scalars['ID']
  modules?: Maybe<Array<ExpandableModule>>
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<Progress>
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag'
  enabled: Scalars['Boolean']
  key: Scalars['String']
}

export type File = {
  __typename?: 'File'
  createdAt: Scalars['ISO8601DateTime']
  downloadFileUrl: Scalars['String']
  fileName: Scalars['String']
  fileType: Scalars['String']
  fileUrl: Scalars['String']
  id: Scalars['ID']
  permanentUrl: Scalars['String']
  tag?: Maybe<UserFileTag>
}

export type FileDynamicFilter = {
  field: FileDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum FileDynamicFilterField {
  Tag = 'tag'
}

export type FileDynamicFilterItem = {
  items: Array<FileDynamicFilter>
  operator: DynamicFilterOperator
}

export type FileMetadata = {
  byteSize: Scalars['Int']
  checksum: Scalars['String']
  contentType: Scalars['String']
  filename: Scalars['String']
}

export type FileSort = {
  direction: SortDirection
  field: FileSortField
}

export enum FileSortField {
  CreatedAt = 'created_at'
}

export enum FilterAppointmentsKind {
  /** Fetch all appointments */
  All = 'ALL',
  /** Fetch past appointments */
  Past = 'PAST',
  /** Fetch pending schedule appointments */
  PendingSchedule = 'PENDING_SCHEDULE',
  /** Fetch upcoming appointments */
  Upcoming = 'UPCOMING'
}

export type Followup = {
  __typename?: 'Followup'
  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
  parentAnswer: Answer
  parentQuestionKey: SurveysQuestionKey
  question: Question
}

export type FulfillmentConfiguration = {
  __typename?: 'FulfillmentConfiguration'
  sensorKind: UserSensorKind
  userId: Scalars['ID']
}

export type GenerateAuthUrl = {
  __typename?: 'GenerateAuthUrl'
  url: Scalars['String']
  userId: Scalars['String']
}

export type GenerateSdkToken = {
  __typename?: 'GenerateSdkToken'
  token: Scalars['String']
}

export type GetHealthieApiKey = {
  __typename?: 'GetHealthieApiKey'
  apiKey?: Maybe<Scalars['String']>
}

export type GlucoseDailyScore = {
  __typename?: 'GlucoseDailyScore'
  today: DailyScore
}

export type GlucoseData = {
  error?: InputMaybe<Scalars['Int']>
  glucoseCalibrated?: InputMaybe<Scalars['Int']>
  glucoseRaw: Scalars['Int']
  hasError?: InputMaybe<Scalars['Boolean']>
  sampleTime: Scalars['Int']
  temperature?: InputMaybe<Scalars['Int']>
  temperatureAdjustment?: InputMaybe<Scalars['Int']>
  timeSinceStart?: InputMaybe<Scalars['Int']>
}

export type GlucoseStatistics = {
  __typename?: 'GlucoseStatistics'
  today: Stat
}

export type GlucoseThresholdPreset = {
  __typename?: 'GlucoseThresholdPreset'
  description: Scalars['String']
  high: Scalars['Int']
  low: Scalars['Int']
  name: Scalars['String']
}

export type Goal = {
  __typename?: 'Goal'
  description?: Maybe<Scalars['String']>
  kind: UserGoalKind
  title: Scalars['String']
}

export type GoalMetric = {
  __typename?: 'GoalMetric'
  highThresholdValue?: Maybe<Scalars['Float']>
  kind: UserGoalMetricKind
  lowThresholdValue?: Maybe<Scalars['Float']>
  primary: Scalars['Boolean']
  section: Scalars['String']
  statKey: Scalars['String']
}

export type GoalMetricOption = {
  __typename?: 'GoalMetricOption'
  text: Scalars['String']
  value: UserGoalMetricKind
}

export type GoalOption = {
  __typename?: 'GoalOption'
  text: Scalars['String']
  value: UserGoalKind
}

export type GoogleFit = {
  __typename?: 'GoogleFit'
  ignore: Array<Scalars['String']>
}

export type HealthDataConfig = {
  __typename?: 'HealthDataConfig'
  measurementSourceDefs: Array<HealthDataMeasurementSourceDef>
  measurementTypeDefs: Array<HealthDataMeasurementTypeDef>
}

export enum HealthDataMeasurementSource {
  Dexcom = 'dexcom',
  Fitbit = 'fitbit',
  Freestylelibre = 'freestylelibre',
  Garmin = 'garmin',
  Googlefit = 'googlefit',
  Healthkit = 'healthkit',
  Ketomojo = 'ketomojo',
  LibreLinkup = 'libre_linkup',
  Nutrisense = 'nutrisense',
  Oura = 'oura',
  Whoop = 'whoop'
}

export type HealthDataMeasurementSourceDef = {
  __typename?: 'HealthDataMeasurementSourceDef'
  iconDef?: Maybe<IconDef>
  key: HealthDataMeasurementSource
  label: Scalars['String']
  providesDailyMeasurements: Scalars['Boolean']
  sourceTags: Array<Scalars['String']>
}

export enum HealthDataMeasurementType {
  Appetite = 'appetite',
  BloodGlucose = 'blood_glucose',
  BloodKetones = 'blood_ketones',
  BloodPressure = 'blood_pressure',
  BodyFatPercentage = 'body_fat_percentage',
  BodyTemperature = 'body_temperature',
  BreathKetones = 'breath_ketones',
  CyclingDistance = 'cycling_distance',
  Energy = 'energy',
  ExerciseTime = 'exercise_time',
  FlightsClimbed = 'flights_climbed',
  Focus = 'focus',
  Glucose = 'glucose',
  Mood = 'mood',
  RunningDistance = 'running_distance',
  StepCount = 'step_count',
  Stress = 'stress',
  SwimmingDistance = 'swimming_distance',
  Weight = 'weight'
}

export type HealthDataMeasurementTypeDef = {
  __typename?: 'HealthDataMeasurementTypeDef'
  icon?: Maybe<UiIcon>
  /** @deprecated Use icon instead */
  iconDef?: Maybe<IconDef>
  isDailyMeasurement: Scalars['Boolean']
  key: HealthDataMeasurementType
  label: Scalars['String']
}

export enum HealthDataMetricBiomarker {
  Bmi = 'bmi',
  FastedGlucose = 'fasted_glucose',
  FastedInsulin = 'fasted_insulin',
  HbA1c = 'hb_a1c',
  HdlCholesterol = 'hdl_cholesterol',
  Height = 'height',
  HsCrp = 'hs_crp',
  LdlCholesterol = 'ldl_cholesterol',
  TotalCholesterol = 'total_cholesterol',
  Triglycerides = 'triglycerides',
  Tsh = 'tsh',
  VitaminD = 'vitamin_d'
}

export type HealthKit = {
  __typename?: 'HealthKit'
  ignore: Array<Scalars['String']>
  sourceIgnore: Array<Scalars['String']>
}

export type HealthKitData = {
  appleExerciseTime?: InputMaybe<Array<NumericValue>>
  bloodGlucose?: InputMaybe<Array<NumericValue>>
  bloodPressure?: InputMaybe<Array<NumericValue>>
  bodyFatPercentage?: InputMaybe<Array<NumericValue>>
  bodyTemperature?: InputMaybe<Array<NumericValue>>
  dailyDistanceCycling?: InputMaybe<Array<NumericValue>>
  dailyDistanceSwimming?: InputMaybe<Array<NumericValue>>
  dailyDistanceWalkingRunning?: InputMaybe<Array<NumericValue>>
  dailyFlightsClimbed?: InputMaybe<Array<NumericValue>>
  dailyStepCount?: InputMaybe<Array<NumericValue>>
  heartRate?: InputMaybe<Array<NumericValue>>
  respiratoryRate?: InputMaybe<Array<NumericValue>>
  sleep?: InputMaybe<Array<StringValue>>
  source?: InputMaybe<Scalars['String']>
  weight?: InputMaybe<Array<NumericValue>>
  workout?: InputMaybe<Array<Workout>>
}

export type HealthMetric = {
  __typename?: 'HealthMetric'
  date: Scalars['ISO8601Date']
  id: Scalars['ID']
  kind: HealthDataMetricBiomarker
  value: Scalars['Float']
}

export type HealthMetricCollection = {
  __typename?: 'HealthMetricCollection'
  healthMetrics: Array<HealthMetric>
  measurements: Array<Measurement>
}

export type HealthMetricData = {
  kind: HealthDataMetricBiomarker
  value: Scalars['Float']
}

export type HealthMetricType = {
  __typename?: 'HealthMetricType'
  key: HealthMetricsParam
  kind: HealthMetricsKind
  label: Scalars['String']
  unit?: Maybe<Scalars['String']>
}

export enum HealthMetricsKind {
  Biomarker = 'biomarker',
  Measurement = 'measurement'
}

export enum HealthMetricsParam {
  BloodPressure = 'blood_pressure',
  Bmi = 'bmi',
  FastedGlucose = 'fasted_glucose',
  FastedInsulin = 'fasted_insulin',
  HbA1c = 'hb_a1c',
  HdlCholesterol = 'hdl_cholesterol',
  Height = 'height',
  HsCrp = 'hs_crp',
  LdlCholesterol = 'ldl_cholesterol',
  TotalCholesterol = 'total_cholesterol',
  Triglycerides = 'triglycerides',
  Tsh = 'tsh',
  VitaminD = 'vitamin_d',
  Weight = 'weight'
}

export type HealthSettings = {
  __typename?: 'HealthSettings'
  dashboardGraphOptions: Array<StringSelect>
  fastingEnd: Scalars['Int']
  fastingStart: Scalars['Int']
  glucoseHighOptions: Array<NumericalSelect>
  glucoseLowOptions: Array<NumericalSelect>
  glucoseSourceOptions: Array<StringSelect>
  glucoseThresholdPresets: Array<GlucoseThresholdPreset>
  ketoneHighOptions: Array<NumericalSelect>
  ketoneLowOptions: Array<NumericalSelect>
  macroGoalSettings: MacroGoalSettings
  scanReminderOptions: Array<NumericalSelect>
  trackingModeOptions: Array<StringSelect>
  unitSystemOptions: Array<StringSelect>
}

export type History = {
  __typename?: 'History'
  filters: HistoryFilters
}

export type HistoryFilters = {
  __typename?: 'HistoryFilters'
  order: Array<Scalars['String']>
  orderBy: Array<Scalars['String']>
  types: Array<KeyValuePairStringArray>
}

export type HoursInterval = {
  __typename?: 'HoursInterval'
  glucoseCheckOptions: Array<NumericalSelect>
}

export type IconDef = {
  __typename?: 'IconDef'
  name: Scalars['String']
  pack: Scalars['String']
}

export type ImageDescription = {
  __typename?: 'ImageDescription'
  description: Scalars['String']
}

export type Ingredient = {
  __typename?: 'Ingredient'
  calories: Scalars['Float']
  createdAt: Scalars['ISO8601DateTime']
  description: Scalars['String']
  id: Scalars['ID']
  nutrition: Array<KeyValuePairFloat>
  servingAmount: Scalars['Float']
  servingUnits: Scalars['String']
  thirdPartyIngredientId?: Maybe<Scalars['String']>
  thirdPartyIngredientSource?: Maybe<IngredientSource>
}

export type IngredientCollection = {
  __typename?: 'IngredientCollection'
  ingredients: Array<Ingredient>
}

export type IngredientData = {
  calories: Scalars['Float']
  description: Scalars['String']
  ingredientId?: InputMaybe<Scalars['ID']>
  mealId?: InputMaybe<Scalars['ID']>
  nutrition: Array<KeyValueFloat>
  servingAmount: Scalars['Float']
  servingUnits: Scalars['String']
  thirdPartyIngredientId?: InputMaybe<Scalars['String']>
  thirdPartyIngredientSource?: InputMaybe<IngredientSource>
}

export type IngredientFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export enum IngredientSource {
  BiteAi = 'biteAI',
  Myfitnesspal = 'myfitnesspal',
  Nutritionix = 'nutritionix'
}

export type InlineAlert = {
  __typename?: 'InlineAlert'
  message: Scalars['String']
  type: UiInlineAlert
}

export enum InsuranceBerryStreetAppointmentChargeType {
  Free = 'free',
  InsuranceCovered = 'insurance_covered',
  Paid = 'paid'
}

export enum InsuranceBerryStreetAppointmentMeetingStatus {
  Cancelled = 'cancelled',
  LateCancellation = 'late_cancellation',
  NoShow = 'no_show',
  Occurred = 'occurred',
  PendingReschedule = 'pending_reschedule',
  Rescheduled = 'rescheduled'
}

export enum InsuranceBerryStreetAppointmentSchedulingRule {
  FreeSingle = 'free_single',
  NoScheduling = 'no_scheduling',
  PaidBundle = 'paid_bundle',
  PaidSingle = 'paid_single'
}

export enum InsuranceBerryStreetAppointmentStatus {
  Deleted = 'deleted',
  InReview = 'in_review',
  ReviewCompleted = 'review_completed',
  SchedulingPending = 'scheduling_pending'
}

export enum InsuranceBerryStreetAppointmentType {
  DpWebinarSession = 'dp_webinar_session',
  DppSession = 'dpp_session',
  Followup = 'followup',
  Followup_30 = 'followup_30',
  Followup_45 = 'followup_45',
  Followup_90 = 'followup_90',
  Initial = 'initial',
  Initial_60 = 'initial_60',
  NoneCovered = 'none_covered'
}

export type InsuranceCardSubmission = {
  __typename?: 'InsuranceCardSubmission'
  id: Scalars['ID']
  insurancePolicyStateId: Scalars['ID']
}

export type InsuranceKind = {
  __typename?: 'InsuranceKind'
  insuranceSurveyAnswer: Scalars['String']
  key: UserInsurancePolicyKind
  title: Scalars['String']
}

export type InsuranceKinds = {
  __typename?: 'InsuranceKinds'
  kindDefs: Array<UserInsurancePolicyKindDef>
}

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy'
  declined: Scalars['Boolean']
  /** @deprecated User last_state instead */
  eligibilityStatus?: Maybe<UserInsurancePolicyEligibilityStatus>
  eligible: Scalars['Boolean']
  hasUnlimitedCalls: Scalars['Boolean']
  hasUnusedCalls: Scalars['Boolean']
  id: Scalars['ID']
  inReview: Scalars['Boolean']
  initialAppointmentKind?: Maybe<ClientConfigEhrAppointmentKind>
  initialAppointmentType?: Maybe<AppointmentType>
  initialAppointmentTypes: Array<AppointmentType>
  kind?: Maybe<InsuranceKind>
  last: Scalars['Boolean']
  lastState?: Maybe<InsurancePolicyState>
  memberId: Scalars['String']
  states?: Maybe<Array<InsurancePolicyState>>
  /** @deprecated User last_state instead */
  status?: Maybe<UserInsurancePolicyStatus>
  unitsAuthorized?: Maybe<Scalars['Int']>
  unitsLeft?: Maybe<Scalars['Int']>
  unitsOccurred?: Maybe<Scalars['Int']>
  unitsUsed?: Maybe<Scalars['Int']>
}

export type InsurancePolicyCollection = {
  __typename?: 'InsurancePolicyCollection'
  canCreateNewPolicy: Scalars['Boolean']
  insurancePolicies: Array<InsurancePolicy>
}

export type InsurancePolicyState = {
  __typename?: 'InsurancePolicyState'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  kind: UserInsurancePolicyStateKind
  reason?: Maybe<Scalars['String']>
}

export type IntegrationUser = {
  __typename?: 'IntegrationUser'
  accessToken: Scalars['String']
  accessTokenExpirationDate: Scalars['ISO8601DateTime']
  idToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type IntegrationUserSetting = {
  accessToken: Scalars['String']
  accessTokenExpirationDate: Scalars['ISO8601DateTime']
  idToken?: InputMaybe<Scalars['String']>
  refreshToken: Scalars['String']
}

export type IntegrationsSync = {
  __typename?: 'IntegrationsSync'
  dexcomUser?: Maybe<IntegrationUser>
  ketoMojoUser?: Maybe<IntegrationUser>
  lastSyncedDexcom?: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedHealth?: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedKetoMojo?: Maybe<Scalars['ISO8601DateTime']>
  lastSyncedLibreView?: Maybe<Scalars['ISO8601DateTime']>
}

export type IntegrationsSyncSettings = {
  dexcomUser?: InputMaybe<IntegrationUserSetting>
  ketoMojoUser?: InputMaybe<IntegrationUserSetting>
  lastSyncedDexcom?: InputMaybe<Scalars['ISO8601DateTime']>
  lastSyncedHealth?: InputMaybe<Scalars['ISO8601DateTime']>
  lastSyncedKetoMojo?: InputMaybe<Scalars['ISO8601DateTime']>
  lastSyncedLibreView?: InputMaybe<Scalars['ISO8601DateTime']>
}

export type Interval = {
  __typename?: 'Interval'
  description: Scalars['String']
  key: Scalars['String']
  prefix: Scalars['String']
  suffix: Scalars['String']
}

export type IntroScreen = {
  __typename?: 'IntroScreen'
  backgroundImageUrl?: Maybe<Scalars['String']>
  footnote?: Maybe<Scalars['String']>
  header: Scalars['String']
  icon?: Maybe<UiIcon>
  imageUrl?: Maybe<Scalars['String']>
  inlineAlert?: Maybe<InlineAlert>
  /** @deprecated use intro_markdown */
  intro?: Maybe<Scalars['String']>
  introMarkdown?: Maybe<Scalars['String']>
}

export type Invoice = {
  __typename?: 'Invoice'
  created: Scalars['ISO8601DateTime']
  downloadUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  latestCharge?: Maybe<Charge>
  paidAt?: Maybe<Scalars['ISO8601DateTime']>
  periodStart: Scalars['ISO8601DateTime']
  /** @deprecated not used anymore */
  primaryInvoiceLine?: Maybe<InvoiceLine>
  primaryProduct: ProductMin
  receiptNumber?: Maybe<Scalars['String']>
  status: InvoiceStatus
  stripeId: Scalars['String']
  /** @deprecated use status enum field */
  stripeStatus: Scalars['String']
  subscription?: Maybe<Subscription>
  total?: Maybe<Scalars['Float']>
  updatedAt: Scalars['ISO8601DateTime']
  user: User
}

export type InvoiceLine = {
  __typename?: 'InvoiceLine'
  id: Scalars['ID']
  plan?: Maybe<Plan>
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export type JournalConfig = {
  __typename?: 'JournalConfig'
  measurementTypes: Array<MeasurementType>
  range: JournalRange
}

export type JournalEntry = {
  __typename?: 'JournalEntry'
  body: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  createdBy: Scalars['String']
  creatorId: Scalars['ID']
  id: Scalars['ID']
  kind: NoteKind
  measurements?: Maybe<Array<Measurement>>
  occurredAt: Scalars['ISO8601DateTime']
  score?: Maybe<Scalars['Float']>
  title: Scalars['String']
}

export type JournalMeasurementsData = {
  id?: InputMaybe<Scalars['ID']>
  type: Scalars['String']
  value: Scalars['Int']
}

export type JournalRange = {
  __typename?: 'JournalRange'
  max: Scalars['Int']
  min: Scalars['Int']
}

export type KetoMojoData = {
  readingId: Scalars['String']
  readingTimestamp: Scalars['ISO8601DateTime']
  readingType: Scalars['String']
  readingUnit?: InputMaybe<Scalars['String']>
  readingValue?: InputMaybe<Scalars['Float']>
}

export type KeyValueFloat = {
  key: Scalars['String']
  value: Scalars['Float']
}

export type KeyValuePairFloat = {
  __typename?: 'KeyValuePairFloat'
  key: Scalars['String']
  value: Scalars['Float']
}

export type KeyValuePairString = {
  __typename?: 'KeyValuePairString'
  key: Scalars['String']
  value: Scalars['String']
}

export type KeyValuePairStringArray = {
  __typename?: 'KeyValuePairStringArray'
  key: Scalars['String']
  value: Array<Scalars['String']>
}

export type KeyValueString = {
  key: Scalars['String']
  value: Scalars['String']
}

export type KeyValueStringArray = {
  key: Scalars['String']
  value: Array<Scalars['String']>
}

export type LatestUserAppointment = {
  __typename?: 'LatestUserAppointment'
  addToGoogleCalendarLink?: Maybe<Scalars['String']>
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  coachProfile?: Maybe<CoachProfile>
  date?: Maybe<Scalars['String']>
  externalVideochatUrl?: Maybe<Scalars['String']>
  followupSchedulingAvailable: Scalars['Boolean']
  id: Scalars['ID']
  meetingStatus?: Maybe<InsuranceBerryStreetAppointmentMeetingStatus>
  nutritionistName?: Maybe<Scalars['String']>
  status: InsuranceBerryStreetAppointmentStatus
  title?: Maybe<Scalars['String']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']>
}

export type LegalPolicy = {
  __typename?: 'LegalPolicy'
  kind: LegalPolicyConsentKind
  markdown?: Maybe<Scalars['String']>
  title: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type LegalPolicyConsent = {
  __typename?: 'LegalPolicyConsent'
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  kind: LegalPolicyConsentKind
  state: LegalPolicyConsentState
}

export enum LegalPolicyConsentKind {
  CompoundedSemaglutide = 'compounded_semaglutide',
  ConsumerHealthDataPrivacyPolicy = 'consumer_health_data_privacy_policy',
  FloridaDigitalBillOfRights = 'florida_digital_bill_of_rights',
  FuturePayments = 'future_payments',
  HipaaAuthorization = 'hipaa_authorization',
  OngoingCareNonRefundable = 'ongoing_care_non_refundable',
  PhoneNumber = 'phone_number',
  PrescriptionRefill = 'prescription_refill',
  PrivacyPolicy = 'privacy_policy',
  Telehealth = 'telehealth',
  TermsOfUse = 'terms_of_use'
}

export enum LegalPolicyConsentState {
  Accepted = 'accepted',
  Invalidated = 'invalidated'
}

export type LegalPolicyConsentStatus = {
  __typename?: 'LegalPolicyConsentStatus'
  kind: LegalPolicyConsentKind
  lastPolicyConsent?: Maybe<LegalPolicyConsent>
  policy: LegalPolicy
  required: Scalars['Boolean']
}

export type LegalPolicyConsentStatuses = {
  __typename?: 'LegalPolicyConsentStatuses'
  statuses: Array<LegalPolicyConsentStatus>
}

export type Lesson = {
  __typename?: 'Lesson'
  contentId: Scalars['ID']
  id: Scalars['ID']
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<Progress>
}

export type LibreAccount = {
  __typename?: 'LibreAccount'
  libreId: Scalars['String']
}

export enum LibreAccountRegion {
  Ae = 'ae',
  Ap = 'ap',
  Au = 'au',
  Ca = 'ca',
  De = 'de',
  Eu = 'eu',
  Fr = 'fr',
  Jp = 'jp',
  La = 'la',
  Me = 'me',
  Us = 'us'
}

export enum LibreLinkupConnectionStatus {
  AuthenticationInitiated = 'authentication_initiated',
  AuthorizationCodeSent = 'authorization_code_sent',
  Connected = 'connected',
  ConnectionAuthorized = 'connection_authorized',
  Dead = 'dead',
  Failed = 'failed',
  Inactive = 'inactive',
  InviteSent = 'invite_sent'
}

export type Link = {
  __typename?: 'Link'
  text: Scalars['String']
  url: Scalars['String']
}

export type LinkupConnection = {
  __typename?: 'LinkupConnection'
  active: Scalars['Boolean']
  lastSyncedAt?: Maybe<Scalars['ISO8601DateTime']>
  region?: Maybe<LibreAccountRegion>
  status: LibreLinkupConnectionStatus
}

export type ListFiles = {
  __typename?: 'ListFiles'
  files: Array<File>
  pageCount?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export type ListFilters = {
  __typename?: 'ListFilters'
  order: Array<Scalars['String']>
  orderBy: Array<Scalars['String']>
  types: Array<Scalars['String']>
}

/** Autogenerated return type of ListPlansAction. */
export type ListPlansActionPayload = {
  __typename?: 'ListPlansActionPayload'
  insurancePlans: Array<InsuranceKind>
}

export type ListProviders = {
  __typename?: 'ListProviders'
  providers: Array<Provider>
}

export type Macro = {
  __typename?: 'Macro'
  indent: Scalars['Int']
  name: Scalars['String']
}

export type MacroGoalSettings = {
  __typename?: 'MacroGoalSettings'
  caloriesMax: Scalars['Int']
  carbsMax: Scalars['Int']
  fatMax: Scalars['Int']
  proteinMax: Scalars['Int']
}

export type MacroGoals = {
  __typename?: 'MacroGoals'
  calories: Scalars['Int']
  carbs: Scalars['Int']
  fat: Scalars['Int']
  netCarbs?: Maybe<Scalars['Int']>
  protein: Scalars['Int']
}

export type MacroGoalsDaily = {
  calories?: InputMaybe<Scalars['Int']>
  carbs?: InputMaybe<Scalars['Int']>
  fat?: InputMaybe<Scalars['Int']>
  netCarbs?: InputMaybe<Scalars['Int']>
  protein?: InputMaybe<Scalars['Int']>
}

export type MarketingInfo = {
  utm?: InputMaybe<Scalars['JSON']>
}

export type Meal = {
  __typename?: 'Meal'
  avatar?: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  createdBy: User
  description: Scalars['String']
  favorite: Scalars['Boolean']
  generatedOccurredAt?: Maybe<Scalars['Boolean']>
  glucose: EntryGlucose
  id: Scalars['ID']
  ingredients: Array<Ingredient>
  kind?: Maybe<MealKind>
  nutrition: Array<KeyValuePairFloat>
  occurredAt: Scalars['ISO8601DateTime']
  source: MealSource
  statistics: EntryStatistics
  /** @deprecated use kind enum field */
  time?: Maybe<Scalars['String']>
  title: Scalars['String']
  /** @deprecated use kind enum field */
  type: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type MealCollection = {
  __typename?: 'MealCollection'
  meals: Array<Meal>
}

export type MealFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export enum MealKind {
  Breakfast = 'breakfast',
  Dessert = 'dessert',
  Dinner = 'dinner',
  Drink = 'drink',
  Lunch = 'lunch',
  Snack = 'snack',
  Supplement = 'supplement'
}

export enum MealSource {
  HealthPortal = 'health_portal',
  MobileApp = 'mobile_app',
  MyFitnessPal = 'my_fitness_pal'
}

export type Meals = {
  __typename?: 'Meals'
  filters: ListFilters
}

export type Measurement = {
  __typename?: 'Measurement'
  createdAt: Scalars['ISO8601DateTime']
  cumulative: Scalars['Boolean']
  description: Scalars['String']
  displayValue?: Maybe<Scalars['String']>
  externalSource?: Maybe<Scalars['String']>
  id: Scalars['ID']
  interpolated: Scalars['Boolean']
  occurredAt: Scalars['ISO8601DateTime']
  scan?: Maybe<Scan>
  timestamp: Scalars['ISO8601DateTime']
  title: Scalars['String']
  type: Scalars['String']
  units: Scalars['String']
  value?: Maybe<Scalars['Float']>
  values?: Maybe<MeasurementMultiValue>
}

export type MeasurementCollection = {
  __typename?: 'MeasurementCollection'
  measurements: Array<Measurement>
}

export type MeasurementConfig = {
  __typename?: 'MeasurementConfig'
  imperial: MeasurementConfigUnit
  metric: MeasurementConfigUnit
  name: Scalars['String']
}

export type MeasurementConfigUnit = {
  __typename?: 'MeasurementConfigUnit'
  maxThreshold?: Maybe<Scalars['Float']>
  minThreshold?: Maybe<Scalars['Float']>
  precision: Scalars['Int']
  units: Scalars['String']
}

export type MeasurementData = {
  id?: InputMaybe<Scalars['ID']>
  time?: InputMaybe<Scalars['String']>
  type: Scalars['String']
  value?: InputMaybe<Scalars['Float']>
  values?: InputMaybe<MultiValue>
}

export type MeasurementFilter = {
  endDate?: InputMaybe<Scalars['String']>
  endHour?: InputMaybe<Scalars['Int']>
  excludeTypes?: InputMaybe<Array<Scalars['String']>>
  favorite?: InputMaybe<Scalars['Boolean']>
  graph?: InputMaybe<Scalars['String']>
  includeTypes?: InputMaybe<Array<Scalars['String']>>
  order?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  startHour?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export type MeasurementMultiValue = BloodPressureMeasurement | SingleValueMeasurement

export type MeasurementType = {
  __typename?: 'MeasurementType'
  reverse?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export enum MobileAppFeature {
  AccountDetailsEditName = 'account_details_edit_name',
  ActivateOwnSensor = 'activate_own_sensor',
  AdminAccess = 'admin_access',
  AdminImpersonate = 'admin_impersonate',
  DppDashboard = 'dpp_dashboard',
  InsuranceCoveredVideoCalls = 'insurance_covered_video_calls',
  ScanCgm = 'scan_cgm',
  ViewGlucoseChart = 'view_glucose_chart'
}

export type Module = {
  __typename?: 'Module'
  contentId: Scalars['ID']
  id: Scalars['ID']
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<Progress>
}

export type MultiValue = {
  diastolic?: InputMaybe<Scalars['Float']>
  systolic?: InputMaybe<Scalars['Float']>
  value?: InputMaybe<Scalars['Float']>
}

export type MutationType = {
  __typename?: 'MutationType'
  acceptLegalPolicy: LegalPolicyConsent
  activatePrescriptionDexcom: ActivatePrescriptionDexcomActionPayload
  bookVideoCall: LatestUserAppointment
  cancelEhrAppointment: EhrAppointment
  cancelSubscription: Subscription
  cancelVideoCall?: Maybe<Appointment>
  claimFreeEhrAppointment: EhrAppointment
  claimFreeVideoCall: LatestUserAppointment
  completeLinkupConnection: LinkupConnection
  completeNotification: Notification
  completeSurvey: SurveyLink
  createActivation: Sensor
  createActivity: Activity
  createAppleAdsAttribution: CreateActionPayload
  createBluetoothScan: Scan
  createChatConversationNote: ChatConversationNote
  createChatToken: Token
  createChatbotDelimiter: Delimiter
  /** @deprecated user createChatToken instead */
  createChatbotToken: Token
  createCustomIngredient: Ingredient
  createDirectUpload: DirectUpload
  createDppHealthDataRecord: Success
  createEhrChartNote: Success
  createFileFromSignedId: File
  createHealthMetric: HealthMetric
  createInsurancePolicy?: Maybe<InsurancePolicy>
  createJournalEntry: JournalEntry
  createMeal: Meal
  createMeasurement: Measurement
  /** @deprecated Use :upsert_note */
  createNote: Note
  createOneTimePayment: CreatedOneTimePayment
  createProviderSetupIntent: ProviderSetupIntent
  createRefillConsent: UserRefillConsent
  createReminder: Reminder
  createScan: Scan
  createSubscription: CreatedSubscription
  createSubscriptionBonus: Success
  createThirdPartyUser: UserAuth
  createUser: UserAuth
  deauthTerraProvider: ListProviders
  deleteActivity: Activity
  deleteCustomIngredient: Ingredient
  deleteDexcomConnection?: Maybe<DexcomConnectionType>
  deleteEhrAppointmentRecurrence: Success
  deleteFile: File
  deleteHealthMetric: Success
  deleteJournalEntry: JournalEntry
  deleteMeal: Meal
  deleteMeasurement: Measurement
  deleteNote: Note
  deleteReminder: Reminder
  disableLinkupConnection: LinkupConnection
  dismissNotification: Notification
  ehrBookAppointment: EhrAppointment
  initLinkupConnection: LinkupConnection
  invalidateRefillConsent: UserRefillConsent
  joinCourseCohort: Cohort
  /** @deprecated Use join_course_cohort */
  joinDppCourseCohort: Cohort
  markDailyActionComplete: Success
  markDailyActionIncomplete: Success
  pauseSubscription: Subscription
  rateCoach: Success
  recordLessonProgress: RecordedProgress
  registerNotificationToken: RegisterNotificationTokenActionPayload
  rescheduleEhrAppointment: EhrAppointment
  rescheduleVideoCall: Appointment
  resetPassword: Success
  runInsuranceBenefitsCheck: Success
  saveSurveyResponse: SurveyResponse
  scheduleReminder: Reminder
  scheduleSubscriptionChange: Subscription
  /** @deprecated use select_sensor instead */
  selectDefaultSensorForDexcomResearch: Success
  selectSensor: Success
  sendChatConversationMessage: Success
  setPrimaryGoalMetrics: Array<GoalMetric>
  setSubscriptionBillingCycle: Subscription
  signInPasswordlessUser: UserAuth
  signInThirdPartyUser: UserAuth
  signOutUser: Success
  signinUser?: Maybe<UserAuth>
  startDexcomResearchParticipation: Success
  startSurvey: SurveyLink
  submitHealthMetrics: Success
  submitInsuranceCard: InsuranceCardSubmission
  submitInsurancePolicy: InsurancePolicy
  switchCourseCohortSession: Success
  /** @deprecated Use switch_course_cohort_session */
  switchDppCourseCohortSession: Success
  switchSensorOnCancellation: FulfillmentConfiguration
  toggleProviderDataType: ListProviders
  uncancelSubscription: Subscription
  updateAddress: Address
  updateCourseCohortSessionAttendance: CohortSessionAttendance
  updateDailyMeasurementsSourcePriority: DailyMeasurementsSourcePriority
  updateDexcomSteloEligibility: Success
  /** @deprecated Use update_course_cohort_session_attendance */
  updateDppCourseCohortSessionAttendance: CohortSessionAttendance
  updateEffectiveDailyMeasurement: EffectiveDailyMeasurement
  updateGoalMetric: GoalMetric
  updateHealthData: Success
  updateHealthMetric: HealthMetric
  updateJournalEntry: JournalEntry
  updateKetoMojoData: Success
  updateReminder: Reminder
  updateSensorSettings: Sensor
  updateSensorStatus: Sensor
  updateSettings: UserSettings
  updateUser: User
  upgradeSubscriptionProduct: UpgradedSubscription
  upgradeSubscriptionTrial: UpgradedSubscription
  upsertDailyAction: DailyAction
  upsertDexcomConnection: DexcomConnectionType
  upsertEhrAppointmentRecurrence: EhrAppointmentRecurrence
  upsertNote: Note
  upsertPrimaryGoal: Goal
  upsertUiState: UiState
}

export type MutationTypeAcceptLegalPolicyArgs = {
  kind: LegalPolicyConsentKind
}

export type MutationTypeBookVideoCallArgs = {
  agenda?: InputMaybe<Scalars['String']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['String']>
  pendingAppointmentId?: InputMaybe<Scalars['ID']>
  referringCoachUserId?: InputMaybe<Scalars['ID']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type MutationTypeCancelEhrAppointmentArgs = {
  id: Scalars['ID']
}

export type MutationTypeCancelSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeCancelVideoCallArgs = {
  id: Scalars['ID']
}

export type MutationTypeCompleteLinkupConnectionArgs = {
  libre2faCode: Scalars['String']
}

export type MutationTypeCompleteNotificationArgs = {
  id: Scalars['ID']
}

export type MutationTypeCompleteSurveyArgs = {
  id: Scalars['ID']
}

export type MutationTypeCreateActivationArgs = {
  data: ActivationData
}

export type MutationTypeCreateActivityArgs = {
  description?: InputMaybe<Scalars['String']>
  endTime?: InputMaybe<Scalars['String']>
  favorite?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  startTime?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateAppleAdsAttributionArgs = {
  anonymousId?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateBluetoothScanArgs = {
  data: BluetoothScanData
}

export type MutationTypeCreateChatConversationNoteArgs = {
  body?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateChatbotDelimiterArgs = {
  providerConversationId: Scalars['ID']
}

export type MutationTypeCreateCustomIngredientArgs = {
  calories?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  ingredientId?: InputMaybe<Scalars['ID']>
  mealId?: InputMaybe<Scalars['ID']>
  nutrition?: InputMaybe<Array<KeyValueFloat>>
  servingAmount?: InputMaybe<Scalars['Float']>
  servingUnits?: InputMaybe<Scalars['String']>
  thirdPartyIngredientId?: InputMaybe<Scalars['String']>
  thirdPartyIngredientSource?: InputMaybe<IngredientSource>
}

export type MutationTypeCreateDirectUploadArgs = {
  file?: InputMaybe<FileMetadata>
}

export type MutationTypeCreateDppHealthDataRecordArgs = {
  kind?: InputMaybe<DppHealthDataRecordKind>
  userFileId?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['Float']>
}

export type MutationTypeCreateEhrChartNoteArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
  data?: InputMaybe<Scalars['JSON']>
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
  kind?: InputMaybe<EhrChartNoteKind>
  legacyAppointmentId?: InputMaybe<Scalars['ID']>
}

export type MutationTypeCreateFileFromSignedIdArgs = {
  fileTag?: InputMaybe<Scalars['String']>
  signedId?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateHealthMetricArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>
  kind?: InputMaybe<HealthDataMetricBiomarker>
  value?: InputMaybe<Scalars['Float']>
}

export type MutationTypeCreateInsurancePolicyArgs = {
  groupId?: InputMaybe<Scalars['String']>
  holderDateOfBirth?: InputMaybe<Scalars['ISO8601Date']>
  holderFirstName?: InputMaybe<Scalars['String']>
  holderLastName?: InputMaybe<Scalars['String']>
  holderRelationship?: InputMaybe<UserInsurancePolicyHolderRelationship>
  kind?: InputMaybe<UserInsurancePolicyKind>
  memberId?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateJournalEntryArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  measurements?: InputMaybe<Array<JournalMeasurementsData>>
  time?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateMealArgs = {
  description?: InputMaybe<Scalars['String']>
  favorite?: InputMaybe<Scalars['Boolean']>
  favoriteId?: InputMaybe<Scalars['ID']>
  id?: InputMaybe<Scalars['ID']>
  imageUrl?: InputMaybe<Scalars['String']>
  ingredients?: InputMaybe<Array<IngredientData>>
  kind?: InputMaybe<MealKind>
  photo?: InputMaybe<Scalars['Upload']>
  tags?: InputMaybe<Array<Scalars['String']>>
  time?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateMeasurementArgs = {
  id?: InputMaybe<Scalars['ID']>
  time?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['Float']>
  values?: InputMaybe<MultiValue>
}

export type MutationTypeCreateNoteArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  pinned?: InputMaybe<Scalars['Boolean']>
  time?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateOneTimePaymentArgs = {
  address?: InputMaybe<OneTimePaymentAddress>
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Array<KeyValueString>>
  phoneNumber?: InputMaybe<Scalars['String']>
  providerPaymentMethodId?: InputMaybe<Scalars['String']>
  providerPromotionCodeId?: InputMaybe<Scalars['String']>
  purchasableItems?: InputMaybe<Array<PurchasableItem>>
}

export type MutationTypeCreateProviderSetupIntentArgs = {
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  referralPartner?: InputMaybe<UserReferralPartner>
}

export type MutationTypeCreateReminderArgs = {
  dayOfWeek?: InputMaybe<NotificationEngineReminderDayOfWeek>
  frequency?: InputMaybe<NotificationEngineReminderFrequency>
  hoursInterval?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<NotificationEngineReminderKind>
  sendOn?: InputMaybe<Scalars['ISO8601Date']>
  timeOfDay?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateScanArgs = {
  data: ScanData
}

export type MutationTypeCreateSubscriptionArgs = {
  address?: InputMaybe<SubscriptionAddress>
  bonusKinds?: InputMaybe<Array<SubscriptionBonusKind>>
  checkoutOptionKinds?: InputMaybe<Array<SubscriptionCheckoutOptionKind>>
  email?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind>>
  product?: InputMaybe<BillingProduct>
  providerPaymentMethodId?: InputMaybe<Scalars['String']>
  providerPromotionCodeId?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateSubscriptionBonusArgs = {
  bonusKind?: InputMaybe<Scalars['String']>
  providerSubscriptionId?: InputMaybe<Scalars['ID']>
  testGroup?: InputMaybe<Scalars['String']>
}

export type MutationTypeCreateThirdPartyUserArgs = {
  authorization?: InputMaybe<ThirdPartySignIn>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind>>
}

export type MutationTypeCreateUserArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']>
  email?: InputMaybe<SignIn>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  marketingInfo?: InputMaybe<MarketingInfo>
  phoneNumber?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['Upload']>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind>>
  referralPartner?: InputMaybe<UserReferralPartner>
  sendResetPasswordEmail?: InputMaybe<Scalars['Boolean']>
  sendSignInLinkEmail?: InputMaybe<Scalars['Boolean']>
  sex?: InputMaybe<Scalars['String']>
  source?: InputMaybe<UserSignUpSource>
  userProperties?: InputMaybe<Array<UserPropertySubmission>>
}

export type MutationTypeDeauthTerraProviderArgs = {
  provider: Scalars['String']
}

export type MutationTypeDeleteActivityArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeDeleteCustomIngredientArgs = {
  id: Scalars['ID']
}

export type MutationTypeDeleteDexcomConnectionArgs = {
  dexcomConnectionKind?: InputMaybe<DexcomConnectionKind>
}

export type MutationTypeDeleteFileArgs = {
  id: Scalars['ID']
}

export type MutationTypeDeleteHealthMetricArgs = {
  id: Scalars['ID']
}

export type MutationTypeDeleteJournalEntryArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeDeleteMealArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeDeleteMeasurementArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeDeleteNoteArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeDeleteReminderArgs = {
  id: Scalars['ID']
}

export type MutationTypeDismissNotificationArgs = {
  id: Scalars['ID']
}

export type MutationTypeEhrBookAppointmentArgs = {
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  attendanceAgenda?: InputMaybe<Scalars['String']>
  attendanceChargeKind?: InputMaybe<EhrAppointmentAttendanceChargeKind>
  coachId?: InputMaybe<Scalars['ID']>
  coachUserId?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['String']>
  doctorId?: InputMaybe<Scalars['ID']>
  isGroup?: InputMaybe<Scalars['Boolean']>
  pendingAppointmentId?: InputMaybe<Scalars['ID']>
  referringCoachUserId?: InputMaybe<Scalars['ID']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type MutationTypeInitLinkupConnectionArgs = {
  libreEmail: Scalars['String']
  librePassword: Scalars['String']
}

export type MutationTypeJoinCourseCohortArgs = {
  id: Scalars['ID']
}

export type MutationTypeJoinDppCourseCohortArgs = {
  id: Scalars['ID']
}

export type MutationTypeMarkDailyActionCompleteArgs = {
  dailyActionId?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationTypeMarkDailyActionIncompleteArgs = {
  dailyActionId?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationTypePauseSubscriptionArgs = {
  cycleCount?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeRateCoachArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
  coachUserId?: InputMaybe<Scalars['ID']>
  rating?: InputMaybe<Scalars['Int']>
}

export type MutationTypeRecordLessonProgressArgs = {
  complete?: InputMaybe<Scalars['Boolean']>
  lessonId?: InputMaybe<Scalars['ID']>
}

export type MutationTypeRegisterNotificationTokenArgs = {
  id?: InputMaybe<Scalars['ID']>
  token?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type MutationTypeRescheduleEhrAppointmentArgs = {
  date?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeRescheduleVideoCallArgs = {
  date?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeResetPasswordArgs = {
  password?: InputMaybe<Scalars['String']>
  passwordToken?: InputMaybe<Scalars['String']>
}

export type MutationTypeRunInsuranceBenefitsCheckArgs = {
  addressState?: InputMaybe<AddressState>
  insurancePolicyKind?: InputMaybe<UserInsurancePolicyKind>
  insuranceVideoCallGoal?: InputMaybe<Scalars['String']>
  insuranceVideoCallGoalOther?: InputMaybe<Scalars['String']>
  memberId?: InputMaybe<Scalars['String']>
}

export type MutationTypeSaveSurveyResponseArgs = {
  answer: Scalars['JSON']
  questionKey: Scalars['String']
  surveyLinkId: Scalars['ID']
  warnBeforeStopping?: InputMaybe<Scalars['Boolean']>
}

export type MutationTypeScheduleReminderArgs = {
  kind: NotificationEngineReminderKind
  sendOn: Scalars['ISO8601Date']
}

export type MutationTypeScheduleSubscriptionChangeArgs = {
  id?: InputMaybe<Scalars['ID']>
  product?: InputMaybe<BillingProduct>
}

export type MutationTypeSelectSensorArgs = {
  model: SensorModel
}

export type MutationTypeSendChatConversationMessageArgs = {
  body?: InputMaybe<Scalars['String']>
}

export type MutationTypeSetPrimaryGoalMetricsArgs = {
  kinds?: InputMaybe<Array<UserGoalMetricKind>>
}

export type MutationTypeSetSubscriptionBillingCycleArgs = {
  id?: InputMaybe<Scalars['ID']>
  newBillingCycleAnchor?: InputMaybe<Scalars['ISO8601DateTime']>
}

export type MutationTypeSignInPasswordlessUserArgs = {
  signInToken?: InputMaybe<Scalars['String']>
}

export type MutationTypeSignInThirdPartyUserArgs = {
  provider?: InputMaybe<ThirdPartySignInProvider>
  providerToken?: InputMaybe<Scalars['String']>
  providerUserId?: InputMaybe<Scalars['String']>
}

export type MutationTypeSigninUserArgs = {
  email?: InputMaybe<SignIn>
}

export type MutationTypeStartSurveyArgs = {
  kind: Scalars['String']
}

export type MutationTypeSubmitHealthMetricsArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>
  healthMetricsData?: InputMaybe<Array<HealthMetricData>>
  measurementsData?: InputMaybe<Array<MeasurementData>>
}

export type MutationTypeSubmitInsuranceCardArgs = {
  backPhotoFileId?: InputMaybe<Scalars['ID']>
  frontPhotoFileId?: InputMaybe<Scalars['ID']>
  insurancePolicyStateId?: InputMaybe<Scalars['ID']>
}

export type MutationTypeSwitchCourseCohortSessionArgs = {
  currentSessionId: Scalars['ID']
  switchSessionId: Scalars['ID']
}

export type MutationTypeSwitchDppCourseCohortSessionArgs = {
  currentSessionId: Scalars['ID']
  switchSessionId: Scalars['ID']
}

export type MutationTypeToggleProviderDataTypeArgs = {
  dataType: Scalars['String']
  provider: Scalars['String']
}

export type MutationTypeUncancelSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationTypeUpdateAddressArgs = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<AddressCountries>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpdateCourseCohortSessionAttendanceArgs = {
  id: Scalars['ID']
  rsvp: CoursesCohortSessionAttendanceRsvp
}

export type MutationTypeUpdateDailyMeasurementsSourcePriorityArgs = {
  sources: Array<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type MutationTypeUpdateDexcomSteloEligibilityArgs = {
  isEligible: Scalars['Boolean']
}

export type MutationTypeUpdateDppCourseCohortSessionAttendanceArgs = {
  id: Scalars['ID']
  rsvp: CoursesCohortSessionAttendanceRsvp
}

export type MutationTypeUpdateEffectiveDailyMeasurementArgs = {
  date: Scalars['String']
  selectedSource?: InputMaybe<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type MutationTypeUpdateGoalMetricArgs = {
  highThresholdValue?: InputMaybe<Scalars['Float']>
  kind?: InputMaybe<UserGoalMetricKind>
  lowThresholdValue?: InputMaybe<Scalars['Float']>
}

export type MutationTypeUpdateHealthDataArgs = {
  healthData?: InputMaybe<HealthKitData>
}

export type MutationTypeUpdateHealthMetricArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>
  id?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['Float']>
}

export type MutationTypeUpdateJournalEntryArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  measurements?: InputMaybe<Array<JournalMeasurementsData>>
  time?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpdateKetoMojoDataArgs = {
  data?: InputMaybe<Array<KetoMojoData>>
}

export type MutationTypeUpdateReminderArgs = {
  dayOfWeek?: InputMaybe<NotificationEngineReminderDayOfWeek>
  frequency?: InputMaybe<NotificationEngineReminderFrequency>
  hoursInterval?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<NotificationEngineReminderKind>
  sendOn?: InputMaybe<Scalars['ISO8601Date']>
  timeOfDay?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpdateSensorSettingsArgs = {
  baselineAdjustment?: InputMaybe<Scalars['Int']>
}

export type MutationTypeUpdateSensorStatusArgs = {
  serialNumber?: InputMaybe<Scalars['String']>
  status?: InputMaybe<SensorStatus>
}

export type MutationTypeUpdateSettingsArgs = {
  appVersionReminder?: InputMaybe<Scalars['Boolean']>
  canScanExpired?: InputMaybe<Scalars['Boolean']>
  dashboardGraph?: InputMaybe<Scalars['String']>
  dexcomSync?: InputMaybe<Scalars['Boolean']>
  displayActivityScore?: InputMaybe<Scalars['Boolean']>
  displayDailyScore?: InputMaybe<Scalars['Boolean']>
  displayMealScore?: InputMaybe<Scalars['Boolean']>
  fastingEnd?: InputMaybe<Scalars['Int']>
  fastingStart?: InputMaybe<Scalars['Int']>
  glucoseHighThreshold?: InputMaybe<Scalars['Int']>
  glucoseLowThreshold?: InputMaybe<Scalars['Int']>
  glucoseSource?: InputMaybe<Scalars['String']>
  googleFitSync?: InputMaybe<Scalars['Boolean']>
  healthKitSync?: InputMaybe<Scalars['Boolean']>
  integrationsSyncSettings?: InputMaybe<IntegrationsSyncSettings>
  ketoMojoSync?: InputMaybe<Scalars['Boolean']>
  ketoneHighThreshold?: InputMaybe<Scalars['Float']>
  ketoneLowThreshold?: InputMaybe<Scalars['Float']>
  libreViewSync?: InputMaybe<Scalars['Boolean']>
  macroGoalsDaily?: InputMaybe<MacroGoalsDaily>
  patternsNotifications?: InputMaybe<Scalars['Boolean']>
  reminders?: InputMaybe<Scalars['Boolean']>
  scanReminder?: InputMaybe<Scalars['Int']>
  showNetCarbs?: InputMaybe<Scalars['Boolean']>
  statsSettings?: InputMaybe<Array<StatsSettingsOrder>>
  trackingMode?: InputMaybe<TrackingMode>
  unitSystem?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpdateUserArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  lastName?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['Upload']>
  sex?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpgradeSubscriptionProductArgs = {
  checkoutOptionKinds?: InputMaybe<Array<SubscriptionCheckoutOptionKind>>
  id?: InputMaybe<Scalars['ID']>
  nextProduct?: InputMaybe<BillingProduct>
  providerPaymentMethodId?: InputMaybe<Scalars['String']>
  providerPromotionCodeId?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpgradeSubscriptionTrialArgs = {
  id?: InputMaybe<Scalars['ID']>
  providerPaymentMethodId?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpsertDailyActionArgs = {
  kind?: InputMaybe<UserDailyActionKind>
  status?: InputMaybe<UserDailyActionStatus>
}

export type MutationTypeUpsertDexcomConnectionArgs = {
  authorizationCode?: InputMaybe<Scalars['String']>
  dexcomConnectionKind?: InputMaybe<DexcomConnectionKind>
}

export type MutationTypeUpsertEhrAppointmentRecurrenceArgs = {
  cadence?: InputMaybe<EhrAppointmentRecurrenceCadence>
  days?: InputMaybe<Array<EhrAppointmentRecurrenceDay>>
  numberOfCalls?: InputMaybe<Scalars['Int']>
  timeSlots?: InputMaybe<Array<Scalars['ISO8601DateTime']>>
}

export type MutationTypeUpsertNoteArgs = {
  body?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind?: InputMaybe<Scalars['String']>
  pinned?: InputMaybe<Scalars['Boolean']>
  time?: InputMaybe<Scalars['String']>
}

export type MutationTypeUpsertPrimaryGoalArgs = {
  description?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<UserGoalKind>
}

export type MutationTypeUpsertUiStateArgs = {
  name?: InputMaybe<UiStateNames>
  value?: InputMaybe<Scalars['Boolean']>
}

export type Note = {
  __typename?: 'Note'
  body: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  createdBy: Scalars['String']
  creator: User
  /** @deprecated Use creator instead */
  creatorId: Scalars['ID']
  id: Scalars['ID']
  kind: NoteKind
  occurredAt: Scalars['ISO8601DateTime']
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']>
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type NoteCollection = {
  __typename?: 'NoteCollection'
  notes: Array<Note>
}

export type NoteDynamicFilter = {
  field: NoteDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']
}

export enum NoteDynamicFilterField {
  Kind = 'kind'
}

export type NoteDynamicFilterItem = {
  items: Array<NoteDynamicFilter>
  operator: DynamicFilterOperator
}

export enum NoteKind {
  DietitianNote = 'dietitian_note',
  DietitianUpNext = 'dietitian_up_next',
  GlucoseChart = 'glucose_chart',
  MemberNote = 'member_note',
  OpsAccountRequest = 'ops_account_request'
}

export type NoteSort = {
  direction: SortDirection
  field: NoteSortField
}

export enum NoteSortField {
  OccurredAt = 'occurred_at'
}

export type Notification = {
  __typename?: 'Notification'
  actionButtonLabel: Scalars['String']
  actionKind: NotificationEngineNotificationActionKind
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']>
  content: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>
  dismissButtonLabel: Scalars['String']
  dismissedAt?: Maybe<Scalars['ISO8601DateTime']>
  icon: UiIcon
  id: Scalars['ID']
  kind: NotificationEngineNotificationKind
  payload: Scalars['JSON']
  screen: NotificationEngineScreen
  style: NotificationEngineNotificationStyle
  updatedAt: Scalars['ISO8601DateTime']
}

export type NotificationCollection = {
  __typename?: 'NotificationCollection'
  notifications: Array<Notification>
}

export enum NotificationEngineNotificationActionKind {
  DeepLink = 'deep_link',
  EditExperimentScreen = 'edit_experiment_screen',
  EditJournalScreen = 'edit_journal_screen',
  ExternalLink = 'external_link',
  InsightsGlucoseScreen = 'insights_glucose_screen',
  InsightsMealScreen = 'insights_meal_screen',
  MealScreen = 'meal_screen',
  NewActivityScreen = 'new_activity_screen',
  NewJournalScreen = 'new_journal_screen',
  NewMealScreen = 'new_meal_screen',
  None = 'none',
  NoraPrompt = 'nora_prompt',
  ScanScreen = 'scan_screen'
}

export enum NotificationEngineNotificationKind {
  ActivityReminder = 'activity_reminder',
  AddedToCohort = 'added_to_cohort',
  CgmReviewVideoCallUpsell = 'cgm_review_video_call_upsell',
  ChatNewMessage = 'chat_new_message',
  CohortSessionAttendanceRsvp = 'cohort_session_attendance_rsvp',
  CohortSessionMakeUpMissed = 'cohort_session_make_up_missed',
  CohortSessionMissed = 'cohort_session_missed',
  CohortSessionReminder = 'cohort_session_reminder',
  CohortSessionRsvpMissing = 'cohort_session_rsvp_missing',
  CohortSessionScheduleChanged = 'cohort_session_schedule_changed',
  CohortSessionWeightLogReminder = 'cohort_session_weight_log_reminder',
  DailyActionCompleted = 'daily_action_completed',
  GlucoseCheckReminder = 'glucose_check_reminder',
  InsuranceOptionAvailable = 'insurance_option_available',
  InsurancePolicyPatientResponsibilityRequired = 'insurance_policy_patient_responsibility_required',
  JournalReminder = 'journal_reminder',
  LoseWeightRecurrentVideoCallUpsell = 'lose_weight_recurrent_video_call_upsell',
  LowDailyScoreVideoCallUpsell = 'low_daily_score_video_call_upsell',
  MealReminder = 'meal_reminder',
  MealScoreSpike = 'meal_score_spike',
  MeasurementReminder = 'measurement_reminder',
  MedicalConsultReminder = 'medical_consult_reminder',
  MedicalConsultStartingSoonReminder = 'medical_consult_starting_soon_reminder',
  NewCoachExperiment = 'new_coach_experiment',
  NewCoachNote = 'new_coach_note',
  NewCourseAvailable = 'new_course_available',
  NewEnrollableCohort = 'new_enrollable_cohort',
  PatternsGlucoseAverage = 'patterns_glucose_average',
  PatternsGlucoseMax = 'patterns_glucose_max',
  PatternsGlucoseMin = 'patterns_glucose_min',
  PatternsGlucoseMorningAverage = 'patterns_glucose_morning_average',
  PatternsGlucoseScore = 'patterns_glucose_score',
  PatternsGlucoseSleepAverage = 'patterns_glucose_sleep_average',
  PatternsGlucoseSpikeWithExercise = 'patterns_glucose_spike_with_exercise',
  PatternsGlucoseSpikeWithMeal = 'patterns_glucose_spike_with_meal',
  PatternsGlucoseTimeInRange = 'patterns_glucose_time_in_range',
  PatternsGlucoseVariability = 'patterns_glucose_variability',
  PatternsMealScore = 'patterns_meal_score',
  PatternsUnannotatedGlucoseSpike = 'patterns_unannotated_glucose_spike',
  ProactiveUpsell = 'proactive_upsell',
  ScanReminder = 'scan_reminder',
  SubmitLabResultsCustomDate = 'submit_lab_results_custom_date',
  SubmitLabResultsPeriodical = 'submit_lab_results_periodical',
  SurveyReminder = 'survey_reminder',
  VideoCallBookNow = 'video_call_book_now',
  VideoCallCheckInReminder = 'video_call_check_in_reminder',
  VideoCallClaimFreeCall = 'video_call_claim_free_call',
  VideoCallEarlierSlotAvailable = 'video_call_earlier_slot_available',
  VideoCallNoShowFeeChargeFailed = 'video_call_no_show_fee_charge_failed',
  VideoCallNoShowFeeCharged = 'video_call_no_show_fee_charged',
  VideoCallRecurrenceBookingCompleted = 'video_call_recurrence_booking_completed',
  VideoCallReminder = 'video_call_reminder',
  VideoCallStartingSoonReminder = 'video_call_starting_soon_reminder',
  VideoCallUpsell = 'video_call_upsell'
}

export enum NotificationEngineNotificationStyle {
  Chatlike = 'chatlike',
  Standard = 'standard'
}

export enum NotificationEngineReminderDayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export enum NotificationEngineReminderFrequency {
  Bimonthly = 'bimonthly',
  Daily = 'daily',
  HoursInterval = 'hours_interval',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Weekly = 'weekly'
}

export enum NotificationEngineReminderKind {
  Breakfast = 'breakfast',
  Dinner = 'dinner',
  Exercise = 'exercise',
  Fasting = 'fasting',
  GlucoseCheck = 'glucose_check',
  Journal = 'journal',
  Lunch = 'lunch',
  Meditation = 'meditation',
  OtherMeal = 'other_meal',
  Sleep = 'sleep',
  SubmitLabResults = 'submit_lab_results',
  Weight = 'weight'
}

export enum NotificationEngineScreen {
  Home = 'home',
  Insights = 'insights',
  None = 'none',
  NutritionistHub = 'nutritionist_hub'
}

export type NumericPair = {
  __typename?: 'NumericPair'
  x: Scalars['Float']
  y?: Maybe<Scalars['Numeric']>
}

export type NumericValue = {
  endDate: Scalars['ISO8601DateTime']
  id?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Scalars['JSON']>
  sourceId?: InputMaybe<Scalars['String']>
  sourceName?: InputMaybe<Scalars['String']>
  startDate: Scalars['ISO8601DateTime']
  value?: InputMaybe<Scalars['Float']>
}

export type NumericalSelect = {
  __typename?: 'NumericalSelect'
  text: Scalars['String']
  value: Scalars['Float']
}

export type NutritionCard = {
  __typename?: 'NutritionCard'
  nutrition: NutritionStatistics
  score: GlucoseDailyScore
  statistics: GlucoseStatistics
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type NutritionStatistics = {
  __typename?: 'NutritionStatistics'
  today: Array<KeyValuePairFloat>
}

export type Onboarding = {
  __typename?: 'Onboarding'
  tutorials: Array<Tutorials>
}

export type OneTimePaymentAddress = {
  city: Scalars['String']
  country?: InputMaybe<AddressCountries>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street: Scalars['String']
  street2?: InputMaybe<Scalars['String']>
  zipCode: Scalars['String']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['Int']
  isNutrisense: Scalars['Boolean']
  name: Scalars['String']
}

export type Outcome = {
  __typename?: 'Outcome'
  id: Scalars['ID']
  key: Scalars['String']
  reason: Reason
}

export type Pagination = {
  endTime?: InputMaybe<Scalars['ISO8601DateTime']>
  first?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  startTime?: InputMaybe<Scalars['ISO8601DateTime']>
  type?: InputMaybe<Scalars['String']>
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  cardType: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  expirationDate: Scalars['ISO8601Date']
  last4: Scalars['String']
  stripeId: Scalars['String']
}

export enum Period {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Week = 'week'
}

export type Pill = {
  __typename?: 'Pill'
  fill?: Maybe<UiPillFill>
  size?: Maybe<UiPillSize>
  text: Scalars['String']
  type?: Maybe<UiPillType>
}

export type Plan = {
  __typename?: 'Plan'
  amount: Scalars['Float']
  id: Scalars['ID']
  nickname: Scalars['String']
  product: ProductMin
  stripeId: Scalars['String']
}

export type Product = {
  __typename?: 'Product'
  addon: Scalars['Boolean']
  billingDateChangeable: Scalars['Boolean']
  cgm: Scalars['Boolean']
  commitmentMonths?: Maybe<Scalars['Int']>
  core: Scalars['Boolean']
  description?: Maybe<Scalars['String']>
  details?: Maybe<Array<Scalars['String']>>
  dietitian: Scalars['Boolean']
  dietitianMenu: Scalars['Boolean']
  /** @deprecated Field removed from product */
  dietitianTrialDays?: Maybe<Scalars['Int']>
  eligibilityListItems: Array<Scalars['String']>
  eligibleUpgradeProducts: Array<ProductMin>
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  includedOptions?: Maybe<Array<Scalars['String']>>
  includesCgm: Scalars['Boolean']
  incompatibleAddons: Array<ProductMin>
  insuranceCoverage: Scalars['Boolean']
  interval: Interval
  key: Scalars['String']
  order: Scalars['Int']
  ownSensor: Scalars['Boolean']
  patch: Scalars['Boolean']
  pill?: Maybe<Pill>
  /** @deprecated Use price instead */
  plan: ProductPlan
  price: Scalars['Float']
  productGroup?: Maybe<ProductGroup>
  programsName?: Maybe<Scalars['String']>
  recurring: Scalars['Boolean']
  requiresShipping: Scalars['Boolean']
  schedulingRule: SchedulingRule
  shortDescription?: Maybe<Scalars['String']>
  softwareOnly: Scalars['Boolean']
  title: Scalars['String']
  trialDays?: Maybe<Scalars['Int']>
  uncancelable: Scalars['Boolean']
  url?: Maybe<Scalars['String']>
}

export type ProductCollection = {
  __typename?: 'ProductCollection'
  products: Array<Product>
}

export type ProductGroup = {
  __typename?: 'ProductGroup'
  description: Scalars['String']
  details: Array<Scalars['String']>
  id: Scalars['ID']
  interval: Interval
  key: Scalars['String']
  kind?: Maybe<BillingProductGroupKind>
  order: Scalars['Int']
  shortDescription: Scalars['String']
  title: Scalars['String']
}

export type ProductMin = {
  __typename?: 'ProductMin'
  cgm: Scalars['Boolean']
  core: Scalars['Boolean']
  details?: Maybe<Array<Scalars['String']>>
  eligibleUpgradeProducts: Array<ProductMin>
  id: Scalars['ID']
  includesCgm: Scalars['Boolean']
  incompatibleAddons: Array<ProductMin>
  interval: Interval
  key: Scalars['String']
  ownSensor: Scalars['Boolean']
  patch: Scalars['Boolean']
  requiresShipping: Scalars['Boolean']
  softwareOnly: Scalars['Boolean']
  title: Scalars['String']
  uncancelable: Scalars['Boolean']
}

export type ProductPlan = {
  __typename?: 'ProductPlan'
  amount: Scalars['Int']
  id: Scalars['ID']
  stripeId: Scalars['String']
}

export type Program = {
  __typename?: 'Program'
  contentId: Scalars['ID']
  id: Scalars['ID']
  position?: Maybe<Scalars['Int']>
  progress?: Maybe<Progress>
}

export type ProgramCollection = {
  __typename?: 'ProgramCollection'
  programs: Array<ExpandableProgram>
}

export type Progress = {
  __typename?: 'Progress'
  complete: Scalars['Boolean']
}

export type PromotionCode = {
  __typename?: 'PromotionCode'
  amountOff?: Maybe<Scalars['Float']>
  applicable: Scalars['Boolean']
  code: Scalars['String']
  percentOff?: Maybe<Scalars['Float']>
  providerId: Scalars['String']
}

export type Provider = {
  __typename?: 'Provider'
  active: Scalars['Boolean']
  availableTypes?: Maybe<Array<Scalars['String']>>
  connectionId?: Maybe<Scalars['Int']>
  disabledTypes?: Maybe<Array<Scalars['String']>>
  errorReason?: Maybe<Scalars['String']>
  name: Scalars['String']
  provider: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export type ProviderSetupIntent = {
  __typename?: 'ProviderSetupIntent'
  clientSecret: Scalars['String']
}

export type PurchasableItem = {
  product: BillingProduct
  quantity: Scalars['Int']
}

export type QueryType = {
  __typename?: 'QueryType'
  activeNotifications: NotificationCollection
  allActivities: ActivityCollection
  allAppointments: AppointmentCollection
  allCharts: Charts
  allChatConversationMessages: ChatConversationMessageCollection
  allCourses: ProgramCollection
  allCustomIngredients: IngredientCollection
  allEvents: EventsData
  allFiles: ListFiles
  allMeals: MealCollection
  allMeasurements: MeasurementCollection
  allNotes: NoteCollection
  allNutrition: NutritionCard
  allProducts: ProductCollection
  allStats: Stats
  allSubscriptionSchedules: ScheduleCollection
  allSurveyLinks: SurveyLinkCollection
  allTerraProviders: ListProviders
  allUsersAutocomplete: UserCollection
  availableCourseCohortSessions: Array<CohortSession>
  /** @deprecated Use available_course_cohort_sessions */
  availableDppCourseCohortSessions: Array<CohortSession>
  calendar: UserCalendar
  chartsData: ChartsData
  checklistItems: ChecklistItemCollection
  clientConfig: ClientConfig
  coachAssignments: CoachAssignmentCollection
  coachProfile: CoachProfile
  courseCohort?: Maybe<Cohort>
  currentUser?: Maybe<User>
  dailyMeasurementsSourcePriority: DailyMeasurementsSourcePriority
  dataExport: Success
  /** @deprecated Use course_cohort */
  dppCourseCohort?: Maybe<Cohort>
  /** @deprecated Use enrollable_course_cohorts */
  dppEnrollableCourseCohorts?: Maybe<CohortCollection>
  dppHealthDataRecordsChecklist: DppHealthDataRecordsChecklist
  effectiveDailyMeasurement?: Maybe<EffectiveDailyMeasurement>
  ehrAllAvailabilities: Array<Availability>
  ehrAppointmentRecurrence?: Maybe<EhrAppointmentRecurrence>
  ehrAppointments: EhrAppointmentCollection
  ehrAvailableDateSlots: Slots
  ehrAvailableDates: Dates
  ehrAvailableDatesWithSlots: DatesWithSlots
  ehrEarlierAvailableSlots: Slots
  ehrSuggestedAvailability?: Maybe<Suggested>
  enrollableCourseCohorts?: Maybe<CohortCollection>
  estimateTax: EstimatedTax
  generateTerraAuthUrl: GenerateAuthUrl
  generateTerraSdkToken: GenerateSdkToken
  getHealthieApiKey: GetHealthieApiKey
  healthMetrics: HealthMetricCollection
  insuranceCardSubmission?: Maybe<InsuranceCardSubmission>
  insurancePlans: ListPlansActionPayload
  insurancePolicies: InsurancePolicyCollection
  insurancePolicy?: Maybe<InsurancePolicy>
  insuranceSchedulingAvailableDates: AvailableDates
  insuranceSchedulingAvailableSlots: AvailableSlots
  insuranceSchedulingEarlierAvailableSlots: AvailableSlots
  invoice: Invoice
  latestCharge?: Maybe<Charge>
  latestSurveyLinkByKind?: Maybe<SurveyLink>
  latestUserAppointment?: Maybe<LatestUserAppointment>
  latestUserEhrAppointment?: Maybe<EhrAppointment>
  legalPolicyConsentStatuses: LegalPolicyConsentStatuses
  linkupConnection?: Maybe<LinkupConnection>
  listUserDailyActions: DailyActionCollection
  meal: Meal
  mealImageDescription: ImageDescription
  product: Product
  promotionCode: PromotionCode
  referralPartner?: Maybe<UserReferralPartnerDef>
  reminders: ReminderCollection
  resetPasswordRequest: Success
  schedulingAllAvailabilities: Array<EhrAvailability>
  schedulingAvailableDatesWithSlots: AvailableDatesWithSlots
  schedulingSuggestedAvailability?: Maybe<SuggestedAvailability>
  sendUserInvite: Success
  upcomingCourseCohortSession?: Maybe<CohortSession>
  /** @deprecated Use upcoming_course_cohort_session */
  upcomingDppCourseCohortSession?: Maybe<CohortSession>
  validateGlucosePresenceTerraGoogleFit: Success
}

export type QueryTypeAllActivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllAppointmentsArgs = {
  dynamicFilters?: InputMaybe<Array<AppointmentDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<AppointmentSort>>
}

export type QueryTypeAllChartsArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeAllChatConversationMessagesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<WebMembersChatConversationMessageSort>>
}

export type QueryTypeAllCustomIngredientsArgs = {
  filter?: InputMaybe<IngredientFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllEventsArgs = {
  filter?: InputMaybe<DateFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllFilesArgs = {
  dynamicFilters?: InputMaybe<Array<FileDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<FileSort>>
}

export type QueryTypeAllMealsArgs = {
  filter?: InputMaybe<MealFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllMeasurementsArgs = {
  filter?: InputMaybe<MeasurementFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllNotesArgs = {
  dynamicFilters?: InputMaybe<Array<NoteDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<NoteSort>>
}

export type QueryTypeAllNutritionArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeAllProductsArgs = {
  category?: InputMaybe<BillingProductCategory>
}

export type QueryTypeAllStatsArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeAllUsersAutocompleteArgs = {
  limit?: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type QueryTypeAvailableCourseCohortSessionsArgs = {
  cohortKind: CoursesCohortKind
}

export type QueryTypeCalendarArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeChartsDataArgs = {
  filter: ChartFilter
}

export type QueryTypeChecklistItemsArgs = {
  kind: UserChecklistItemsKind
}

export type QueryTypeCoachProfileArgs = {
  coachId: Scalars['ID']
}

export type QueryTypeCourseCohortArgs = {
  kind: CoursesCohortKind
}

export type QueryTypeDailyMeasurementsSourcePriorityArgs = {
  type: HealthDataMeasurementType
}

export type QueryTypeEffectiveDailyMeasurementArgs = {
  date: Scalars['String']
  type: HealthDataMeasurementType
}

export type QueryTypeEhrAppointmentsArgs = {
  category?: InputMaybe<EhrAppointmentCategory>
  filterKind?: InputMaybe<FilterAppointmentsKind>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<EhrAppointmentSort>>
}

export type QueryTypeEhrAvailableDateSlotsArgs = {
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  date?: InputMaybe<Scalars['String']>
  providerId?: InputMaybe<Scalars['ID']>
  providerKind?: InputMaybe<EhrAppointmentProviderKind>
  timeZone?: InputMaybe<Scalars['String']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeEhrAvailableDatesArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  providerId?: InputMaybe<Scalars['ID']>
  providerKind?: InputMaybe<EhrAppointmentProviderKind>
  timeZone?: InputMaybe<Scalars['String']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeEhrAvailableDatesWithSlotsArgs = {
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
  providerId?: InputMaybe<Scalars['ID']>
  providerKind?: InputMaybe<EhrAppointmentProviderKind>
  rescheduleAppointmentId?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<AddressState>
  timeZone?: InputMaybe<Scalars['String']>
  uniqueSlots?: InputMaybe<Scalars['Boolean']>
  useCache?: InputMaybe<Scalars['Boolean']>
}

export type QueryTypeEhrEarlierAvailableSlotsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
}

export type QueryTypeEnrollableCourseCohortsArgs = {
  kind: CoursesCohortKind
}

export type QueryTypeEstimateTaxArgs = {
  product?: InputMaybe<BillingProduct>
  providerPromotionCodeId?: InputMaybe<Scalars['String']>
}

export type QueryTypeGenerateTerraAuthUrlArgs = {
  authRedirectUrl?: InputMaybe<Scalars['String']>
  provider: Scalars['String']
}

export type QueryTypeGetHealthieApiKeyArgs = {
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
}

export type QueryTypeInsuranceCardSubmissionArgs = {
  insurancePolicyStateId: Scalars['ID']
}

export type QueryTypeInsuranceSchedulingAvailableDatesArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']>
  timeZone?: InputMaybe<Scalars['String']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeInsuranceSchedulingAvailableSlotsArgs = {
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeInsuranceSchedulingEarlierAvailableSlotsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
}

export type QueryTypeInvoiceArgs = {
  id: Scalars['ID']
}

export type QueryTypeLatestChargeArgs = {
  providerSubscriptionId: Scalars['ID']
}

export type QueryTypeLatestSurveyLinkByKindArgs = {
  kind: SurveysConfigKind
}

export type QueryTypeLatestUserEhrAppointmentArgs = {
  category?: InputMaybe<EhrAppointmentCategory>
}

export type QueryTypeListUserDailyActionsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  status?: InputMaybe<UserDailyActionStatus>
}

export type QueryTypeMealArgs = {
  id: Scalars['ID']
}

export type QueryTypeMealImageDescriptionArgs = {
  image: Scalars['Upload']
}

export type QueryTypeProductArgs = {
  product: BillingProduct
}

export type QueryTypePromotionCodeArgs = {
  code?: InputMaybe<Scalars['String']>
  product?: InputMaybe<BillingProduct>
}

export type QueryTypeReferralPartnerArgs = {
  partner: UserReferralPartner
}

export type QueryTypeResetPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']>
}

export type QueryTypeSchedulingAvailableDatesWithSlotsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']>
  timeZone?: InputMaybe<Scalars['String']>
  uniqueSlots?: InputMaybe<Scalars['Boolean']>
  useCache?: InputMaybe<Scalars['Boolean']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeSendUserInviteArgs = {
  email?: InputMaybe<Scalars['String']>
}

export type QueryTypeUpcomingCourseCohortSessionArgs = {
  cohortKind: CoursesCohortKind
}

export type Question = {
  __typename?: 'Question'
  coachBubbleLabel?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  key: SurveysQuestionKey
  label?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  required: Scalars['Boolean']
  response: Response
}

export type QuestionsGroup = {
  __typename?: 'QuestionsGroup'
  banner?: Maybe<Scalars['String']>
  introScreen?: Maybe<IntroScreen>
  questions: Array<Question>
}

export type Range = {
  __typename?: 'Range'
  max: Scalars['Float']
  min: Scalars['Float']
}

export type RangePair = {
  __typename?: 'RangePair'
  x: Range
  y: Scalars['Numeric']
}

export type RangeUnit = {
  __typename?: 'RangeUnit'
  from?: Maybe<Scalars['Float']>
  rating: Scalars['String']
  to?: Maybe<Scalars['Float']>
  type: Scalars['String']
}

export type Reason = {
  __typename?: 'Reason'
  headline: Scalars['String']
  inlineAlert?: Maybe<InlineAlert>
  message: ReasonMessage
  notes: Array<Scalars['String']>
}

export type ReasonMessage = {
  __typename?: 'ReasonMessage'
  content: Scalars['String']
  links?: Maybe<Array<ReasonMessageLink>>
}

export type ReasonMessageLink = {
  __typename?: 'ReasonMessageLink'
  placeholder: Scalars['String']
  url: Scalars['String']
}

export type RecordedProgress = {
  __typename?: 'RecordedProgress'
  lesson: Lesson
  module: Module
  program: Program
}

export type Referral = {
  __typename?: 'Referral'
  credits: Scalars['Int']
  description: Scalars['String']
  discount: Scalars['Int']
  message: Scalars['String']
  title: Scalars['String']
}

/** Autogenerated return type of RegisterNotificationTokenAction. */
export type RegisterNotificationTokenActionPayload = {
  __typename?: 'RegisterNotificationTokenActionPayload'
  success: Scalars['Boolean']
}

export type Reminder = {
  __typename?: 'Reminder'
  dayOfWeek?: Maybe<NotificationEngineReminderDayOfWeek>
  frequency: NotificationEngineReminderFrequency
  hoursInterval?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  kind: NotificationEngineReminderKind
  sendOn?: Maybe<Scalars['ISO8601Date']>
  timeOfDay?: Maybe<Scalars['String']>
}

export type ReminderCollection = {
  __typename?: 'ReminderCollection'
  reminders: Array<Reminder>
}

export type ReminderFrequency = {
  __typename?: 'ReminderFrequency'
  key: NotificationEngineReminderFrequency
}

export type ReminderKind = {
  __typename?: 'ReminderKind'
  allowedFrequencies: Array<ReminderFrequency>
  icon: Scalars['String']
  key: NotificationEngineReminderKind
  title: Scalars['String']
}

export type RemindersSettings = {
  __typename?: 'RemindersSettings'
  hoursInterval: HoursInterval
}

export type ResearchFaqs = {
  __typename?: 'ResearchFaqs'
  dataProtectionUrl: Scalars['String']
  disableLibreScanUrl: Scalars['String']
  eligibilityUrl: Scalars['String']
  gettingStartedUrl: Scalars['String']
  rootUrl: Scalars['String']
  sensorComparisonUrl: Scalars['String']
}

export type ResearchState = {
  __typename?: 'ResearchState'
  status: DexcomResearchStatus
}

export type Response = {
  __typename?: 'Response'
  answersGroups?: Maybe<Array<AnswersGroup>>
  kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
  kindMetadata?: Maybe<Scalars['JSON']>
}

export type Scan = {
  __typename?: 'Scan'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  measurements: Array<Measurement>
  sensor?: Maybe<Sensor>
}

export type ScanData = {
  calibrationInfo?: InputMaybe<CalibrationInfo>
  history: Array<GlucoseData>
  isReady: Scalars['Boolean']
  isValid: Scalars['Boolean']
  maxLifeInMinutes?: InputMaybe<Scalars['Int']>
  model: Scalars['String']
  serialNumber: Scalars['String']
  startTime: Scalars['Int']
  status: Scalars['String']
  timeSinceStart: Scalars['Int']
  trend: Array<GlucoseData>
}

export type Schedule = {
  __typename?: 'Schedule'
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  currentPhaseEndAt?: Maybe<Scalars['ISO8601DateTime']>
  currentPhaseStartAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  phases: Array<SchedulePhase>
  releasedAt?: Maybe<Scalars['ISO8601DateTime']>
  status: SubscriptionScheduleStatus
  stripeId: Scalars['String']
  subscription?: Maybe<Subscription>
  updatedAt: Scalars['ISO8601DateTime']
}

export type ScheduleCollection = {
  __typename?: 'ScheduleCollection'
  schedules: Array<Schedule>
}

export type SchedulePhase = {
  __typename?: 'SchedulePhase'
  billingCycleAnchorChange?: Maybe<SubscriptionSchedulePhaseBillingCycleAnchorChange>
  createdAt: Scalars['ISO8601DateTime']
  description?: Maybe<Scalars['String']>
  endAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  price: Scalars['Float']
  primaryProduct?: Maybe<ProductMin>
  startAt: Scalars['ISO8601DateTime']
  updatedAt: Scalars['ISO8601DateTime']
}

export type SchedulingRule = {
  __typename?: 'SchedulingRule'
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  count?: Maybe<Scalars['Int']>
  key: InsuranceBerryStreetAppointmentSchedulingRule
}

export type Score = {
  __typename?: 'Score'
  danger: Scalars['Int']
  max: Scalars['Int']
  warning: Scalars['Int']
}

export type Sensor = {
  __typename?: 'Sensor'
  activationDate: Scalars['ISO8601Date']
  activationTime: Scalars['ISO8601DateTime']
  baselineAdjustment: Scalars['Int']
  createdAt: Scalars['ISO8601DateTime']
  daysRemaining: Scalars['Int']
  description: Scalars['String']
  expirationDate: Scalars['ISO8601Date']
  expirationTime: Scalars['ISO8601DateTime']
  expired: Scalars['Boolean']
  id: Scalars['ID']
  lastStatusChange?: Maybe<SensorStatusChange>
  model: Scalars['String']
  modelEnum?: Maybe<SensorModel>
  serialNumber: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export type SensorMode = {
  __typename?: 'SensorMode'
  kind: UserExperienceSensorModeKind
}

export enum SensorModel {
  DexcomG6 = 'dexcom_g6',
  DexcomG7 = 'dexcom_g7',
  DexcomOnePlus = 'dexcom_one_plus',
  DexcomStelo = 'dexcom_stelo',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre2Eu = 'libre2_eu',
  Libre3 = 'libre3',
  LibrePro = 'libre_pro',
  LibreUs = 'libre_us',
  Unknown = 'unknown'
}

export type SensorSettings = {
  __typename?: 'SensorSettings'
  baselineAdjustmentOptions: Array<NumericalSelect>
}

export enum SensorStatus {
  Active = 'active',
  Error = 'error',
  Expired = 'expired',
  Failure = 'failure',
  InsertionDetection = 'insertion_detection',
  InsertionFailed = 'insertion_failed',
  Paired = 'paired',
  Ready = 'ready',
  Shutdown = 'shutdown',
  Starting = 'starting',
  Unknown = 'unknown'
}

export type SensorStatusChange = {
  __typename?: 'SensorStatusChange'
  createdAt: Scalars['ISO8601Date']
  failure: Scalars['Boolean']
  status: SensorStatus
}

export type Settings = {
  __typename?: 'Settings'
  cancellationPolicy: Scalars['String']
  consumerHealthDataPrivacyPolicy: Scalars['String']
  privacyPolicy: Scalars['String']
  telehealth: Scalars['String']
  termsOfUse: Scalars['String']
}

export type ShowAlertOnCloseBehavior = {
  __typename?: 'ShowAlertOnCloseBehavior'
  body: Scalars['String']
  cancelTitle: Scalars['String']
  confirmTitle: Scalars['String']
  icon?: Maybe<UiIcon>
  title: Scalars['String']
}

export type SignIn = {
  email: Scalars['String']
  impersonate?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}

export enum SignUpState {
  Complete = 'complete',
  PendingPurchase = 'pending_purchase'
}

export type Signup = {
  __typename?: 'Signup'
  learnMore: Scalars['String']
}

export type SingleValueMeasurement = {
  __typename?: 'SingleValueMeasurement'
  value: Scalars['Float']
}

export type Slot = {
  __typename?: 'Slot'
  coachId: Scalars['ID']
  time: Scalars['ISO8601DateTime']
}

export type Slots = {
  __typename?: 'Slots'
  slots: Array<EhrSlot>
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Stat = {
  __typename?: 'Stat'
  mean: Scalars['Float']
}

export type StatMeta = {
  __typename?: 'StatMeta'
  key: Scalars['String']
  section: Scalars['String']
}

export type StatRange = {
  __typename?: 'StatRange'
  danger?: Maybe<Scalars['Float']>
  max?: Maybe<Scalars['Float']>
  min?: Maybe<Scalars['Float']>
  reverse?: Maybe<Scalars['Boolean']>
  warning?: Maybe<Scalars['Float']>
}

export type State = {
  __typename?: 'State'
  abbreviation: Scalars['String']
  name: Scalars['String']
}

export type Statistic = {
  __typename?: 'Statistic'
  change: Scalars['Int']
  description: Scalars['String']
  displayValue?: Maybe<Scalars['String']>
  meta: StatMeta
  range: StatRange
  title: Scalars['String']
  type: Scalars['String']
  units: Scalars['String']
  value: Scalars['Float']
  valuePrev: Scalars['Float']
}

export type Statistics = {
  __typename?: 'Statistics'
  imperial: ConfigUnit
  metric: ConfigUnit
  name: Scalars['String']
}

export type Stats = {
  __typename?: 'Stats'
  statistics: Array<Statistic>
}

export type StatsConfig = {
  __typename?: 'StatsConfig'
  filters: StatsFilters
}

export type StatsFilters = {
  __typename?: 'StatsFilters'
  types: Array<KeyValuePairStringArray>
}

export type StatsSettings = {
  __typename?: 'StatsSettings'
  key: Scalars['String']
  order: Scalars['Int']
  section: Scalars['String']
  visible: Scalars['Boolean']
}

export type StatsSettingsOrder = {
  key: Scalars['String']
  order: Scalars['Int']
  section: Scalars['String']
  visible: Scalars['Boolean']
}

export type StringPair = {
  __typename?: 'StringPair'
  displayValue?: Maybe<Scalars['String']>
  /** @deprecated Removed in v3.9.1 */
  name?: Maybe<Scalars['String']>
  x: Scalars['String']
  y?: Maybe<Scalars['Numeric']>
}

export type StringSelect = {
  __typename?: 'StringSelect'
  text: Scalars['String']
  value: Scalars['String']
}

export type StringValue = {
  endDate: Scalars['ISO8601DateTime']
  id?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Scalars['JSON']>
  sourceId?: InputMaybe<Scalars['String']>
  sourceName?: InputMaybe<Scalars['String']>
  startDate: Scalars['ISO8601DateTime']
  value: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  allowedPauseCycles: Scalars['Int']
  attachedSchedule?: Maybe<Schedule>
  cancelAt?: Maybe<Scalars['ISO8601DateTime']>
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>
  checkoutOptions: Array<CheckoutOption>
  commitmentEndAt?: Maybe<Scalars['ISO8601DateTime']>
  createdAt: Scalars['ISO8601DateTime']
  currentPeriodEndAt?: Maybe<Scalars['ISO8601DateTime']>
  dietitianAddOn: Scalars['Boolean']
  effectivelyActive: Scalars['Boolean']
  endAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  latestInvoice?: Maybe<Invoice>
  paidMonthsCount: Scalars['Int']
  paused: Scalars['Boolean']
  pausedMonthsCount: Scalars['Int']
  /** @deprecated Removed usage in v2.7.0 */
  plans: Array<Plan>
  price: Scalars['Float']
  /** @deprecated Use price and primary_product */
  primaryPlan: Plan
  primaryProduct: ProductMin
  resetBillingCycleAnchorAt?: Maybe<Scalars['ISO8601DateTime']>
  resumesAt?: Maybe<Scalars['ISO8601DateTime']>
  startAt: Scalars['ISO8601DateTime']
  status: SubscriptionStatus
  stripeId: Scalars['String']
  /** @deprecated Use status enum field */
  stripeStatus: Scalars['String']
  subscribedAt: Scalars['ISO8601DateTime']
  trialEndAt?: Maybe<Scalars['ISO8601DateTime']>
  updatedAt: Scalars['ISO8601DateTime']
  user: User
}

export type SubscriptionAddress = {
  city: Scalars['String']
  country?: InputMaybe<AddressCountries>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street: Scalars['String']
  street2?: InputMaybe<Scalars['String']>
  zipCode: Scalars['String']
}

export enum SubscriptionBonusKind {
  ComplimentarySupportFullSub_1mo = 'complimentary_support_full_sub_1mo',
  ComplimentarySupportFullSub_3mo = 'complimentary_support_full_sub_3mo',
  ComplimentarySupportFullSub_6mo = 'complimentary_support_full_sub_6mo',
  ComplimentarySupportFullSub_12mo = 'complimentary_support_full_sub_12mo',
  Free_2MonthDietitianFor_6And_12MonthsCommitment = 'free_2_month_dietitian_for_6_and_12_months_commitment',
  Free_2MonthsDietitianFor_3_6_12MonthsCommitment = 'free_2_months_dietitian_for_3_6_12_months_commitment',
  Free_3MonthsDietitianFor_3MonthsCommitment = 'free_3_months_dietitian_for_3_months_commitment',
  FreeDietitianFor_4thMonthOnRenewal = 'free_dietitian_for_4th_month_on_renewal',
  FreeDietitianFor_6MonthsCommitment = 'free_dietitian_for_6_months_commitment',
  FreeDietitianFor_7thMonthOnRenewal = 'free_dietitian_for_7th_month_on_renewal',
  FreeDietitianFor_12MonthsCommitment = 'free_dietitian_for_12_months_commitment',
  FreeDietitianFor_13thMonthOnRenewal = 'free_dietitian_for_13th_month_on_renewal',
  MedicalConsultAddon = 'medical_consult_addon',
  PaidDietitianSupport = 'paid_dietitian_support'
}

export enum SubscriptionCheckoutOptionKind {
  Prescription = 'prescription',
  Research = 'research',
  Stelo = 'stelo'
}

export enum SubscriptionSchedulePhaseBillingCycleAnchorChange {
  Automatic = 'automatic',
  PhaseStart = 'phase_start'
}

export enum SubscriptionScheduleStatus {
  Active = 'active',
  Canceled = 'canceled',
  Completed = 'completed',
  NotStarted = 'not_started',
  Released = 'released'
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type Success = {
  __typename?: 'Success'
  success: Scalars['Boolean']
}

export type Suggested = {
  __typename?: 'Suggested'
  appointmentKind: EhrAppointmentKind
  meetingAt: Scalars['ISO8601DateTime']
  providerId: Scalars['ID']
  providerName?: Maybe<Scalars['String']>
  providerProfile?: Maybe<CoachProfile>
  title?: Maybe<Scalars['String']>
}

export type SuggestedAvailability = {
  __typename?: 'SuggestedAvailability'
  appointmentType: InsuranceBerryStreetAppointmentType
  meetingAt: Scalars['ISO8601DateTime']
  nutritionistId: Scalars['ID']
  nutritionistName?: Maybe<Scalars['String']>
  nutritionistProfile?: Maybe<CoachProfile>
  title?: Maybe<Scalars['String']>
}

export type Survey = {
  __typename?: 'Survey'
  /** @deprecated use survey_link.early_exit_deep_link instead */
  allowEarlyExitBehavior?: Maybe<AllowEarlyExitBehavior>
  behavior: Behavior
  completedScreen?: Maybe<CompletedScreen>
  completionFlashMessage?: Maybe<Scalars['String']>
  followups: Array<Followup>
  header?: Maybe<Scalars['String']>
  kind: SurveysConfigKind
  questionsGroups: Array<QuestionsGroup>
  research: Scalars['Boolean']
  showAlertOnCloseBehavior?: Maybe<ShowAlertOnCloseBehavior>
  version: Scalars['String']
}

export enum SurveyConfigItem {
  IntroScreen = 'intro_screen',
  Question = 'question'
}

export type SurveyLink = {
  __typename?: 'SurveyLink'
  completionDeepLink?: Maybe<Scalars['String']>
  config: SurveyLinkConfig
  earlyExitDeepLink?: Maybe<Scalars['String']>
  finished: Scalars['Boolean']
  finishedAt?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  inProgress: Scalars['Boolean']
  outcome?: Maybe<Outcome>
  responses: Array<SurveyResponse>
  startedAt: Scalars['ISO8601DateTime']
  state: SurveysUserLinkState
  survey: Survey
  user?: Maybe<User>
}

export type SurveyLinkCollection = {
  __typename?: 'SurveyLinkCollection'
  surveyLinks: Array<SurveyLink>
}

export type SurveyLinkConfig = {
  __typename?: 'SurveyLinkConfig'
  currentItemIndex: Scalars['Int']
  items: Array<SurveyLinkConfigItem>
  questionsCount: Scalars['Int']
  sectionsCount: Scalars['Int']
}

export type SurveyLinkConfigIntroItem = {
  __typename?: 'SurveyLinkConfigIntroItem'
  footnote?: Maybe<Scalars['String']>
  header: Scalars['String']
  icon?: Maybe<UiIcon>
  imageUrl?: Maybe<Scalars['String']>
  inlineAlert?: Maybe<InlineAlert>
  introMarkdown?: Maybe<Scalars['String']>
  type: SurveyConfigItem
}

export type SurveyLinkConfigItem = SurveyLinkConfigIntroItem | SurveyLinkConfigQuestionItem

export type SurveyLinkConfigQuestionItem = {
  __typename?: 'SurveyLinkConfigQuestionItem'
  imageUrl?: Maybe<Scalars['String']>
  key: SurveysQuestionKey
  label?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  required: Scalars['Boolean']
  response: Response
  type: SurveyConfigItem
}

export type SurveyResponse = {
  __typename?: 'SurveyResponse'
  answer: Scalars['JSON']
  id: Scalars['ID']
  questionKey: SurveysQuestionKey
  surveyLink: SurveyLink
}

export enum SurveysConfigKind {
  AccountInterest = 'account_interest',
  Cancellation = 'cancellation',
  CareEligibility = 'care_eligibility',
  CareIntake = 'care_intake',
  CareLabWorkResults = 'care_lab_work_results',
  CareRenewal = 'care_renewal',
  CgmMedicalDisclaimer = 'cgm_medical_disclaimer',
  CgmPrerequisite = 'cgm_prerequisite',
  Demographics = 'demographics',
  DexcomResearchConsent = 'dexcom_research_consent',
  DietaryHabits = 'dietary_habits',
  DppEligibility = 'dpp_eligibility',
  DppEnrollment = 'dpp_enrollment',
  DppEvidence = 'dpp_evidence',
  DppScheduling = 'dpp_scheduling',
  ExerciseHabits = 'exercise_habits',
  Goals = 'goals',
  GovernmentId = 'government_id',
  GroupVideoCallsEligibility = 'group_video_calls_eligibility',
  GroupVideoCallsInsuranceIneligible = 'group_video_calls_insurance_ineligible',
  Health = 'health',
  HealthUpdate = 'health_update',
  Insurance = 'insurance',
  InsuranceCallHesitation = 'insurance_call_hesitation',
  InsuranceEarlyExit = 'insurance_early_exit',
  InsuranceFallback = 'insurance_fallback',
  MedicalDisclaimer = 'medical_disclaimer',
  MedicalHistory = 'medical_history',
  MidpointPulse = 'midpoint_pulse',
  OneMonthActiveFeedback = 'one_month_active_feedback',
  OwnSensor = 'own_sensor',
  OwnSensorGoal = 'own_sensor_goal',
  PostCallFeedback = 'post_call_feedback',
  PostCallFeedbackLowRating = 'post_call_feedback_low_rating',
  SleepHabits = 'sleep_habits',
  SteloEligibility = 'stelo_eligibility',
  VideoCallFeedback = 'video_call_feedback',
  WeightLossExperienceDesiredWeight = 'weight_loss_experience_desired_weight',
  WeightLossExperienceGoalsAndHabits = 'weight_loss_experience_goals_and_habits',
  WeightLossExperiencePace = 'weight_loss_experience_pace'
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind {
  Inline = 'inline',
  Standalone = 'standalone'
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder {
  Random = 'random',
  Serial = 'serial'
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseKind {
  Address = 'address',
  CheckboxMulti = 'checkbox_multi',
  Consent = 'consent',
  DateInput = 'date_input',
  FileUpload = 'file_upload',
  HeightImperial = 'height_imperial',
  PhoneNumber = 'phone_number',
  Radio = 'radio',
  SelectSingle = 'select_single',
  Slider = 'slider',
  SliderWithSkip = 'slider_with_skip',
  TabsTextInput = 'tabs_text_input',
  TextArea = 'text_area',
  TextAreaWithSkip = 'text_area_with_skip',
  TextInput = 'text_input'
}

export enum SurveysQuestionKey {
  Address = 'address',
  AdhesiveAllergy = 'adhesive_allergy',
  AetnaInsurancePlanType = 'aetna_insurance_plan_type',
  AgeRange = 'age_range',
  AlcoholicBeveragesFrequency = 'alcoholic_beverages_frequency',
  AnnualIncomeRange = 'annual_income_range',
  AnthemInsurancePlanType = 'anthem_insurance_plan_type',
  AnythingElse = 'anything_else',
  AppetiteChange = 'appetite_change',
  AppetiteLevel = 'appetite_level',
  AvoidingDiabetesPriority = 'avoiding_diabetes_priority',
  BedtimeRoutine = 'bedtime_routine',
  BedtimeRoutineOther = 'bedtime_routine_other',
  BetterStatementAboutOwnHealth = 'better_statement_about_own_health',
  BiologicalSex = 'biological_sex',
  BirthDate = 'birth_date',
  BloodPressureRisk = 'blood_pressure_risk',
  BloodTestRisk = 'blood_test_risk',
  BlueCrossBlueShieldInsurancePlanType = 'blue_cross_blue_shield_insurance_plan_type',
  Bmi = 'bmi',
  BmiRange = 'bmi_range',
  BodyFat = 'body_fat',
  BodyPhoto = 'body_photo',
  BreakfastFrequency = 'breakfast_frequency',
  CancellationReason = 'cancellation_reason',
  CancellationReasonOther = 'cancellation_reason_other',
  CancerTreatment = 'cancer_treatment',
  CgmDevice = 'cgm_device',
  CgmDeviceOther = 'cgm_device_other',
  CignaInsurancePlanType = 'cigna_insurance_plan_type',
  CoachBetterSupport = 'coach_better_support',
  CoachFocus = 'coach_focus',
  CoachValueSoFar = 'coach_value_so_far',
  CoachingStyle = 'coaching_style',
  CommunicationFrequency = 'communication_frequency',
  ComplimentarySupportDecision = 'complimentary_support_decision',
  CompoundedSemaglutideConsent = 'compounded_semaglutide_consent',
  ConfidentInterpretGlucoseData = 'confident_interpret_glucose_data',
  ConfirmReject = 'confirm_reject',
  CurrentHba1cLevel = 'current_hba1c_level',
  DairyProductsFrequency = 'dairy_products_frequency',
  DefineProgress = 'define_progress',
  DescribesYou = 'describes_you',
  DescribesYouOther = 'describes_you_other',
  DexcomAppCompatibility = 'dexcom_app_compatibility',
  DexcomResearchConsent = 'dexcom_research_consent',
  Diabetes = 'diabetes',
  DiabetesDiagnosis = 'diabetes_diagnosis',
  DiabetesMedication = 'diabetes_medication',
  DiagnosedConditions = 'diagnosed_conditions',
  DiagnosedConditions2 = 'diagnosed_conditions2',
  DiagnosedConditionsOther = 'diagnosed_conditions_other',
  Diet = 'diet',
  DietOther = 'diet_other',
  DietaryGuidelinesRestrictions = 'dietary_guidelines_restrictions',
  DietaryGuidelinesRestrictionsOther = 'dietary_guidelines_restrictions_other',
  DietaryHabitsChanges = 'dietary_habits_changes',
  DietaryHabitsChangesDetails = 'dietary_habits_changes_details',
  DietaryHabitsChangesDetailsOther = 'dietary_habits_changes_details_other',
  DinnerFrequency = 'dinner_frequency',
  Disabilities = 'disabilities',
  DiscoverNs = 'discover_ns',
  DiscoverNsBlog = 'discover_ns_blog',
  DiscoverNsDoctor = 'discover_ns_doctor',
  DiscoverNsFacebook = 'discover_ns_facebook',
  DiscoverNsFamily = 'discover_ns_family',
  DiscoverNsFriend = 'discover_ns_friend',
  DiscoverNsInfluencer = 'discover_ns_influencer',
  DiscoverNsInstagram = 'discover_ns_instagram',
  DiscoverNsLinkedin = 'discover_ns_linkedin',
  DiscoverNsMagazine = 'discover_ns_magazine',
  DiscoverNsMedia = 'discover_ns_media',
  DiscoverNsNewsletter = 'discover_ns_newsletter',
  DiscoverNsOther = 'discover_ns_other',
  DiscoverNsPinterest = 'discover_ns_pinterest',
  DiscoverNsPodcast = 'discover_ns_podcast',
  DiscoverNsRadio = 'discover_ns_radio',
  DiscoverNsReddit = 'discover_ns_reddit',
  DiscoverNsSearchEngine = 'discover_ns_search_engine',
  DiscoverNsTikTok = 'discover_ns_tik_tok',
  DiscoverNsTwitter = 'discover_ns_twitter',
  DiscoverNsYoutube = 'discover_ns_youtube',
  DppCommercialInsurancePlan = 'dpp_commercial_insurance_plan',
  DppCommercialInsurancePlanOther = 'dpp_commercial_insurance_plan_other',
  DppHbA1cEvidence = 'dpp_hb_a1c_evidence',
  DppHealthcareProfessionalRecommendation = 'dpp_healthcare_professional_recommendation',
  DppInsurancePlan = 'dpp_insurance_plan',
  DppPreferredCallDays = 'dpp_preferred_call_days',
  DppPreferredCallTimes = 'dpp_preferred_call_times',
  DppProgramMotivation = 'dpp_program_motivation',
  EarliestWakeUpTime = 'earliest_wake_up_time',
  EatOutFrequency = 'eat_out_frequency',
  EatingDisorder = 'eating_disorder',
  EatingDisorderType = 'eating_disorder_type',
  EducationLevel = 'education_level',
  EducationLevelOther = 'education_level_other',
  ElectronicDevicesBeforeBedtime = 'electronic_devices_before_bedtime',
  EmploymentStatus = 'employment_status',
  EmploymentStatusOther = 'employment_status_other',
  EnergyLevelsChanges = 'energy_levels_changes',
  Ethnicity = 'ethnicity',
  EthnicityOther = 'ethnicity_other',
  ExerciseDuration = 'exercise_duration',
  ExerciseHabitsChanges = 'exercise_habits_changes',
  ExerciseHabitsChangesDetails = 'exercise_habits_changes_details',
  ExerciseHabitsChangesDetailsOther = 'exercise_habits_changes_details_other',
  ExerciseLocation = 'exercise_location',
  ExerciseLocationOther = 'exercise_location_other',
  ExerciseMotivation = 'exercise_motivation',
  ExerciseMotivationOther = 'exercise_motivation_other',
  ExerciseTypes = 'exercise_types',
  ExerciseTypesOther = 'exercise_types_other',
  FallAsleepTime = 'fall_asleep_time',
  FamiliarPrediabeticsConfirmation = 'familiar_prediabetics_confirmation',
  FamilyDiabetes = 'family_diabetes',
  FamilyMedicalConditions = 'family_medical_conditions',
  FastingGlucoseToleranceBloodTest = 'fasting_glucose_tolerance_blood_test',
  FishSeafoodFrequency = 'fish_seafood_frequency',
  FoodAllergies = 'food_allergies',
  FoodAllergiesIntolerances = 'food_allergies_intolerances',
  FoodAllergiesIntolerancesOther = 'food_allergies_intolerances_other',
  FoodAllergiesOther = 'food_allergies_other',
  FoodCravings = 'food_cravings',
  FoodCravingsChange = 'food_cravings_change',
  FoodCravingsDescription = 'food_cravings_description',
  FoodSymptoms = 'food_symptoms',
  FoodSymptomsOther = 'food_symptoms_other',
  FruitsVegetablesFrequency = 'fruits_vegetables_frequency',
  Gender = 'gender',
  GestationalDiabetesDiagnosis = 'gestational_diabetes_diagnosis',
  GlpActivePrescription = 'glp_active_prescription',
  GlpAdditionalHealthConditions = 'glp_additional_health_conditions',
  GlpCommonSideEffects = 'glp_common_side_effects',
  GlpConditions = 'glp_conditions',
  GlpConsiderPregnant = 'glp_consider_pregnant',
  GlpCurrentDose = 'glp_current_dose',
  GlpDosePreference = 'glp_dose_preference',
  GlpDoseScheduleAdherence = 'glp_dose_schedule_adherence',
  GlpDoseScheduleAdherenceDescription = 'glp_dose_schedule_adherence_description',
  GlpElevatedTriglycerides = 'glp_elevated_triglycerides',
  GlpElevatedTriglyceridesValue = 'glp_elevated_triglycerides_value',
  GlpGallbladderDisease = 'glp_gallbladder_disease',
  GlpGallbladderRemoved = 'glp_gallbladder_removed',
  GlpGastricBypassSurgery = 'glp_gastric_bypass_surgery',
  GlpGastricBypassSurgeryDate = 'glp_gastric_bypass_surgery_date',
  GlpGastricBypassSurgeryType = 'glp_gastric_bypass_surgery_type',
  GlpGoal = 'glp_goal',
  GlpGoalOther = 'glp_goal_other',
  GlpHeaviestWeightInPounds = 'glp_heaviest_weight_in_pounds',
  GlpHistoryBipolarDepression = 'glp_history_bipolar_depression',
  GlpIncreasePhysicalActivity = 'glp_increase_physical_activity',
  GlpIndianAsianDescent = 'glp_indian_asian_descent',
  GlpKidneyDisease = 'glp_kidney_disease',
  GlpKidneyDiseaseGfr = 'glp_kidney_disease_gfr',
  GlpLabWorkResults = 'glp_lab_work_results',
  GlpLastInPersonMedicalEvaluationDate = 'glp_last_in_person_medical_evaluation_date',
  GlpMedicationChallenges = 'glp_medication_challenges',
  GlpMedicationChallengesDescription = 'glp_medication_challenges_description',
  GlpMedicationQualityOfLife = 'glp_medication_quality_of_life',
  GlpOtherDose = 'glp_other_dose',
  GlpOtherSideEffects = 'glp_other_side_effects',
  GlpPhysicianQuestions = 'glp_physician_questions',
  GlpPrescriptionMedications = 'glp_prescription_medications',
  GlpPrescriptionMedicationsList = 'glp_prescription_medications_list',
  GlpProgressRate = 'glp_progress_rate',
  GlpProviderQuestions = 'glp_provider_questions',
  GlpPsychiatricFacility = 'glp_psychiatric_facility',
  GlpReduceCaloricIntake = 'glp_reduce_caloric_intake',
  GlpRestrictedConditions = 'glp_restricted_conditions',
  GlpSevereSideEffects = 'glp_severe_side_effects',
  GlpTakingWeightMedication = 'glp_taking_weight_medication',
  GlpTriedWeightManagement = 'glp_tried_weight_management',
  GlpTriedWeightManagementOther = 'glp_tried_weight_management_other',
  GlpWeightGoalInPounds = 'glp_weight_goal_in_pounds',
  GlucoseManagementKnowledge = 'glucose_management_knowledge',
  GoalsPrevention = 'goals_prevention',
  GoalsPreventionOther = 'goals_prevention_other',
  GovernmentIdCard = 'government_id_card',
  GroupCoachingParticipation = 'group_coaching_participation',
  HavingOwnHealthUpsAndDownsConfirmation = 'having_own_health_ups_and_downs_confirmation',
  Hba1cBloodTest = 'hba1c_blood_test',
  Hba1cBloodTestRange = 'hba1c_blood_test_range',
  Hba1cBloodTestResult = 'hba1c_blood_test_result',
  Hba1cMeasurementTime = 'hba1c_measurement_time',
  HealthConditions = 'health_conditions',
  HealthConditionsImpactExercise = 'health_conditions_impact_exercise',
  HealthConditionsOther = 'health_conditions_other',
  HealthGoal = 'health_goal',
  HealthInsuranceCard = 'health_insurance_card',
  Height = 'height',
  HeightInInches = 'height_in_inches',
  HighmarkInsurancePlanType = 'highmark_insurance_plan_type',
  HipCircumferenceInInches = 'hip_circumference_in_inches',
  HorizonInsurancePlanType = 'horizon_insurance_plan_type',
  InjuriesImpactExercise = 'injuries_impact_exercise',
  Insulin = 'insulin',
  InsulinOrCancerTreatment = 'insulin_or_cancer_treatment',
  InsuranceCallHesitationReason = 'insurance_call_hesitation_reason',
  InsuranceCallHesitationReasonOther = 'insurance_call_hesitation_reason_other',
  InsuranceEarlyExitReason = 'insurance_early_exit_reason',
  InsuranceEarlyExitReasonOther = 'insurance_early_exit_reason_other',
  InsuranceEarlyExitSecondReason = 'insurance_early_exit_second_reason',
  InsuranceEarlyExitSecondReasonOther = 'insurance_early_exit_second_reason_other',
  InsuranceGroupNumber = 'insurance_group_number',
  InsuranceMemberId = 'insurance_member_id',
  InsurancePlan = 'insurance_plan',
  InsurancePlanOther = 'insurance_plan_other',
  InsurancePolicyHolder = 'insurance_policy_holder',
  InsurancePolicyHolderDateOfBirth = 'insurance_policy_holder_date_of_birth',
  InsurancePolicyHolderFirstName = 'insurance_policy_holder_first_name',
  InsurancePolicyHolderLastName = 'insurance_policy_holder_last_name',
  InsurancePolicyHolderOther = 'insurance_policy_holder_other',
  InsuranceReimbursementPossibility = 'insurance_reimbursement_possibility',
  InsuranceVideoCallGoal = 'insurance_video_call_goal',
  InsuranceVideoCallGoalOther = 'insurance_video_call_goal_other',
  LabWork = 'lab_work',
  LabWorkResults = 'lab_work_results',
  LearnSpecificFoodsAffectDiabetes = 'learn_specific_foods_affect_diabetes',
  LifestyleChanges = 'lifestyle_changes',
  LifestyleConfidence = 'lifestyle_confidence',
  LikelihoodFollowWorkoutPlan = 'likelihood_follow_workout_plan',
  LikelihoodToChangeSleepHabits = 'likelihood_to_change_sleep_habits',
  LongTermWeightGoalEffort = 'long_term_weight_goal_effort',
  LunchFrequency = 'lunch_frequency',
  MedicalDisclaimerConsent = 'medical_disclaimer_consent',
  MedicalHistoryChanges = 'medical_history_changes',
  MedicalHistoryChangesDescription = 'medical_history_changes_description',
  MedicationChanges = 'medication_changes',
  Medications = 'medications',
  MetabolismDisorder = 'metabolism_disorder',
  MobileAppMedicalDisclaimer = 'mobile_app_medical_disclaimer',
  MorningRestfulness = 'morning_restfulness',
  NfcSupported = 'nfc_supported',
  NotUnderstandingOwnHealthConfirmation = 'not_understanding_own_health_confirmation',
  NsValueInPastTwoWeeks = 'ns_value_in_past_two_weeks',
  NutritionKnowledge = 'nutrition_knowledge',
  NutritionistContinueReachGoals = 'nutritionist_continue_reach_goals',
  NutritionistStartStopReachGoals = 'nutritionist_start_stop_reach_goals',
  Occupation = 'occupation',
  OneMonthActiveFeedbackDietitian = 'one_month_active_feedback_dietitian',
  OtherAllergies = 'other_allergies',
  OtherAllergiesList = 'other_allergies_list',
  OtherInsurancePlanType = 'other_insurance_plan_type',
  OwnGlucometer = 'own_glucometer',
  OwnSensorGoal = 'own_sensor_goal',
  OwnSensorGoalOther = 'own_sensor_goal_other',
  PainOrDiscomfortDuringSleep = 'pain_or_discomfort_during_sleep',
  PastHealthConditions = 'past_health_conditions',
  PharmacyServicesConsent = 'pharmacy_services_consent',
  PhoneNumber = 'phone_number',
  PhysicalActivity = 'physical_activity',
  PhysicalActivityFrequency = 'physical_activity_frequency',
  PostCallFeedbackContinuation = 'post_call_feedback_continuation',
  PostCallFeedbackDoneBetter = 'post_call_feedback_done_better',
  PostCallFeedbackMostValuable = 'post_call_feedback_most_valuable',
  PoultryFrequency = 'poultry_frequency',
  PreExistingMedicalConditions = 'pre_existing_medical_conditions',
  PreExistingMedicalConditionsList = 'pre_existing_medical_conditions_list',
  Pregnancy = 'pregnancy',
  PregnancyTerms = 'pregnancy_terms',
  PrescriptionMedications = 'prescription_medications',
  PrescriptionMedicationsList = 'prescription_medications_list',
  PrimaryJoiningGoal = 'primary_joining_goal',
  PrimaryJoiningGoalOther = 'primary_joining_goal_other',
  ProcessedFoodFrequency = 'processed_food_frequency',
  RecurringSubscriptionConsent = 'recurring_subscription_consent',
  RedMeatFrequency = 'red_meat_frequency',
  ReversedConditions = 'reversed_conditions',
  ReversedConditionsOther = 'reversed_conditions_other',
  SanaBenefitsInsurancePlanType = 'sana_benefits_insurance_plan_type',
  Sex = 'sex',
  ShareWithCoach = 'share_with_coach',
  SittingHours = 'sitting_hours',
  SleepChanges = 'sleep_changes',
  SleepChangesDetails = 'sleep_changes_details',
  SleepChangesDetailsOther = 'sleep_changes_details_other',
  SleepDisorderDiagnosis = 'sleep_disorder_diagnosis',
  SleepHours = 'sleep_hours',
  SnackFrequency = 'snack_frequency',
  SteloDeviceEligibility = 'stelo_device_eligibility',
  SteloMedicalEligibility = 'stelo_medical_eligibility',
  StepsPerDay = 'steps_per_day',
  Stress = 'stress',
  SubstancesBeforeBedtime = 'substances_before_bedtime',
  SuccessLookLike = 'success_look_like',
  SugaryDrinksFrequency = 'sugary_drinks_frequency',
  Supplements = 'supplements',
  SurgeriesHospitalizations = 'surgeries_hospitalizations',
  TelehealthConsent = 'telehealth_consent',
  Type_2DiabetesComplications = 'type_2_diabetes_complications',
  Type_2DiabetesDate = 'type_2_diabetes_date',
  Type_2DiabetesManagement = 'type_2_diabetes_management',
  UnfamiliarPrediabeticsConfirmation = 'unfamiliar_prediabetics_confirmation',
  UnitedHealthcareInsurancePlanType = 'united_healthcare_insurance_plan_type',
  VideoCallFeedbackConfidence = 'video_call_feedback_confidence',
  VideoCallFeedbackContinuation = 'video_call_feedback_continuation',
  VideoCallFeedbackImprovements = 'video_call_feedback_improvements',
  VideoCallFeedbackMostValuable = 'video_call_feedback_most_valuable',
  VideoCallFeedbackUsefulness = 'video_call_feedback_usefulness',
  VideoCallFeedbackWhy = 'video_call_feedback_why',
  WaistCircumferenceInInches = 'waist_circumference_in_inches',
  WakeUpFrequency = 'wake_up_frequency',
  Weight = 'weight',
  WeightChange = 'weight_change',
  WeightGainedInPounds = 'weight_gained_in_pounds',
  WeightGoalEffortWorksForYou = 'weight_goal_effort_works_for_you',
  WeightInPounds = 'weight_in_pounds',
  WeightLossExperienceBiggestChallenges = 'weight_loss_experience_biggest_challenges',
  WeightLossExperienceBiggestChallengesOther = 'weight_loss_experience_biggest_challenges_other',
  WeightLossExperienceComfortablePace = 'weight_loss_experience_comfortable_pace',
  WeightLossExperienceCurrentDiet = 'weight_loss_experience_current_diet',
  WeightLossExperienceDesiredWeight = 'weight_loss_experience_desired_weight',
  WeightLossExperienceMotivationToChangeEatingHabits = 'weight_loss_experience_motivation_to_change_eating_habits',
  WeightLossExperienceOpenToPhysicalActivityChanges = 'weight_loss_experience_open_to_physical_activity_changes',
  WeightLossExperiencePhysicalActivity = 'weight_loss_experience_physical_activity',
  WeightLossExperiencePrimaryMotivation = 'weight_loss_experience_primary_motivation',
  WeightLossExperiencePrimaryMotivationOther = 'weight_loss_experience_primary_motivation_other',
  WeightLossExperienceTriedLoseWeight = 'weight_loss_experience_tried_lose_weight',
  WeightLostInPounds = 'weight_lost_in_pounds',
  WillingToPay = 'willing_to_pay',
  WillingToPayComments = 'willing_to_pay_comments',
  WillingToPayMaximum = 'willing_to_pay_maximum',
  WillingToPayMaximumOther = 'willing_to_pay_maximum_other',
  WorkedWithTrainer = 'worked_with_trainer'
}

export enum SurveysUserLinkState {
  Completed = 'completed',
  Processed = 'processed',
  Restarted = 'restarted',
  Started = 'started',
  Stopped = 'stopped'
}

export type ThirdPartySignIn = {
  provider: ThirdPartySignInProvider
  providerToken: Scalars['String']
  providerUserId?: InputMaybe<Scalars['String']>
}

export enum ThirdPartySignInProvider {
  Apple = 'apple',
  Google = 'google'
}

export enum TicketKind {
  Subscription = 'subscription',
  Troubleshooting = 'troubleshooting'
}

export enum TicketReason {
  Accounts = 'accounts',
  Addons = 'addons',
  AppLogin = 'app_login',
  AppMealLogging = 'app_meal_logging',
  AppQuestionnaires = 'app_questionnaires',
  Billing = 'billing',
  Cancellation = 'cancellation',
  GeneralInquiries = 'general_inquiries',
  GeneralNutritionSupport = 'general_nutrition_support',
  HealthPortal = 'health_portal',
  InformationUpdate = 'information_update',
  Insurance = 'insurance',
  InsuranceInquiries = 'insurance_inquiries',
  IntegrationHealth = 'integration_health',
  IntegrationsDexcom = 'integrations_dexcom',
  IntegrationsOther = 'integrations_other',
  Other = 'other',
  Patches = 'patches',
  Pauses = 'pauses',
  Payments = 'payments',
  SensorActivation = 'sensor_activation',
  SensorCalibration = 'sensor_calibration',
  SensorFellOff = 'sensor_fell_off',
  SensorOther = 'sensor_other',
  SensorPainSkinIrritation = 'sensor_pain_skin_irritation',
  SensorScanning = 'sensor_scanning',
  Shipments = 'shipments',
  SubscriptionPurchase = 'subscription_purchase',
  SubscriptionUpdate = 'subscription_update',
  VideoCalls = 'video_calls'
}

export type TicketReasonDef = {
  __typename?: 'TicketReasonDef'
  key: TicketReason
  kind: TicketKind
  title: Scalars['String']
}

export type Tickets = {
  __typename?: 'Tickets'
  reasonDefs: Array<TicketReasonDef>
}

export type TimePair = {
  __typename?: 'TimePair'
  displayValue?: Maybe<Scalars['String']>
  interpolated: Scalars['Boolean']
  x: Scalars['ISO8601DateTime']
  y: Scalars['Numeric']
}

export type Token = {
  __typename?: 'Token'
  value: Scalars['String']
}

export enum TrackingMode {
  Cgm = 'cgm',
  Logging = 'logging'
}

export type TutorialStep = {
  __typename?: 'TutorialStep'
  description?: Maybe<Scalars['String']>
  moreUrl?: Maybe<Scalars['String']>
  photoUrlDark?: Maybe<Scalars['String']>
  photoUrlLight?: Maybe<Scalars['String']>
  title: Scalars['String']
  videoUrl?: Maybe<Scalars['String']>
}

export type Tutorials = {
  __typename?: 'Tutorials'
  key: Scalars['String']
  value: Array<TutorialStep>
}

export enum UiIcon {
  Avocado = 'avocado',
  Bed = 'bed',
  BellRinging = 'bell_ringing',
  Bluetooth = 'bluetooth',
  BookmarkSimple = 'bookmark_simple',
  CalendarBlank = 'calendar_blank',
  Carrot = 'carrot',
  ChartLine = 'chart_line',
  Coaching = 'coaching',
  Confetti = 'confetti',
  Done = 'done',
  Drop = 'drop',
  Fish = 'fish',
  Food = 'food',
  Footprints = 'footprints',
  ForkKnife = 'fork_knife',
  Gift = 'gift',
  Glucose = 'glucose',
  Goals = 'goals',
  Hamburger = 'hamburger',
  HandHeart = 'hand_heart',
  Health = 'health',
  Lifecycle = 'lifecycle',
  MoonStars = 'moon_stars',
  Percent = 'percent',
  PersonSimpleBike = 'person_simple_bike',
  PersonSimpleRun = 'person_simple_run',
  PersonSimpleSwim = 'person_simple_swim',
  PersonSimpleWalk = 'person_simple_walk',
  Plant = 'plant',
  Scales = 'scales',
  SealCheck = 'seal_check',
  Sleep = 'sleep',
  Sparkle = 'sparkle',
  Steps = 'steps',
  Thermometer = 'thermometer',
  Timer = 'timer',
  Warning = 'warning'
}

export enum UiInlineAlert {
  Danger = 'danger',
  Info = 'info',
  Neutral = 'neutral',
  Success = 'success',
  Warning = 'warning'
}

export enum UiPillFill {
  Brick = 'brick',
  Citron = 'citron',
  Moonstone = 'moonstone',
  Tea = 'tea'
}

export enum UiPillSize {
  M = 'm',
  S = 's'
}

export enum UiPillType {
  Alert = 'alert',
  Info = 'info',
  Neutral = 'neutral',
  Success = 'success',
  Warning = 'warning'
}

export type UiState = {
  __typename?: 'UIState'
  createdAt: Scalars['ISO8601DateTime']
  name: UiStateNames
  value: Scalars['Boolean']
}

export enum UiStateNames {
  CheckoutCompleted = 'checkout_completed',
  DexcomSensorPrompt = 'dexcom_sensor_prompt',
  OwnSensorGlucoseDetected = 'own_sensor_glucose_detected',
  OwnSensorOnboardingCompleted = 'own_sensor_onboarding_completed',
  OwnSensorTutorialCompleted = 'own_sensor_tutorial_completed',
  PrescriptionDexcomG7Allowed = 'prescription_dexcom_g7_allowed',
  SteloRealtimeApiUpgradeBanner = 'stelo_realtime_api_upgrade_banner',
  SteloShowMigrationBanner = 'stelo_show_migration_banner',
  SteloShowUpgradedBanner = 'stelo_show_upgraded_banner',
  SyncedLinkup = 'synced_linkup',
  SyncedSteloRealtime = 'synced_stelo_realtime',
  ViewedTrends = 'viewed_trends'
}

export type UpgradedSubscription = {
  __typename?: 'UpgradedSubscription'
  subscription: Subscription
}

export type User = {
  __typename?: 'User'
  activeRefillConsent?: Maybe<UserRefillConsent>
  addonLimits: Array<AddonLimit>
  address?: Maybe<Address>
  age?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  coachAssignmentInfo: CoachAssignmentInfo
  coachAssignments?: Maybe<Array<CoachAssignment>>
  coachChatConversation?: Maybe<ChatConversation>
  coachProfile?: Maybe<CoachProfile>
  compoundedSemaglutideConsent: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  /** @deprecated use ongoing_dietitian_subscription */
  currentDietitianSubscription?: Maybe<Subscription>
  dateOfBirth?: Maybe<Scalars['String']>
  dexcomConnection?: Maybe<DexcomConnectionType>
  dexcomSensorPromptUiState?: Maybe<Scalars['Boolean']>
  dexcomSteloConnection?: Maybe<DexcomConnectionType>
  eligibleForDexcomResearch: Scalars['Boolean']
  eligibleForInsuranceCoverage: Scalars['Boolean']
  email: Scalars['String']
  features: Array<MobileAppFeature>
  firstName: Scalars['String']
  floridaDigitalBillOfRightsConsent: Scalars['Boolean']
  followupSchedulingAvailable: Scalars['Boolean']
  fulfillmentConfiguration: FulfillmentConfiguration
  fullName: Scalars['String']
  futurePaymentsConsent: Scalars['Boolean']
  goalMetrics: Array<GoalMetric>
  governmentIdCard?: Maybe<Scalars['String']>
  healthReject?: Maybe<Scalars['String']>
  healthieId?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  intercomDigest?: Maybe<Scalars['String']>
  kind: UserKind
  lastAnsweredHealthSurveyQuestionKey?: Maybe<SurveysQuestionKey>
  lastAnsweredMedicalHistorySurveyQuestionKey?: Maybe<SurveysQuestionKey>
  lastAppPlatform?: Maybe<Scalars['String']>
  lastAppVersion?: Maybe<Scalars['String']>
  lastAppointmentDate?: Maybe<Scalars['ISO8601DateTime']>
  lastAsyncChatAssignment?: Maybe<CoachAssignment>
  /** @deprecated use last_async_chat_assignment */
  lastCoachAssignment?: Maybe<CoachAssignment>
  lastCoreSubscription?: Maybe<Subscription>
  lastDexcomResearchState?: Maybe<ResearchState>
  lastDietitianSubscription?: Maybe<Subscription>
  lastDoctorAssignment?: Maybe<DoctorAssignment>
  lastDppApprovalRequest?: Maybe<ApprovalRequest>
  lastGlpApprovalRequest?: Maybe<ApprovalRequest>
  lastGlpRenewalApprovalRequest?: Maybe<ApprovalRequest>
  lastGovernmentIdApprovalRequest?: Maybe<ApprovalRequest>
  lastMedicalAppointment?: Maybe<EhrAppointment>
  lastName: Scalars['String']
  lastOccurredCoachingAppointment?: Maybe<EhrAppointment>
  lastPrescriptionApprovalRequest?: Maybe<ApprovalRequest>
  lastResearchApprovalRequest?: Maybe<ApprovalRequest>
  lastSteloApprovalRequest?: Maybe<ApprovalRequest>
  lastVideoChatAssignment?: Maybe<CoachAssignment>
  latestCgmOrderDate?: Maybe<Scalars['ISO8601DateTime']>
  /** @deprecated use core subscription */
  latestInvoice?: Maybe<Invoice>
  libreAccount?: Maybe<LibreAccount>
  migrationFromInsuranceEnabled: Scalars['Boolean']
  nextAppointmentDate?: Maybe<Scalars['ISO8601DateTime']>
  nutrisenseAdmin: Scalars['Boolean']
  nutrisenseGetHealthieId?: Maybe<Scalars['Int']>
  ongoingCareNonRefundableConsent: Scalars['Boolean']
  ongoingCoreSubscription?: Maybe<Subscription>
  ongoingDietitianSubscription?: Maybe<Subscription>
  ongoingSubscriptions: Array<Subscription>
  organization: Organization
  /** @deprecated intents are deprecated */
  paymentFailedProviderErrorMessage?: Maybe<Scalars['String']>
  paymentMethod?: Maybe<PaymentMethod>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberConsent?: Maybe<Scalars['Boolean']>
  primaryGoal?: Maybe<Goal>
  referralPartner: UserReferralPartnerDef
  referralUrl: Scalars['String']
  refillConsentNeeded: Scalars['Boolean']
  role: UserRole
  sensor?: Maybe<Sensor>
  sensorInfo?: Maybe<UserSensorInfo>
  settings: UserSettings
  sex?: Maybe<Scalars['String']>
  signUpSource?: Maybe<UserSignUpSource>
  /** @deprecated deprecated in 2.8 */
  signUpState: SignUpState
  stripeCreditBalance: Scalars['Float']
  stripeId?: Maybe<Scalars['String']>
  subscriptions: Array<Subscription>
  surveyLinks: Array<SurveyLink>
  telehealthConsent: Scalars['Boolean']
  timeZone: Scalars['String']
  truepillPatientToken?: Maybe<Scalars['String']>
  uiStates: Array<UiState>
  userProperties?: Maybe<UserProperties>
  uxSensorMode: SensorMode
}

export enum UserApprovalRequestKind {
  Dpp = 'dpp',
  Glp = 'glp',
  GlpRenewal = 'glp_renewal',
  GovernmentId = 'government_id',
  Prescription = 'prescription',
  Research = 'research',
  Stelo = 'stelo'
}

export enum UserApprovalRequestState {
  Approved = 'approved',
  ConsentRejected = 'consent_rejected',
  ConsentWithdrawn = 'consent_withdrawn',
  DidNotComplete = 'did_not_complete',
  Init = 'init',
  PendingApproval = 'pending_approval',
  PendingMember = 'pending_member',
  PendingRejection = 'pending_rejection',
  PendingUpdate = 'pending_update',
  Rejected = 'rejected',
  Stale = 'stale'
}

export type UserAuth = {
  __typename?: 'UserAuth'
  /** @deprecated Use user_presenter instead */
  features: Array<MobileAppFeature>
  role?: Maybe<UserRole>
  status?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type UserCalendar = {
  __typename?: 'UserCalendar'
  dates: Array<CalendarDate>
}

export enum UserChecklistItem {
  CompleteGoalsQuestionnaire = 'complete_goals_questionnaire',
  CompleteVideoCall = 'complete_video_call',
  LogMeal = 'log_meal',
  ProvideInsuranceInformation = 'provide_insurance_information',
  ShareLabResults = 'share_lab_results',
  SignInToTheApp = 'sign_in_to_the_app',
  StartLesson = 'start_lesson',
  ViewTrends = 'view_trends'
}

export enum UserChecklistItemsKind {
  Onboarding = 'onboarding'
}

export type UserCollection = {
  __typename?: 'UserCollection'
  users: Array<UserMin>
}

export enum UserDailyActionCategory {
  Activity = 'activity',
  Experiment = 'experiment',
  Journal = 'journal',
  Nutrition = 'nutrition',
  Trends = 'trends'
}

export enum UserDailyActionKind {
  Carbs_15Grams_30MinutesBeforeExercise = 'carbs_15_grams_30_minutes_before_exercise',
  Carbs_30GramsFromBread = 'carbs_30_grams_from_bread',
  Carbs_30GramsFromDessert = 'carbs_30_grams_from_dessert',
  Carbs_30GramsFromFruit = 'carbs_30_grams_from_fruit',
  Carbs_30GramsFromRice = 'carbs_30_grams_from_rice',
  Carbs_30GramsFromStarchyVegetable = 'carbs_30_grams_from_starchy_vegetable',
  CompareMeals = 'compare_meals',
  DigestionQuality = 'digestion_quality',
  FastingWindow = 'fasting_window',
  Fiber_25Grams = 'fiber_25_grams',
  Fiber_38Grams = 'fiber_38_grams',
  GlucoseAverage = 'glucose_average',
  GlucoseMax = 'glucose_max',
  GlucoseMorningAverage = 'glucose_morning_average',
  GlucoseSleepAverage = 'glucose_sleep_average',
  GlucoseTimeInRange = 'glucose_time_in_range',
  GlucoseVariability = 'glucose_variability',
  HalfOfPlateNonStarchyVegetables = 'half_of_plate_non_starchy_vegetables',
  HighFiberAndProteinBreakfast = 'high_fiber_and_protein_breakfast',
  HungerLevels = 'hunger_levels',
  LastMeal_3HoursBeforeBed = 'last_meal_3_hours_before_bed',
  LowCarbDinner = 'low_carb_dinner',
  NoNakedCarbs = 'no_naked_carbs',
  NoScreenTime_1HourBeforeBed = 'no_screen_time_1_hour_before_bed',
  NonStarchVegetables_3To_5Cups = 'non_starch_vegetables_3_to_5_cups',
  Protein_30GramsPerMeal = 'protein_30_grams_per_meal',
  ProteinAfterExercise = 'protein_after_exercise',
  QuarterOfPlateProtein = 'quarter_of_plate_protein',
  Sleep_7To_9Hours = 'sleep_7_to_9_hours',
  SleepQuality = 'sleep_quality',
  Steps_7500 = 'steps_7500',
  StressLevels = 'stress_levels',
  VegetableOrProteinBeforeStarchyCarbs = 'vegetable_or_protein_before_starchy_carbs',
  Walk_15To_30MinutesAfterMeals = 'walk_15_to_30_minutes_after_meals',
  Water_8Glasses = 'water_8_glasses',
  Weight = 'weight'
}

export enum UserDailyActionStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export enum UserExperienceSensorModeKind {
  ActivePrescriptionDexcomG7 = 'active_prescription_dexcom_g7',
  ActiveResearchDexcom = 'active_research_dexcom',
  Default = 'default',
  DexcomStelo = 'dexcom_stelo',
  LibreLinkup = 'libre_linkup',
  OwnSensorDexcomG6 = 'own_sensor_dexcom_g6',
  OwnSensorDexcomG7 = 'own_sensor_dexcom_g7',
  OwnSensorDexcomStelo = 'own_sensor_dexcom_stelo',
  OwnSensorLibre1 = 'own_sensor_libre1',
  OwnSensorLibre2 = 'own_sensor_libre2',
  OwnSensorLibre3 = 'own_sensor_libre3',
  PendingLibre3 = 'pending_libre3',
  PendingPrescriptionDexcomG7 = 'pending_prescription_dexcom_g7',
  PendingResearchDexcom = 'pending_research_dexcom'
}

export enum UserFileTag {
  BodyPhoto = 'body_photo',
  DexcomResearchConsent = 'dexcom_research_consent',
  DppHbA1cEvidence = 'dpp_hb_a1c_evidence',
  DppWeightEvidence = 'dpp_weight_evidence',
  GlpLabWorkResults = 'glp_lab_work_results',
  GovernmentIdCard = 'government_id_card',
  HealthInsuranceCard = 'health_insurance_card',
  InsuranceCardBackPhoto = 'insurance_card_back_photo',
  InsuranceCardFrontPhoto = 'insurance_card_front_photo',
  IntercomAttachment = 'intercom_attachment',
  LabWorkResults = 'lab_work_results'
}

export enum UserGoalKind {
  ImproveGlucoseValues = 'improve_glucose_values',
  LearnAboutGlucoseLevels = 'learn_about_glucose_levels',
  LoseWeight = 'lose_weight',
  ManageChronicCondition = 'manage_chronic_condition',
  OptimizeFitnessPerformance = 'optimize_fitness_performance',
  Other = 'other',
  PreventDisease = 'prevent_disease',
  PromoteLongevity = 'promote_longevity'
}

export enum UserGoalMetricKind {
  GlucoseMax = 'glucose_max',
  GlucoseMean = 'glucose_mean',
  GlucoseMin = 'glucose_min',
  GlucoseMorningAverage = 'glucose_morning_average',
  GlucoseSleepAverage = 'glucose_sleep_average',
  GlucoseStandardDeviation = 'glucose_standard_deviation',
  GlucoseTimeWithinRange = 'glucose_time_within_range',
  JournalAppetite = 'journal_appetite',
  JournalEnergy = 'journal_energy',
  JournalFocus = 'journal_focus',
  JournalMood = 'journal_mood',
  JournalStress = 'journal_stress',
  MeasurementsWeight = 'measurements_weight'
}

export enum UserInsurancePolicyEligibilityStatus {
  Coinsurance = 'coinsurance',
  Copay = 'copay',
  Deductible = 'deductible',
  Eligible = 'eligible',
  NotEligible = 'not_eligible',
  NotSupported = 'not_supported',
  Pending = 'pending'
}

export enum UserInsurancePolicyHolderRelationship {
  Child = 'child',
  Other = 'other',
  Self = 'self',
  Spouse = 'spouse'
}

export enum UserInsurancePolicyKind {
  Aetna = 'aetna',
  Anthem = 'anthem',
  BlueCrossBlueShield = 'blue_cross_blue_shield',
  Cigna = 'cigna',
  Empire = 'empire',
  Highmark = 'highmark',
  Horizon = 'horizon',
  SanaBenefits = 'sana_benefits',
  UnitedHealthcare = 'united_healthcare'
}

export type UserInsurancePolicyKindDef = {
  __typename?: 'UserInsurancePolicyKindDef'
  key: UserInsurancePolicyKind
  title: Scalars['String']
}

export enum UserInsurancePolicyStateKind {
  Canceled = 'canceled',
  CanceledInsuranceNotAccepted = 'canceled_insurance_not_accepted',
  CanceledNoProvidersInn = 'canceled_no_providers_inn',
  Created = 'created',
  Eligible = 'eligible',
  EligiblePatientResponsibility = 'eligible_patient_responsibility',
  EligiblePreAuth = 'eligible_pre_auth',
  Hold = 'hold',
  InReview = 'in_review',
  NotEligible = 'not_eligible',
  NotSupported = 'not_supported'
}

export enum UserInsurancePolicyStatus {
  Created = 'created',
  InReview = 'in_review',
  Reviewed = 'reviewed',
  Submitted = 'submitted'
}

export enum UserKind {
  Influencer = 'influencer',
  Internal = 'internal',
  Investor = 'investor',
  Regular = 'regular'
}

export type UserMin = {
  __typename?: 'UserMin'
  email: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  phoneNumber: Scalars['String']
  timeZone: Scalars['String']
}

export type UserProperties = {
  __typename?: 'UserProperties'
  communicationFrequency?: Maybe<UserProperty>
  complimentarySupportDecision?: Maybe<UserProperty>
  dietitianRequest?: Maybe<UserProperty>
  dietitianSensorCountNotes?: Maybe<UserProperty>
  healthGoal?: Maybe<UserProperty>
  nutritionistGroup?: Maybe<UserProperty>
  oneMonthActiveFeedbackDietitian?: Maybe<UserProperty>
  scheduledVideoCallStatus?: Maybe<UserProperty>
  testGroup?: Maybe<UserProperty>
  videoCallRescheduleFreePassUsed?: Maybe<UserProperty>
  weightLossExperience?: Maybe<UserProperty>
}

export type UserProperty = {
  __typename?: 'UserProperty'
  id: Scalars['Int']
  kind: UserPropertyKind
  userId: Scalars['ID']
  value: Scalars['String']
}

export enum UserPropertyKind {
  ActiveInterventions = 'active_interventions',
  Background = 'background',
  BmiRange = 'bmi_range',
  CoachingStyle = 'coaching_style',
  CommunicationFrequency = 'communication_frequency',
  ComplimentarySupportDecision = 'complimentary_support_decision',
  CustomKeyPoint = 'custom_key_point',
  Diet = 'diet',
  DietitianNotes = 'dietitian_notes',
  DietitianRequest = 'dietitian_request',
  DietitianSensorCountNotes = 'dietitian_sensor_count_notes',
  DoNotSendWrapUpMessage = 'do_not_send_wrap_up_message',
  Education = 'education',
  FoodAllergies = 'food_allergies',
  FutureInterventions = 'future_interventions',
  GlucoseManagementKnowledge = 'glucose_management_knowledge',
  HealthConditions = 'health_conditions',
  HealthGoal = 'health_goal',
  Height = 'height',
  InitialInsuranceCallDuration = 'initial_insurance_call_duration',
  InsuranceReimbursementInterest = 'insurance_reimbursement_interest',
  InsuranceSurveySkipReason = 'insurance_survey_skip_reason',
  InsuranceVideoCallGoal = 'insurance_video_call_goal',
  Libre_3Outage = 'libre_3_outage',
  Libre_3OutagePreShipmentNotice = 'libre_3_outage_pre_shipment_notice',
  Medications = 'medications',
  NutritionistGroup = 'nutritionist_group',
  OneMonthActiveFeedbackDietitian = 'one_month_active_feedback_dietitian',
  OwnCgmDevice = 'own_cgm_device',
  OwnGlucometer = 'own_glucometer',
  PrimaryJoiningGoal = 'primary_joining_goal',
  ScheduledVideoCallStatus = 'scheduled_video_call_status',
  StepsPerDay = 'steps_per_day',
  TestGroup = 'test_group',
  VideoCallRescheduleFreePassUsed = 'video_call_reschedule_free_pass_used',
  Weight = 'weight',
  WeightLossExperience = 'weight_loss_experience'
}

export type UserPropertySubmission = {
  kind: UserPropertyKind
  value: Scalars['String']
}

export enum UserReferralPartner {
  Bf = 'bf',
  Bm = 'bm',
  Bodyspec = 'bodyspec',
  Bp = 'bp',
  Influencer = 'influencer',
  It = 'it',
  Lg = 'lg',
  LoseIt = 'lose_it',
  Lpd = 'lpd',
  Mfp = 'mfp',
  Mindypelz = 'mindypelz',
  NoPartner = 'no_partner',
  Pnmag = 'pnmag',
  Proov = 'proov',
  Sc = 'sc',
  Simple = 'simple',
  Stelo = 'stelo',
  Tovala = 'tovala',
  Trg = 'trg',
  Vip = 'vip'
}

export type UserReferralPartnerDef = {
  __typename?: 'UserReferralPartnerDef'
  key: UserReferralPartner
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  product?: Maybe<BillingProduct>
  requiresProtocol: Scalars['Boolean']
  schedulingWithoutInsuranceInformation: Scalars['Boolean']
  schedulingWithoutPaymentMethod: Scalars['Boolean']
}

export type UserRefillConsent = {
  __typename?: 'UserRefillConsent'
  givenAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  invalidatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export enum UserRole {
  Admin = 'admin',
  CustomerSupport = 'customer_support',
  Engineer = 'engineer',
  ExternalAdmin = 'external_admin',
  Nutritionist = 'nutritionist',
  NutritionistManager = 'nutritionist_manager',
  Operations = 'operations',
  User = 'user'
}

export type UserSensorInfo = {
  __typename?: 'UserSensorInfo'
  firstActivationDate?: Maybe<Scalars['ISO8601Date']>
  firstStartTime?: Maybe<Scalars['ISO8601DateTime']>
  lastSensor?: Maybe<Sensor>
  replacementRequestsCount: Scalars['Int']
  totalCount: Scalars['Int']
}

export enum UserSensorKind {
  DexcomG7 = 'dexcom_g7',
  DexcomStelo = 'dexcom_stelo',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre3 = 'libre3',
  PrescriptionDexcomG7 = 'prescription_dexcom_g7'
}

export type UserSettings = {
  __typename?: 'UserSettings'
  /** @deprecated Removed in v2.6.0 */
  appVersionReminder: Scalars['Boolean']
  canScanExpired: Scalars['Boolean']
  dashboardGraph: Scalars['String']
  dexcomSync: Scalars['Boolean']
  displayActivityScore: Scalars['Boolean']
  displayDailyScore: Scalars['Boolean']
  displayMealScore: Scalars['Boolean']
  fastingEnd: Scalars['Int']
  fastingStart: Scalars['Int']
  glucoseHighThreshold: Scalars['Int']
  glucoseLowThreshold: Scalars['Int']
  googleFitSync: Scalars['Boolean']
  healthKitSync: Scalars['Boolean']
  id: Scalars['ID']
  integrationsSyncSettings: IntegrationsSync
  journalNotifications: Scalars['Boolean']
  ketoMojoSync: Scalars['Boolean']
  ketoneHighThreshold: Scalars['Float']
  ketoneLowThreshold: Scalars['Float']
  libreViewSync: Scalars['Boolean']
  macroGoalsDaily: MacroGoals
  patternsNotifications: Scalars['Boolean']
  reminders: Scalars['Boolean']
  scanReminder: Scalars['Int']
  showNetCarbs: Scalars['Boolean']
  statsSettings: Array<StatsSettings>
  /** @deprecated Removed in v2.6.0 */
  trackingMode: TrackingMode
  unitSystem: Scalars['String']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export enum UserSignUpSource {
  Checkout = 'checkout',
  CoursesWebFlow = 'courses_web_flow',
  DppWebFlow = 'dpp_web_flow',
  GlpWebFlow = 'glp_web_flow',
  InsuranceFormSubmission = 'insurance_form_submission',
  InsuranceWebFlow = 'insurance_web_flow',
  MobileApp = 'mobile_app',
  OwnSensorWebFlow = 'own_sensor_web_flow',
  PartnerWebFlow = 'partner_web_flow'
}

export type ValuePair = NumericPair | RangePair | StringPair | TimePair

export enum WebMembersChatConversationEventSortField {
  SentAt = 'sent_at'
}

export type WebMembersChatConversationMessage = {
  __typename?: 'WebMembersChatConversationMessage'
  id: Scalars['ID']
  senderAvatar?: Maybe<Scalars['String']>
  senderId: Scalars['String']
  senderKind: ChatConversationMessageSenderKind
  senderName: Scalars['String']
  sentAt: Scalars['ISO8601DateTime']
  value: Scalars['String']
}

export type WebMembersChatConversationMessageSort = {
  direction: SortDirection
  field: WebMembersChatConversationEventSortField
}

export type Workout = {
  activityId?: InputMaybe<Scalars['Float']>
  activityName: Scalars['String']
  calories?: InputMaybe<Scalars['Float']>
  device?: InputMaybe<Scalars['String']>
  distance?: InputMaybe<Scalars['Float']>
  end?: InputMaybe<Scalars['ISO8601DateTime']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']>
  id?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Scalars['JSON']>
  sourceId?: InputMaybe<Scalars['String']>
  sourceName?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['ISO8601DateTime']>
  startDate?: InputMaybe<Scalars['ISO8601DateTime']>
  tracked?: InputMaybe<Scalars['Boolean']>
}

export type CoreAddressFieldsFragment = {
  __typename?: 'Address'
  id: string
  name: string
  street: string
  street2: string
  city: string
  state?: AddressState | null
  zipCode: string
  country?: string | null
}

export type AppointmentFieldsFragment = {
  __typename?: 'Appointment'
  id: string
  meetingAt?: any | null
  meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
  status: InsuranceBerryStreetAppointmentStatus
  title?: string | null
  nutritionistName?: string | null
  addToGoogleCalendarLink?: string | null
  unauthenticatedIcsLink?: string | null
  appointmentType?: InsuranceBerryStreetAppointmentType | null
  chargeType?: InsuranceBerryStreetAppointmentChargeType | null
  recurrent: boolean
}

export type CoachFragment = {
  __typename?: 'Coach'
  id: string
  bio?: string | null
  coachId: string
  credentials?: string | null
  fullName: string
  photo?: string | null
  stateLicences: Array<string>
  kind: EhrAppointmentProviderKind
  specialties: Array<{ __typename?: 'EhrCoachSpecialtyDef'; key: EhrCoachSpecialty; name: string }>
}

export type CoachProfileFragment = {
  __typename?: 'CoachProfile'
  bio?: string | null
  coachId: string
  credentials?: string | null
  fullName: string
  photo?: string | null
  stateLicences: Array<string>
  specialties: Array<{ __typename?: 'EhrCoachSpecialtyDef'; key: EhrCoachSpecialty; name: string }>
}

export type CourseCohortFragment = {
  __typename?: 'Cohort'
  id: string
  state: CoursesCohortState
  name: string
  instructor?: {
    __typename?: 'CoachProfile'
    bio?: string | null
    photo?: string | null
    credentials?: string | null
    stateLicences: Array<string>
    fullName: string
    outOfOffice: boolean
    coachId: string
  } | null
  sessions: Array<{
    __typename?: 'CohortSession'
    id: string
    name: string
    kind: CoursesCohortSessionKind
    position: number
    scheduledAt: any
    scheduledEndAt: any
    cancelledAt?: any | null
    joinUrl?: string | null
    attendance?: {
      __typename?: 'CohortSessionAttendance'
      id: string
      state: CoursesCohortSessionAttendanceState
      rsvp: CoursesCohortSessionAttendanceRsvp
    } | null
  }>
}

export type CourseCohortSessionFragment = {
  __typename?: 'CohortSession'
  id: string
  name: string
  kind: CoursesCohortSessionKind
  position: number
  scheduledAt: any
  scheduledEndAt: any
  cancelledAt?: any | null
  joinUrl?: string | null
  attendance?: {
    __typename?: 'CohortSessionAttendance'
    id: string
    state: CoursesCohortSessionAttendanceState
    rsvp: CoursesCohortSessionAttendanceRsvp
  } | null
}

export type CourseCohortSessionAttendanceFragment = {
  __typename?: 'CohortSessionAttendance'
  id: string
  state: CoursesCohortSessionAttendanceState
  rsvp: CoursesCohortSessionAttendanceRsvp
}

export type UserFragment = {
  __typename?: 'User'
  id: string
  email: string
  firstName: string
  lastName: string
  fullName: string
  role: UserRole
  sex?: string | null
  dateOfBirth?: string | null
  phoneNumber?: string | null
  kind: UserKind
  avatar?: string | null
  healthieId?: number | null
  nutrisenseGetHealthieId?: number | null
  phoneNumberConsent?: boolean | null
  floridaDigitalBillOfRightsConsent: boolean
  telehealthConsent: boolean
  timeZone: string
  migrationFromInsuranceEnabled: boolean
  features: Array<MobileAppFeature>
  lastAppPlatform?: string | null
  signUpSource?: UserSignUpSource | null
  ongoingCoreSubscription?: {
    __typename?: 'Subscription'
    primaryProduct: {
      __typename?: 'ProductMin'
      key: string
      ownSensor: boolean
      includesCgm: boolean
    }
  } | null
  address?: {
    __typename?: 'Address'
    id: string
    name: string
    street: string
    street2: string
    city: string
    state?: AddressState | null
    zipCode: string
    country?: string | null
  } | null
  lastPrescriptionApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
  } | null
  lastResearchApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
  } | null
  lastSteloApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
  } | null
  lastDppApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
  } | null
  lastGlpApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
  } | null
  lastGlpRenewalApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
    createdAt: any
  } | null
  lastGovernmentIdApprovalRequest?: {
    __typename?: 'ApprovalRequest'
    id: string
    state: UserApprovalRequestState
  } | null
  lastDoctorAssignment?: {
    __typename?: 'DoctorAssignment'
    id: string
    doctor: {
      __typename?: 'Doctor'
      id: string
      firstName?: string | null
      lastName?: string | null
      getHealthieId?: string | null
      bio?: string | null
      credentials?: string | null
      state: EhrDoctorState
      avatar?: string | null
      kind: EhrAppointmentProviderKind
    }
  } | null
  lastVideoChatAssignment?: {
    __typename?: 'CoachAssignment'
    id: string
    coach?: {
      __typename?: 'User'
      id: string
      fullName: string
      avatar?: string | null
      coachProfile?: {
        __typename?: 'CoachProfile'
        photo?: string | null
        bio?: string | null
        credentials?: string | null
      } | null
    } | null
  } | null
  coachChatConversation?: {
    __typename?: 'ChatConversation'
    id: string
    candidateRead: boolean
    intercomCreatedAt: any
    lastMessage?: { __typename?: 'ChatConversationMessage'; id: string } | null
  } | null
  lastMedicalAppointment?: {
    __typename?: 'EhrAppointment'
    id: string
    meetingAt?: any | null
    joinUrl?: string | null
    lastState: { __typename?: 'EhrAppointmentState'; id: string; kind: EhrAppointmentStateKind }
  } | null
  paymentMethod?: {
    __typename?: 'PaymentMethod'
    stripeId: string
    cardType: string
    last4: string
    expirationDate: any
  } | null
  uiStates: Array<{ __typename?: 'UIState'; name: UiStateNames; value: boolean }>
  surveyLinks: Array<{
    __typename?: 'SurveyLink'
    id: string
    finished: boolean
    finishedAt?: any | null
    startedAt: any
    state: SurveysUserLinkState
    survey: { __typename?: 'Survey'; kind: SurveysConfigKind }
  }>
  subscriptions: Array<{
    __typename?: 'Subscription'
    id: string
    status: SubscriptionStatus
    primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
    latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
  }>
  referralPartner: {
    __typename?: 'UserReferralPartnerDef'
    key: UserReferralPartner
    schedulingWithoutPaymentMethod: boolean
    schedulingWithoutInsuranceInformation: boolean
  }
  userProperties?: {
    __typename?: 'UserProperties'
    weightLossExperience?: { __typename?: 'UserProperty'; value: string } | null
  } | null
}

export type DoctorFragment = {
  __typename?: 'Doctor'
  id: string
  firstName?: string | null
  lastName?: string | null
  getHealthieId?: string | null
  bio?: string | null
  credentials?: string | null
  state: EhrDoctorState
  avatar?: string | null
  kind: EhrAppointmentProviderKind
}

export type EhrAppointmentFragment = {
  __typename?: 'EhrAppointment'
  id: string
  getHealthieId?: string | null
  category: EhrAppointmentCategory
  kind: EhrAppointmentKind
  meetingAt?: any | null
  title?: string | null
  getHealthieInstanceKind: EhrGetHealthieInstanceKind
  startUrl?: string | null
  joinUrl?: string | null
  addToGcalLink?: string | null
  unauthenticatedIcsLink?: string | null
  lastState: {
    __typename?: 'EhrAppointmentState'
    reason: EhrAppointmentReason
    kind: EhrAppointmentStateKind
  }
  providers: Array<{
    __typename?: 'EhrProvider'
    id: string
    kind: EhrAppointmentProviderKind
    state: EhrAppointmentProviderState
    coach?: {
      __typename?: 'Coach'
      id: string
      bio?: string | null
      coachId: string
      credentials?: string | null
      fullName: string
      photo?: string | null
      stateLicences: Array<string>
      kind: EhrAppointmentProviderKind
      specialties: Array<{
        __typename?: 'EhrCoachSpecialtyDef'
        key: EhrCoachSpecialty
        name: string
      }>
    } | null
    doctor?: {
      __typename?: 'Doctor'
      id: string
      firstName?: string | null
      lastName?: string | null
      getHealthieId?: string | null
      bio?: string | null
      credentials?: string | null
      state: EhrDoctorState
      avatar?: string | null
      kind: EhrAppointmentProviderKind
    } | null
  }>
  attendances: Array<{
    __typename?: 'EhrAppointmentAttendance'
    state: EhrAppointmentAttendanceState
    chargeKind: EhrAppointmentAttendanceChargeKind
    user: { __typename?: 'User'; id: string }
  }>
}

export type InsurancePolicyFieldsFragment = {
  __typename?: 'InsurancePolicy'
  id: string
  eligible: boolean
  declined: boolean
  inReview: boolean
  unitsLeft?: number | null
  unitsUsed?: number | null
  initialAppointmentType?: {
    __typename?: 'AppointmentType'
    key: InsuranceBerryStreetAppointmentType
    title: string
    duration: string
  } | null
  lastState?: { __typename?: 'InsurancePolicyState'; kind: UserInsurancePolicyStateKind } | null
}

export type LatestUserAppointmentFragment = {
  __typename?: 'LatestUserAppointment'
  id: string
  date?: string | null
  meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
  status: InsuranceBerryStreetAppointmentStatus
  title?: string | null
  nutritionistName?: string | null
  addToGoogleCalendarLink?: string | null
  unauthenticatedIcsLink?: string | null
  appointmentType?: InsuranceBerryStreetAppointmentType | null
  chargeType?: InsuranceBerryStreetAppointmentChargeType | null
  externalVideochatUrl?: string | null
  coachProfile?: {
    __typename?: 'CoachProfile'
    bio?: string | null
    coachId: string
    credentials?: string | null
    fullName: string
    photo?: string | null
    stateLicences: Array<string>
    specialties: Array<{
      __typename?: 'EhrCoachSpecialtyDef'
      key: EhrCoachSpecialty
      name: string
    }>
  } | null
}

export type CoreSubscriptionFieldsFragment = {
  __typename?: 'Subscription'
  id: string
  status: SubscriptionStatus
  primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
  latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
}

export type SurveyFragment = {
  __typename?: 'Survey'
  kind: SurveysConfigKind
  version: string
  header?: string | null
  behavior: {
    __typename?: 'Behavior'
    allowEarlyExit: boolean
    allowMultipleSubmissions: boolean
    showAlertOnClose: boolean
    showProgressIndicator: boolean
    trackResponses: boolean
    warnBeforeStopping: boolean
    redirectOnOutcome: boolean
  }
  showAlertOnCloseBehavior?: {
    __typename?: 'ShowAlertOnCloseBehavior'
    title: string
    body: string
    icon?: UiIcon | null
    confirmTitle: string
    cancelTitle: string
  } | null
  followups: Array<{
    __typename?: 'Followup'
    kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
    parentQuestionKey: SurveysQuestionKey
    parentAnswer: { __typename?: 'Answer'; value: string }
    question: {
      __typename?: 'Question'
      key: SurveysQuestionKey
      label?: string | null
      note?: string | null
      imageUrl?: string | null
      required: boolean
      response: {
        __typename?: 'Response'
        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
        kindMetadata?: any | null
        answersGroups?: Array<{
          __typename?: 'AnswersGroup'
          answers: Array<{
            __typename?: 'Answer'
            label: string
            value: string
            inlineAlert?: {
              __typename?: 'InlineAlert'
              type: UiInlineAlert
              message: string
            } | null
            followups: Array<{
              __typename?: 'Followup'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
              question: {
                __typename?: 'Question'
                key: SurveysQuestionKey
                label?: string | null
                note?: string | null
                imageUrl?: string | null
                required: boolean
                response: {
                  __typename?: 'Response'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                  kindMetadata?: any | null
                  answersGroups?: Array<{
                    __typename?: 'AnswersGroup'
                    answers: Array<{
                      __typename?: 'Answer'
                      label: string
                      value: string
                      inlineAlert?: {
                        __typename?: 'InlineAlert'
                        type: UiInlineAlert
                        message: string
                      } | null
                    }>
                  }> | null
                }
              }
            }>
          }>
        }> | null
      }
    }
  }>
  completedScreen?: {
    __typename?: 'CompletedScreen'
    title?: string | null
    header: string
    intro?: string | null
    introMarkdown?: string | null
    icon: UiIcon
    primaryActionText?: string | null
    footnote?: string | null
    secondaryActionLink?: { __typename?: 'Link'; text: string; url: string } | null
  } | null
  questionsGroups: Array<{
    __typename?: 'QuestionsGroup'
    banner?: string | null
    introScreen?: {
      __typename?: 'IntroScreen'
      header: string
      intro?: string | null
      introMarkdown?: string | null
      icon?: UiIcon | null
      footnote?: string | null
      imageUrl?: string | null
      inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
    } | null
    questions: Array<{
      __typename?: 'Question'
      key: SurveysQuestionKey
      label?: string | null
      note?: string | null
      imageUrl?: string | null
      required: boolean
      response: {
        __typename?: 'Response'
        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
        kindMetadata?: any | null
        answersGroups?: Array<{
          __typename?: 'AnswersGroup'
          answers: Array<{
            __typename?: 'Answer'
            label: string
            value: string
            inlineAlert?: {
              __typename?: 'InlineAlert'
              type: UiInlineAlert
              message: string
            } | null
            followups: Array<{
              __typename?: 'Followup'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
              question: {
                __typename?: 'Question'
                key: SurveysQuestionKey
                label?: string | null
                note?: string | null
                imageUrl?: string | null
                required: boolean
                response: {
                  __typename?: 'Response'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                  kindMetadata?: any | null
                  answersGroups?: Array<{
                    __typename?: 'AnswersGroup'
                    answers: Array<{
                      __typename?: 'Answer'
                      label: string
                      value: string
                      inlineAlert?: {
                        __typename?: 'InlineAlert'
                        type: UiInlineAlert
                        message: string
                      } | null
                    }>
                  }> | null
                }
              }
            }>
          }>
        }> | null
      }
    }>
  }>
}

export type SurveyLinkFragment = {
  __typename?: 'SurveyLink'
  id: string
  state: SurveysUserLinkState
  inProgress: boolean
  finished: boolean
  earlyExitDeepLink?: string | null
  completionDeepLink?: string | null
  outcome?: {
    __typename?: 'Outcome'
    id: string
    key: string
    reason: {
      __typename?: 'Reason'
      headline: string
      notes: Array<string>
      message: {
        __typename?: 'ReasonMessage'
        content: string
        links?: Array<{ __typename?: 'ReasonMessageLink'; placeholder: string; url: string }> | null
      }
      inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
    }
  } | null
  survey: {
    __typename?: 'Survey'
    kind: SurveysConfigKind
    version: string
    header?: string | null
    behavior: {
      __typename?: 'Behavior'
      allowEarlyExit: boolean
      allowMultipleSubmissions: boolean
      showAlertOnClose: boolean
      showProgressIndicator: boolean
      trackResponses: boolean
      warnBeforeStopping: boolean
      redirectOnOutcome: boolean
    }
    showAlertOnCloseBehavior?: {
      __typename?: 'ShowAlertOnCloseBehavior'
      title: string
      body: string
      icon?: UiIcon | null
      confirmTitle: string
      cancelTitle: string
    } | null
    followups: Array<{
      __typename?: 'Followup'
      kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
      parentQuestionKey: SurveysQuestionKey
      parentAnswer: { __typename?: 'Answer'; value: string }
      question: {
        __typename?: 'Question'
        key: SurveysQuestionKey
        label?: string | null
        note?: string | null
        imageUrl?: string | null
        required: boolean
        response: {
          __typename?: 'Response'
          kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
          kindMetadata?: any | null
          answersGroups?: Array<{
            __typename?: 'AnswersGroup'
            answers: Array<{
              __typename?: 'Answer'
              label: string
              value: string
              inlineAlert?: {
                __typename?: 'InlineAlert'
                type: UiInlineAlert
                message: string
              } | null
              followups: Array<{
                __typename?: 'Followup'
                kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                question: {
                  __typename?: 'Question'
                  key: SurveysQuestionKey
                  label?: string | null
                  note?: string | null
                  imageUrl?: string | null
                  required: boolean
                  response: {
                    __typename?: 'Response'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                    kindMetadata?: any | null
                    answersGroups?: Array<{
                      __typename?: 'AnswersGroup'
                      answers: Array<{
                        __typename?: 'Answer'
                        label: string
                        value: string
                        inlineAlert?: {
                          __typename?: 'InlineAlert'
                          type: UiInlineAlert
                          message: string
                        } | null
                      }>
                    }> | null
                  }
                }
              }>
            }>
          }> | null
        }
      }
    }>
    completedScreen?: {
      __typename?: 'CompletedScreen'
      title?: string | null
      header: string
      intro?: string | null
      introMarkdown?: string | null
      icon: UiIcon
      primaryActionText?: string | null
      footnote?: string | null
      secondaryActionLink?: { __typename?: 'Link'; text: string; url: string } | null
    } | null
    questionsGroups: Array<{
      __typename?: 'QuestionsGroup'
      banner?: string | null
      introScreen?: {
        __typename?: 'IntroScreen'
        header: string
        intro?: string | null
        introMarkdown?: string | null
        icon?: UiIcon | null
        footnote?: string | null
        imageUrl?: string | null
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
      } | null
      questions: Array<{
        __typename?: 'Question'
        key: SurveysQuestionKey
        label?: string | null
        note?: string | null
        imageUrl?: string | null
        required: boolean
        response: {
          __typename?: 'Response'
          kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
          kindMetadata?: any | null
          answersGroups?: Array<{
            __typename?: 'AnswersGroup'
            answers: Array<{
              __typename?: 'Answer'
              label: string
              value: string
              inlineAlert?: {
                __typename?: 'InlineAlert'
                type: UiInlineAlert
                message: string
              } | null
              followups: Array<{
                __typename?: 'Followup'
                kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                question: {
                  __typename?: 'Question'
                  key: SurveysQuestionKey
                  label?: string | null
                  note?: string | null
                  imageUrl?: string | null
                  required: boolean
                  response: {
                    __typename?: 'Response'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                    kindMetadata?: any | null
                    answersGroups?: Array<{
                      __typename?: 'AnswersGroup'
                      answers: Array<{
                        __typename?: 'Answer'
                        label: string
                        value: string
                        inlineAlert?: {
                          __typename?: 'InlineAlert'
                          type: UiInlineAlert
                          message: string
                        } | null
                      }>
                    }> | null
                  }
                }
              }>
            }>
          }> | null
        }
      }>
    }>
  }
  responses: Array<{
    __typename?: 'SurveyResponse'
    id: string
    questionKey: SurveysQuestionKey
    answer: any
  }>
  user?: { __typename?: 'User'; id: string } | null
}

export type SurveyLinkConfigFragment = {
  __typename?: 'SurveyLinkConfig'
  currentItemIndex: number
  sectionsCount: number
  questionsCount: number
  items: Array<
    | {
        __typename?: 'SurveyLinkConfigIntroItem'
        type: SurveyConfigItem
        header: string
        imageUrl?: string | null
        introMarkdown?: string | null
        icon?: UiIcon | null
        footnote?: string | null
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
      }
    | {
        __typename?: 'SurveyLinkConfigQuestionItem'
        type: SurveyConfigItem
        key: SurveysQuestionKey
        label?: string | null
        note?: string | null
        imageUrl?: string | null
        required: boolean
        response: {
          __typename?: 'Response'
          kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
          kindMetadata?: any | null
          answersGroups?: Array<{
            __typename?: 'AnswersGroup'
            answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
            answers: Array<{
              __typename?: 'Answer'
              label: string
              value: string
              inlineAlert?: {
                __typename?: 'InlineAlert'
                type: UiInlineAlert
                message: string
              } | null
              followups: Array<{
                __typename?: 'Followup'
                kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                question: {
                  __typename?: 'Question'
                  key: SurveysQuestionKey
                  label?: string | null
                  note?: string | null
                  imageUrl?: string | null
                  required: boolean
                  response: {
                    __typename?: 'Response'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                    kindMetadata?: any | null
                    answersGroups?: Array<{
                      __typename?: 'AnswersGroup'
                      answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                      answers: Array<{
                        __typename?: 'Answer'
                        label: string
                        value: string
                        inlineAlert?: {
                          __typename?: 'InlineAlert'
                          type: UiInlineAlert
                          message: string
                        } | null
                      }>
                    }> | null
                  }
                }
              }>
            }>
          }> | null
        }
      }
  >
}

export type SurveyOutcomeFragment = {
  __typename?: 'Outcome'
  id: string
  key: string
  reason: {
    __typename?: 'Reason'
    headline: string
    notes: Array<string>
    message: {
      __typename?: 'ReasonMessage'
      content: string
      links?: Array<{ __typename?: 'ReasonMessageLink'; placeholder: string; url: string }> | null
    }
    inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
  }
}

export type SurveyQuestionFragment = {
  __typename?: 'Question'
  key: SurveysQuestionKey
  label?: string | null
  note?: string | null
  imageUrl?: string | null
  required: boolean
  response: {
    __typename?: 'Response'
    kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
    kindMetadata?: any | null
    answersGroups?: Array<{
      __typename?: 'AnswersGroup'
      answers: Array<{
        __typename?: 'Answer'
        label: string
        value: string
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
        followups: Array<{
          __typename?: 'Followup'
          kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
          question: {
            __typename?: 'Question'
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            imageUrl?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  inlineAlert?: {
                    __typename?: 'InlineAlert'
                    type: UiInlineAlert
                    message: string
                  } | null
                }>
              }> | null
            }
          }
        }>
      }>
    }> | null
  }
}

export type QuestionItemFieldsFragment = {
  __typename?: 'SurveyLinkConfigQuestionItem'
  key: SurveysQuestionKey
  label?: string | null
  note?: string | null
  imageUrl?: string | null
  required: boolean
  response: {
    __typename?: 'Response'
    kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
    kindMetadata?: any | null
    answersGroups?: Array<{
      __typename?: 'AnswersGroup'
      answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
      answers: Array<{
        __typename?: 'Answer'
        label: string
        value: string
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
        followups: Array<{
          __typename?: 'Followup'
          kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
          question: {
            __typename?: 'Question'
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            imageUrl?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  inlineAlert?: {
                    __typename?: 'InlineAlert'
                    type: UiInlineAlert
                    message: string
                  } | null
                }>
              }> | null
            }
          }
        }>
      }>
    }> | null
  }
}

export type AcceptLegalPolicyMutationVariables = Exact<{
  kind: LegalPolicyConsentKind
}>

export type AcceptLegalPolicyMutation = {
  __typename?: 'MutationType'
  acceptLegalPolicy: {
    __typename?: 'LegalPolicyConsent'
    kind: LegalPolicyConsentKind
    state: LegalPolicyConsentState
    createdAt?: any | null
  }
}

export type BookVideoCallMutationVariables = Exact<{
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  timeZone?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['String']>
  coachId?: InputMaybe<Scalars['ID']>
  referringCoachUserId?: InputMaybe<Scalars['ID']>
  pendingAppointmentId?: InputMaybe<Scalars['ID']>
  agenda?: InputMaybe<Scalars['String']>
}>

export type BookVideoCallMutation = {
  __typename?: 'MutationType'
  bookVideoCall: { __typename?: 'LatestUserAppointment'; date?: string | null }
}

export type CancelEhrAppointmentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CancelEhrAppointmentMutation = {
  __typename?: 'MutationType'
  cancelEhrAppointment: { __typename?: 'EhrAppointment'; id: string }
}

export type CancelVideoCallMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CancelVideoCallMutation = {
  __typename?: 'MutationType'
  cancelVideoCall?: { __typename?: 'Appointment'; id: string } | null
}

export type ClaimFreeVideoCallMutationVariables = Exact<{ [key: string]: never }>

export type ClaimFreeVideoCallMutation = {
  __typename?: 'MutationType'
  claimFreeVideoCall: {
    __typename?: 'LatestUserAppointment'
    id: string
    appointmentType?: InsuranceBerryStreetAppointmentType | null
  }
}

export type CompleteSurveyMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CompleteSurveyMutation = {
  __typename?: 'MutationType'
  completeSurvey: {
    __typename?: 'SurveyLink'
    completionDeepLink?: string | null
    id: string
    state: SurveysUserLinkState
    inProgress: boolean
    finished: boolean
    earlyExitDeepLink?: string | null
    outcome?: {
      __typename?: 'Outcome'
      id: string
      key: string
      reason: {
        __typename?: 'Reason'
        headline: string
        notes: Array<string>
        message: {
          __typename?: 'ReasonMessage'
          content: string
          links?: Array<{
            __typename?: 'ReasonMessageLink'
            placeholder: string
            url: string
          }> | null
        }
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
      }
    } | null
    survey: {
      __typename?: 'Survey'
      kind: SurveysConfigKind
      version: string
      header?: string | null
      behavior: {
        __typename?: 'Behavior'
        allowEarlyExit: boolean
        allowMultipleSubmissions: boolean
        showAlertOnClose: boolean
        showProgressIndicator: boolean
        trackResponses: boolean
        warnBeforeStopping: boolean
        redirectOnOutcome: boolean
      }
      showAlertOnCloseBehavior?: {
        __typename?: 'ShowAlertOnCloseBehavior'
        title: string
        body: string
        icon?: UiIcon | null
        confirmTitle: string
        cancelTitle: string
      } | null
      followups: Array<{
        __typename?: 'Followup'
        kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
        parentQuestionKey: SurveysQuestionKey
        parentAnswer: { __typename?: 'Answer'; value: string }
        question: {
          __typename?: 'Question'
          key: SurveysQuestionKey
          label?: string | null
          note?: string | null
          imageUrl?: string | null
          required: boolean
          response: {
            __typename?: 'Response'
            kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
            kindMetadata?: any | null
            answersGroups?: Array<{
              __typename?: 'AnswersGroup'
              answers: Array<{
                __typename?: 'Answer'
                label: string
                value: string
                inlineAlert?: {
                  __typename?: 'InlineAlert'
                  type: UiInlineAlert
                  message: string
                } | null
                followups: Array<{
                  __typename?: 'Followup'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                  question: {
                    __typename?: 'Question'
                    key: SurveysQuestionKey
                    label?: string | null
                    note?: string | null
                    imageUrl?: string | null
                    required: boolean
                    response: {
                      __typename?: 'Response'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                      kindMetadata?: any | null
                      answersGroups?: Array<{
                        __typename?: 'AnswersGroup'
                        answers: Array<{
                          __typename?: 'Answer'
                          label: string
                          value: string
                          inlineAlert?: {
                            __typename?: 'InlineAlert'
                            type: UiInlineAlert
                            message: string
                          } | null
                        }>
                      }> | null
                    }
                  }
                }>
              }>
            }> | null
          }
        }
      }>
      completedScreen?: {
        __typename?: 'CompletedScreen'
        title?: string | null
        header: string
        intro?: string | null
        introMarkdown?: string | null
        icon: UiIcon
        primaryActionText?: string | null
        footnote?: string | null
        secondaryActionLink?: { __typename?: 'Link'; text: string; url: string } | null
      } | null
      questionsGroups: Array<{
        __typename?: 'QuestionsGroup'
        banner?: string | null
        introScreen?: {
          __typename?: 'IntroScreen'
          header: string
          intro?: string | null
          introMarkdown?: string | null
          icon?: UiIcon | null
          footnote?: string | null
          imageUrl?: string | null
          inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
        } | null
        questions: Array<{
          __typename?: 'Question'
          key: SurveysQuestionKey
          label?: string | null
          note?: string | null
          imageUrl?: string | null
          required: boolean
          response: {
            __typename?: 'Response'
            kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
            kindMetadata?: any | null
            answersGroups?: Array<{
              __typename?: 'AnswersGroup'
              answers: Array<{
                __typename?: 'Answer'
                label: string
                value: string
                inlineAlert?: {
                  __typename?: 'InlineAlert'
                  type: UiInlineAlert
                  message: string
                } | null
                followups: Array<{
                  __typename?: 'Followup'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                  question: {
                    __typename?: 'Question'
                    key: SurveysQuestionKey
                    label?: string | null
                    note?: string | null
                    imageUrl?: string | null
                    required: boolean
                    response: {
                      __typename?: 'Response'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                      kindMetadata?: any | null
                      answersGroups?: Array<{
                        __typename?: 'AnswersGroup'
                        answers: Array<{
                          __typename?: 'Answer'
                          label: string
                          value: string
                          inlineAlert?: {
                            __typename?: 'InlineAlert'
                            type: UiInlineAlert
                            message: string
                          } | null
                        }>
                      }> | null
                    }
                  }
                }>
              }>
            }> | null
          }
        }>
      }>
    }
    responses: Array<{
      __typename?: 'SurveyResponse'
      id: string
      questionKey: SurveysQuestionKey
      answer: any
    }>
    user?: { __typename?: 'User'; id: string } | null
  }
}

export type CreateChatTokenMutationVariables = Exact<{ [key: string]: never }>

export type CreateChatTokenMutation = {
  __typename?: 'MutationType'
  createChatToken: { __typename?: 'Token'; value: string }
}

export type CreateDirectUploadMutationVariables = Exact<{
  file: FileMetadata
}>

export type CreateDirectUploadMutation = {
  __typename?: 'MutationType'
  createDirectUpload: {
    __typename?: 'DirectUpload'
    url: string
    signedId: string
    headers: Array<{ __typename?: 'KeyValuePairString'; key: string; value: string }>
  }
}

export type CreateDppHealthDataRecordMutationVariables = Exact<{
  kind: DppHealthDataRecordKind
  value: Scalars['Float']
  userFileId?: InputMaybe<Scalars['ID']>
}>

export type CreateDppHealthDataRecordMutation = {
  __typename?: 'MutationType'
  createDppHealthDataRecord: { __typename?: 'Success'; success: boolean }
}

export type CreateFileFromSignedIdMutationVariables = Exact<{
  signedId: Scalars['String']
  fileTag: Scalars['String']
}>

export type CreateFileFromSignedIdMutation = {
  __typename?: 'MutationType'
  createFileFromSignedId: {
    __typename?: 'File'
    downloadFileUrl: string
    permanentUrl: string
    fileName: string
    id: string
  }
}

export type CreateMeasurementMutationVariables = Exact<{
  type: Scalars['String']
  values: MultiValue
  occurredAt: Scalars['String']
}>

export type CreateMeasurementMutation = {
  __typename?: 'MutationType'
  createMeasurement: { __typename?: 'Measurement'; id: string }
}

export type CreateOneTimePaymentMutationVariables = Exact<{
  email: Scalars['String']
  fullName: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
  providerPaymentMethodId: Scalars['String']
  providerPromotionCodeId?: InputMaybe<Scalars['String']>
  purchasableItems: Array<PurchasableItem> | PurchasableItem
  metadata?: InputMaybe<Array<KeyValueString> | KeyValueString>
}>

export type CreateOneTimePaymentMutation = {
  __typename?: 'MutationType'
  createOneTimePayment: {
    __typename?: 'CreatedOneTimePayment'
    invoice: {
      __typename?: 'Invoice'
      id: string
      stripeId: string
      total?: number | null
      status: InvoiceStatus
      primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
    }
  }
}

export type CreateProviderSetupIntentMutationVariables = Exact<{
  email: Scalars['String']
  fullName: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
}>

export type CreateProviderSetupIntentMutation = {
  __typename?: 'MutationType'
  createProviderSetupIntent: { __typename?: 'ProviderSetupIntent'; clientSecret: string }
}

export type CreateSubscriptionMutationVariables = Exact<{
  providerPaymentMethodId?: InputMaybe<Scalars['String']>
  product: BillingProduct
  email: Scalars['String']
  fullName: Scalars['String']
  address: SubscriptionAddress
  providerPromotionCodeId?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  bonusKinds?: InputMaybe<Array<SubscriptionBonusKind> | SubscriptionBonusKind>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind> | LegalPolicyConsentKind>
  checkoutOptionKinds?: InputMaybe<
    Array<SubscriptionCheckoutOptionKind> | SubscriptionCheckoutOptionKind
  >
}>

export type CreateSubscriptionMutation = {
  __typename?: 'MutationType'
  createSubscription: {
    __typename?: 'CreatedSubscription'
    providerSubscriptionId: string
    clientSecret?: string | null
    subscription: {
      __typename?: 'Subscription'
      id: string
      status: SubscriptionStatus
      primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
      latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
    }
  }
}

export type CreateUserMutationVariables = Exact<{
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  password?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['Upload']>
  source?: InputMaybe<UserSignUpSource>
  phoneNumber?: InputMaybe<Scalars['String']>
  sendSignInLinkEmail?: InputMaybe<Scalars['Boolean']>
  sendResetPasswordEmail?: InputMaybe<Scalars['Boolean']>
  referralPartner?: InputMaybe<UserReferralPartner>
  dateOfBirth?: InputMaybe<Scalars['String']>
  sex?: InputMaybe<Scalars['String']>
  marketingInfo?: InputMaybe<MarketingInfo>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind> | LegalPolicyConsentKind>
  userProperties?: InputMaybe<Array<UserPropertySubmission> | UserPropertySubmission>
}>

export type CreateUserMutation = {
  __typename?: 'MutationType'
  createUser: {
    __typename?: 'UserAuth'
    token?: string | null
    role?: UserRole | null
    success: boolean
    status?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      fullName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      kind: UserKind
      avatar?: string | null
      healthieId?: number | null
      nutrisenseGetHealthieId?: number | null
      phoneNumberConsent?: boolean | null
      floridaDigitalBillOfRightsConsent: boolean
      telehealthConsent: boolean
      timeZone: string
      migrationFromInsuranceEnabled: boolean
      features: Array<MobileAppFeature>
      lastAppPlatform?: string | null
      signUpSource?: UserSignUpSource | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        primaryProduct: {
          __typename?: 'ProductMin'
          key: string
          ownSensor: boolean
          includesCgm: boolean
        }
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastResearchApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastSteloApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastDppApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastGlpApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastGlpRenewalApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
        createdAt: any
      } | null
      lastGovernmentIdApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastDoctorAssignment?: {
        __typename?: 'DoctorAssignment'
        id: string
        doctor: {
          __typename?: 'Doctor'
          id: string
          firstName?: string | null
          lastName?: string | null
          getHealthieId?: string | null
          bio?: string | null
          credentials?: string | null
          state: EhrDoctorState
          avatar?: string | null
          kind: EhrAppointmentProviderKind
        }
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        coach?: {
          __typename?: 'User'
          id: string
          fullName: string
          avatar?: string | null
          coachProfile?: {
            __typename?: 'CoachProfile'
            photo?: string | null
            bio?: string | null
            credentials?: string | null
          } | null
        } | null
      } | null
      coachChatConversation?: {
        __typename?: 'ChatConversation'
        id: string
        candidateRead: boolean
        intercomCreatedAt: any
        lastMessage?: { __typename?: 'ChatConversationMessage'; id: string } | null
      } | null
      lastMedicalAppointment?: {
        __typename?: 'EhrAppointment'
        id: string
        meetingAt?: any | null
        joinUrl?: string | null
        lastState: { __typename?: 'EhrAppointmentState'; id: string; kind: EhrAppointmentStateKind }
      } | null
      paymentMethod?: {
        __typename?: 'PaymentMethod'
        stripeId: string
        cardType: string
        last4: string
        expirationDate: any
      } | null
      uiStates: Array<{ __typename?: 'UIState'; name: UiStateNames; value: boolean }>
      surveyLinks: Array<{
        __typename?: 'SurveyLink'
        id: string
        finished: boolean
        finishedAt?: any | null
        startedAt: any
        state: SurveysUserLinkState
        survey: { __typename?: 'Survey'; kind: SurveysConfigKind }
      }>
      subscriptions: Array<{
        __typename?: 'Subscription'
        id: string
        status: SubscriptionStatus
        primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
        latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
      }>
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        schedulingWithoutPaymentMethod: boolean
        schedulingWithoutInsuranceInformation: boolean
      }
      userProperties?: {
        __typename?: 'UserProperties'
        weightLossExperience?: { __typename?: 'UserProperty'; value: string } | null
      } | null
    } | null
  }
}

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteFileMutation = {
  __typename?: 'MutationType'
  deleteFile: { __typename?: 'File'; id: string }
}

export type EhrBookAppointmentMutationVariables = Exact<{
  timeZone?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['String']>
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  attendanceChargeKind?: InputMaybe<EhrAppointmentAttendanceChargeKind>
  isGroup?: InputMaybe<Scalars['Boolean']>
  coachId?: InputMaybe<Scalars['ID']>
  doctorId?: InputMaybe<Scalars['ID']>
  referringCoachUserId?: InputMaybe<Scalars['ID']>
  pendingAppointmentId?: InputMaybe<Scalars['ID']>
  attendanceAgenda?: InputMaybe<Scalars['String']>
}>

export type EhrBookAppointmentMutation = {
  __typename?: 'MutationType'
  ehrBookAppointment: { __typename?: 'EhrAppointment'; id: string; meetingAt?: any | null }
}

export type JoinCourseCohortMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type JoinCourseCohortMutation = {
  __typename?: 'MutationType'
  joinCourseCohort: {
    __typename?: 'Cohort'
    id: string
    state: CoursesCohortState
    name: string
    instructor?: {
      __typename?: 'CoachProfile'
      bio?: string | null
      photo?: string | null
      credentials?: string | null
      stateLicences: Array<string>
      fullName: string
      outOfOffice: boolean
      coachId: string
    } | null
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      name: string
      kind: CoursesCohortSessionKind
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
      attendance?: {
        __typename?: 'CohortSessionAttendance'
        id: string
        state: CoursesCohortSessionAttendanceState
        rsvp: CoursesCohortSessionAttendanceRsvp
      } | null
    }>
  }
}

export type JoinDppCourseCohortMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type JoinDppCourseCohortMutation = {
  __typename?: 'MutationType'
  joinDppCourseCohort: {
    __typename?: 'Cohort'
    id: string
    state: CoursesCohortState
    name: string
    instructor?: {
      __typename?: 'CoachProfile'
      bio?: string | null
      photo?: string | null
      credentials?: string | null
      stateLicences: Array<string>
      fullName: string
      outOfOffice: boolean
      coachId: string
    } | null
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      name: string
      kind: CoursesCohortSessionKind
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
      attendance?: {
        __typename?: 'CohortSessionAttendance'
        id: string
        state: CoursesCohortSessionAttendanceState
        rsvp: CoursesCohortSessionAttendanceRsvp
      } | null
    }>
  }
}

export type RescheduleEhrAppointmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['String']>
}>

export type RescheduleEhrAppointmentMutation = {
  __typename?: 'MutationType'
  rescheduleEhrAppointment: { __typename?: 'EhrAppointment'; id: string; meetingAt?: any | null }
}

export type RescheduleVideoCallMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['String']>
}>

export type RescheduleVideoCallMutation = {
  __typename?: 'MutationType'
  rescheduleVideoCall: { __typename?: 'Appointment'; id: string; date?: string | null }
}

export type ResetPasswordRequestQueryVariables = Exact<{
  email: Scalars['String']
}>

export type ResetPasswordRequestQuery = {
  __typename?: 'QueryType'
  resetPasswordRequest: { __typename?: 'Success'; success: boolean }
}

export type RunInsuranceBenefitsCheckMutationVariables = Exact<{
  addressState: AddressState
  insurancePolicyKind: UserInsurancePolicyKind
  insuranceVideoCallGoal: Scalars['String']
  insuranceVideoCallGoalOther?: InputMaybe<Scalars['String']>
  memberId?: InputMaybe<Scalars['String']>
}>

export type RunInsuranceBenefitsCheckMutation = {
  __typename?: 'MutationType'
  runInsuranceBenefitsCheck: { __typename?: 'Success'; success: boolean }
}

export type SaveSurveyResponseMutationVariables = Exact<{
  surveyLinkId: Scalars['ID']
  questionKey: Scalars['String']
  answer: Scalars['JSON']
  warnBeforeStopping?: InputMaybe<Scalars['Boolean']>
}>

export type SaveSurveyResponseMutation = {
  __typename?: 'MutationType'
  saveSurveyResponse: {
    __typename?: 'SurveyResponse'
    id: string
    questionKey: SurveysQuestionKey
    answer: any
    surveyLink: {
      __typename?: 'SurveyLink'
      user?: { __typename?: 'User'; id: string } | null
      config: {
        __typename?: 'SurveyLinkConfig'
        questionsCount: number
        items: Array<
          | {
              __typename?: 'SurveyLinkConfigIntroItem'
              type: SurveyConfigItem
              header: string
              imageUrl?: string | null
              introMarkdown?: string | null
              icon?: UiIcon | null
              footnote?: string | null
              inlineAlert?: {
                __typename?: 'InlineAlert'
                type: UiInlineAlert
                message: string
              } | null
            }
          | {
              __typename?: 'SurveyLinkConfigQuestionItem'
              type: SurveyConfigItem
              key: SurveysQuestionKey
              label?: string | null
              note?: string | null
              imageUrl?: string | null
              required: boolean
              response: {
                __typename?: 'Response'
                kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                kindMetadata?: any | null
                answersGroups?: Array<{
                  __typename?: 'AnswersGroup'
                  answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                  answers: Array<{
                    __typename?: 'Answer'
                    label: string
                    value: string
                    inlineAlert?: {
                      __typename?: 'InlineAlert'
                      type: UiInlineAlert
                      message: string
                    } | null
                    followups: Array<{
                      __typename?: 'Followup'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                      question: {
                        __typename?: 'Question'
                        key: SurveysQuestionKey
                        label?: string | null
                        note?: string | null
                        imageUrl?: string | null
                        required: boolean
                        response: {
                          __typename?: 'Response'
                          kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                          kindMetadata?: any | null
                          answersGroups?: Array<{
                            __typename?: 'AnswersGroup'
                            answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                            answers: Array<{
                              __typename?: 'Answer'
                              label: string
                              value: string
                              inlineAlert?: {
                                __typename?: 'InlineAlert'
                                type: UiInlineAlert
                                message: string
                              } | null
                            }>
                          }> | null
                        }
                      }
                    }>
                  }>
                }> | null
              }
            }
        >
      }
    }
  }
}

export type SelectDefaultSensorForDexcomResearchMutationVariables = Exact<{ [key: string]: never }>

export type SelectDefaultSensorForDexcomResearchMutation = {
  __typename?: 'MutationType'
  selectDefaultSensorForDexcomResearch: { __typename?: 'Success'; success: boolean }
}

export type SelectSensorMutationVariables = Exact<{
  model: SensorModel
}>

export type SelectSensorMutation = {
  __typename?: 'MutationType'
  selectSensor: { __typename?: 'Success'; success: boolean }
}

export type SendChatConversationMessageMutationVariables = Exact<{
  body: Scalars['String']
}>

export type SendChatConversationMessageMutation = {
  __typename?: 'MutationType'
  sendChatConversationMessage: { __typename?: 'Success'; success: boolean }
}

export type SignInPasswordlessUserMutationVariables = Exact<{
  signInToken: Scalars['String']
}>

export type SignInPasswordlessUserMutation = {
  __typename?: 'MutationType'
  signInPasswordlessUser: {
    __typename?: 'UserAuth'
    token?: string | null
    role?: UserRole | null
    success: boolean
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      fullName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      kind: UserKind
      avatar?: string | null
      healthieId?: number | null
      nutrisenseGetHealthieId?: number | null
      phoneNumberConsent?: boolean | null
      floridaDigitalBillOfRightsConsent: boolean
      telehealthConsent: boolean
      timeZone: string
      migrationFromInsuranceEnabled: boolean
      features: Array<MobileAppFeature>
      lastAppPlatform?: string | null
      signUpSource?: UserSignUpSource | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        primaryProduct: {
          __typename?: 'ProductMin'
          key: string
          ownSensor: boolean
          includesCgm: boolean
        }
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastResearchApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastSteloApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastDppApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastGlpApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastGlpRenewalApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
        createdAt: any
      } | null
      lastGovernmentIdApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastDoctorAssignment?: {
        __typename?: 'DoctorAssignment'
        id: string
        doctor: {
          __typename?: 'Doctor'
          id: string
          firstName?: string | null
          lastName?: string | null
          getHealthieId?: string | null
          bio?: string | null
          credentials?: string | null
          state: EhrDoctorState
          avatar?: string | null
          kind: EhrAppointmentProviderKind
        }
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        coach?: {
          __typename?: 'User'
          id: string
          fullName: string
          avatar?: string | null
          coachProfile?: {
            __typename?: 'CoachProfile'
            photo?: string | null
            bio?: string | null
            credentials?: string | null
          } | null
        } | null
      } | null
      coachChatConversation?: {
        __typename?: 'ChatConversation'
        id: string
        candidateRead: boolean
        intercomCreatedAt: any
        lastMessage?: { __typename?: 'ChatConversationMessage'; id: string } | null
      } | null
      lastMedicalAppointment?: {
        __typename?: 'EhrAppointment'
        id: string
        meetingAt?: any | null
        joinUrl?: string | null
        lastState: { __typename?: 'EhrAppointmentState'; id: string; kind: EhrAppointmentStateKind }
      } | null
      paymentMethod?: {
        __typename?: 'PaymentMethod'
        stripeId: string
        cardType: string
        last4: string
        expirationDate: any
      } | null
      uiStates: Array<{ __typename?: 'UIState'; name: UiStateNames; value: boolean }>
      surveyLinks: Array<{
        __typename?: 'SurveyLink'
        id: string
        finished: boolean
        finishedAt?: any | null
        startedAt: any
        state: SurveysUserLinkState
        survey: { __typename?: 'Survey'; kind: SurveysConfigKind }
      }>
      subscriptions: Array<{
        __typename?: 'Subscription'
        id: string
        status: SubscriptionStatus
        primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
        latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
      }>
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        schedulingWithoutPaymentMethod: boolean
        schedulingWithoutInsuranceInformation: boolean
      }
      userProperties?: {
        __typename?: 'UserProperties'
        weightLossExperience?: { __typename?: 'UserProperty'; value: string } | null
      } | null
    } | null
  }
}

export type SigninUserMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
  impersonate?: InputMaybe<Scalars['String']>
}>

export type SigninUserMutation = {
  __typename?: 'MutationType'
  signinUser?: {
    __typename?: 'UserAuth'
    token?: string | null
    success: boolean
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      fullName: string
      role: UserRole
      sex?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      kind: UserKind
      avatar?: string | null
      healthieId?: number | null
      nutrisenseGetHealthieId?: number | null
      phoneNumberConsent?: boolean | null
      floridaDigitalBillOfRightsConsent: boolean
      telehealthConsent: boolean
      timeZone: string
      migrationFromInsuranceEnabled: boolean
      features: Array<MobileAppFeature>
      lastAppPlatform?: string | null
      signUpSource?: UserSignUpSource | null
      ongoingCoreSubscription?: {
        __typename?: 'Subscription'
        primaryProduct: {
          __typename?: 'ProductMin'
          key: string
          ownSensor: boolean
          includesCgm: boolean
        }
      } | null
      address?: {
        __typename?: 'Address'
        id: string
        name: string
        street: string
        street2: string
        city: string
        state?: AddressState | null
        zipCode: string
        country?: string | null
      } | null
      lastPrescriptionApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastResearchApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastSteloApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastDppApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastGlpApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastGlpRenewalApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
        createdAt: any
      } | null
      lastGovernmentIdApprovalRequest?: {
        __typename?: 'ApprovalRequest'
        id: string
        state: UserApprovalRequestState
      } | null
      lastDoctorAssignment?: {
        __typename?: 'DoctorAssignment'
        id: string
        doctor: {
          __typename?: 'Doctor'
          id: string
          firstName?: string | null
          lastName?: string | null
          getHealthieId?: string | null
          bio?: string | null
          credentials?: string | null
          state: EhrDoctorState
          avatar?: string | null
          kind: EhrAppointmentProviderKind
        }
      } | null
      lastVideoChatAssignment?: {
        __typename?: 'CoachAssignment'
        id: string
        coach?: {
          __typename?: 'User'
          id: string
          fullName: string
          avatar?: string | null
          coachProfile?: {
            __typename?: 'CoachProfile'
            photo?: string | null
            bio?: string | null
            credentials?: string | null
          } | null
        } | null
      } | null
      coachChatConversation?: {
        __typename?: 'ChatConversation'
        id: string
        candidateRead: boolean
        intercomCreatedAt: any
        lastMessage?: { __typename?: 'ChatConversationMessage'; id: string } | null
      } | null
      lastMedicalAppointment?: {
        __typename?: 'EhrAppointment'
        id: string
        meetingAt?: any | null
        joinUrl?: string | null
        lastState: { __typename?: 'EhrAppointmentState'; id: string; kind: EhrAppointmentStateKind }
      } | null
      paymentMethod?: {
        __typename?: 'PaymentMethod'
        stripeId: string
        cardType: string
        last4: string
        expirationDate: any
      } | null
      uiStates: Array<{ __typename?: 'UIState'; name: UiStateNames; value: boolean }>
      surveyLinks: Array<{
        __typename?: 'SurveyLink'
        id: string
        finished: boolean
        finishedAt?: any | null
        startedAt: any
        state: SurveysUserLinkState
        survey: { __typename?: 'Survey'; kind: SurveysConfigKind }
      }>
      subscriptions: Array<{
        __typename?: 'Subscription'
        id: string
        status: SubscriptionStatus
        primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
        latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
      }>
      referralPartner: {
        __typename?: 'UserReferralPartnerDef'
        key: UserReferralPartner
        schedulingWithoutPaymentMethod: boolean
        schedulingWithoutInsuranceInformation: boolean
      }
      userProperties?: {
        __typename?: 'UserProperties'
        weightLossExperience?: { __typename?: 'UserProperty'; value: string } | null
      } | null
    } | null
  } | null
}

export type SignOutUserMutationVariables = Exact<{ [key: string]: never }>

export type SignOutUserMutation = {
  __typename?: 'MutationType'
  signOutUser: { __typename?: 'Success'; success: boolean }
}

export type StartSurveyMutationVariables = Exact<{
  kind: Scalars['String']
}>

export type StartSurveyMutation = {
  __typename?: 'MutationType'
  startSurvey: {
    __typename?: 'SurveyLink'
    id: string
    state: SurveysUserLinkState
    inProgress: boolean
    finished: boolean
    earlyExitDeepLink?: string | null
    completionDeepLink?: string | null
    config: {
      __typename?: 'SurveyLinkConfig'
      currentItemIndex: number
      sectionsCount: number
      questionsCount: number
      items: Array<
        | {
            __typename?: 'SurveyLinkConfigIntroItem'
            type: SurveyConfigItem
            header: string
            imageUrl?: string | null
            introMarkdown?: string | null
            icon?: UiIcon | null
            footnote?: string | null
            inlineAlert?: {
              __typename?: 'InlineAlert'
              type: UiInlineAlert
              message: string
            } | null
          }
        | {
            __typename?: 'SurveyLinkConfigQuestionItem'
            type: SurveyConfigItem
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            imageUrl?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  inlineAlert?: {
                    __typename?: 'InlineAlert'
                    type: UiInlineAlert
                    message: string
                  } | null
                  followups: Array<{
                    __typename?: 'Followup'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                    question: {
                      __typename?: 'Question'
                      key: SurveysQuestionKey
                      label?: string | null
                      note?: string | null
                      imageUrl?: string | null
                      required: boolean
                      response: {
                        __typename?: 'Response'
                        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                        kindMetadata?: any | null
                        answersGroups?: Array<{
                          __typename?: 'AnswersGroup'
                          answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                          answers: Array<{
                            __typename?: 'Answer'
                            label: string
                            value: string
                            inlineAlert?: {
                              __typename?: 'InlineAlert'
                              type: UiInlineAlert
                              message: string
                            } | null
                          }>
                        }> | null
                      }
                    }
                  }>
                }>
              }> | null
            }
          }
      >
    }
    outcome?: {
      __typename?: 'Outcome'
      id: string
      key: string
      reason: {
        __typename?: 'Reason'
        headline: string
        notes: Array<string>
        message: {
          __typename?: 'ReasonMessage'
          content: string
          links?: Array<{
            __typename?: 'ReasonMessageLink'
            placeholder: string
            url: string
          }> | null
        }
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
      }
    } | null
    survey: {
      __typename?: 'Survey'
      kind: SurveysConfigKind
      version: string
      header?: string | null
      behavior: {
        __typename?: 'Behavior'
        allowEarlyExit: boolean
        allowMultipleSubmissions: boolean
        showAlertOnClose: boolean
        showProgressIndicator: boolean
        trackResponses: boolean
        warnBeforeStopping: boolean
        redirectOnOutcome: boolean
      }
      showAlertOnCloseBehavior?: {
        __typename?: 'ShowAlertOnCloseBehavior'
        title: string
        body: string
        icon?: UiIcon | null
        confirmTitle: string
        cancelTitle: string
      } | null
      followups: Array<{
        __typename?: 'Followup'
        kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
        parentQuestionKey: SurveysQuestionKey
        parentAnswer: { __typename?: 'Answer'; value: string }
        question: {
          __typename?: 'Question'
          key: SurveysQuestionKey
          label?: string | null
          note?: string | null
          imageUrl?: string | null
          required: boolean
          response: {
            __typename?: 'Response'
            kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
            kindMetadata?: any | null
            answersGroups?: Array<{
              __typename?: 'AnswersGroup'
              answers: Array<{
                __typename?: 'Answer'
                label: string
                value: string
                inlineAlert?: {
                  __typename?: 'InlineAlert'
                  type: UiInlineAlert
                  message: string
                } | null
                followups: Array<{
                  __typename?: 'Followup'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                  question: {
                    __typename?: 'Question'
                    key: SurveysQuestionKey
                    label?: string | null
                    note?: string | null
                    imageUrl?: string | null
                    required: boolean
                    response: {
                      __typename?: 'Response'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                      kindMetadata?: any | null
                      answersGroups?: Array<{
                        __typename?: 'AnswersGroup'
                        answers: Array<{
                          __typename?: 'Answer'
                          label: string
                          value: string
                          inlineAlert?: {
                            __typename?: 'InlineAlert'
                            type: UiInlineAlert
                            message: string
                          } | null
                        }>
                      }> | null
                    }
                  }
                }>
              }>
            }> | null
          }
        }
      }>
      completedScreen?: {
        __typename?: 'CompletedScreen'
        title?: string | null
        header: string
        intro?: string | null
        introMarkdown?: string | null
        icon: UiIcon
        primaryActionText?: string | null
        footnote?: string | null
        secondaryActionLink?: { __typename?: 'Link'; text: string; url: string } | null
      } | null
      questionsGroups: Array<{
        __typename?: 'QuestionsGroup'
        banner?: string | null
        introScreen?: {
          __typename?: 'IntroScreen'
          header: string
          intro?: string | null
          introMarkdown?: string | null
          icon?: UiIcon | null
          footnote?: string | null
          imageUrl?: string | null
          inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
        } | null
        questions: Array<{
          __typename?: 'Question'
          key: SurveysQuestionKey
          label?: string | null
          note?: string | null
          imageUrl?: string | null
          required: boolean
          response: {
            __typename?: 'Response'
            kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
            kindMetadata?: any | null
            answersGroups?: Array<{
              __typename?: 'AnswersGroup'
              answers: Array<{
                __typename?: 'Answer'
                label: string
                value: string
                inlineAlert?: {
                  __typename?: 'InlineAlert'
                  type: UiInlineAlert
                  message: string
                } | null
                followups: Array<{
                  __typename?: 'Followup'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                  question: {
                    __typename?: 'Question'
                    key: SurveysQuestionKey
                    label?: string | null
                    note?: string | null
                    imageUrl?: string | null
                    required: boolean
                    response: {
                      __typename?: 'Response'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                      kindMetadata?: any | null
                      answersGroups?: Array<{
                        __typename?: 'AnswersGroup'
                        answers: Array<{
                          __typename?: 'Answer'
                          label: string
                          value: string
                          inlineAlert?: {
                            __typename?: 'InlineAlert'
                            type: UiInlineAlert
                            message: string
                          } | null
                        }>
                      }> | null
                    }
                  }
                }>
              }>
            }> | null
          }
        }>
      }>
    }
    responses: Array<{
      __typename?: 'SurveyResponse'
      id: string
      questionKey: SurveysQuestionKey
      answer: any
    }>
    user?: { __typename?: 'User'; id: string } | null
  }
}

export type SubmitInsurancePolicyMutationVariables = Exact<{ [key: string]: never }>

export type SubmitInsurancePolicyMutation = {
  __typename?: 'MutationType'
  submitInsurancePolicy: {
    __typename?: 'InsurancePolicy'
    id: string
    eligible: boolean
    declined: boolean
    inReview: boolean
    unitsLeft?: number | null
    unitsUsed?: number | null
    initialAppointmentType?: {
      __typename?: 'AppointmentType'
      key: InsuranceBerryStreetAppointmentType
      title: string
      duration: string
    } | null
    lastState?: { __typename?: 'InsurancePolicyState'; kind: UserInsurancePolicyStateKind } | null
  }
}

export type SwitchDppCourseCohortSessionMutationVariables = Exact<{
  currentSessionId: Scalars['ID']
  switchSessionId: Scalars['ID']
}>

export type SwitchDppCourseCohortSessionMutation = {
  __typename?: 'MutationType'
  switchDppCourseCohortSession: { __typename?: 'Success'; success: boolean }
}

export type UpdateAddressMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  street: Scalars['String']
  street2: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
  country: AddressCountries
  zipCode: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}>

export type UpdateAddressMutation = {
  __typename?: 'MutationType'
  updateAddress: {
    __typename?: 'Address'
    id: string
    name: string
    street: string
    street2: string
    city: string
    state?: AddressState | null
    zipCode: string
    country?: string | null
  }
}

export type UpdateDppCourseCohortSessionAttendanceMutationVariables = Exact<{
  id: Scalars['ID']
  rsvp: CoursesCohortSessionAttendanceRsvp
}>

export type UpdateDppCourseCohortSessionAttendanceMutation = {
  __typename?: 'MutationType'
  updateDppCourseCohortSessionAttendance: {
    __typename?: 'CohortSessionAttendance'
    id: string
    state: CoursesCohortSessionAttendanceState
    rsvp: CoursesCohortSessionAttendanceRsvp
  }
}

export type UpdateUserMutationVariables = Exact<{
  sex?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}>

export type UpdateUserMutation = {
  __typename?: 'MutationType'
  updateUser: {
    __typename?: 'User'
    id: string
    email: string
    firstName: string
    lastName: string
    fullName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    kind: UserKind
    avatar?: string | null
    healthieId?: number | null
    nutrisenseGetHealthieId?: number | null
    phoneNumberConsent?: boolean | null
    floridaDigitalBillOfRightsConsent: boolean
    telehealthConsent: boolean
    timeZone: string
    migrationFromInsuranceEnabled: boolean
    features: Array<MobileAppFeature>
    lastAppPlatform?: string | null
    signUpSource?: UserSignUpSource | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      primaryProduct: {
        __typename?: 'ProductMin'
        key: string
        ownSensor: boolean
        includesCgm: boolean
      }
    } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastResearchApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastSteloApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastDppApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastGlpApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastGlpRenewalApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
      createdAt: any
    } | null
    lastGovernmentIdApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastDoctorAssignment?: {
      __typename?: 'DoctorAssignment'
      id: string
      doctor: {
        __typename?: 'Doctor'
        id: string
        firstName?: string | null
        lastName?: string | null
        getHealthieId?: string | null
        bio?: string | null
        credentials?: string | null
        state: EhrDoctorState
        avatar?: string | null
        kind: EhrAppointmentProviderKind
      }
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      coach?: {
        __typename?: 'User'
        id: string
        fullName: string
        avatar?: string | null
        coachProfile?: {
          __typename?: 'CoachProfile'
          photo?: string | null
          bio?: string | null
          credentials?: string | null
        } | null
      } | null
    } | null
    coachChatConversation?: {
      __typename?: 'ChatConversation'
      id: string
      candidateRead: boolean
      intercomCreatedAt: any
      lastMessage?: { __typename?: 'ChatConversationMessage'; id: string } | null
    } | null
    lastMedicalAppointment?: {
      __typename?: 'EhrAppointment'
      id: string
      meetingAt?: any | null
      joinUrl?: string | null
      lastState: { __typename?: 'EhrAppointmentState'; id: string; kind: EhrAppointmentStateKind }
    } | null
    paymentMethod?: {
      __typename?: 'PaymentMethod'
      stripeId: string
      cardType: string
      last4: string
      expirationDate: any
    } | null
    uiStates: Array<{ __typename?: 'UIState'; name: UiStateNames; value: boolean }>
    surveyLinks: Array<{
      __typename?: 'SurveyLink'
      id: string
      finished: boolean
      finishedAt?: any | null
      startedAt: any
      state: SurveysUserLinkState
      survey: { __typename?: 'Survey'; kind: SurveysConfigKind }
    }>
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      status: SubscriptionStatus
      primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
      latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
    }>
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      schedulingWithoutPaymentMethod: boolean
      schedulingWithoutInsuranceInformation: boolean
    }
    userProperties?: {
      __typename?: 'UserProperties'
      weightLossExperience?: { __typename?: 'UserProperty'; value: string } | null
    } | null
  }
}

export type UpsertEhrAppointmentRecurrenceMutationVariables = Exact<{
  cadence?: InputMaybe<EhrAppointmentRecurrenceCadence>
  timeSlots?: InputMaybe<Array<Scalars['ISO8601DateTime']> | Scalars['ISO8601DateTime']>
  days?: InputMaybe<Array<EhrAppointmentRecurrenceDay> | EhrAppointmentRecurrenceDay>
  numberOfCalls?: InputMaybe<Scalars['Int']>
}>

export type UpsertEhrAppointmentRecurrenceMutation = {
  __typename?: 'MutationType'
  upsertEhrAppointmentRecurrence: { __typename?: 'EhrAppointmentRecurrence'; id: string }
}

export type AllAppointmentsQueryVariables = Exact<{
  sorts?: InputMaybe<Array<AppointmentSort> | AppointmentSort>
  dynamicFilters?: InputMaybe<Array<AppointmentDynamicFilterItem> | AppointmentDynamicFilterItem>
}>

export type AllAppointmentsQuery = {
  __typename?: 'QueryType'
  allAppointments: {
    __typename?: 'AppointmentCollection'
    appointments: Array<{
      __typename?: 'Appointment'
      id: string
      meetingAt?: any | null
      meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
      status: InsuranceBerryStreetAppointmentStatus
      title?: string | null
      nutritionistName?: string | null
      addToGoogleCalendarLink?: string | null
      unauthenticatedIcsLink?: string | null
      appointmentType?: InsuranceBerryStreetAppointmentType | null
      chargeType?: InsuranceBerryStreetAppointmentChargeType | null
      recurrent: boolean
    }>
  }
}

export type AllChatConversationMessagesQueryVariables = Exact<{
  sorts?: InputMaybe<
    Array<WebMembersChatConversationMessageSort> | WebMembersChatConversationMessageSort
  >
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}>

export type AllChatConversationMessagesQuery = {
  __typename?: 'QueryType'
  allChatConversationMessages: {
    __typename?: 'ChatConversationMessageCollection'
    totalCount: number
    pageCount?: number | null
    messages: Array<{
      __typename?: 'WebMembersChatConversationMessage'
      id: string
      senderId: string
      senderName: string
      senderAvatar?: string | null
      senderKind: ChatConversationMessageSenderKind
      value: string
      sentAt: any
    }>
  }
}

export type AllFilesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorts?: InputMaybe<Array<FileSort> | FileSort>
  dynamicFilters?: InputMaybe<Array<FileDynamicFilterItem> | FileDynamicFilterItem>
}>

export type AllFilesQuery = {
  __typename?: 'QueryType'
  allFiles: {
    __typename?: 'ListFiles'
    files: Array<{
      __typename?: 'File'
      id: string
      tag?: UserFileTag | null
      fileType: string
      fileName: string
      downloadFileUrl: string
    }>
  }
}

export type AllProductsQueryVariables = Exact<{
  category?: InputMaybe<BillingProductCategory>
}>

export type AllProductsQuery = {
  __typename?: 'QueryType'
  allProducts: {
    __typename?: 'ProductCollection'
    products: Array<{
      __typename?: 'Product'
      id: string
      key: string
      order: number
      title: string
      description?: string | null
      details?: Array<string> | null
      price: number
      interval: { __typename?: 'Interval'; key: string; description: string; suffix: string }
    }>
  }
}

export type AllSurveyLinksQueryVariables = Exact<{ [key: string]: never }>

export type AllSurveyLinksQuery = {
  __typename?: 'QueryType'
  allSurveyLinks: {
    __typename?: 'SurveyLinkCollection'
    surveyLinks: Array<{
      __typename?: 'SurveyLink'
      id: string
      state: SurveysUserLinkState
      inProgress: boolean
      finished: boolean
      earlyExitDeepLink?: string | null
      completionDeepLink?: string | null
      outcome?: {
        __typename?: 'Outcome'
        id: string
        key: string
        reason: {
          __typename?: 'Reason'
          headline: string
          notes: Array<string>
          message: {
            __typename?: 'ReasonMessage'
            content: string
            links?: Array<{
              __typename?: 'ReasonMessageLink'
              placeholder: string
              url: string
            }> | null
          }
          inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
        }
      } | null
      survey: {
        __typename?: 'Survey'
        kind: SurveysConfigKind
        version: string
        header?: string | null
        behavior: {
          __typename?: 'Behavior'
          allowEarlyExit: boolean
          allowMultipleSubmissions: boolean
          showAlertOnClose: boolean
          showProgressIndicator: boolean
          trackResponses: boolean
          warnBeforeStopping: boolean
          redirectOnOutcome: boolean
        }
        showAlertOnCloseBehavior?: {
          __typename?: 'ShowAlertOnCloseBehavior'
          title: string
          body: string
          icon?: UiIcon | null
          confirmTitle: string
          cancelTitle: string
        } | null
        followups: Array<{
          __typename?: 'Followup'
          kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
          parentQuestionKey: SurveysQuestionKey
          parentAnswer: { __typename?: 'Answer'; value: string }
          question: {
            __typename?: 'Question'
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            imageUrl?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  inlineAlert?: {
                    __typename?: 'InlineAlert'
                    type: UiInlineAlert
                    message: string
                  } | null
                  followups: Array<{
                    __typename?: 'Followup'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                    question: {
                      __typename?: 'Question'
                      key: SurveysQuestionKey
                      label?: string | null
                      note?: string | null
                      imageUrl?: string | null
                      required: boolean
                      response: {
                        __typename?: 'Response'
                        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                        kindMetadata?: any | null
                        answersGroups?: Array<{
                          __typename?: 'AnswersGroup'
                          answers: Array<{
                            __typename?: 'Answer'
                            label: string
                            value: string
                            inlineAlert?: {
                              __typename?: 'InlineAlert'
                              type: UiInlineAlert
                              message: string
                            } | null
                          }>
                        }> | null
                      }
                    }
                  }>
                }>
              }> | null
            }
          }
        }>
        completedScreen?: {
          __typename?: 'CompletedScreen'
          title?: string | null
          header: string
          intro?: string | null
          introMarkdown?: string | null
          icon: UiIcon
          primaryActionText?: string | null
          footnote?: string | null
          secondaryActionLink?: { __typename?: 'Link'; text: string; url: string } | null
        } | null
        questionsGroups: Array<{
          __typename?: 'QuestionsGroup'
          banner?: string | null
          introScreen?: {
            __typename?: 'IntroScreen'
            header: string
            intro?: string | null
            introMarkdown?: string | null
            icon?: UiIcon | null
            footnote?: string | null
            imageUrl?: string | null
            inlineAlert?: {
              __typename?: 'InlineAlert'
              type: UiInlineAlert
              message: string
            } | null
          } | null
          questions: Array<{
            __typename?: 'Question'
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            imageUrl?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  inlineAlert?: {
                    __typename?: 'InlineAlert'
                    type: UiInlineAlert
                    message: string
                  } | null
                  followups: Array<{
                    __typename?: 'Followup'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                    question: {
                      __typename?: 'Question'
                      key: SurveysQuestionKey
                      label?: string | null
                      note?: string | null
                      imageUrl?: string | null
                      required: boolean
                      response: {
                        __typename?: 'Response'
                        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                        kindMetadata?: any | null
                        answersGroups?: Array<{
                          __typename?: 'AnswersGroup'
                          answers: Array<{
                            __typename?: 'Answer'
                            label: string
                            value: string
                            inlineAlert?: {
                              __typename?: 'InlineAlert'
                              type: UiInlineAlert
                              message: string
                            } | null
                          }>
                        }> | null
                      }
                    }
                  }>
                }>
              }> | null
            }
          }>
        }>
      }
      responses: Array<{
        __typename?: 'SurveyResponse'
        id: string
        questionKey: SurveysQuestionKey
        answer: any
      }>
      user?: { __typename?: 'User'; id: string } | null
    }>
  }
}

export type AvailableDppCourseCohortSessionsQueryVariables = Exact<{ [key: string]: never }>

export type AvailableDppCourseCohortSessionsQuery = {
  __typename?: 'QueryType'
  availableDppCourseCohortSessions: Array<{
    __typename?: 'CohortSession'
    id: string
    name: string
    kind: CoursesCohortSessionKind
    position: number
    scheduledAt: any
    scheduledEndAt: any
    cancelledAt?: any | null
    joinUrl?: string | null
    attendance?: {
      __typename?: 'CohortSessionAttendance'
      id: string
      state: CoursesCohortSessionAttendanceState
      rsvp: CoursesCohortSessionAttendanceRsvp
    } | null
  }>
}

export type ChartsDataQueryVariables = Exact<{
  filter: ChartFilter
}>

export type ChartsDataQuery = {
  __typename?: 'QueryType'
  chartsData: {
    __typename?: 'ChartsData'
    values?: Array<{
      __typename?: 'ChartData'
      section: string
      key: string
      data: Array<
        | { __typename?: 'NumericPair' }
        | { __typename?: 'RangePair' }
        | { __typename?: 'StringPair'; x: string; y?: number | null }
        | { __typename?: 'TimePair' }
      >
    }> | null
  }
}

export type ClientConfigQueryVariables = Exact<{ [key: string]: never }>

export type ClientConfigQuery = {
  __typename?: 'QueryType'
  clientConfig: {
    __typename?: 'ClientConfig'
    healthSettings: {
      __typename?: 'HealthSettings'
      unitSystemOptions: Array<{ __typename?: 'StringSelect'; text: string; value: string }>
    }
    appointmentTypes: Array<{
      __typename?: 'AppointmentType'
      duration: string
      key: InsuranceBerryStreetAppointmentType
      title: string
    }>
    usStates: Array<{ __typename?: 'State'; name: string; abbreviation: string }>
    researchFaqs: {
      __typename?: 'ResearchFaqs'
      rootUrl: string
      gettingStartedUrl: string
      eligibilityUrl: string
      dataProtectionUrl: string
      sensorComparisonUrl: string
    }
  }
}

export type CoachProfileQueryVariables = Exact<{
  coachId: Scalars['ID']
}>

export type CoachProfileQuery = {
  __typename?: 'QueryType'
  coachProfile: {
    __typename?: 'CoachProfile'
    bio?: string | null
    coachId: string
    credentials?: string | null
    fullName: string
    photo?: string | null
    stateLicences: Array<string>
    specialties: Array<{
      __typename?: 'EhrCoachSpecialtyDef'
      key: EhrCoachSpecialty
      name: string
    }>
  }
}

export type CourseCohortQueryVariables = Exact<{
  kind: CoursesCohortKind
}>

export type CourseCohortQuery = {
  __typename?: 'QueryType'
  courseCohort?: {
    __typename?: 'Cohort'
    id: string
    state: CoursesCohortState
    name: string
    instructor?: {
      __typename?: 'CoachProfile'
      bio?: string | null
      photo?: string | null
      credentials?: string | null
      stateLicences: Array<string>
      fullName: string
      outOfOffice: boolean
      coachId: string
    } | null
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      name: string
      kind: CoursesCohortSessionKind
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
      attendance?: {
        __typename?: 'CohortSessionAttendance'
        id: string
        state: CoursesCohortSessionAttendanceState
        rsvp: CoursesCohortSessionAttendanceRsvp
      } | null
    }>
  } | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'QueryType'
  currentUser?: {
    __typename?: 'User'
    id: string
    email: string
    firstName: string
    lastName: string
    fullName: string
    role: UserRole
    sex?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    kind: UserKind
    avatar?: string | null
    healthieId?: number | null
    nutrisenseGetHealthieId?: number | null
    phoneNumberConsent?: boolean | null
    floridaDigitalBillOfRightsConsent: boolean
    telehealthConsent: boolean
    timeZone: string
    migrationFromInsuranceEnabled: boolean
    features: Array<MobileAppFeature>
    lastAppPlatform?: string | null
    signUpSource?: UserSignUpSource | null
    ongoingCoreSubscription?: {
      __typename?: 'Subscription'
      primaryProduct: {
        __typename?: 'ProductMin'
        key: string
        ownSensor: boolean
        includesCgm: boolean
      }
    } | null
    address?: {
      __typename?: 'Address'
      id: string
      name: string
      street: string
      street2: string
      city: string
      state?: AddressState | null
      zipCode: string
      country?: string | null
    } | null
    lastPrescriptionApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastResearchApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastSteloApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastDppApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastGlpApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastGlpRenewalApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
      createdAt: any
    } | null
    lastGovernmentIdApprovalRequest?: {
      __typename?: 'ApprovalRequest'
      id: string
      state: UserApprovalRequestState
    } | null
    lastDoctorAssignment?: {
      __typename?: 'DoctorAssignment'
      id: string
      doctor: {
        __typename?: 'Doctor'
        id: string
        firstName?: string | null
        lastName?: string | null
        getHealthieId?: string | null
        bio?: string | null
        credentials?: string | null
        state: EhrDoctorState
        avatar?: string | null
        kind: EhrAppointmentProviderKind
      }
    } | null
    lastVideoChatAssignment?: {
      __typename?: 'CoachAssignment'
      id: string
      coach?: {
        __typename?: 'User'
        id: string
        fullName: string
        avatar?: string | null
        coachProfile?: {
          __typename?: 'CoachProfile'
          photo?: string | null
          bio?: string | null
          credentials?: string | null
        } | null
      } | null
    } | null
    coachChatConversation?: {
      __typename?: 'ChatConversation'
      id: string
      candidateRead: boolean
      intercomCreatedAt: any
      lastMessage?: { __typename?: 'ChatConversationMessage'; id: string } | null
    } | null
    lastMedicalAppointment?: {
      __typename?: 'EhrAppointment'
      id: string
      meetingAt?: any | null
      joinUrl?: string | null
      lastState: { __typename?: 'EhrAppointmentState'; id: string; kind: EhrAppointmentStateKind }
    } | null
    paymentMethod?: {
      __typename?: 'PaymentMethod'
      stripeId: string
      cardType: string
      last4: string
      expirationDate: any
    } | null
    uiStates: Array<{ __typename?: 'UIState'; name: UiStateNames; value: boolean }>
    surveyLinks: Array<{
      __typename?: 'SurveyLink'
      id: string
      finished: boolean
      finishedAt?: any | null
      startedAt: any
      state: SurveysUserLinkState
      survey: { __typename?: 'Survey'; kind: SurveysConfigKind }
    }>
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      status: SubscriptionStatus
      primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
      latestInvoice?: { __typename?: 'Invoice'; status: InvoiceStatus } | null
    }>
    referralPartner: {
      __typename?: 'UserReferralPartnerDef'
      key: UserReferralPartner
      schedulingWithoutPaymentMethod: boolean
      schedulingWithoutInsuranceInformation: boolean
    }
    userProperties?: {
      __typename?: 'UserProperties'
      weightLossExperience?: { __typename?: 'UserProperty'; value: string } | null
    } | null
  } | null
}

export type DppCourseCohortQueryVariables = Exact<{ [key: string]: never }>

export type DppCourseCohortQuery = {
  __typename?: 'QueryType'
  dppCourseCohort?: {
    __typename?: 'Cohort'
    id: string
    state: CoursesCohortState
    name: string
    instructor?: {
      __typename?: 'CoachProfile'
      bio?: string | null
      photo?: string | null
      credentials?: string | null
      stateLicences: Array<string>
      fullName: string
      outOfOffice: boolean
      coachId: string
    } | null
    sessions: Array<{
      __typename?: 'CohortSession'
      id: string
      name: string
      kind: CoursesCohortSessionKind
      position: number
      scheduledAt: any
      scheduledEndAt: any
      cancelledAt?: any | null
      joinUrl?: string | null
      attendance?: {
        __typename?: 'CohortSessionAttendance'
        id: string
        state: CoursesCohortSessionAttendanceState
        rsvp: CoursesCohortSessionAttendanceRsvp
      } | null
    }>
  } | null
}

export type DppEnrollableCourseCohortsQueryVariables = Exact<{ [key: string]: never }>

export type DppEnrollableCourseCohortsQuery = {
  __typename?: 'QueryType'
  dppEnrollableCourseCohorts?: {
    __typename?: 'CohortCollection'
    cohorts: Array<{
      __typename?: 'Cohort'
      id: string
      state: CoursesCohortState
      name: string
      instructor?: {
        __typename?: 'CoachProfile'
        bio?: string | null
        photo?: string | null
        credentials?: string | null
        stateLicences: Array<string>
        fullName: string
        outOfOffice: boolean
        coachId: string
      } | null
      sessions: Array<{
        __typename?: 'CohortSession'
        id: string
        name: string
        kind: CoursesCohortSessionKind
        position: number
        scheduledAt: any
        scheduledEndAt: any
        cancelledAt?: any | null
        joinUrl?: string | null
        attendance?: {
          __typename?: 'CohortSessionAttendance'
          id: string
          state: CoursesCohortSessionAttendanceState
          rsvp: CoursesCohortSessionAttendanceRsvp
        } | null
      }>
    }>
  } | null
}

export type DppHealthDataRecordsChecklistQueryVariables = Exact<{ [key: string]: never }>

export type DppHealthDataRecordsChecklistQuery = {
  __typename?: 'QueryType'
  dppHealthDataRecordsChecklist: {
    __typename?: 'DppHealthDataRecordsChecklist'
    baselineChecklist: {
      __typename?: 'DppHealthDataRecordChecklist'
      state: DppHealthDataRecordChecklistState
      requiresPhotoUpload: boolean
    }
    hbA1cChecklist: {
      __typename?: 'DppHealthDataRecordChecklist'
      state: DppHealthDataRecordChecklistState
      requiresPhotoUpload: boolean
    }
    weightChecklist: {
      __typename?: 'DppHealthDataRecordChecklist'
      state: DppHealthDataRecordChecklistState
      requiresPhotoUpload: boolean
    }
    physicalActivityChecklist: {
      __typename?: 'DppHealthDataRecordChecklist'
      state: DppHealthDataRecordChecklistState
      requiresPhotoUpload: boolean
    }
  }
}

export type EhrAppointmentsQueryVariables = Exact<{
  sorts?: InputMaybe<Array<EhrAppointmentSort> | EhrAppointmentSort>
  filterKind?: InputMaybe<FilterAppointmentsKind>
  category?: InputMaybe<EhrAppointmentCategory>
}>

export type EhrAppointmentsQuery = {
  __typename?: 'QueryType'
  ehrAppointments: {
    __typename?: 'EhrAppointmentCollection'
    appointments: Array<{
      __typename?: 'EhrAppointment'
      id: string
      getHealthieId?: string | null
      category: EhrAppointmentCategory
      kind: EhrAppointmentKind
      meetingAt?: any | null
      title?: string | null
      getHealthieInstanceKind: EhrGetHealthieInstanceKind
      startUrl?: string | null
      joinUrl?: string | null
      addToGcalLink?: string | null
      unauthenticatedIcsLink?: string | null
      lastState: {
        __typename?: 'EhrAppointmentState'
        reason: EhrAppointmentReason
        kind: EhrAppointmentStateKind
      }
      providers: Array<{
        __typename?: 'EhrProvider'
        id: string
        kind: EhrAppointmentProviderKind
        state: EhrAppointmentProviderState
        coach?: {
          __typename?: 'Coach'
          id: string
          bio?: string | null
          coachId: string
          credentials?: string | null
          fullName: string
          photo?: string | null
          stateLicences: Array<string>
          kind: EhrAppointmentProviderKind
          specialties: Array<{
            __typename?: 'EhrCoachSpecialtyDef'
            key: EhrCoachSpecialty
            name: string
          }>
        } | null
        doctor?: {
          __typename?: 'Doctor'
          id: string
          firstName?: string | null
          lastName?: string | null
          getHealthieId?: string | null
          bio?: string | null
          credentials?: string | null
          state: EhrDoctorState
          avatar?: string | null
          kind: EhrAppointmentProviderKind
        } | null
      }>
      attendances: Array<{
        __typename?: 'EhrAppointmentAttendance'
        state: EhrAppointmentAttendanceState
        chargeKind: EhrAppointmentAttendanceChargeKind
        user: { __typename?: 'User'; id: string }
      }>
    }>
  }
}

export type EhrAvailableDatesWithSlotsQueryVariables = Exact<{
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  timeZone?: InputMaybe<Scalars['String']>
  providerKind?: InputMaybe<EhrAppointmentProviderKind>
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
  rescheduleAppointmentId?: InputMaybe<Scalars['ID']>
  providerId?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<AddressState>
  useCache?: InputMaybe<Scalars['Boolean']>
}>

export type EhrAvailableDatesWithSlotsQuery = {
  __typename?: 'QueryType'
  ehrAvailableDatesWithSlots: {
    __typename?: 'DatesWithSlots'
    dates: Array<{
      __typename?: 'EhrDate'
      date: any
      slots: Array<{
        __typename?: 'EhrSlot'
        time: any
        providerId: string
        providerUserId?: string | null
      }>
    }>
    providers: Array<
      | {
          __typename?: 'Coach'
          id: string
          bio?: string | null
          coachId: string
          credentials?: string | null
          fullName: string
          photo?: string | null
          stateLicences: Array<string>
          kind: EhrAppointmentProviderKind
          specialties: Array<{
            __typename?: 'EhrCoachSpecialtyDef'
            key: EhrCoachSpecialty
            name: string
          }>
        }
      | {
          __typename?: 'Doctor'
          id: string
          firstName?: string | null
          lastName?: string | null
          getHealthieId?: string | null
          bio?: string | null
          credentials?: string | null
          state: EhrDoctorState
          avatar?: string | null
          kind: EhrAppointmentProviderKind
        }
    >
  }
}

export type EnrollableCourseCohortsQueryVariables = Exact<{
  kind: CoursesCohortKind
}>

export type EnrollableCourseCohortsQuery = {
  __typename?: 'QueryType'
  enrollableCourseCohorts?: {
    __typename?: 'CohortCollection'
    cohorts: Array<{
      __typename?: 'Cohort'
      id: string
      state: CoursesCohortState
      name: string
      instructor?: {
        __typename?: 'CoachProfile'
        bio?: string | null
        photo?: string | null
        credentials?: string | null
        stateLicences: Array<string>
        fullName: string
        outOfOffice: boolean
        coachId: string
      } | null
      sessions: Array<{
        __typename?: 'CohortSession'
        id: string
        name: string
        kind: CoursesCohortSessionKind
        position: number
        scheduledAt: any
        scheduledEndAt: any
        cancelledAt?: any | null
        joinUrl?: string | null
        attendance?: {
          __typename?: 'CohortSessionAttendance'
          id: string
          state: CoursesCohortSessionAttendanceState
          rsvp: CoursesCohortSessionAttendanceRsvp
        } | null
      }>
    }>
  } | null
}

export type SchedulingAllAvailabilitiesQueryVariables = Exact<{ [key: string]: never }>

export type SchedulingAllAvailabilitiesQuery = {
  __typename?: 'QueryType'
  schedulingAllAvailabilities: Array<{
    __typename?: 'EhrAvailability'
    dayOfWeek: number
    appointmentType: InsuranceBerryStreetAppointmentType
    rangeStartAt: any
    rangeEndAt: any
  }>
}

export type EhrAllAvailabilitiesQueryVariables = Exact<{ [key: string]: never }>

export type EhrAllAvailabilitiesQuery = {
  __typename?: 'QueryType'
  ehrAllAvailabilities: Array<{
    __typename?: 'Availability'
    dayOfWeek: number
    appointmentKind: EhrAppointmentKind
    rangeStartAt: any
    rangeEndAt: any
  }>
}

export type InsurancePolicyQueryVariables = Exact<{ [key: string]: never }>

export type InsurancePolicyQuery = {
  __typename?: 'QueryType'
  insurancePolicy?: {
    __typename?: 'InsurancePolicy'
    id: string
    eligible: boolean
    declined: boolean
    inReview: boolean
    unitsLeft?: number | null
    unitsUsed?: number | null
    initialAppointmentType?: {
      __typename?: 'AppointmentType'
      key: InsuranceBerryStreetAppointmentType
      title: string
      duration: string
    } | null
    lastState?: { __typename?: 'InsurancePolicyState'; kind: UserInsurancePolicyStateKind } | null
  } | null
}

export type GetHealthieApiKeyQueryVariables = Exact<{
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
}>

export type GetHealthieApiKeyQuery = {
  __typename?: 'QueryType'
  getHealthieApiKey: { __typename?: 'GetHealthieApiKey'; apiKey?: string | null }
}

export type InsurancePlansQueryVariables = Exact<{ [key: string]: never }>

export type InsurancePlansQuery = {
  __typename?: 'QueryType'
  insurancePlans: {
    __typename?: 'ListPlansActionPayload'
    insurancePlans: Array<{
      __typename?: 'InsuranceKind'
      key: UserInsurancePolicyKind
      title: string
      insuranceSurveyAnswer: string
    }>
  }
}

export type InsuranceSchedulingAvailableDatesQueryVariables = Exact<{
  timeZone?: InputMaybe<Scalars['String']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']>
  userState?: InputMaybe<AddressState>
}>

export type InsuranceSchedulingAvailableDatesQuery = {
  __typename?: 'QueryType'
  insuranceSchedulingAvailableDates: { __typename?: 'AvailableDates'; dates: Array<any> }
}

export type InsuranceSchedulingAvailableSlotsQueryVariables = Exact<{
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  timeZone?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['String']>
  coachId?: InputMaybe<Scalars['ID']>
  userState?: InputMaybe<AddressState>
}>

export type InsuranceSchedulingAvailableSlotsQuery = {
  __typename?: 'QueryType'
  insuranceSchedulingAvailableSlots: {
    __typename?: 'AvailableSlots'
    slots: Array<{ __typename?: 'Slot'; time: any; coachId: string }>
  }
}

export type InvoiceQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type InvoiceQuery = {
  __typename?: 'QueryType'
  invoice: {
    __typename?: 'Invoice'
    id: string
    stripeId: string
    total?: number | null
    status: InvoiceStatus
    primaryProduct: { __typename?: 'ProductMin'; id: string; key: string; title: string }
  }
}

export type LatestUserAppointmentQueryVariables = Exact<{ [key: string]: never }>

export type LatestUserAppointmentQuery = {
  __typename?: 'QueryType'
  latestUserAppointment?: {
    __typename?: 'LatestUserAppointment'
    id: string
    date?: string | null
    meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
    status: InsuranceBerryStreetAppointmentStatus
    title?: string | null
    nutritionistName?: string | null
    addToGoogleCalendarLink?: string | null
    unauthenticatedIcsLink?: string | null
    appointmentType?: InsuranceBerryStreetAppointmentType | null
    chargeType?: InsuranceBerryStreetAppointmentChargeType | null
    externalVideochatUrl?: string | null
    coachProfile?: {
      __typename?: 'CoachProfile'
      bio?: string | null
      coachId: string
      credentials?: string | null
      fullName: string
      photo?: string | null
      stateLicences: Array<string>
      specialties: Array<{
        __typename?: 'EhrCoachSpecialtyDef'
        key: EhrCoachSpecialty
        name: string
      }>
    } | null
  } | null
}

export type LatestChargeQueryVariables = Exact<{
  providerSubscriptionId: Scalars['ID']
}>

export type LatestChargeQuery = {
  __typename?: 'QueryType'
  latestCharge?: {
    __typename?: 'Charge'
    status: string
    failureMessage?: string | null
    created: any
    providerPaymentMethodId?: string | null
  } | null
}

export type LatestSurveyLinkByKindQueryVariables = Exact<{
  kind: SurveysConfigKind
}>

export type LatestSurveyLinkByKindQuery = {
  __typename?: 'QueryType'
  latestSurveyLinkByKind?: {
    __typename?: 'SurveyLink'
    id: string
    state: SurveysUserLinkState
    inProgress: boolean
    finished: boolean
    earlyExitDeepLink?: string | null
    completionDeepLink?: string | null
    config: {
      __typename?: 'SurveyLinkConfig'
      currentItemIndex: number
      sectionsCount: number
      questionsCount: number
      items: Array<
        | {
            __typename?: 'SurveyLinkConfigIntroItem'
            type: SurveyConfigItem
            header: string
            imageUrl?: string | null
            introMarkdown?: string | null
            icon?: UiIcon | null
            footnote?: string | null
            inlineAlert?: {
              __typename?: 'InlineAlert'
              type: UiInlineAlert
              message: string
            } | null
          }
        | {
            __typename?: 'SurveyLinkConfigQuestionItem'
            type: SurveyConfigItem
            key: SurveysQuestionKey
            label?: string | null
            note?: string | null
            imageUrl?: string | null
            required: boolean
            response: {
              __typename?: 'Response'
              kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
              kindMetadata?: any | null
              answersGroups?: Array<{
                __typename?: 'AnswersGroup'
                answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                answers: Array<{
                  __typename?: 'Answer'
                  label: string
                  value: string
                  inlineAlert?: {
                    __typename?: 'InlineAlert'
                    type: UiInlineAlert
                    message: string
                  } | null
                  followups: Array<{
                    __typename?: 'Followup'
                    kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                    question: {
                      __typename?: 'Question'
                      key: SurveysQuestionKey
                      label?: string | null
                      note?: string | null
                      imageUrl?: string | null
                      required: boolean
                      response: {
                        __typename?: 'Response'
                        kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                        kindMetadata?: any | null
                        answersGroups?: Array<{
                          __typename?: 'AnswersGroup'
                          answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
                          answers: Array<{
                            __typename?: 'Answer'
                            label: string
                            value: string
                            inlineAlert?: {
                              __typename?: 'InlineAlert'
                              type: UiInlineAlert
                              message: string
                            } | null
                          }>
                        }> | null
                      }
                    }
                  }>
                }>
              }> | null
            }
          }
      >
    }
    outcome?: {
      __typename?: 'Outcome'
      id: string
      key: string
      reason: {
        __typename?: 'Reason'
        headline: string
        notes: Array<string>
        message: {
          __typename?: 'ReasonMessage'
          content: string
          links?: Array<{
            __typename?: 'ReasonMessageLink'
            placeholder: string
            url: string
          }> | null
        }
        inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
      }
    } | null
    survey: {
      __typename?: 'Survey'
      kind: SurveysConfigKind
      version: string
      header?: string | null
      behavior: {
        __typename?: 'Behavior'
        allowEarlyExit: boolean
        allowMultipleSubmissions: boolean
        showAlertOnClose: boolean
        showProgressIndicator: boolean
        trackResponses: boolean
        warnBeforeStopping: boolean
        redirectOnOutcome: boolean
      }
      showAlertOnCloseBehavior?: {
        __typename?: 'ShowAlertOnCloseBehavior'
        title: string
        body: string
        icon?: UiIcon | null
        confirmTitle: string
        cancelTitle: string
      } | null
      followups: Array<{
        __typename?: 'Followup'
        kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
        parentQuestionKey: SurveysQuestionKey
        parentAnswer: { __typename?: 'Answer'; value: string }
        question: {
          __typename?: 'Question'
          key: SurveysQuestionKey
          label?: string | null
          note?: string | null
          imageUrl?: string | null
          required: boolean
          response: {
            __typename?: 'Response'
            kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
            kindMetadata?: any | null
            answersGroups?: Array<{
              __typename?: 'AnswersGroup'
              answers: Array<{
                __typename?: 'Answer'
                label: string
                value: string
                inlineAlert?: {
                  __typename?: 'InlineAlert'
                  type: UiInlineAlert
                  message: string
                } | null
                followups: Array<{
                  __typename?: 'Followup'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                  question: {
                    __typename?: 'Question'
                    key: SurveysQuestionKey
                    label?: string | null
                    note?: string | null
                    imageUrl?: string | null
                    required: boolean
                    response: {
                      __typename?: 'Response'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                      kindMetadata?: any | null
                      answersGroups?: Array<{
                        __typename?: 'AnswersGroup'
                        answers: Array<{
                          __typename?: 'Answer'
                          label: string
                          value: string
                          inlineAlert?: {
                            __typename?: 'InlineAlert'
                            type: UiInlineAlert
                            message: string
                          } | null
                        }>
                      }> | null
                    }
                  }
                }>
              }>
            }> | null
          }
        }
      }>
      completedScreen?: {
        __typename?: 'CompletedScreen'
        title?: string | null
        header: string
        intro?: string | null
        introMarkdown?: string | null
        icon: UiIcon
        primaryActionText?: string | null
        footnote?: string | null
        secondaryActionLink?: { __typename?: 'Link'; text: string; url: string } | null
      } | null
      questionsGroups: Array<{
        __typename?: 'QuestionsGroup'
        banner?: string | null
        introScreen?: {
          __typename?: 'IntroScreen'
          header: string
          intro?: string | null
          introMarkdown?: string | null
          icon?: UiIcon | null
          footnote?: string | null
          imageUrl?: string | null
          inlineAlert?: { __typename?: 'InlineAlert'; type: UiInlineAlert; message: string } | null
        } | null
        questions: Array<{
          __typename?: 'Question'
          key: SurveysQuestionKey
          label?: string | null
          note?: string | null
          imageUrl?: string | null
          required: boolean
          response: {
            __typename?: 'Response'
            kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
            kindMetadata?: any | null
            answersGroups?: Array<{
              __typename?: 'AnswersGroup'
              answers: Array<{
                __typename?: 'Answer'
                label: string
                value: string
                inlineAlert?: {
                  __typename?: 'InlineAlert'
                  type: UiInlineAlert
                  message: string
                } | null
                followups: Array<{
                  __typename?: 'Followup'
                  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
                  question: {
                    __typename?: 'Question'
                    key: SurveysQuestionKey
                    label?: string | null
                    note?: string | null
                    imageUrl?: string | null
                    required: boolean
                    response: {
                      __typename?: 'Response'
                      kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
                      kindMetadata?: any | null
                      answersGroups?: Array<{
                        __typename?: 'AnswersGroup'
                        answers: Array<{
                          __typename?: 'Answer'
                          label: string
                          value: string
                          inlineAlert?: {
                            __typename?: 'InlineAlert'
                            type: UiInlineAlert
                            message: string
                          } | null
                        }>
                      }> | null
                    }
                  }
                }>
              }>
            }> | null
          }
        }>
      }>
    }
    responses: Array<{
      __typename?: 'SurveyResponse'
      id: string
      questionKey: SurveysQuestionKey
      answer: any
    }>
    user?: { __typename?: 'User'; id: string } | null
  } | null
}

export type LatestUserEhrAppointmentQueryVariables = Exact<{
  category: EhrAppointmentCategory
}>

export type LatestUserEhrAppointmentQuery = {
  __typename?: 'QueryType'
  latestUserEhrAppointment?: {
    __typename?: 'EhrAppointment'
    id: string
    getHealthieId?: string | null
    category: EhrAppointmentCategory
    kind: EhrAppointmentKind
    meetingAt?: any | null
    title?: string | null
    getHealthieInstanceKind: EhrGetHealthieInstanceKind
    startUrl?: string | null
    joinUrl?: string | null
    addToGcalLink?: string | null
    unauthenticatedIcsLink?: string | null
    lastState: {
      __typename?: 'EhrAppointmentState'
      reason: EhrAppointmentReason
      kind: EhrAppointmentStateKind
    }
    providers: Array<{
      __typename?: 'EhrProvider'
      id: string
      kind: EhrAppointmentProviderKind
      state: EhrAppointmentProviderState
      coach?: {
        __typename?: 'Coach'
        id: string
        bio?: string | null
        coachId: string
        credentials?: string | null
        fullName: string
        photo?: string | null
        stateLicences: Array<string>
        kind: EhrAppointmentProviderKind
        specialties: Array<{
          __typename?: 'EhrCoachSpecialtyDef'
          key: EhrCoachSpecialty
          name: string
        }>
      } | null
      doctor?: {
        __typename?: 'Doctor'
        id: string
        firstName?: string | null
        lastName?: string | null
        getHealthieId?: string | null
        bio?: string | null
        credentials?: string | null
        state: EhrDoctorState
        avatar?: string | null
        kind: EhrAppointmentProviderKind
      } | null
    }>
    attendances: Array<{
      __typename?: 'EhrAppointmentAttendance'
      state: EhrAppointmentAttendanceState
      chargeKind: EhrAppointmentAttendanceChargeKind
      user: { __typename?: 'User'; id: string }
    }>
  } | null
}

export type ProductQueryVariables = Exact<{
  product: BillingProduct
}>

export type ProductQuery = {
  __typename?: 'QueryType'
  product: {
    __typename?: 'Product'
    id: string
    key: string
    order: number
    title: string
    description?: string | null
    details?: Array<string> | null
    price: number
    recurring: boolean
    trialDays?: number | null
    interval: { __typename?: 'Interval'; key: string; description: string; suffix: string }
  }
}

export type PromotionCodeQueryVariables = Exact<{
  code: Scalars['String']
  product?: InputMaybe<BillingProduct>
}>

export type PromotionCodeQuery = {
  __typename?: 'QueryType'
  promotionCode: {
    __typename?: 'PromotionCode'
    providerId: string
    applicable: boolean
    code: string
    percentOff?: number | null
    amountOff?: number | null
  }
}

export type ReferralPartnerQueryVariables = Exact<{
  partner: UserReferralPartner
}>

export type ReferralPartnerQuery = {
  __typename?: 'QueryType'
  referralPartner?: {
    __typename?: 'UserReferralPartnerDef'
    logoUrl?: string | null
    product?: BillingProduct | null
  } | null
}

export type SchedulingAvailableDatesWithSlotsQueryVariables = Exact<{
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  timeZone?: InputMaybe<Scalars['String']>
  coachId?: InputMaybe<Scalars['ID']>
  userState?: InputMaybe<AddressState>
  useCache?: InputMaybe<Scalars['Boolean']>
}>

export type SchedulingAvailableDatesWithSlotsQuery = {
  __typename?: 'QueryType'
  schedulingAvailableDatesWithSlots: {
    __typename?: 'AvailableDatesWithSlots'
    coachProfiles?: Array<{
      __typename?: 'CoachProfile'
      bio?: string | null
      coachId: string
      credentials?: string | null
      fullName: string
      photo?: string | null
      stateLicences: Array<string>
      specialties: Array<{
        __typename?: 'EhrCoachSpecialtyDef'
        key: EhrCoachSpecialty
        name: string
      }>
    }> | null
    dates: Array<{
      __typename?: 'Date'
      date: any
      slots: Array<{ __typename?: 'Slot'; time: any; coachId: string }>
    }>
  }
}

export type UpcomingCourseCohortSessionQueryVariables = Exact<{
  cohortKind: CoursesCohortKind
}>

export type UpcomingCourseCohortSessionQuery = {
  __typename?: 'QueryType'
  upcomingCourseCohortSession?: {
    __typename?: 'CohortSession'
    id: string
    name: string
    kind: CoursesCohortSessionKind
    position: number
    scheduledAt: any
    scheduledEndAt: any
    cancelledAt?: any | null
    joinUrl?: string | null
    attendance?: {
      __typename?: 'CohortSessionAttendance'
      id: string
      state: CoursesCohortSessionAttendanceState
      rsvp: CoursesCohortSessionAttendanceRsvp
    } | null
  } | null
}

export type UpcomingDppCourseCohortSessionQueryVariables = Exact<{ [key: string]: never }>

export type UpcomingDppCourseCohortSessionQuery = {
  __typename?: 'QueryType'
  upcomingDppCourseCohortSession?: {
    __typename?: 'CohortSession'
    id: string
    name: string
    kind: CoursesCohortSessionKind
    position: number
    scheduledAt: any
    scheduledEndAt: any
    cancelledAt?: any | null
    joinUrl?: string | null
    attendance?: {
      __typename?: 'CohortSessionAttendance'
      id: string
      state: CoursesCohortSessionAttendanceState
      rsvp: CoursesCohortSessionAttendanceRsvp
    } | null
  } | null
}

export const AppointmentFieldsFragmentDoc = gql`
  fragment AppointmentFields on Appointment {
    id
    meetingAt
    meetingStatus
    status
    title
    nutritionistName
    addToGoogleCalendarLink
    unauthenticatedIcsLink
    appointmentType
    chargeType
    recurrent
  }
`
export const CourseCohortSessionAttendanceFragmentDoc = gql`
  fragment CourseCohortSessionAttendance on CohortSessionAttendance {
    id
    state
    rsvp
  }
`
export const CourseCohortSessionFragmentDoc = gql`
  fragment CourseCohortSession on CohortSession {
    id
    name
    kind
    position
    scheduledAt
    scheduledEndAt
    cancelledAt
    joinUrl
    attendance {
      ...CourseCohortSessionAttendance
    }
  }
  ${CourseCohortSessionAttendanceFragmentDoc}
`
export const CourseCohortFragmentDoc = gql`
  fragment CourseCohort on Cohort {
    id
    state
    instructor {
      bio
      photo
      credentials
      stateLicences
      fullName
      outOfOffice
      coachId
    }
    sessions {
      ...CourseCohortSession
    }
    name
  }
  ${CourseCohortSessionFragmentDoc}
`
export const CoreAddressFieldsFragmentDoc = gql`
  fragment CoreAddressFields on Address {
    id
    name
    street
    street2
    city
    state
    zipCode
    country
  }
`
export const DoctorFragmentDoc = gql`
  fragment Doctor on Doctor {
    id
    firstName
    lastName
    getHealthieId
    bio
    credentials
    state
    avatar
    kind
  }
`
export const CoreSubscriptionFieldsFragmentDoc = gql`
  fragment CoreSubscriptionFields on Subscription {
    primaryProduct {
      id
      key
      title
    }
    id
    status
    latestInvoice {
      status
    }
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
    fullName
    role
    sex
    dateOfBirth
    phoneNumber
    kind
    avatar
    healthieId
    nutrisenseGetHealthieId
    phoneNumberConsent
    floridaDigitalBillOfRightsConsent
    telehealthConsent
    timeZone
    migrationFromInsuranceEnabled
    ongoingCoreSubscription {
      primaryProduct {
        key
        ownSensor
        includesCgm
      }
    }
    address {
      ...CoreAddressFields
    }
    lastPrescriptionApprovalRequest {
      id
      state
    }
    lastResearchApprovalRequest {
      id
      state
    }
    lastSteloApprovalRequest {
      id
      state
    }
    lastDppApprovalRequest {
      id
      state
    }
    lastGlpApprovalRequest {
      id
      state
    }
    lastGlpRenewalApprovalRequest {
      id
      state
      createdAt
    }
    lastGovernmentIdApprovalRequest {
      id
      state
    }
    lastDoctorAssignment {
      id
      doctor {
        ...Doctor
      }
    }
    lastVideoChatAssignment {
      id
      coach {
        id
        fullName
        avatar
        coachProfile {
          photo
          bio
          credentials
        }
      }
    }
    coachChatConversation {
      id
      candidateRead
      lastMessage {
        id
      }
      intercomCreatedAt
    }
    lastMedicalAppointment {
      id
      meetingAt
      joinUrl
      lastState {
        id
        kind
      }
    }
    paymentMethod {
      stripeId
      cardType
      last4
      expirationDate
    }
    uiStates {
      name
      value
    }
    surveyLinks {
      id
      finished
      finishedAt
      startedAt
      state
      survey {
        kind
      }
    }
    subscriptions {
      ...CoreSubscriptionFields
    }
    referralPartner {
      key
      schedulingWithoutPaymentMethod
      schedulingWithoutInsuranceInformation
    }
    features
    lastAppPlatform
    signUpSource
    userProperties {
      weightLossExperience {
        value
      }
    }
  }
  ${CoreAddressFieldsFragmentDoc}
  ${DoctorFragmentDoc}
  ${CoreSubscriptionFieldsFragmentDoc}
`
export const CoachFragmentDoc = gql`
  fragment Coach on Coach {
    id
    bio
    coachId
    credentials
    fullName
    photo
    stateLicences
    specialties {
      key
      name
    }
    kind
  }
`
export const EhrAppointmentFragmentDoc = gql`
  fragment EhrAppointment on EhrAppointment {
    id
    getHealthieId
    category
    kind
    meetingAt
    title
    getHealthieInstanceKind
    startUrl
    joinUrl
    addToGcalLink
    unauthenticatedIcsLink
    lastState {
      reason
      kind
    }
    providers {
      id
      coach {
        ...Coach
      }
      doctor {
        ...Doctor
      }
      kind
      state
    }
    attendances {
      user {
        id
      }
      state
      chargeKind
    }
  }
  ${CoachFragmentDoc}
  ${DoctorFragmentDoc}
`
export const InsurancePolicyFieldsFragmentDoc = gql`
  fragment InsurancePolicyFields on InsurancePolicy {
    id
    initialAppointmentType {
      key
      title
      duration
    }
    eligible
    declined
    inReview
    unitsLeft
    unitsUsed
    lastState {
      kind
    }
  }
`
export const CoachProfileFragmentDoc = gql`
  fragment CoachProfile on CoachProfile {
    bio
    coachId
    credentials
    fullName
    photo
    stateLicences
    specialties {
      key
      name
    }
  }
`
export const LatestUserAppointmentFragmentDoc = gql`
  fragment LatestUserAppointment on LatestUserAppointment {
    id
    date
    meetingStatus
    status
    title
    nutritionistName
    addToGoogleCalendarLink
    unauthenticatedIcsLink
    appointmentType
    chargeType
    externalVideochatUrl
    coachProfile {
      ...CoachProfile
    }
  }
  ${CoachProfileFragmentDoc}
`
export const SurveyOutcomeFragmentDoc = gql`
  fragment SurveyOutcome on Outcome {
    id
    key
    reason {
      headline
      notes
      message {
        content
        links {
          placeholder
          url
        }
      }
      inlineAlert {
        type
        message
      }
    }
  }
`
export const SurveyQuestionFragmentDoc = gql`
  fragment SurveyQuestion on Question {
    key
    label
    note
    imageUrl
    required
    response {
      kind
      kindMetadata
      answersGroups {
        answers {
          label
          value
          inlineAlert {
            type
            message
          }
          followups {
            kind
            question {
              key
              label
              note
              imageUrl
              required
              response {
                kind
                kindMetadata
                answersGroups {
                  answers {
                    label
                    value
                    inlineAlert {
                      type
                      message
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const SurveyFragmentDoc = gql`
  fragment Survey on Survey {
    kind
    behavior {
      allowEarlyExit
      allowMultipleSubmissions
      showAlertOnClose
      showProgressIndicator
      trackResponses
      warnBeforeStopping
      redirectOnOutcome
    }
    showAlertOnCloseBehavior {
      title
      body
      icon
      confirmTitle
      cancelTitle
    }
    version
    followups {
      parentAnswer {
        value
      }
      kind
      parentQuestionKey
      question {
        ...SurveyQuestion
      }
    }
    header
    completedScreen {
      title
      header
      intro
      introMarkdown
      icon
      primaryActionText
      footnote
      secondaryActionLink {
        text
        url
      }
    }
    questionsGroups {
      introScreen {
        header
        intro
        introMarkdown
        icon
        footnote
        imageUrl
        inlineAlert {
          type
          message
        }
      }
      banner
      questions {
        ...SurveyQuestion
      }
    }
  }
  ${SurveyQuestionFragmentDoc}
`
export const SurveyLinkFragmentDoc = gql`
  fragment SurveyLink on SurveyLink {
    id
    state
    inProgress
    finished
    outcome {
      ...SurveyOutcome
    }
    survey {
      ...Survey
    }
    responses {
      id
      questionKey
      answer
    }
    user {
      id
    }
    earlyExitDeepLink
    completionDeepLink
  }
  ${SurveyOutcomeFragmentDoc}
  ${SurveyFragmentDoc}
`
export const QuestionItemFieldsFragmentDoc = gql`
  fragment QuestionItemFields on SurveyLinkConfigQuestionItem {
    key
    label
    note
    imageUrl
    required
    response {
      kind
      kindMetadata
      answersGroups {
        answersOrder
        answers {
          label
          value
          inlineAlert {
            type
            message
          }
          followups {
            kind
            question {
              key
              label
              note
              imageUrl
              required
              response {
                kind
                kindMetadata
                answersGroups {
                  answersOrder
                  answers {
                    label
                    value
                    inlineAlert {
                      type
                      message
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const SurveyLinkConfigFragmentDoc = gql`
  fragment SurveyLinkConfig on SurveyLinkConfig {
    currentItemIndex
    sectionsCount
    questionsCount
    items {
      ... on SurveyLinkConfigQuestionItem {
        type
        ...QuestionItemFields
      }
      ... on SurveyLinkConfigIntroItem {
        type
        header
        imageUrl
        introMarkdown
        icon
        footnote
        inlineAlert {
          type
          message
        }
      }
    }
  }
  ${QuestionItemFieldsFragmentDoc}
`
export const AcceptLegalPolicyDocument = gql`
  mutation acceptLegalPolicy($kind: LegalPolicyConsentKind!) {
    acceptLegalPolicy(kind: $kind) {
      kind
      state
      createdAt
    }
  }
`
export type AcceptLegalPolicyMutationFn = Apollo.MutationFunction<
  AcceptLegalPolicyMutation,
  AcceptLegalPolicyMutationVariables
>

/**
 * __useAcceptLegalPolicyMutation__
 *
 * To run a mutation, you first call `useAcceptLegalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptLegalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptLegalPolicyMutation, { data, loading, error }] = useAcceptLegalPolicyMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useAcceptLegalPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptLegalPolicyMutation,
    AcceptLegalPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptLegalPolicyMutation, AcceptLegalPolicyMutationVariables>(
    AcceptLegalPolicyDocument,
    options
  )
}
export type AcceptLegalPolicyMutationHookResult = ReturnType<typeof useAcceptLegalPolicyMutation>
export type AcceptLegalPolicyMutationResult = Apollo.MutationResult<AcceptLegalPolicyMutation>
export type AcceptLegalPolicyMutationOptions = Apollo.BaseMutationOptions<
  AcceptLegalPolicyMutation,
  AcceptLegalPolicyMutationVariables
>
export const BookVideoCallDocument = gql`
  mutation bookVideoCall(
    $appointmentType: InsuranceBerryStreetAppointmentType
    $timeZone: String
    $date: String
    $coachId: ID
    $referringCoachUserId: ID
    $pendingAppointmentId: ID
    $agenda: String
  ) {
    bookVideoCall(
      appointmentType: $appointmentType
      timeZone: $timeZone
      date: $date
      coachId: $coachId
      referringCoachUserId: $referringCoachUserId
      pendingAppointmentId: $pendingAppointmentId
      agenda: $agenda
    ) {
      date
    }
  }
`
export type BookVideoCallMutationFn = Apollo.MutationFunction<
  BookVideoCallMutation,
  BookVideoCallMutationVariables
>

/**
 * __useBookVideoCallMutation__
 *
 * To run a mutation, you first call `useBookVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookVideoCallMutation, { data, loading, error }] = useBookVideoCallMutation({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *      timeZone: // value for 'timeZone'
 *      date: // value for 'date'
 *      coachId: // value for 'coachId'
 *      referringCoachUserId: // value for 'referringCoachUserId'
 *      pendingAppointmentId: // value for 'pendingAppointmentId'
 *      agenda: // value for 'agenda'
 *   },
 * });
 */
export function useBookVideoCallMutation(
  baseOptions?: Apollo.MutationHookOptions<BookVideoCallMutation, BookVideoCallMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BookVideoCallMutation, BookVideoCallMutationVariables>(
    BookVideoCallDocument,
    options
  )
}
export type BookVideoCallMutationHookResult = ReturnType<typeof useBookVideoCallMutation>
export type BookVideoCallMutationResult = Apollo.MutationResult<BookVideoCallMutation>
export type BookVideoCallMutationOptions = Apollo.BaseMutationOptions<
  BookVideoCallMutation,
  BookVideoCallMutationVariables
>
export const CancelEhrAppointmentDocument = gql`
  mutation cancelEhrAppointment($id: ID!) {
    cancelEhrAppointment(id: $id) {
      id
    }
  }
`
export type CancelEhrAppointmentMutationFn = Apollo.MutationFunction<
  CancelEhrAppointmentMutation,
  CancelEhrAppointmentMutationVariables
>

/**
 * __useCancelEhrAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelEhrAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEhrAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEhrAppointmentMutation, { data, loading, error }] = useCancelEhrAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelEhrAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelEhrAppointmentMutation,
    CancelEhrAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelEhrAppointmentMutation, CancelEhrAppointmentMutationVariables>(
    CancelEhrAppointmentDocument,
    options
  )
}
export type CancelEhrAppointmentMutationHookResult = ReturnType<
  typeof useCancelEhrAppointmentMutation
>
export type CancelEhrAppointmentMutationResult = Apollo.MutationResult<CancelEhrAppointmentMutation>
export type CancelEhrAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelEhrAppointmentMutation,
  CancelEhrAppointmentMutationVariables
>
export const CancelVideoCallDocument = gql`
  mutation cancelVideoCall($id: ID!) {
    cancelVideoCall(id: $id) {
      id
    }
  }
`
export type CancelVideoCallMutationFn = Apollo.MutationFunction<
  CancelVideoCallMutation,
  CancelVideoCallMutationVariables
>

/**
 * __useCancelVideoCallMutation__
 *
 * To run a mutation, you first call `useCancelVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVideoCallMutation, { data, loading, error }] = useCancelVideoCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelVideoCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelVideoCallMutation,
    CancelVideoCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelVideoCallMutation, CancelVideoCallMutationVariables>(
    CancelVideoCallDocument,
    options
  )
}
export type CancelVideoCallMutationHookResult = ReturnType<typeof useCancelVideoCallMutation>
export type CancelVideoCallMutationResult = Apollo.MutationResult<CancelVideoCallMutation>
export type CancelVideoCallMutationOptions = Apollo.BaseMutationOptions<
  CancelVideoCallMutation,
  CancelVideoCallMutationVariables
>
export const ClaimFreeVideoCallDocument = gql`
  mutation claimFreeVideoCall {
    claimFreeVideoCall {
      id
      appointmentType
    }
  }
`
export type ClaimFreeVideoCallMutationFn = Apollo.MutationFunction<
  ClaimFreeVideoCallMutation,
  ClaimFreeVideoCallMutationVariables
>

/**
 * __useClaimFreeVideoCallMutation__
 *
 * To run a mutation, you first call `useClaimFreeVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimFreeVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimFreeVideoCallMutation, { data, loading, error }] = useClaimFreeVideoCallMutation({
 *   variables: {
 *   },
 * });
 */
export function useClaimFreeVideoCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimFreeVideoCallMutation,
    ClaimFreeVideoCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClaimFreeVideoCallMutation, ClaimFreeVideoCallMutationVariables>(
    ClaimFreeVideoCallDocument,
    options
  )
}
export type ClaimFreeVideoCallMutationHookResult = ReturnType<typeof useClaimFreeVideoCallMutation>
export type ClaimFreeVideoCallMutationResult = Apollo.MutationResult<ClaimFreeVideoCallMutation>
export type ClaimFreeVideoCallMutationOptions = Apollo.BaseMutationOptions<
  ClaimFreeVideoCallMutation,
  ClaimFreeVideoCallMutationVariables
>
export const CompleteSurveyDocument = gql`
  mutation completeSurvey($id: ID!) {
    completeSurvey(id: $id) {
      ...SurveyLink
      completionDeepLink
    }
  }
  ${SurveyLinkFragmentDoc}
`
export type CompleteSurveyMutationFn = Apollo.MutationFunction<
  CompleteSurveyMutation,
  CompleteSurveyMutationVariables
>

/**
 * __useCompleteSurveyMutation__
 *
 * To run a mutation, you first call `useCompleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSurveyMutation, { data, loading, error }] = useCompleteSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteSurveyMutation, CompleteSurveyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteSurveyMutation, CompleteSurveyMutationVariables>(
    CompleteSurveyDocument,
    options
  )
}
export type CompleteSurveyMutationHookResult = ReturnType<typeof useCompleteSurveyMutation>
export type CompleteSurveyMutationResult = Apollo.MutationResult<CompleteSurveyMutation>
export type CompleteSurveyMutationOptions = Apollo.BaseMutationOptions<
  CompleteSurveyMutation,
  CompleteSurveyMutationVariables
>
export const CreateChatTokenDocument = gql`
  mutation createChatToken {
    createChatToken {
      value
    }
  }
`
export type CreateChatTokenMutationFn = Apollo.MutationFunction<
  CreateChatTokenMutation,
  CreateChatTokenMutationVariables
>

/**
 * __useCreateChatTokenMutation__
 *
 * To run a mutation, you first call `useCreateChatTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatTokenMutation, { data, loading, error }] = useCreateChatTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateChatTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatTokenMutation,
    CreateChatTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChatTokenMutation, CreateChatTokenMutationVariables>(
    CreateChatTokenDocument,
    options
  )
}
export type CreateChatTokenMutationHookResult = ReturnType<typeof useCreateChatTokenMutation>
export type CreateChatTokenMutationResult = Apollo.MutationResult<CreateChatTokenMutation>
export type CreateChatTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateChatTokenMutation,
  CreateChatTokenMutationVariables
>
export const CreateDirectUploadDocument = gql`
  mutation createDirectUpload($file: FileMetadata!) {
    createDirectUpload(file: $file) {
      url
      signedId
      headers {
        key
        value
      }
    }
  }
`
export type CreateDirectUploadMutationFn = Apollo.MutationFunction<
  CreateDirectUploadMutation,
  CreateDirectUploadMutationVariables
>

/**
 * __useCreateDirectUploadMutation__
 *
 * To run a mutation, you first call `useCreateDirectUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectUploadMutation, { data, loading, error }] = useCreateDirectUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateDirectUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectUploadMutation,
    CreateDirectUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDirectUploadMutation, CreateDirectUploadMutationVariables>(
    CreateDirectUploadDocument,
    options
  )
}
export type CreateDirectUploadMutationHookResult = ReturnType<typeof useCreateDirectUploadMutation>
export type CreateDirectUploadMutationResult = Apollo.MutationResult<CreateDirectUploadMutation>
export type CreateDirectUploadMutationOptions = Apollo.BaseMutationOptions<
  CreateDirectUploadMutation,
  CreateDirectUploadMutationVariables
>
export const CreateDppHealthDataRecordDocument = gql`
  mutation createDppHealthDataRecord(
    $kind: DppHealthDataRecordKind!
    $value: Float!
    $userFileId: ID
  ) {
    createDppHealthDataRecord(kind: $kind, value: $value, userFileId: $userFileId) {
      success
    }
  }
`
export type CreateDppHealthDataRecordMutationFn = Apollo.MutationFunction<
  CreateDppHealthDataRecordMutation,
  CreateDppHealthDataRecordMutationVariables
>

/**
 * __useCreateDppHealthDataRecordMutation__
 *
 * To run a mutation, you first call `useCreateDppHealthDataRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDppHealthDataRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDppHealthDataRecordMutation, { data, loading, error }] = useCreateDppHealthDataRecordMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      value: // value for 'value'
 *      userFileId: // value for 'userFileId'
 *   },
 * });
 */
export function useCreateDppHealthDataRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDppHealthDataRecordMutation,
    CreateDppHealthDataRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDppHealthDataRecordMutation,
    CreateDppHealthDataRecordMutationVariables
  >(CreateDppHealthDataRecordDocument, options)
}
export type CreateDppHealthDataRecordMutationHookResult = ReturnType<
  typeof useCreateDppHealthDataRecordMutation
>
export type CreateDppHealthDataRecordMutationResult =
  Apollo.MutationResult<CreateDppHealthDataRecordMutation>
export type CreateDppHealthDataRecordMutationOptions = Apollo.BaseMutationOptions<
  CreateDppHealthDataRecordMutation,
  CreateDppHealthDataRecordMutationVariables
>
export const CreateFileFromSignedIdDocument = gql`
  mutation createFileFromSignedId($signedId: String!, $fileTag: String!) {
    createFileFromSignedId(signedId: $signedId, fileTag: $fileTag) {
      downloadFileUrl
      permanentUrl
      fileName
      id
    }
  }
`
export type CreateFileFromSignedIdMutationFn = Apollo.MutationFunction<
  CreateFileFromSignedIdMutation,
  CreateFileFromSignedIdMutationVariables
>

/**
 * __useCreateFileFromSignedIdMutation__
 *
 * To run a mutation, you first call `useCreateFileFromSignedIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileFromSignedIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileFromSignedIdMutation, { data, loading, error }] = useCreateFileFromSignedIdMutation({
 *   variables: {
 *      signedId: // value for 'signedId'
 *      fileTag: // value for 'fileTag'
 *   },
 * });
 */
export function useCreateFileFromSignedIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFileFromSignedIdMutation,
    CreateFileFromSignedIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFileFromSignedIdMutation,
    CreateFileFromSignedIdMutationVariables
  >(CreateFileFromSignedIdDocument, options)
}
export type CreateFileFromSignedIdMutationHookResult = ReturnType<
  typeof useCreateFileFromSignedIdMutation
>
export type CreateFileFromSignedIdMutationResult =
  Apollo.MutationResult<CreateFileFromSignedIdMutation>
export type CreateFileFromSignedIdMutationOptions = Apollo.BaseMutationOptions<
  CreateFileFromSignedIdMutation,
  CreateFileFromSignedIdMutationVariables
>
export const CreateMeasurementDocument = gql`
  mutation createMeasurement($type: String!, $values: MultiValue!, $occurredAt: String!) {
    createMeasurement(type: $type, values: $values, time: $occurredAt) {
      id
    }
  }
`
export type CreateMeasurementMutationFn = Apollo.MutationFunction<
  CreateMeasurementMutation,
  CreateMeasurementMutationVariables
>

/**
 * __useCreateMeasurementMutation__
 *
 * To run a mutation, you first call `useCreateMeasurementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeasurementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeasurementMutation, { data, loading, error }] = useCreateMeasurementMutation({
 *   variables: {
 *      type: // value for 'type'
 *      values: // value for 'values'
 *      occurredAt: // value for 'occurredAt'
 *   },
 * });
 */
export function useCreateMeasurementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMeasurementMutation,
    CreateMeasurementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateMeasurementMutation, CreateMeasurementMutationVariables>(
    CreateMeasurementDocument,
    options
  )
}
export type CreateMeasurementMutationHookResult = ReturnType<typeof useCreateMeasurementMutation>
export type CreateMeasurementMutationResult = Apollo.MutationResult<CreateMeasurementMutation>
export type CreateMeasurementMutationOptions = Apollo.BaseMutationOptions<
  CreateMeasurementMutation,
  CreateMeasurementMutationVariables
>
export const CreateOneTimePaymentDocument = gql`
  mutation createOneTimePayment(
    $email: String!
    $fullName: String!
    $phoneNumber: String
    $providerPaymentMethodId: String!
    $providerPromotionCodeId: String
    $purchasableItems: [PurchasableItem!]!
    $metadata: [KeyValueString!]
  ) {
    createOneTimePayment(
      email: $email
      fullName: $fullName
      phoneNumber: $phoneNumber
      providerPaymentMethodId: $providerPaymentMethodId
      providerPromotionCodeId: $providerPromotionCodeId
      purchasableItems: $purchasableItems
      metadata: $metadata
    ) {
      invoice {
        id
        stripeId
        total
        status
        primaryProduct {
          id
          key
          title
        }
      }
    }
  }
`
export type CreateOneTimePaymentMutationFn = Apollo.MutationFunction<
  CreateOneTimePaymentMutation,
  CreateOneTimePaymentMutationVariables
>

/**
 * __useCreateOneTimePaymentMutation__
 *
 * To run a mutation, you first call `useCreateOneTimePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTimePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTimePaymentMutation, { data, loading, error }] = useCreateOneTimePaymentMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      phoneNumber: // value for 'phoneNumber'
 *      providerPaymentMethodId: // value for 'providerPaymentMethodId'
 *      providerPromotionCodeId: // value for 'providerPromotionCodeId'
 *      purchasableItems: // value for 'purchasableItems'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateOneTimePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneTimePaymentMutation,
    CreateOneTimePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOneTimePaymentMutation, CreateOneTimePaymentMutationVariables>(
    CreateOneTimePaymentDocument,
    options
  )
}
export type CreateOneTimePaymentMutationHookResult = ReturnType<
  typeof useCreateOneTimePaymentMutation
>
export type CreateOneTimePaymentMutationResult = Apollo.MutationResult<CreateOneTimePaymentMutation>
export type CreateOneTimePaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateOneTimePaymentMutation,
  CreateOneTimePaymentMutationVariables
>
export const CreateProviderSetupIntentDocument = gql`
  mutation createProviderSetupIntent($email: String!, $fullName: String!, $phoneNumber: String) {
    createProviderSetupIntent(email: $email, fullName: $fullName, phoneNumber: $phoneNumber) {
      clientSecret
    }
  }
`
export type CreateProviderSetupIntentMutationFn = Apollo.MutationFunction<
  CreateProviderSetupIntentMutation,
  CreateProviderSetupIntentMutationVariables
>

/**
 * __useCreateProviderSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateProviderSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderSetupIntentMutation, { data, loading, error }] = useCreateProviderSetupIntentMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateProviderSetupIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProviderSetupIntentMutation,
    CreateProviderSetupIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProviderSetupIntentMutation,
    CreateProviderSetupIntentMutationVariables
  >(CreateProviderSetupIntentDocument, options)
}
export type CreateProviderSetupIntentMutationHookResult = ReturnType<
  typeof useCreateProviderSetupIntentMutation
>
export type CreateProviderSetupIntentMutationResult =
  Apollo.MutationResult<CreateProviderSetupIntentMutation>
export type CreateProviderSetupIntentMutationOptions = Apollo.BaseMutationOptions<
  CreateProviderSetupIntentMutation,
  CreateProviderSetupIntentMutationVariables
>
export const CreateSubscriptionDocument = gql`
  mutation createSubscription(
    $providerPaymentMethodId: String
    $product: BillingProduct!
    $email: String!
    $fullName: String!
    $address: SubscriptionAddress!
    $providerPromotionCodeId: String
    $phoneNumber: String
    $bonusKinds: [SubscriptionBonusKind!]
    $policyConsentKinds: [LegalPolicyConsentKind!]
    $checkoutOptionKinds: [SubscriptionCheckoutOptionKind!]
  ) {
    createSubscription(
      providerPaymentMethodId: $providerPaymentMethodId
      product: $product
      email: $email
      fullName: $fullName
      address: $address
      providerPromotionCodeId: $providerPromotionCodeId
      phoneNumber: $phoneNumber
      bonusKinds: $bonusKinds
      policyConsentKinds: $policyConsentKinds
      checkoutOptionKinds: $checkoutOptionKinds
    ) {
      subscription {
        ...CoreSubscriptionFields
      }
      providerSubscriptionId
      clientSecret
    }
  }
  ${CoreSubscriptionFieldsFragmentDoc}
`
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      providerPaymentMethodId: // value for 'providerPaymentMethodId'
 *      product: // value for 'product'
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      address: // value for 'address'
 *      providerPromotionCodeId: // value for 'providerPromotionCodeId'
 *      phoneNumber: // value for 'phoneNumber'
 *      bonusKinds: // value for 'bonusKinds'
 *      policyConsentKinds: // value for 'policyConsentKinds'
 *      checkoutOptionKinds: // value for 'checkoutOptionKinds'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(
    CreateSubscriptionDocument,
    options
  )
}
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>
export const CreateUserDocument = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String
    $photo: Upload
    $source: UserSignUpSource
    $phoneNumber: String
    $sendSignInLinkEmail: Boolean
    $sendResetPasswordEmail: Boolean
    $referralPartner: UserReferralPartner
    $dateOfBirth: String
    $sex: String
    $marketingInfo: MarketingInfo
    $policyConsentKinds: [LegalPolicyConsentKind!]
    $userProperties: [UserPropertySubmission!]
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      photo: $photo
      email: { email: $email, password: $password }
      phoneNumber: $phoneNumber
      source: $source
      sendSignInLinkEmail: $sendSignInLinkEmail
      sendResetPasswordEmail: $sendResetPasswordEmail
      referralPartner: $referralPartner
      dateOfBirth: $dateOfBirth
      sex: $sex
      marketingInfo: $marketingInfo
      policyConsentKinds: $policyConsentKinds
      userProperties: $userProperties
    ) {
      token
      role
      success
      status
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      photo: // value for 'photo'
 *      source: // value for 'source'
 *      phoneNumber: // value for 'phoneNumber'
 *      sendSignInLinkEmail: // value for 'sendSignInLinkEmail'
 *      sendResetPasswordEmail: // value for 'sendResetPasswordEmail'
 *      referralPartner: // value for 'referralPartner'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      sex: // value for 'sex'
 *      marketingInfo: // value for 'marketingInfo'
 *      policyConsentKinds: // value for 'policyConsentKinds'
 *      userProperties: // value for 'userProperties'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  )
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const DeleteFileDocument = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      id
    }
  }
`
export type DeleteFileMutationFn = Apollo.MutationFunction<
  DeleteFileMutation,
  DeleteFileMutationVariables
>

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
    DeleteFileDocument,
    options
  )
}
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileMutation,
  DeleteFileMutationVariables
>
export const EhrBookAppointmentDocument = gql`
  mutation ehrBookAppointment(
    $timeZone: String
    $date: String
    $appointmentKind: EhrAppointmentKind
    $attendanceChargeKind: EhrAppointmentAttendanceChargeKind
    $isGroup: Boolean
    $coachId: ID
    $doctorId: ID
    $referringCoachUserId: ID
    $pendingAppointmentId: ID
    $attendanceAgenda: String
  ) {
    ehrBookAppointment(
      timeZone: $timeZone
      date: $date
      appointmentKind: $appointmentKind
      attendanceChargeKind: $attendanceChargeKind
      isGroup: $isGroup
      coachId: $coachId
      doctorId: $doctorId
      referringCoachUserId: $referringCoachUserId
      pendingAppointmentId: $pendingAppointmentId
      attendanceAgenda: $attendanceAgenda
    ) {
      id
      meetingAt
    }
  }
`
export type EhrBookAppointmentMutationFn = Apollo.MutationFunction<
  EhrBookAppointmentMutation,
  EhrBookAppointmentMutationVariables
>

/**
 * __useEhrBookAppointmentMutation__
 *
 * To run a mutation, you first call `useEhrBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEhrBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ehrBookAppointmentMutation, { data, loading, error }] = useEhrBookAppointmentMutation({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *      date: // value for 'date'
 *      appointmentKind: // value for 'appointmentKind'
 *      attendanceChargeKind: // value for 'attendanceChargeKind'
 *      isGroup: // value for 'isGroup'
 *      coachId: // value for 'coachId'
 *      doctorId: // value for 'doctorId'
 *      referringCoachUserId: // value for 'referringCoachUserId'
 *      pendingAppointmentId: // value for 'pendingAppointmentId'
 *      attendanceAgenda: // value for 'attendanceAgenda'
 *   },
 * });
 */
export function useEhrBookAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EhrBookAppointmentMutation,
    EhrBookAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EhrBookAppointmentMutation, EhrBookAppointmentMutationVariables>(
    EhrBookAppointmentDocument,
    options
  )
}
export type EhrBookAppointmentMutationHookResult = ReturnType<typeof useEhrBookAppointmentMutation>
export type EhrBookAppointmentMutationResult = Apollo.MutationResult<EhrBookAppointmentMutation>
export type EhrBookAppointmentMutationOptions = Apollo.BaseMutationOptions<
  EhrBookAppointmentMutation,
  EhrBookAppointmentMutationVariables
>
export const JoinCourseCohortDocument = gql`
  mutation joinCourseCohort($id: ID!) {
    joinCourseCohort(id: $id) {
      ...CourseCohort
    }
  }
  ${CourseCohortFragmentDoc}
`
export type JoinCourseCohortMutationFn = Apollo.MutationFunction<
  JoinCourseCohortMutation,
  JoinCourseCohortMutationVariables
>

/**
 * __useJoinCourseCohortMutation__
 *
 * To run a mutation, you first call `useJoinCourseCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCourseCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCourseCohortMutation, { data, loading, error }] = useJoinCourseCohortMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinCourseCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinCourseCohortMutation,
    JoinCourseCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<JoinCourseCohortMutation, JoinCourseCohortMutationVariables>(
    JoinCourseCohortDocument,
    options
  )
}
export type JoinCourseCohortMutationHookResult = ReturnType<typeof useJoinCourseCohortMutation>
export type JoinCourseCohortMutationResult = Apollo.MutationResult<JoinCourseCohortMutation>
export type JoinCourseCohortMutationOptions = Apollo.BaseMutationOptions<
  JoinCourseCohortMutation,
  JoinCourseCohortMutationVariables
>
export const JoinDppCourseCohortDocument = gql`
  mutation joinDppCourseCohort($id: ID!) {
    joinDppCourseCohort(id: $id) {
      ...CourseCohort
    }
  }
  ${CourseCohortFragmentDoc}
`
export type JoinDppCourseCohortMutationFn = Apollo.MutationFunction<
  JoinDppCourseCohortMutation,
  JoinDppCourseCohortMutationVariables
>

/**
 * __useJoinDppCourseCohortMutation__
 *
 * To run a mutation, you first call `useJoinDppCourseCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinDppCourseCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinDppCourseCohortMutation, { data, loading, error }] = useJoinDppCourseCohortMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinDppCourseCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinDppCourseCohortMutation,
    JoinDppCourseCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<JoinDppCourseCohortMutation, JoinDppCourseCohortMutationVariables>(
    JoinDppCourseCohortDocument,
    options
  )
}
export type JoinDppCourseCohortMutationHookResult = ReturnType<
  typeof useJoinDppCourseCohortMutation
>
export type JoinDppCourseCohortMutationResult = Apollo.MutationResult<JoinDppCourseCohortMutation>
export type JoinDppCourseCohortMutationOptions = Apollo.BaseMutationOptions<
  JoinDppCourseCohortMutation,
  JoinDppCourseCohortMutationVariables
>
export const RescheduleEhrAppointmentDocument = gql`
  mutation rescheduleEhrAppointment($id: ID, $date: String) {
    rescheduleEhrAppointment(id: $id, date: $date) {
      id
      meetingAt
    }
  }
`
export type RescheduleEhrAppointmentMutationFn = Apollo.MutationFunction<
  RescheduleEhrAppointmentMutation,
  RescheduleEhrAppointmentMutationVariables
>

/**
 * __useRescheduleEhrAppointmentMutation__
 *
 * To run a mutation, you first call `useRescheduleEhrAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleEhrAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleEhrAppointmentMutation, { data, loading, error }] = useRescheduleEhrAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRescheduleEhrAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleEhrAppointmentMutation,
    RescheduleEhrAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RescheduleEhrAppointmentMutation,
    RescheduleEhrAppointmentMutationVariables
  >(RescheduleEhrAppointmentDocument, options)
}
export type RescheduleEhrAppointmentMutationHookResult = ReturnType<
  typeof useRescheduleEhrAppointmentMutation
>
export type RescheduleEhrAppointmentMutationResult =
  Apollo.MutationResult<RescheduleEhrAppointmentMutation>
export type RescheduleEhrAppointmentMutationOptions = Apollo.BaseMutationOptions<
  RescheduleEhrAppointmentMutation,
  RescheduleEhrAppointmentMutationVariables
>
export const RescheduleVideoCallDocument = gql`
  mutation rescheduleVideoCall($id: ID, $date: String) {
    rescheduleVideoCall(id: $id, date: $date) {
      id
      date
    }
  }
`
export type RescheduleVideoCallMutationFn = Apollo.MutationFunction<
  RescheduleVideoCallMutation,
  RescheduleVideoCallMutationVariables
>

/**
 * __useRescheduleVideoCallMutation__
 *
 * To run a mutation, you first call `useRescheduleVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleVideoCallMutation, { data, loading, error }] = useRescheduleVideoCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRescheduleVideoCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleVideoCallMutation,
    RescheduleVideoCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RescheduleVideoCallMutation, RescheduleVideoCallMutationVariables>(
    RescheduleVideoCallDocument,
    options
  )
}
export type RescheduleVideoCallMutationHookResult = ReturnType<
  typeof useRescheduleVideoCallMutation
>
export type RescheduleVideoCallMutationResult = Apollo.MutationResult<RescheduleVideoCallMutation>
export type RescheduleVideoCallMutationOptions = Apollo.BaseMutationOptions<
  RescheduleVideoCallMutation,
  RescheduleVideoCallMutationVariables
>
export const ResetPasswordRequestDocument = gql`
  query resetPasswordRequest($email: String!) {
    resetPasswordRequest(email: $email) {
      success
    }
  }
`

/**
 * __useResetPasswordRequestQuery__
 *
 * To run a query within a React component, call `useResetPasswordRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetPasswordRequestQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResetPasswordRequestQuery,
    ResetPasswordRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ResetPasswordRequestQuery, ResetPasswordRequestQueryVariables>(
    ResetPasswordRequestDocument,
    options
  )
}
export function useResetPasswordRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResetPasswordRequestQuery,
    ResetPasswordRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ResetPasswordRequestQuery, ResetPasswordRequestQueryVariables>(
    ResetPasswordRequestDocument,
    options
  )
}
export type ResetPasswordRequestQueryHookResult = ReturnType<typeof useResetPasswordRequestQuery>
export type ResetPasswordRequestLazyQueryHookResult = ReturnType<
  typeof useResetPasswordRequestLazyQuery
>
export type ResetPasswordRequestQueryResult = Apollo.QueryResult<
  ResetPasswordRequestQuery,
  ResetPasswordRequestQueryVariables
>
export const RunInsuranceBenefitsCheckDocument = gql`
  mutation runInsuranceBenefitsCheck(
    $addressState: AddressState!
    $insurancePolicyKind: UserInsurancePolicyKind!
    $insuranceVideoCallGoal: String!
    $insuranceVideoCallGoalOther: String
    $memberId: String
  ) {
    runInsuranceBenefitsCheck(
      addressState: $addressState
      insurancePolicyKind: $insurancePolicyKind
      insuranceVideoCallGoal: $insuranceVideoCallGoal
      insuranceVideoCallGoalOther: $insuranceVideoCallGoalOther
      memberId: $memberId
    ) {
      success
    }
  }
`
export type RunInsuranceBenefitsCheckMutationFn = Apollo.MutationFunction<
  RunInsuranceBenefitsCheckMutation,
  RunInsuranceBenefitsCheckMutationVariables
>

/**
 * __useRunInsuranceBenefitsCheckMutation__
 *
 * To run a mutation, you first call `useRunInsuranceBenefitsCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunInsuranceBenefitsCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runInsuranceBenefitsCheckMutation, { data, loading, error }] = useRunInsuranceBenefitsCheckMutation({
 *   variables: {
 *      addressState: // value for 'addressState'
 *      insurancePolicyKind: // value for 'insurancePolicyKind'
 *      insuranceVideoCallGoal: // value for 'insuranceVideoCallGoal'
 *      insuranceVideoCallGoalOther: // value for 'insuranceVideoCallGoalOther'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRunInsuranceBenefitsCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunInsuranceBenefitsCheckMutation,
    RunInsuranceBenefitsCheckMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RunInsuranceBenefitsCheckMutation,
    RunInsuranceBenefitsCheckMutationVariables
  >(RunInsuranceBenefitsCheckDocument, options)
}
export type RunInsuranceBenefitsCheckMutationHookResult = ReturnType<
  typeof useRunInsuranceBenefitsCheckMutation
>
export type RunInsuranceBenefitsCheckMutationResult =
  Apollo.MutationResult<RunInsuranceBenefitsCheckMutation>
export type RunInsuranceBenefitsCheckMutationOptions = Apollo.BaseMutationOptions<
  RunInsuranceBenefitsCheckMutation,
  RunInsuranceBenefitsCheckMutationVariables
>
export const SaveSurveyResponseDocument = gql`
  mutation saveSurveyResponse(
    $surveyLinkId: ID!
    $questionKey: String!
    $answer: JSON!
    $warnBeforeStopping: Boolean
  ) {
    saveSurveyResponse(
      surveyLinkId: $surveyLinkId
      questionKey: $questionKey
      answer: $answer
      warnBeforeStopping: $warnBeforeStopping
    ) {
      id
      questionKey
      answer
      surveyLink {
        user {
          id
        }
        config {
          items {
            ... on SurveyLinkConfigQuestionItem {
              type
              ...QuestionItemFields
            }
            ... on SurveyLinkConfigIntroItem {
              type
              header
              imageUrl
              introMarkdown
              icon
              footnote
              inlineAlert {
                type
                message
              }
            }
          }
          questionsCount
        }
      }
    }
  }
  ${QuestionItemFieldsFragmentDoc}
`
export type SaveSurveyResponseMutationFn = Apollo.MutationFunction<
  SaveSurveyResponseMutation,
  SaveSurveyResponseMutationVariables
>

/**
 * __useSaveSurveyResponseMutation__
 *
 * To run a mutation, you first call `useSaveSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSurveyResponseMutation, { data, loading, error }] = useSaveSurveyResponseMutation({
 *   variables: {
 *      surveyLinkId: // value for 'surveyLinkId'
 *      questionKey: // value for 'questionKey'
 *      answer: // value for 'answer'
 *      warnBeforeStopping: // value for 'warnBeforeStopping'
 *   },
 * });
 */
export function useSaveSurveyResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSurveyResponseMutation,
    SaveSurveyResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveSurveyResponseMutation, SaveSurveyResponseMutationVariables>(
    SaveSurveyResponseDocument,
    options
  )
}
export type SaveSurveyResponseMutationHookResult = ReturnType<typeof useSaveSurveyResponseMutation>
export type SaveSurveyResponseMutationResult = Apollo.MutationResult<SaveSurveyResponseMutation>
export type SaveSurveyResponseMutationOptions = Apollo.BaseMutationOptions<
  SaveSurveyResponseMutation,
  SaveSurveyResponseMutationVariables
>
export const SelectDefaultSensorForDexcomResearchDocument = gql`
  mutation selectDefaultSensorForDexcomResearch {
    selectDefaultSensorForDexcomResearch {
      success
    }
  }
`
export type SelectDefaultSensorForDexcomResearchMutationFn = Apollo.MutationFunction<
  SelectDefaultSensorForDexcomResearchMutation,
  SelectDefaultSensorForDexcomResearchMutationVariables
>

/**
 * __useSelectDefaultSensorForDexcomResearchMutation__
 *
 * To run a mutation, you first call `useSelectDefaultSensorForDexcomResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectDefaultSensorForDexcomResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectDefaultSensorForDexcomResearchMutation, { data, loading, error }] = useSelectDefaultSensorForDexcomResearchMutation({
 *   variables: {
 *   },
 * });
 */
export function useSelectDefaultSensorForDexcomResearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectDefaultSensorForDexcomResearchMutation,
    SelectDefaultSensorForDexcomResearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SelectDefaultSensorForDexcomResearchMutation,
    SelectDefaultSensorForDexcomResearchMutationVariables
  >(SelectDefaultSensorForDexcomResearchDocument, options)
}
export type SelectDefaultSensorForDexcomResearchMutationHookResult = ReturnType<
  typeof useSelectDefaultSensorForDexcomResearchMutation
>
export type SelectDefaultSensorForDexcomResearchMutationResult =
  Apollo.MutationResult<SelectDefaultSensorForDexcomResearchMutation>
export type SelectDefaultSensorForDexcomResearchMutationOptions = Apollo.BaseMutationOptions<
  SelectDefaultSensorForDexcomResearchMutation,
  SelectDefaultSensorForDexcomResearchMutationVariables
>
export const SelectSensorDocument = gql`
  mutation selectSensor($model: SensorModel!) {
    selectSensor(model: $model) {
      success
    }
  }
`
export type SelectSensorMutationFn = Apollo.MutationFunction<
  SelectSensorMutation,
  SelectSensorMutationVariables
>

/**
 * __useSelectSensorMutation__
 *
 * To run a mutation, you first call `useSelectSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectSensorMutation, { data, loading, error }] = useSelectSensorMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useSelectSensorMutation(
  baseOptions?: Apollo.MutationHookOptions<SelectSensorMutation, SelectSensorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SelectSensorMutation, SelectSensorMutationVariables>(
    SelectSensorDocument,
    options
  )
}
export type SelectSensorMutationHookResult = ReturnType<typeof useSelectSensorMutation>
export type SelectSensorMutationResult = Apollo.MutationResult<SelectSensorMutation>
export type SelectSensorMutationOptions = Apollo.BaseMutationOptions<
  SelectSensorMutation,
  SelectSensorMutationVariables
>
export const SendChatConversationMessageDocument = gql`
  mutation sendChatConversationMessage($body: String!) {
    sendChatConversationMessage(body: $body) {
      success
    }
  }
`
export type SendChatConversationMessageMutationFn = Apollo.MutationFunction<
  SendChatConversationMessageMutation,
  SendChatConversationMessageMutationVariables
>

/**
 * __useSendChatConversationMessageMutation__
 *
 * To run a mutation, you first call `useSendChatConversationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatConversationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatConversationMessageMutation, { data, loading, error }] = useSendChatConversationMessageMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendChatConversationMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChatConversationMessageMutation,
    SendChatConversationMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendChatConversationMessageMutation,
    SendChatConversationMessageMutationVariables
  >(SendChatConversationMessageDocument, options)
}
export type SendChatConversationMessageMutationHookResult = ReturnType<
  typeof useSendChatConversationMessageMutation
>
export type SendChatConversationMessageMutationResult =
  Apollo.MutationResult<SendChatConversationMessageMutation>
export type SendChatConversationMessageMutationOptions = Apollo.BaseMutationOptions<
  SendChatConversationMessageMutation,
  SendChatConversationMessageMutationVariables
>
export const SignInPasswordlessUserDocument = gql`
  mutation signInPasswordlessUser($signInToken: String!) {
    signInPasswordlessUser(signInToken: $signInToken) {
      token
      role
      success
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type SignInPasswordlessUserMutationFn = Apollo.MutationFunction<
  SignInPasswordlessUserMutation,
  SignInPasswordlessUserMutationVariables
>

/**
 * __useSignInPasswordlessUserMutation__
 *
 * To run a mutation, you first call `useSignInPasswordlessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInPasswordlessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInPasswordlessUserMutation, { data, loading, error }] = useSignInPasswordlessUserMutation({
 *   variables: {
 *      signInToken: // value for 'signInToken'
 *   },
 * });
 */
export function useSignInPasswordlessUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInPasswordlessUserMutation,
    SignInPasswordlessUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignInPasswordlessUserMutation,
    SignInPasswordlessUserMutationVariables
  >(SignInPasswordlessUserDocument, options)
}
export type SignInPasswordlessUserMutationHookResult = ReturnType<
  typeof useSignInPasswordlessUserMutation
>
export type SignInPasswordlessUserMutationResult =
  Apollo.MutationResult<SignInPasswordlessUserMutation>
export type SignInPasswordlessUserMutationOptions = Apollo.BaseMutationOptions<
  SignInPasswordlessUserMutation,
  SignInPasswordlessUserMutationVariables
>
export const SigninUserDocument = gql`
  mutation signinUser($email: String!, $password: String!, $impersonate: String) {
    signinUser(email: { email: $email, password: $password, impersonate: $impersonate }) {
      token
      success
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type SigninUserMutationFn = Apollo.MutationFunction<
  SigninUserMutation,
  SigninUserMutationVariables
>

/**
 * __useSigninUserMutation__
 *
 * To run a mutation, you first call `useSigninUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinUserMutation, { data, loading, error }] = useSigninUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      impersonate: // value for 'impersonate'
 *   },
 * });
 */
export function useSigninUserMutation(
  baseOptions?: Apollo.MutationHookOptions<SigninUserMutation, SigninUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SigninUserMutation, SigninUserMutationVariables>(
    SigninUserDocument,
    options
  )
}
export type SigninUserMutationHookResult = ReturnType<typeof useSigninUserMutation>
export type SigninUserMutationResult = Apollo.MutationResult<SigninUserMutation>
export type SigninUserMutationOptions = Apollo.BaseMutationOptions<
  SigninUserMutation,
  SigninUserMutationVariables
>
export const SignOutUserDocument = gql`
  mutation signOutUser {
    signOutUser {
      success
    }
  }
`
export type SignOutUserMutationFn = Apollo.MutationFunction<
  SignOutUserMutation,
  SignOutUserMutationVariables
>

/**
 * __useSignOutUserMutation__
 *
 * To run a mutation, you first call `useSignOutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutUserMutation, { data, loading, error }] = useSignOutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutUserMutation(
  baseOptions?: Apollo.MutationHookOptions<SignOutUserMutation, SignOutUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignOutUserMutation, SignOutUserMutationVariables>(
    SignOutUserDocument,
    options
  )
}
export type SignOutUserMutationHookResult = ReturnType<typeof useSignOutUserMutation>
export type SignOutUserMutationResult = Apollo.MutationResult<SignOutUserMutation>
export type SignOutUserMutationOptions = Apollo.BaseMutationOptions<
  SignOutUserMutation,
  SignOutUserMutationVariables
>
export const StartSurveyDocument = gql`
  mutation startSurvey($kind: String!) {
    startSurvey(kind: $kind) {
      ...SurveyLink
      config {
        ...SurveyLinkConfig
      }
    }
  }
  ${SurveyLinkFragmentDoc}
  ${SurveyLinkConfigFragmentDoc}
`
export type StartSurveyMutationFn = Apollo.MutationFunction<
  StartSurveyMutation,
  StartSurveyMutationVariables
>

/**
 * __useStartSurveyMutation__
 *
 * To run a mutation, you first call `useStartSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSurveyMutation, { data, loading, error }] = useStartSurveyMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useStartSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<StartSurveyMutation, StartSurveyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartSurveyMutation, StartSurveyMutationVariables>(
    StartSurveyDocument,
    options
  )
}
export type StartSurveyMutationHookResult = ReturnType<typeof useStartSurveyMutation>
export type StartSurveyMutationResult = Apollo.MutationResult<StartSurveyMutation>
export type StartSurveyMutationOptions = Apollo.BaseMutationOptions<
  StartSurveyMutation,
  StartSurveyMutationVariables
>
export const SubmitInsurancePolicyDocument = gql`
  mutation submitInsurancePolicy {
    submitInsurancePolicy {
      ...InsurancePolicyFields
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`
export type SubmitInsurancePolicyMutationFn = Apollo.MutationFunction<
  SubmitInsurancePolicyMutation,
  SubmitInsurancePolicyMutationVariables
>

/**
 * __useSubmitInsurancePolicyMutation__
 *
 * To run a mutation, you first call `useSubmitInsurancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInsurancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInsurancePolicyMutation, { data, loading, error }] = useSubmitInsurancePolicyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitInsurancePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitInsurancePolicyMutation,
    SubmitInsurancePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitInsurancePolicyMutation, SubmitInsurancePolicyMutationVariables>(
    SubmitInsurancePolicyDocument,
    options
  )
}
export type SubmitInsurancePolicyMutationHookResult = ReturnType<
  typeof useSubmitInsurancePolicyMutation
>
export type SubmitInsurancePolicyMutationResult =
  Apollo.MutationResult<SubmitInsurancePolicyMutation>
export type SubmitInsurancePolicyMutationOptions = Apollo.BaseMutationOptions<
  SubmitInsurancePolicyMutation,
  SubmitInsurancePolicyMutationVariables
>
export const SwitchDppCourseCohortSessionDocument = gql`
  mutation switchDppCourseCohortSession($currentSessionId: ID!, $switchSessionId: ID!) {
    switchDppCourseCohortSession(
      currentSessionId: $currentSessionId
      switchSessionId: $switchSessionId
    ) {
      success
    }
  }
`
export type SwitchDppCourseCohortSessionMutationFn = Apollo.MutationFunction<
  SwitchDppCourseCohortSessionMutation,
  SwitchDppCourseCohortSessionMutationVariables
>

/**
 * __useSwitchDppCourseCohortSessionMutation__
 *
 * To run a mutation, you first call `useSwitchDppCourseCohortSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchDppCourseCohortSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchDppCourseCohortSessionMutation, { data, loading, error }] = useSwitchDppCourseCohortSessionMutation({
 *   variables: {
 *      currentSessionId: // value for 'currentSessionId'
 *      switchSessionId: // value for 'switchSessionId'
 *   },
 * });
 */
export function useSwitchDppCourseCohortSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchDppCourseCohortSessionMutation,
    SwitchDppCourseCohortSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SwitchDppCourseCohortSessionMutation,
    SwitchDppCourseCohortSessionMutationVariables
  >(SwitchDppCourseCohortSessionDocument, options)
}
export type SwitchDppCourseCohortSessionMutationHookResult = ReturnType<
  typeof useSwitchDppCourseCohortSessionMutation
>
export type SwitchDppCourseCohortSessionMutationResult =
  Apollo.MutationResult<SwitchDppCourseCohortSessionMutation>
export type SwitchDppCourseCohortSessionMutationOptions = Apollo.BaseMutationOptions<
  SwitchDppCourseCohortSessionMutation,
  SwitchDppCourseCohortSessionMutationVariables
>
export const UpdateAddressDocument = gql`
  mutation updateAddress(
    $id: ID
    $street: String!
    $street2: String!
    $city: String!
    $state: String!
    $country: AddressCountries!
    $zipCode: String!
    $name: String
  ) {
    updateAddress(
      id: $id
      street: $street
      street2: $street2
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      name: $name
    ) {
      ...CoreAddressFields
    }
  }
  ${CoreAddressFieldsFragmentDoc}
`
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      street: // value for 'street'
 *      street2: // value for 'street2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      zipCode: // value for 'zipCode'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(
    UpdateAddressDocument,
    options
  )
}
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>
export const UpdateDppCourseCohortSessionAttendanceDocument = gql`
  mutation updateDppCourseCohortSessionAttendance(
    $id: ID!
    $rsvp: CoursesCohortSessionAttendanceRsvp!
  ) {
    updateDppCourseCohortSessionAttendance(id: $id, rsvp: $rsvp) {
      ...CourseCohortSessionAttendance
    }
  }
  ${CourseCohortSessionAttendanceFragmentDoc}
`
export type UpdateDppCourseCohortSessionAttendanceMutationFn = Apollo.MutationFunction<
  UpdateDppCourseCohortSessionAttendanceMutation,
  UpdateDppCourseCohortSessionAttendanceMutationVariables
>

/**
 * __useUpdateDppCourseCohortSessionAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateDppCourseCohortSessionAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDppCourseCohortSessionAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDppCourseCohortSessionAttendanceMutation, { data, loading, error }] = useUpdateDppCourseCohortSessionAttendanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rsvp: // value for 'rsvp'
 *   },
 * });
 */
export function useUpdateDppCourseCohortSessionAttendanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDppCourseCohortSessionAttendanceMutation,
    UpdateDppCourseCohortSessionAttendanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDppCourseCohortSessionAttendanceMutation,
    UpdateDppCourseCohortSessionAttendanceMutationVariables
  >(UpdateDppCourseCohortSessionAttendanceDocument, options)
}
export type UpdateDppCourseCohortSessionAttendanceMutationHookResult = ReturnType<
  typeof useUpdateDppCourseCohortSessionAttendanceMutation
>
export type UpdateDppCourseCohortSessionAttendanceMutationResult =
  Apollo.MutationResult<UpdateDppCourseCohortSessionAttendanceMutation>
export type UpdateDppCourseCohortSessionAttendanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateDppCourseCohortSessionAttendanceMutation,
  UpdateDppCourseCohortSessionAttendanceMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($sex: String, $phoneNumber: String, $dateOfBirth: String, $password: String) {
    updateUser(
      sex: $sex
      phoneNumber: $phoneNumber
      dateOfBirth: $dateOfBirth
      password: $password
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      sex: // value for 'sex'
 *      phoneNumber: // value for 'phoneNumber'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const UpsertEhrAppointmentRecurrenceDocument = gql`
  mutation upsertEhrAppointmentRecurrence(
    $cadence: EhrAppointmentRecurrenceCadence
    $timeSlots: [ISO8601DateTime!]
    $days: [EhrAppointmentRecurrenceDay!]
    $numberOfCalls: Int
  ) {
    upsertEhrAppointmentRecurrence(
      cadence: $cadence
      timeSlots: $timeSlots
      days: $days
      numberOfCalls: $numberOfCalls
    ) {
      id
    }
  }
`
export type UpsertEhrAppointmentRecurrenceMutationFn = Apollo.MutationFunction<
  UpsertEhrAppointmentRecurrenceMutation,
  UpsertEhrAppointmentRecurrenceMutationVariables
>

/**
 * __useUpsertEhrAppointmentRecurrenceMutation__
 *
 * To run a mutation, you first call `useUpsertEhrAppointmentRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEhrAppointmentRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEhrAppointmentRecurrenceMutation, { data, loading, error }] = useUpsertEhrAppointmentRecurrenceMutation({
 *   variables: {
 *      cadence: // value for 'cadence'
 *      timeSlots: // value for 'timeSlots'
 *      days: // value for 'days'
 *      numberOfCalls: // value for 'numberOfCalls'
 *   },
 * });
 */
export function useUpsertEhrAppointmentRecurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertEhrAppointmentRecurrenceMutation,
    UpsertEhrAppointmentRecurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertEhrAppointmentRecurrenceMutation,
    UpsertEhrAppointmentRecurrenceMutationVariables
  >(UpsertEhrAppointmentRecurrenceDocument, options)
}
export type UpsertEhrAppointmentRecurrenceMutationHookResult = ReturnType<
  typeof useUpsertEhrAppointmentRecurrenceMutation
>
export type UpsertEhrAppointmentRecurrenceMutationResult =
  Apollo.MutationResult<UpsertEhrAppointmentRecurrenceMutation>
export type UpsertEhrAppointmentRecurrenceMutationOptions = Apollo.BaseMutationOptions<
  UpsertEhrAppointmentRecurrenceMutation,
  UpsertEhrAppointmentRecurrenceMutationVariables
>
export const AllAppointmentsDocument = gql`
  query allAppointments(
    $sorts: [AppointmentSort!]
    $dynamicFilters: [AppointmentDynamicFilterItem!]
  ) {
    allAppointments(sorts: $sorts, dynamicFilters: $dynamicFilters) {
      appointments {
        ...AppointmentFields
      }
    }
  }
  ${AppointmentFieldsFragmentDoc}
`

/**
 * __useAllAppointmentsQuery__
 *
 * To run a query within a React component, call `useAllAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppointmentsQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAppointmentsQuery, AllAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllAppointmentsQuery, AllAppointmentsQueryVariables>(
    AllAppointmentsDocument,
    options
  )
}
export function useAllAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAppointmentsQuery, AllAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllAppointmentsQuery, AllAppointmentsQueryVariables>(
    AllAppointmentsDocument,
    options
  )
}
export type AllAppointmentsQueryHookResult = ReturnType<typeof useAllAppointmentsQuery>
export type AllAppointmentsLazyQueryHookResult = ReturnType<typeof useAllAppointmentsLazyQuery>
export type AllAppointmentsQueryResult = Apollo.QueryResult<
  AllAppointmentsQuery,
  AllAppointmentsQueryVariables
>
export const AllChatConversationMessagesDocument = gql`
  query allChatConversationMessages(
    $sorts: [WebMembersChatConversationMessageSort!]
    $page: Int
    $perPage: Int
  ) {
    allChatConversationMessages(sorts: $sorts, page: $page, perPage: $perPage) {
      totalCount
      pageCount
      messages {
        id
        senderId
        senderName
        senderAvatar
        senderKind
        value
        sentAt
      }
    }
  }
`

/**
 * __useAllChatConversationMessagesQuery__
 *
 * To run a query within a React component, call `useAllChatConversationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatConversationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatConversationMessagesQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useAllChatConversationMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllChatConversationMessagesQuery,
    AllChatConversationMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllChatConversationMessagesQuery,
    AllChatConversationMessagesQueryVariables
  >(AllChatConversationMessagesDocument, options)
}
export function useAllChatConversationMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllChatConversationMessagesQuery,
    AllChatConversationMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllChatConversationMessagesQuery,
    AllChatConversationMessagesQueryVariables
  >(AllChatConversationMessagesDocument, options)
}
export type AllChatConversationMessagesQueryHookResult = ReturnType<
  typeof useAllChatConversationMessagesQuery
>
export type AllChatConversationMessagesLazyQueryHookResult = ReturnType<
  typeof useAllChatConversationMessagesLazyQuery
>
export type AllChatConversationMessagesQueryResult = Apollo.QueryResult<
  AllChatConversationMessagesQuery,
  AllChatConversationMessagesQueryVariables
>
export const AllFilesDocument = gql`
  query allFiles(
    $page: Int
    $perPage: Int
    $sorts: [FileSort!]
    $dynamicFilters: [FileDynamicFilterItem!]
  ) {
    allFiles(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      files {
        id
        tag
        fileType
        fileName
        downloadFileUrl
      }
    }
  }
`

/**
 * __useAllFilesQuery__
 *
 * To run a query within a React component, call `useAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFilesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorts: // value for 'sorts'
 *      dynamicFilters: // value for 'dynamicFilters'
 *   },
 * });
 */
export function useAllFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllFilesQuery, AllFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllFilesQuery, AllFilesQueryVariables>(AllFilesDocument, options)
}
export function useAllFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllFilesQuery, AllFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllFilesQuery, AllFilesQueryVariables>(AllFilesDocument, options)
}
export type AllFilesQueryHookResult = ReturnType<typeof useAllFilesQuery>
export type AllFilesLazyQueryHookResult = ReturnType<typeof useAllFilesLazyQuery>
export type AllFilesQueryResult = Apollo.QueryResult<AllFilesQuery, AllFilesQueryVariables>
export const AllProductsDocument = gql`
  query allProducts($category: BillingProductCategory) {
    allProducts(category: $category) {
      products {
        id
        key
        order
        title
        description
        details
        interval {
          key
          description
          suffix
        }
        price
      }
    }
  }
`

/**
 * __useAllProductsQuery__
 *
 * To run a query within a React component, call `useAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductsQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllProductsQuery, AllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, options)
}
export function useAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllProductsQuery, AllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllProductsQuery, AllProductsQueryVariables>(
    AllProductsDocument,
    options
  )
}
export type AllProductsQueryHookResult = ReturnType<typeof useAllProductsQuery>
export type AllProductsLazyQueryHookResult = ReturnType<typeof useAllProductsLazyQuery>
export type AllProductsQueryResult = Apollo.QueryResult<AllProductsQuery, AllProductsQueryVariables>
export const AllSurveyLinksDocument = gql`
  query allSurveyLinks {
    allSurveyLinks {
      surveyLinks {
        ...SurveyLink
      }
    }
  }
  ${SurveyLinkFragmentDoc}
`

/**
 * __useAllSurveyLinksQuery__
 *
 * To run a query within a React component, call `useAllSurveyLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSurveyLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSurveyLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSurveyLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<AllSurveyLinksQuery, AllSurveyLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllSurveyLinksQuery, AllSurveyLinksQueryVariables>(
    AllSurveyLinksDocument,
    options
  )
}
export function useAllSurveyLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllSurveyLinksQuery, AllSurveyLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllSurveyLinksQuery, AllSurveyLinksQueryVariables>(
    AllSurveyLinksDocument,
    options
  )
}
export type AllSurveyLinksQueryHookResult = ReturnType<typeof useAllSurveyLinksQuery>
export type AllSurveyLinksLazyQueryHookResult = ReturnType<typeof useAllSurveyLinksLazyQuery>
export type AllSurveyLinksQueryResult = Apollo.QueryResult<
  AllSurveyLinksQuery,
  AllSurveyLinksQueryVariables
>
export const AvailableDppCourseCohortSessionsDocument = gql`
  query availableDppCourseCohortSessions {
    availableDppCourseCohortSessions {
      ...CourseCohortSession
    }
  }
  ${CourseCohortSessionFragmentDoc}
`

/**
 * __useAvailableDppCourseCohortSessionsQuery__
 *
 * To run a query within a React component, call `useAvailableDppCourseCohortSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDppCourseCohortSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDppCourseCohortSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableDppCourseCohortSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableDppCourseCohortSessionsQuery,
    AvailableDppCourseCohortSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AvailableDppCourseCohortSessionsQuery,
    AvailableDppCourseCohortSessionsQueryVariables
  >(AvailableDppCourseCohortSessionsDocument, options)
}
export function useAvailableDppCourseCohortSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableDppCourseCohortSessionsQuery,
    AvailableDppCourseCohortSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AvailableDppCourseCohortSessionsQuery,
    AvailableDppCourseCohortSessionsQueryVariables
  >(AvailableDppCourseCohortSessionsDocument, options)
}
export type AvailableDppCourseCohortSessionsQueryHookResult = ReturnType<
  typeof useAvailableDppCourseCohortSessionsQuery
>
export type AvailableDppCourseCohortSessionsLazyQueryHookResult = ReturnType<
  typeof useAvailableDppCourseCohortSessionsLazyQuery
>
export type AvailableDppCourseCohortSessionsQueryResult = Apollo.QueryResult<
  AvailableDppCourseCohortSessionsQuery,
  AvailableDppCourseCohortSessionsQueryVariables
>
export const ChartsDataDocument = gql`
  query chartsData($filter: ChartFilter!) {
    chartsData(filter: $filter) {
      values {
        section
        key
        data {
          ... on StringPair {
            x
            y
          }
        }
      }
    }
  }
`

/**
 * __useChartsDataQuery__
 *
 * To run a query within a React component, call `useChartsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartsDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChartsDataQuery(
  baseOptions: Apollo.QueryHookOptions<ChartsDataQuery, ChartsDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChartsDataQuery, ChartsDataQueryVariables>(ChartsDataDocument, options)
}
export function useChartsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChartsDataQuery, ChartsDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChartsDataQuery, ChartsDataQueryVariables>(ChartsDataDocument, options)
}
export type ChartsDataQueryHookResult = ReturnType<typeof useChartsDataQuery>
export type ChartsDataLazyQueryHookResult = ReturnType<typeof useChartsDataLazyQuery>
export type ChartsDataQueryResult = Apollo.QueryResult<ChartsDataQuery, ChartsDataQueryVariables>
export const ClientConfigDocument = gql`
  query clientConfig {
    clientConfig {
      healthSettings {
        unitSystemOptions {
          text
          value
        }
      }
      appointmentTypes {
        duration
        key
        title
      }
      usStates {
        name
        abbreviation
      }
      researchFaqs {
        rootUrl
        gettingStartedUrl
        eligibilityUrl
        dataProtectionUrl
        sensorComparisonUrl
      }
    }
  }
`

/**
 * __useClientConfigQuery__
 *
 * To run a query within a React component, call `useClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<ClientConfigQuery, ClientConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ClientConfigQuery, ClientConfigQueryVariables>(
    ClientConfigDocument,
    options
  )
}
export function useClientConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientConfigQuery, ClientConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ClientConfigQuery, ClientConfigQueryVariables>(
    ClientConfigDocument,
    options
  )
}
export type ClientConfigQueryHookResult = ReturnType<typeof useClientConfigQuery>
export type ClientConfigLazyQueryHookResult = ReturnType<typeof useClientConfigLazyQuery>
export type ClientConfigQueryResult = Apollo.QueryResult<
  ClientConfigQuery,
  ClientConfigQueryVariables
>
export const CoachProfileDocument = gql`
  query coachProfile($coachId: ID!) {
    coachProfile(coachId: $coachId) {
      ...CoachProfile
    }
  }
  ${CoachProfileFragmentDoc}
`

/**
 * __useCoachProfileQuery__
 *
 * To run a query within a React component, call `useCoachProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProfileQuery({
 *   variables: {
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useCoachProfileQuery(
  baseOptions: Apollo.QueryHookOptions<CoachProfileQuery, CoachProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoachProfileQuery, CoachProfileQueryVariables>(
    CoachProfileDocument,
    options
  )
}
export function useCoachProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoachProfileQuery, CoachProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoachProfileQuery, CoachProfileQueryVariables>(
    CoachProfileDocument,
    options
  )
}
export type CoachProfileQueryHookResult = ReturnType<typeof useCoachProfileQuery>
export type CoachProfileLazyQueryHookResult = ReturnType<typeof useCoachProfileLazyQuery>
export type CoachProfileQueryResult = Apollo.QueryResult<
  CoachProfileQuery,
  CoachProfileQueryVariables
>
export const CourseCohortDocument = gql`
  query courseCohort($kind: CoursesCohortKind!) {
    courseCohort(kind: $kind) {
      ...CourseCohort
    }
  }
  ${CourseCohortFragmentDoc}
`

/**
 * __useCourseCohortQuery__
 *
 * To run a query within a React component, call `useCourseCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCohortQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCourseCohortQuery(
  baseOptions: Apollo.QueryHookOptions<CourseCohortQuery, CourseCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseCohortQuery, CourseCohortQueryVariables>(
    CourseCohortDocument,
    options
  )
}
export function useCourseCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseCohortQuery, CourseCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseCohortQuery, CourseCohortQueryVariables>(
    CourseCohortDocument,
    options
  )
}
export type CourseCohortQueryHookResult = ReturnType<typeof useCourseCohortQuery>
export type CourseCohortLazyQueryHookResult = ReturnType<typeof useCourseCohortLazyQuery>
export type CourseCohortQueryResult = Apollo.QueryResult<
  CourseCohortQuery,
  CourseCohortQueryVariables
>
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...User
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
export const DppCourseCohortDocument = gql`
  query dppCourseCohort {
    dppCourseCohort {
      ...CourseCohort
    }
  }
  ${CourseCohortFragmentDoc}
`

/**
 * __useDppCourseCohortQuery__
 *
 * To run a query within a React component, call `useDppCourseCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useDppCourseCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDppCourseCohortQuery({
 *   variables: {
 *   },
 * });
 */
export function useDppCourseCohortQuery(
  baseOptions?: Apollo.QueryHookOptions<DppCourseCohortQuery, DppCourseCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DppCourseCohortQuery, DppCourseCohortQueryVariables>(
    DppCourseCohortDocument,
    options
  )
}
export function useDppCourseCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DppCourseCohortQuery, DppCourseCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DppCourseCohortQuery, DppCourseCohortQueryVariables>(
    DppCourseCohortDocument,
    options
  )
}
export type DppCourseCohortQueryHookResult = ReturnType<typeof useDppCourseCohortQuery>
export type DppCourseCohortLazyQueryHookResult = ReturnType<typeof useDppCourseCohortLazyQuery>
export type DppCourseCohortQueryResult = Apollo.QueryResult<
  DppCourseCohortQuery,
  DppCourseCohortQueryVariables
>
export const DppEnrollableCourseCohortsDocument = gql`
  query dppEnrollableCourseCohorts {
    dppEnrollableCourseCohorts {
      cohorts {
        ...CourseCohort
      }
    }
  }
  ${CourseCohortFragmentDoc}
`

/**
 * __useDppEnrollableCourseCohortsQuery__
 *
 * To run a query within a React component, call `useDppEnrollableCourseCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDppEnrollableCourseCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDppEnrollableCourseCohortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDppEnrollableCourseCohortsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DppEnrollableCourseCohortsQuery,
    DppEnrollableCourseCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DppEnrollableCourseCohortsQuery, DppEnrollableCourseCohortsQueryVariables>(
    DppEnrollableCourseCohortsDocument,
    options
  )
}
export function useDppEnrollableCourseCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DppEnrollableCourseCohortsQuery,
    DppEnrollableCourseCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DppEnrollableCourseCohortsQuery,
    DppEnrollableCourseCohortsQueryVariables
  >(DppEnrollableCourseCohortsDocument, options)
}
export type DppEnrollableCourseCohortsQueryHookResult = ReturnType<
  typeof useDppEnrollableCourseCohortsQuery
>
export type DppEnrollableCourseCohortsLazyQueryHookResult = ReturnType<
  typeof useDppEnrollableCourseCohortsLazyQuery
>
export type DppEnrollableCourseCohortsQueryResult = Apollo.QueryResult<
  DppEnrollableCourseCohortsQuery,
  DppEnrollableCourseCohortsQueryVariables
>
export const DppHealthDataRecordsChecklistDocument = gql`
  query dppHealthDataRecordsChecklist {
    dppHealthDataRecordsChecklist {
      baselineChecklist {
        state
        requiresPhotoUpload
      }
      hbA1cChecklist {
        state
        requiresPhotoUpload
      }
      weightChecklist {
        state
        requiresPhotoUpload
      }
      physicalActivityChecklist {
        state
        requiresPhotoUpload
      }
    }
  }
`

/**
 * __useDppHealthDataRecordsChecklistQuery__
 *
 * To run a query within a React component, call `useDppHealthDataRecordsChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useDppHealthDataRecordsChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDppHealthDataRecordsChecklistQuery({
 *   variables: {
 *   },
 * });
 */
export function useDppHealthDataRecordsChecklistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DppHealthDataRecordsChecklistQuery,
    DppHealthDataRecordsChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DppHealthDataRecordsChecklistQuery,
    DppHealthDataRecordsChecklistQueryVariables
  >(DppHealthDataRecordsChecklistDocument, options)
}
export function useDppHealthDataRecordsChecklistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DppHealthDataRecordsChecklistQuery,
    DppHealthDataRecordsChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DppHealthDataRecordsChecklistQuery,
    DppHealthDataRecordsChecklistQueryVariables
  >(DppHealthDataRecordsChecklistDocument, options)
}
export type DppHealthDataRecordsChecklistQueryHookResult = ReturnType<
  typeof useDppHealthDataRecordsChecklistQuery
>
export type DppHealthDataRecordsChecklistLazyQueryHookResult = ReturnType<
  typeof useDppHealthDataRecordsChecklistLazyQuery
>
export type DppHealthDataRecordsChecklistQueryResult = Apollo.QueryResult<
  DppHealthDataRecordsChecklistQuery,
  DppHealthDataRecordsChecklistQueryVariables
>
export const EhrAppointmentsDocument = gql`
  query ehrAppointments(
    $sorts: [EhrAppointmentSort!]
    $filterKind: FilterAppointmentsKind
    $category: EhrAppointmentCategory
  ) {
    ehrAppointments(sorts: $sorts, filterKind: $filterKind, category: $category) {
      appointments {
        ...EhrAppointment
      }
    }
  }
  ${EhrAppointmentFragmentDoc}
`

/**
 * __useEhrAppointmentsQuery__
 *
 * To run a query within a React component, call `useEhrAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEhrAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEhrAppointmentsQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *      filterKind: // value for 'filterKind'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useEhrAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<EhrAppointmentsQuery, EhrAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EhrAppointmentsQuery, EhrAppointmentsQueryVariables>(
    EhrAppointmentsDocument,
    options
  )
}
export function useEhrAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EhrAppointmentsQuery, EhrAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EhrAppointmentsQuery, EhrAppointmentsQueryVariables>(
    EhrAppointmentsDocument,
    options
  )
}
export type EhrAppointmentsQueryHookResult = ReturnType<typeof useEhrAppointmentsQuery>
export type EhrAppointmentsLazyQueryHookResult = ReturnType<typeof useEhrAppointmentsLazyQuery>
export type EhrAppointmentsQueryResult = Apollo.QueryResult<
  EhrAppointmentsQuery,
  EhrAppointmentsQueryVariables
>
export const EhrAvailableDatesWithSlotsDocument = gql`
  query ehrAvailableDatesWithSlots(
    $appointmentKind: EhrAppointmentKind
    $timeZone: String
    $providerKind: EhrAppointmentProviderKind
    $getHealthieInstanceKind: EhrGetHealthieInstanceKind
    $rescheduleAppointmentId: ID
    $providerId: ID
    $state: AddressState
    $useCache: Boolean
  ) {
    ehrAvailableDatesWithSlots(
      appointmentKind: $appointmentKind
      timeZone: $timeZone
      providerKind: $providerKind
      getHealthieInstanceKind: $getHealthieInstanceKind
      rescheduleAppointmentId: $rescheduleAppointmentId
      providerId: $providerId
      state: $state
      useCache: $useCache
    ) {
      dates {
        date
        slots {
          time
          providerId
          providerUserId
        }
      }
      providers {
        ... on Doctor {
          ...Doctor
        }
        ... on Coach {
          ...Coach
        }
      }
    }
  }
  ${DoctorFragmentDoc}
  ${CoachFragmentDoc}
`

/**
 * __useEhrAvailableDatesWithSlotsQuery__
 *
 * To run a query within a React component, call `useEhrAvailableDatesWithSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEhrAvailableDatesWithSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEhrAvailableDatesWithSlotsQuery({
 *   variables: {
 *      appointmentKind: // value for 'appointmentKind'
 *      timeZone: // value for 'timeZone'
 *      providerKind: // value for 'providerKind'
 *      getHealthieInstanceKind: // value for 'getHealthieInstanceKind'
 *      rescheduleAppointmentId: // value for 'rescheduleAppointmentId'
 *      providerId: // value for 'providerId'
 *      state: // value for 'state'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useEhrAvailableDatesWithSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EhrAvailableDatesWithSlotsQuery,
    EhrAvailableDatesWithSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EhrAvailableDatesWithSlotsQuery, EhrAvailableDatesWithSlotsQueryVariables>(
    EhrAvailableDatesWithSlotsDocument,
    options
  )
}
export function useEhrAvailableDatesWithSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EhrAvailableDatesWithSlotsQuery,
    EhrAvailableDatesWithSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EhrAvailableDatesWithSlotsQuery,
    EhrAvailableDatesWithSlotsQueryVariables
  >(EhrAvailableDatesWithSlotsDocument, options)
}
export type EhrAvailableDatesWithSlotsQueryHookResult = ReturnType<
  typeof useEhrAvailableDatesWithSlotsQuery
>
export type EhrAvailableDatesWithSlotsLazyQueryHookResult = ReturnType<
  typeof useEhrAvailableDatesWithSlotsLazyQuery
>
export type EhrAvailableDatesWithSlotsQueryResult = Apollo.QueryResult<
  EhrAvailableDatesWithSlotsQuery,
  EhrAvailableDatesWithSlotsQueryVariables
>
export const EnrollableCourseCohortsDocument = gql`
  query enrollableCourseCohorts($kind: CoursesCohortKind!) {
    enrollableCourseCohorts(kind: $kind) {
      cohorts {
        ...CourseCohort
      }
    }
  }
  ${CourseCohortFragmentDoc}
`

/**
 * __useEnrollableCourseCohortsQuery__
 *
 * To run a query within a React component, call `useEnrollableCourseCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollableCourseCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollableCourseCohortsQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useEnrollableCourseCohortsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnrollableCourseCohortsQuery,
    EnrollableCourseCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EnrollableCourseCohortsQuery, EnrollableCourseCohortsQueryVariables>(
    EnrollableCourseCohortsDocument,
    options
  )
}
export function useEnrollableCourseCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnrollableCourseCohortsQuery,
    EnrollableCourseCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EnrollableCourseCohortsQuery, EnrollableCourseCohortsQueryVariables>(
    EnrollableCourseCohortsDocument,
    options
  )
}
export type EnrollableCourseCohortsQueryHookResult = ReturnType<
  typeof useEnrollableCourseCohortsQuery
>
export type EnrollableCourseCohortsLazyQueryHookResult = ReturnType<
  typeof useEnrollableCourseCohortsLazyQuery
>
export type EnrollableCourseCohortsQueryResult = Apollo.QueryResult<
  EnrollableCourseCohortsQuery,
  EnrollableCourseCohortsQueryVariables
>
export const SchedulingAllAvailabilitiesDocument = gql`
  query schedulingAllAvailabilities {
    schedulingAllAvailabilities {
      dayOfWeek
      appointmentType
      rangeStartAt
      rangeEndAt
    }
  }
`

/**
 * __useSchedulingAllAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useSchedulingAllAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingAllAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingAllAvailabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchedulingAllAvailabilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchedulingAllAvailabilitiesQuery,
    SchedulingAllAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchedulingAllAvailabilitiesQuery,
    SchedulingAllAvailabilitiesQueryVariables
  >(SchedulingAllAvailabilitiesDocument, options)
}
export function useSchedulingAllAvailabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingAllAvailabilitiesQuery,
    SchedulingAllAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchedulingAllAvailabilitiesQuery,
    SchedulingAllAvailabilitiesQueryVariables
  >(SchedulingAllAvailabilitiesDocument, options)
}
export type SchedulingAllAvailabilitiesQueryHookResult = ReturnType<
  typeof useSchedulingAllAvailabilitiesQuery
>
export type SchedulingAllAvailabilitiesLazyQueryHookResult = ReturnType<
  typeof useSchedulingAllAvailabilitiesLazyQuery
>
export type SchedulingAllAvailabilitiesQueryResult = Apollo.QueryResult<
  SchedulingAllAvailabilitiesQuery,
  SchedulingAllAvailabilitiesQueryVariables
>
export const EhrAllAvailabilitiesDocument = gql`
  query ehrAllAvailabilities {
    ehrAllAvailabilities {
      dayOfWeek
      appointmentKind
      rangeStartAt
      rangeEndAt
    }
  }
`

/**
 * __useEhrAllAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useEhrAllAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEhrAllAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEhrAllAvailabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEhrAllAvailabilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EhrAllAvailabilitiesQuery,
    EhrAllAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EhrAllAvailabilitiesQuery, EhrAllAvailabilitiesQueryVariables>(
    EhrAllAvailabilitiesDocument,
    options
  )
}
export function useEhrAllAvailabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EhrAllAvailabilitiesQuery,
    EhrAllAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EhrAllAvailabilitiesQuery, EhrAllAvailabilitiesQueryVariables>(
    EhrAllAvailabilitiesDocument,
    options
  )
}
export type EhrAllAvailabilitiesQueryHookResult = ReturnType<typeof useEhrAllAvailabilitiesQuery>
export type EhrAllAvailabilitiesLazyQueryHookResult = ReturnType<
  typeof useEhrAllAvailabilitiesLazyQuery
>
export type EhrAllAvailabilitiesQueryResult = Apollo.QueryResult<
  EhrAllAvailabilitiesQuery,
  EhrAllAvailabilitiesQueryVariables
>
export const InsurancePolicyDocument = gql`
  query insurancePolicy {
    insurancePolicy {
      ...InsurancePolicyFields
    }
  }
  ${InsurancePolicyFieldsFragmentDoc}
`

/**
 * __useInsurancePolicyQuery__
 *
 * To run a query within a React component, call `useInsurancePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsurancePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsurancePolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsurancePolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<InsurancePolicyQuery, InsurancePolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InsurancePolicyQuery, InsurancePolicyQueryVariables>(
    InsurancePolicyDocument,
    options
  )
}
export function useInsurancePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsurancePolicyQuery, InsurancePolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InsurancePolicyQuery, InsurancePolicyQueryVariables>(
    InsurancePolicyDocument,
    options
  )
}
export type InsurancePolicyQueryHookResult = ReturnType<typeof useInsurancePolicyQuery>
export type InsurancePolicyLazyQueryHookResult = ReturnType<typeof useInsurancePolicyLazyQuery>
export type InsurancePolicyQueryResult = Apollo.QueryResult<
  InsurancePolicyQuery,
  InsurancePolicyQueryVariables
>
export const GetHealthieApiKeyDocument = gql`
  query getHealthieApiKey($getHealthieInstanceKind: EhrGetHealthieInstanceKind) {
    getHealthieApiKey(getHealthieInstanceKind: $getHealthieInstanceKind) {
      apiKey
    }
  }
`

/**
 * __useGetHealthieApiKeyQuery__
 *
 * To run a query within a React component, call `useGetHealthieApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthieApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthieApiKeyQuery({
 *   variables: {
 *      getHealthieInstanceKind: // value for 'getHealthieInstanceKind'
 *   },
 * });
 */
export function useGetHealthieApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHealthieApiKeyQuery, GetHealthieApiKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHealthieApiKeyQuery, GetHealthieApiKeyQueryVariables>(
    GetHealthieApiKeyDocument,
    options
  )
}
export function useGetHealthieApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHealthieApiKeyQuery, GetHealthieApiKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetHealthieApiKeyQuery, GetHealthieApiKeyQueryVariables>(
    GetHealthieApiKeyDocument,
    options
  )
}
export type GetHealthieApiKeyQueryHookResult = ReturnType<typeof useGetHealthieApiKeyQuery>
export type GetHealthieApiKeyLazyQueryHookResult = ReturnType<typeof useGetHealthieApiKeyLazyQuery>
export type GetHealthieApiKeyQueryResult = Apollo.QueryResult<
  GetHealthieApiKeyQuery,
  GetHealthieApiKeyQueryVariables
>
export const InsurancePlansDocument = gql`
  query insurancePlans {
    insurancePlans {
      insurancePlans {
        key
        title
        insuranceSurveyAnswer
      }
    }
  }
`

/**
 * __useInsurancePlansQuery__
 *
 * To run a query within a React component, call `useInsurancePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsurancePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsurancePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsurancePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<InsurancePlansQuery, InsurancePlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InsurancePlansQuery, InsurancePlansQueryVariables>(
    InsurancePlansDocument,
    options
  )
}
export function useInsurancePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsurancePlansQuery, InsurancePlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InsurancePlansQuery, InsurancePlansQueryVariables>(
    InsurancePlansDocument,
    options
  )
}
export type InsurancePlansQueryHookResult = ReturnType<typeof useInsurancePlansQuery>
export type InsurancePlansLazyQueryHookResult = ReturnType<typeof useInsurancePlansLazyQuery>
export type InsurancePlansQueryResult = Apollo.QueryResult<
  InsurancePlansQuery,
  InsurancePlansQueryVariables
>
export const InsuranceSchedulingAvailableDatesDocument = gql`
  query insuranceSchedulingAvailableDates(
    $timeZone: String
    $appointmentType: InsuranceBerryStreetAppointmentType
    $coachId: ID
    $userState: AddressState
  ) {
    insuranceSchedulingAvailableDates(
      timeZone: $timeZone
      appointmentType: $appointmentType
      coachId: $coachId
      userState: $userState
    ) {
      dates
    }
  }
`

/**
 * __useInsuranceSchedulingAvailableDatesQuery__
 *
 * To run a query within a React component, call `useInsuranceSchedulingAvailableDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchedulingAvailableDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceSchedulingAvailableDatesQuery({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *      appointmentType: // value for 'appointmentType'
 *      coachId: // value for 'coachId'
 *      userState: // value for 'userState'
 *   },
 * });
 */
export function useInsuranceSchedulingAvailableDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InsuranceSchedulingAvailableDatesQuery,
    InsuranceSchedulingAvailableDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InsuranceSchedulingAvailableDatesQuery,
    InsuranceSchedulingAvailableDatesQueryVariables
  >(InsuranceSchedulingAvailableDatesDocument, options)
}
export function useInsuranceSchedulingAvailableDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InsuranceSchedulingAvailableDatesQuery,
    InsuranceSchedulingAvailableDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InsuranceSchedulingAvailableDatesQuery,
    InsuranceSchedulingAvailableDatesQueryVariables
  >(InsuranceSchedulingAvailableDatesDocument, options)
}
export type InsuranceSchedulingAvailableDatesQueryHookResult = ReturnType<
  typeof useInsuranceSchedulingAvailableDatesQuery
>
export type InsuranceSchedulingAvailableDatesLazyQueryHookResult = ReturnType<
  typeof useInsuranceSchedulingAvailableDatesLazyQuery
>
export type InsuranceSchedulingAvailableDatesQueryResult = Apollo.QueryResult<
  InsuranceSchedulingAvailableDatesQuery,
  InsuranceSchedulingAvailableDatesQueryVariables
>
export const InsuranceSchedulingAvailableSlotsDocument = gql`
  query insuranceSchedulingAvailableSlots(
    $appointmentType: InsuranceBerryStreetAppointmentType
    $timeZone: String
    $date: String
    $coachId: ID
    $userState: AddressState
  ) {
    insuranceSchedulingAvailableSlots(
      appointmentType: $appointmentType
      timeZone: $timeZone
      date: $date
      coachId: $coachId
      userState: $userState
    ) {
      slots {
        time
        coachId
      }
    }
  }
`

/**
 * __useInsuranceSchedulingAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useInsuranceSchedulingAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchedulingAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceSchedulingAvailableSlotsQuery({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *      timeZone: // value for 'timeZone'
 *      date: // value for 'date'
 *      coachId: // value for 'coachId'
 *      userState: // value for 'userState'
 *   },
 * });
 */
export function useInsuranceSchedulingAvailableSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InsuranceSchedulingAvailableSlotsQuery,
    InsuranceSchedulingAvailableSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InsuranceSchedulingAvailableSlotsQuery,
    InsuranceSchedulingAvailableSlotsQueryVariables
  >(InsuranceSchedulingAvailableSlotsDocument, options)
}
export function useInsuranceSchedulingAvailableSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InsuranceSchedulingAvailableSlotsQuery,
    InsuranceSchedulingAvailableSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InsuranceSchedulingAvailableSlotsQuery,
    InsuranceSchedulingAvailableSlotsQueryVariables
  >(InsuranceSchedulingAvailableSlotsDocument, options)
}
export type InsuranceSchedulingAvailableSlotsQueryHookResult = ReturnType<
  typeof useInsuranceSchedulingAvailableSlotsQuery
>
export type InsuranceSchedulingAvailableSlotsLazyQueryHookResult = ReturnType<
  typeof useInsuranceSchedulingAvailableSlotsLazyQuery
>
export type InsuranceSchedulingAvailableSlotsQueryResult = Apollo.QueryResult<
  InsuranceSchedulingAvailableSlotsQuery,
  InsuranceSchedulingAvailableSlotsQueryVariables
>
export const InvoiceDocument = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      id
      stripeId
      total
      status
      primaryProduct {
        id
        key
        title
      }
    }
  }
`

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options)
}
export function useInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options)
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>
export const LatestUserAppointmentDocument = gql`
  query latestUserAppointment {
    latestUserAppointment {
      ...LatestUserAppointment
    }
  }
  ${LatestUserAppointmentFragmentDoc}
`

/**
 * __useLatestUserAppointmentQuery__
 *
 * To run a query within a React component, call `useLatestUserAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestUserAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestUserAppointmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestUserAppointmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestUserAppointmentQuery,
    LatestUserAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestUserAppointmentQuery, LatestUserAppointmentQueryVariables>(
    LatestUserAppointmentDocument,
    options
  )
}
export function useLatestUserAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestUserAppointmentQuery,
    LatestUserAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestUserAppointmentQuery, LatestUserAppointmentQueryVariables>(
    LatestUserAppointmentDocument,
    options
  )
}
export type LatestUserAppointmentQueryHookResult = ReturnType<typeof useLatestUserAppointmentQuery>
export type LatestUserAppointmentLazyQueryHookResult = ReturnType<
  typeof useLatestUserAppointmentLazyQuery
>
export type LatestUserAppointmentQueryResult = Apollo.QueryResult<
  LatestUserAppointmentQuery,
  LatestUserAppointmentQueryVariables
>
export const LatestChargeDocument = gql`
  query latestCharge($providerSubscriptionId: ID!) {
    latestCharge(providerSubscriptionId: $providerSubscriptionId) {
      status
      failureMessage
      created
      providerPaymentMethodId
    }
  }
`

/**
 * __useLatestChargeQuery__
 *
 * To run a query within a React component, call `useLatestChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestChargeQuery({
 *   variables: {
 *      providerSubscriptionId: // value for 'providerSubscriptionId'
 *   },
 * });
 */
export function useLatestChargeQuery(
  baseOptions: Apollo.QueryHookOptions<LatestChargeQuery, LatestChargeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestChargeQuery, LatestChargeQueryVariables>(
    LatestChargeDocument,
    options
  )
}
export function useLatestChargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestChargeQuery, LatestChargeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestChargeQuery, LatestChargeQueryVariables>(
    LatestChargeDocument,
    options
  )
}
export type LatestChargeQueryHookResult = ReturnType<typeof useLatestChargeQuery>
export type LatestChargeLazyQueryHookResult = ReturnType<typeof useLatestChargeLazyQuery>
export type LatestChargeQueryResult = Apollo.QueryResult<
  LatestChargeQuery,
  LatestChargeQueryVariables
>
export const LatestSurveyLinkByKindDocument = gql`
  query latestSurveyLinkByKind($kind: SurveysConfigKind!) {
    latestSurveyLinkByKind(kind: $kind) {
      ...SurveyLink
      config {
        ...SurveyLinkConfig
      }
    }
  }
  ${SurveyLinkFragmentDoc}
  ${SurveyLinkConfigFragmentDoc}
`

/**
 * __useLatestSurveyLinkByKindQuery__
 *
 * To run a query within a React component, call `useLatestSurveyLinkByKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestSurveyLinkByKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestSurveyLinkByKindQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useLatestSurveyLinkByKindQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestSurveyLinkByKindQuery,
    LatestSurveyLinkByKindQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestSurveyLinkByKindQuery, LatestSurveyLinkByKindQueryVariables>(
    LatestSurveyLinkByKindDocument,
    options
  )
}
export function useLatestSurveyLinkByKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestSurveyLinkByKindQuery,
    LatestSurveyLinkByKindQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestSurveyLinkByKindQuery, LatestSurveyLinkByKindQueryVariables>(
    LatestSurveyLinkByKindDocument,
    options
  )
}
export type LatestSurveyLinkByKindQueryHookResult = ReturnType<
  typeof useLatestSurveyLinkByKindQuery
>
export type LatestSurveyLinkByKindLazyQueryHookResult = ReturnType<
  typeof useLatestSurveyLinkByKindLazyQuery
>
export type LatestSurveyLinkByKindQueryResult = Apollo.QueryResult<
  LatestSurveyLinkByKindQuery,
  LatestSurveyLinkByKindQueryVariables
>
export const LatestUserEhrAppointmentDocument = gql`
  query latestUserEhrAppointment($category: EhrAppointmentCategory!) {
    latestUserEhrAppointment(category: $category) {
      ...EhrAppointment
    }
  }
  ${EhrAppointmentFragmentDoc}
`

/**
 * __useLatestUserEhrAppointmentQuery__
 *
 * To run a query within a React component, call `useLatestUserEhrAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestUserEhrAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestUserEhrAppointmentQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useLatestUserEhrAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestUserEhrAppointmentQuery,
    LatestUserEhrAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestUserEhrAppointmentQuery, LatestUserEhrAppointmentQueryVariables>(
    LatestUserEhrAppointmentDocument,
    options
  )
}
export function useLatestUserEhrAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestUserEhrAppointmentQuery,
    LatestUserEhrAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestUserEhrAppointmentQuery, LatestUserEhrAppointmentQueryVariables>(
    LatestUserEhrAppointmentDocument,
    options
  )
}
export type LatestUserEhrAppointmentQueryHookResult = ReturnType<
  typeof useLatestUserEhrAppointmentQuery
>
export type LatestUserEhrAppointmentLazyQueryHookResult = ReturnType<
  typeof useLatestUserEhrAppointmentLazyQuery
>
export type LatestUserEhrAppointmentQueryResult = Apollo.QueryResult<
  LatestUserEhrAppointmentQuery,
  LatestUserEhrAppointmentQueryVariables
>
export const ProductDocument = gql`
  query product($product: BillingProduct!) {
    product(product: $product) {
      id
      key
      order
      title
      description
      details
      interval {
        key
        description
        suffix
      }
      price
      recurring
      trialDays
    }
  }
`

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options)
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options)
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>
export const PromotionCodeDocument = gql`
  query promotionCode($code: String!, $product: BillingProduct) {
    promotionCode(code: $code, product: $product) {
      providerId
      applicable
      code
      percentOff
      amountOff
    }
  }
`

/**
 * __usePromotionCodeQuery__
 *
 * To run a query within a React component, call `usePromotionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      product: // value for 'product'
 *   },
 * });
 */
export function usePromotionCodeQuery(
  baseOptions: Apollo.QueryHookOptions<PromotionCodeQuery, PromotionCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PromotionCodeQuery, PromotionCodeQueryVariables>(
    PromotionCodeDocument,
    options
  )
}
export function usePromotionCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PromotionCodeQuery, PromotionCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PromotionCodeQuery, PromotionCodeQueryVariables>(
    PromotionCodeDocument,
    options
  )
}
export type PromotionCodeQueryHookResult = ReturnType<typeof usePromotionCodeQuery>
export type PromotionCodeLazyQueryHookResult = ReturnType<typeof usePromotionCodeLazyQuery>
export type PromotionCodeQueryResult = Apollo.QueryResult<
  PromotionCodeQuery,
  PromotionCodeQueryVariables
>
export const ReferralPartnerDocument = gql`
  query referralPartner($partner: UserReferralPartner!) {
    referralPartner(partner: $partner) {
      logoUrl
      product
    }
  }
`

/**
 * __useReferralPartnerQuery__
 *
 * To run a query within a React component, call `useReferralPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralPartnerQuery({
 *   variables: {
 *      partner: // value for 'partner'
 *   },
 * });
 */
export function useReferralPartnerQuery(
  baseOptions: Apollo.QueryHookOptions<ReferralPartnerQuery, ReferralPartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReferralPartnerQuery, ReferralPartnerQueryVariables>(
    ReferralPartnerDocument,
    options
  )
}
export function useReferralPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralPartnerQuery, ReferralPartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReferralPartnerQuery, ReferralPartnerQueryVariables>(
    ReferralPartnerDocument,
    options
  )
}
export type ReferralPartnerQueryHookResult = ReturnType<typeof useReferralPartnerQuery>
export type ReferralPartnerLazyQueryHookResult = ReturnType<typeof useReferralPartnerLazyQuery>
export type ReferralPartnerQueryResult = Apollo.QueryResult<
  ReferralPartnerQuery,
  ReferralPartnerQueryVariables
>
export const SchedulingAvailableDatesWithSlotsDocument = gql`
  query schedulingAvailableDatesWithSlots(
    $appointmentType: InsuranceBerryStreetAppointmentType
    $timeZone: String
    $coachId: ID
    $userState: AddressState
    $useCache: Boolean
  ) {
    schedulingAvailableDatesWithSlots(
      appointmentType: $appointmentType
      timeZone: $timeZone
      coachId: $coachId
      userState: $userState
      useCache: $useCache
    ) {
      coachProfiles {
        ...CoachProfile
      }
      dates {
        date
        slots {
          time
          coachId
        }
      }
    }
  }
  ${CoachProfileFragmentDoc}
`

/**
 * __useSchedulingAvailableDatesWithSlotsQuery__
 *
 * To run a query within a React component, call `useSchedulingAvailableDatesWithSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingAvailableDatesWithSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingAvailableDatesWithSlotsQuery({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *      timeZone: // value for 'timeZone'
 *      coachId: // value for 'coachId'
 *      userState: // value for 'userState'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useSchedulingAvailableDatesWithSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchedulingAvailableDatesWithSlotsQuery,
    SchedulingAvailableDatesWithSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchedulingAvailableDatesWithSlotsQuery,
    SchedulingAvailableDatesWithSlotsQueryVariables
  >(SchedulingAvailableDatesWithSlotsDocument, options)
}
export function useSchedulingAvailableDatesWithSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingAvailableDatesWithSlotsQuery,
    SchedulingAvailableDatesWithSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchedulingAvailableDatesWithSlotsQuery,
    SchedulingAvailableDatesWithSlotsQueryVariables
  >(SchedulingAvailableDatesWithSlotsDocument, options)
}
export type SchedulingAvailableDatesWithSlotsQueryHookResult = ReturnType<
  typeof useSchedulingAvailableDatesWithSlotsQuery
>
export type SchedulingAvailableDatesWithSlotsLazyQueryHookResult = ReturnType<
  typeof useSchedulingAvailableDatesWithSlotsLazyQuery
>
export type SchedulingAvailableDatesWithSlotsQueryResult = Apollo.QueryResult<
  SchedulingAvailableDatesWithSlotsQuery,
  SchedulingAvailableDatesWithSlotsQueryVariables
>
export const UpcomingCourseCohortSessionDocument = gql`
  query upcomingCourseCohortSession($cohortKind: CoursesCohortKind!) {
    upcomingCourseCohortSession(cohortKind: $cohortKind) {
      ...CourseCohortSession
    }
  }
  ${CourseCohortSessionFragmentDoc}
`

/**
 * __useUpcomingCourseCohortSessionQuery__
 *
 * To run a query within a React component, call `useUpcomingCourseCohortSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingCourseCohortSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingCourseCohortSessionQuery({
 *   variables: {
 *      cohortKind: // value for 'cohortKind'
 *   },
 * });
 */
export function useUpcomingCourseCohortSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpcomingCourseCohortSessionQuery,
    UpcomingCourseCohortSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpcomingCourseCohortSessionQuery,
    UpcomingCourseCohortSessionQueryVariables
  >(UpcomingCourseCohortSessionDocument, options)
}
export function useUpcomingCourseCohortSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingCourseCohortSessionQuery,
    UpcomingCourseCohortSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpcomingCourseCohortSessionQuery,
    UpcomingCourseCohortSessionQueryVariables
  >(UpcomingCourseCohortSessionDocument, options)
}
export type UpcomingCourseCohortSessionQueryHookResult = ReturnType<
  typeof useUpcomingCourseCohortSessionQuery
>
export type UpcomingCourseCohortSessionLazyQueryHookResult = ReturnType<
  typeof useUpcomingCourseCohortSessionLazyQuery
>
export type UpcomingCourseCohortSessionQueryResult = Apollo.QueryResult<
  UpcomingCourseCohortSessionQuery,
  UpcomingCourseCohortSessionQueryVariables
>
export const UpcomingDppCourseCohortSessionDocument = gql`
  query upcomingDppCourseCohortSession {
    upcomingDppCourseCohortSession {
      ...CourseCohortSession
    }
  }
  ${CourseCohortSessionFragmentDoc}
`

/**
 * __useUpcomingDppCourseCohortSessionQuery__
 *
 * To run a query within a React component, call `useUpcomingDppCourseCohortSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingDppCourseCohortSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingDppCourseCohortSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingDppCourseCohortSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UpcomingDppCourseCohortSessionQuery,
    UpcomingDppCourseCohortSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpcomingDppCourseCohortSessionQuery,
    UpcomingDppCourseCohortSessionQueryVariables
  >(UpcomingDppCourseCohortSessionDocument, options)
}
export function useUpcomingDppCourseCohortSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingDppCourseCohortSessionQuery,
    UpcomingDppCourseCohortSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpcomingDppCourseCohortSessionQuery,
    UpcomingDppCourseCohortSessionQueryVariables
  >(UpcomingDppCourseCohortSessionDocument, options)
}
export type UpcomingDppCourseCohortSessionQueryHookResult = ReturnType<
  typeof useUpcomingDppCourseCohortSessionQuery
>
export type UpcomingDppCourseCohortSessionLazyQueryHookResult = ReturnType<
  typeof useUpcomingDppCourseCohortSessionLazyQuery
>
export type UpcomingDppCourseCohortSessionQueryResult = Apollo.QueryResult<
  UpcomingDppCourseCohortSessionQuery,
  UpcomingDppCourseCohortSessionQueryVariables
>
