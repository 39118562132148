import { OwnSensorFlowStep } from 'components/OwnSensorFlow'
import { Layout } from 'components/OwnSensorFlow/Layout'
import { Navigation } from 'components/OwnSensorFlow/Navigation'
import { LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'
import { OwnSensorFlow } from 'screens/OwnSensor/Steps'

export const ownSensor: RouteObject = {
  path: LocationPaths.OwnSensor,
  element: <Layout />,
  children: [
    {
      element: <Navigation />,
      children: [
        {
          index: true,
          element: <OwnSensorFlowStep stepIndex={0} />
        },
        ...OwnSensorFlow.map((step, index) => ({
          path: step.path.replace(/^\//, ''),
          element: <OwnSensorFlowStep stepIndex={index} />,
          handle: { index }
        }))
      ]
    }
  ]
}
