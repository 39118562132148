import { useCallback } from 'react'
import { CurrentUser } from 'customTypes'
import { useNavigate } from 'react-router-dom'
import { getPaidVideoCallFlow } from '../types'

export const useStepNavigation = (currentIndex: number) => {
  const navigate = useNavigate()
  const paidVideoCallFlow = getPaidVideoCallFlow()

  const findNextStep = useCallback(
    (index: number, user: CurrentUser | undefined) => {
      const nextStep = paidVideoCallFlow[index + 1]

      if (!nextStep) {
        return paidVideoCallFlow[currentIndex]
      }

      if (nextStep.completedResolver(user, undefined)) {
        return findNextStep(index + 1, user)
      }

      return nextStep
    },
    [currentIndex, paidVideoCallFlow]
  )

  const findPreviousStep = useCallback(() => {
    const previousStep = paidVideoCallFlow[currentIndex - 1]

    if (!previousStep) {
      return paidVideoCallFlow[currentIndex]
    }

    return previousStep
  }, [currentIndex, paidVideoCallFlow])

  const navigateToNextStep = useCallback(
    (user: CurrentUser | undefined) => {
      const nextStep = findNextStep(currentIndex, user)
      navigate(nextStep.location)
    },
    [currentIndex, findNextStep, navigate]
  )

  const navigateToPreviousStep = useCallback(() => {
    const previousStep = findPreviousStep()
    navigate(previousStep.location)
  }, [findPreviousStep, navigate])

  return {
    navigateToNextStep,
    navigateToPreviousStep
  }
}
