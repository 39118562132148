import { PageLayout } from 'components/PageLayout'
import { PaidCallFlowStep, Layout as PaidCallFlowStepLayout } from 'components/PaidCallFlowStep'
import { Layout, VideoCallFlowStep } from 'components/VideoCallFlowStep'
import { LocationPaths, VideoCallsPath } from 'location.types'
import { RouteObject } from 'react-router-dom'
import { getPaidVideoCallFlow } from 'screens/PaidVideoCalls/types'
import { getVideoCallFlow } from 'screens/VideoCalls/types'

export const buildVideoCallsFlowRoutes: () => RouteObject = () => ({
  path: LocationPaths.VideoCalls,
  element: <Layout />,
  children: getVideoCallFlow().map((step, index) => ({
    index: index === 0,
    path: step.path,
    element: <VideoCallFlowStep stepIndex={index} />,
    handle: { index }
  }))
})

export const buildPaidVideoCallsFlowRoutes: () => RouteObject = () => ({
  path: LocationPaths.PaidVideoCalls,
  element: <PaidCallFlowStepLayout />,
  children: getPaidVideoCallFlow().map((step, index) => ({
    index: index === 0,
    path: step.path,
    element: <PaidCallFlowStep stepIndex={index} />,
    handle: { index }
  }))
})

export const privateVideoCallsRoutes: RouteObject = {
  path: LocationPaths.VideoCalls,
  element: <PageLayout />,
  children: [
    {
      path: VideoCallsPath.Dashboard,
      lazy: async () => ({ Component: (await import('screens/VideoCalls/Dashboard')).Dashboard })
    },
    {
      path: VideoCallsPath.Chat,
      lazy: async () => ({ Component: (await import('screens/VideoCalls/Chat')).Chat })
    },
    {
      path: VideoCallsPath.WeightLossExperienceStart,
      lazy: async () => ({
        Component: (await import('screens/VideoCalls/WeightLossExperience/StartScreen'))
          .WeightLossExperienceStartScreen
      })
    },
    {
      path: VideoCallsPath.WeightLossExperienceCompleted,
      lazy: async () => ({
        Component: (await import('screens/VideoCalls/WeightLossExperience/CompletedScreen'))
          .WeightLossExperienceCompletedScreen
      })
    },
    {
      path: VideoCallsPath.WeightLossExperiencePlan,
      lazy: async () => ({
        Component: (await import('screens/VideoCalls/WeightLossExperience/PersonalPlan'))
          .WeightLossExperiencePersonalPlanContainer
      })
    }
  ]
}

export const signInRoute: RouteObject = {
  path: LocationPaths.VideoCalls,
  children: [
    {
      path: VideoCallsPath.SignIn,
      lazy: async () => ({ Component: (await import('screens/VideoCalls/SignIn')).SignIn })
    }
  ]
}
