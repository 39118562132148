import { useCallback } from 'react'
import {
  useFlag,
  useUnleashClient,
  useUnleashContext,
  useVariant
} from '@unleash/proxy-client-react'
import { CurrentUser } from 'customTypes'
import { buildContext } from './unleash'

export enum Feature {
  AvailabilityCaching = 'availability_caching',
  ConsumerHealthPrivacyPolicy = 'consumer_health_privacy_policy',
  DexcomResearch = 'dexcom_research',
  DisableLibre3OptIn = 'disable_libre3_opt_in',
  DppFlexModules = 'dpp_flex_modules',
  DppOnboardingUpsell = 'dpp_onboarding_upsell',
  GlpDashboardShowMobileAppQrCode = 'glp_dashboard_show_mobile_app_qr_code',
  GlpObesityPreventionProgram = 'glp_obesity_prevention_program',
  IneligibleCallUpsell = 'ineligible_call_upsell',
  InsuranceBenefitsCheck = 'insurance_benefits_check',
  Libre3Default = 'libre_3_as_default',
  MedicalRescheduleCancel = 'medical_reschedule_cancel',
  MobileAppPresentationMode = 'mobile_app_presentation_mode',
  OwnSensorHideGoalQuestion = 'own_sensor_hide_goal_question',
  OwnSensorSeparateSetPasswordStep = 'own_sensor_separate_set_password_step',
  SchedulingSlotsGroupedByCoaches = 'scheduling_slots_grouped_by_coaches',
  VideoCallsDashboard = 'video_calls_dashboard',
  VideoCallsFiltering = 'video_calls_filtering',
  VideoCallsPhoneNumber = 'video_calls_phone_number',
  VideoCallsSecondaryHealthGoal = 'video_calls_secondary_health_goal',
  VideoCallsSpecialtiesFiltering = 'video_calls_specialties_filtering',
  VideoCallsTimeFiltering = 'video_calls_time_filtering',
  VideoCallsUnavailableSlotsBooking = 'video_calls_unavailable_slots_booking',
  VideoCallsUnavailableSlotsBookingByDefault = 'video_calls_unavailable_slots_booking_by_default',
  WeightLossExperience = 'weight_loss_experience',
  WeightLossExperienceLongSurvey = 'weight_loss_experience_long_survey',
  CompoundedSemaglutideNewCoupon = 'compounded_semaglutide_new_coupon'
}

export const useFeatureFlag = (feature: Feature): boolean => {
  return useFlag(feature)
}

export const useIsFeatureEnabled = () => {
  const unleashClient = useUnleashClient()
  return useCallback(
    (feature: Feature) => {
      return unleashClient.isEnabled(feature)
    },
    [unleashClient]
  )
}

export const useSetFeatureContext = () => {
  const updateContext = useUnleashContext()
  return useCallback(
    async (user: Pick<NonNullable<CurrentUser>, 'id' | 'email'>) => {
      const context = buildContext(user.id, user.email)
      await updateContext(context)
    },
    [updateContext]
  )
}

export const useFeatureFlagVariantPayload = (feature: Feature) => {
  const variant = useVariant(feature)
  return variant.payload?.value
}
