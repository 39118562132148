import { CurrentUser } from 'customTypes'
import { LocationPaths, PaidVideoCallsPath } from 'location.types'
import { LazyComponentWithPreload, lazyPreload } from 'utils/lazyPreload'
import { getUserData } from 'utils/userData'
import { Appointment, EhrAppointmentKind } from 'types'

const PaidCallIntroScreen = lazyPreload(() =>
  import('./PaidCallIntroScreen').then((module) => ({
    default: module.PaidCallIntroScreen
  }))
)

const PaidCallSignUpScreen = lazyPreload(() =>
  import('./PaidCallSignUpScreen').then((module) => ({
    default: module.PaidCallSignUpScreen
  }))
)

const PaidCallSubmitStateScreen = lazyPreload(() =>
  import('./PaidCallSubmitStateScreen').then((module) => ({
    default: module.PaidCallSubmitStateScreen
  }))
)

const ScheduleCallScreen = lazyPreload(() =>
  import('../ScheduleCall').then((module) => ({
    default: module.ScheduleCall
  }))
)
const PaidCallCheckoutScreen = lazyPreload(() =>
  import('./PaidCallCheckoutScreen').then((module) => ({
    default: module.PaidCallCheckoutScreen
  }))
)
const PaidCallCompletedScreen = lazyPreload(() =>
  import('./PaidCallCompletedScreen').then((module) => ({
    default: module.PaidCallCompletedScreen
  }))
)

export interface PaidVideoCallStep {
  path: PaidVideoCallsPath | LocationPaths.PaidVideoCalls
  location: string
  completedResolver: (
    user: CurrentUser | undefined,
    latestAppointment: Appointment | undefined
  ) => boolean
  canNavigateBack: boolean
  routeComponent: LazyComponentWithPreload<() => JSX.Element | null>
  requiresStripe?: boolean
  hideSecurityNote?: boolean
}

export const getPaidVideoCallFlow: () => PaidVideoCallStep[] = () => {
  const signUpStep: PaidVideoCallStep = {
    path: PaidVideoCallsPath.UserInfo,
    location: `${LocationPaths.PaidVideoCalls}/${PaidVideoCallsPath.UserInfo}`,
    completedResolver: (user) => !!user?.email,
    canNavigateBack: true,
    routeComponent: PaidCallSignUpScreen
  }

  const stateStep: PaidVideoCallStep = {
    path: PaidVideoCallsPath.State,
    location: `${LocationPaths.PaidVideoCalls}/${PaidVideoCallsPath.State}`,
    completedResolver: (user) => {
      const userData = getUserData()
      return !!user?.address?.state || !!userData?.state
    },
    canNavigateBack: true,
    routeComponent: PaidCallSubmitStateScreen
  }

  const result: PaidVideoCallStep[] = [
    {
      path: LocationPaths.PaidVideoCalls,
      location: `${LocationPaths.PaidVideoCalls}`,
      completedResolver: () => false,
      canNavigateBack: true,
      routeComponent: PaidCallIntroScreen
    },
    signUpStep,
    stateStep,
    {
      path: PaidVideoCallsPath.ScheduleCall,
      location: `${LocationPaths.PaidVideoCalls}/${PaidVideoCallsPath.ScheduleCall}?appointmentType=${EhrAppointmentKind.NoneCovered}`,
      completedResolver: () => false,
      canNavigateBack: true,
      routeComponent: ScheduleCallScreen
    },
    {
      path: PaidVideoCallsPath.Checkout,
      location: `${LocationPaths.PaidVideoCalls}/${PaidVideoCallsPath.Checkout}`,
      completedResolver: () => false,
      canNavigateBack: false,
      requiresStripe: true,
      routeComponent: PaidCallCheckoutScreen,
      hideSecurityNote: true
    },
    {
      path: PaidVideoCallsPath.Completed,
      location: `${LocationPaths.PaidVideoCalls}/${PaidVideoCallsPath.Completed}`,
      completedResolver: () => false,
      canNavigateBack: false,
      routeComponent: PaidCallCompletedScreen,
      hideSecurityNote: true
    }
  ]

  return result
}
