import { PrivateRoute } from 'components'
import { PageLayout } from 'components/PageLayout'
import { GlpPath, LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

export const glp: RouteObject = {
  path: LocationPaths.Glp,
  element: <PageLayout />,
  children: [
    {
      index: true,
      lazy: async () => ({ Component: (await import('screens/Glp/Redirect')).Redirect })
    },
    {
      path: GlpPath.Goal,
      lazy: async () => ({ Component: (await import('screens/Glp/Goal')).Goal })
    },
    {
      path: GlpPath.TriedLoseWeight,
      lazy: async () => ({
        Component: (await import('screens/Glp/TriedLoseWeight')).TriedLoseWeight
      })
    },
    {
      path: GlpPath.NewApproach,
      lazy: async () => ({ Component: (await import('screens/Glp/NewApproach')).NewApproach })
    },
    {
      path: GlpPath.State,
      lazy: async () => ({ Component: (await import('screens/Glp/State')).State })
    },
    {
      path: GlpPath.DateOfBirth,
      lazy: async () => ({ Component: (await import('screens/Glp/DateOfBirth')).DateOfBirth })
    },
    {
      path: GlpPath.MedicalHistory,
      lazy: async () => ({ Component: (await import('screens/Glp/MedicalHistory')).MedicalHistory })
    },
    {
      path: GlpPath.Bmi,
      lazy: async () => ({ Component: (await import('screens/Glp/Bmi')).Bmi })
    },
    {
      path: GlpPath.Descent,
      lazy: async () => ({ Component: (await import('screens/Glp/Descent')).Descent })
    },
    {
      path: GlpPath.CurrentGlpStatus,
      lazy: async () => ({
        Component: (await import('screens/Glp/CurrentGlpStatus')).CurrentGlpStatus
      })
    },
    {
      path: GlpPath.ObesityPreventionProgramOffer,
      lazy: async () => ({
        Component: (await import('screens/Glp/ObesityPreventionProgramOffer'))
          .ObesityPreventionProgramOffer
      })
    },
    {
      path: GlpPath.Conditions,
      lazy: async () => ({ Component: (await import('screens/Glp/Conditions')).Conditions })
    },
    {
      path: GlpPath.WeightLossAmount,
      lazy: async () => ({
        Component: (await import('screens/Glp/WeightLossAmount')).WeightLossAmount
      })
    },
    {
      path: GlpPath.Ineligible,
      lazy: async () => ({ Component: (await import('screens/Glp/NotEligible')).NotEligible })
    },
    {
      path: GlpPath.NotRightProgram,
      lazy: async () => ({
        Component: (await import('screens/Glp/NotRightProgram')).NotRightProgram
      })
    },
    {
      path: GlpPath.BmiTooLow,
      lazy: async () => ({ Component: (await import('screens/Glp/BmiTooLow')).BmiTooLow })
    },
    {
      path: GlpPath.SignUp,
      lazy: async () => ({ Component: (await import('screens/Glp/SignUp')).SignUp })
    },
    {
      path: GlpPath.Checkout,
      lazy: async () => ({ Component: (await import('screens/Glp/Checkout')).Checkout })
    },
    {
      path: GlpPath.PaymentSuccessful,
      lazy: async () => ({
        Component: (await import('screens/Glp/PaymentSuccessful')).PaymentSuccessful
      })
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: GlpPath.MissingInformation,
          lazy: async () => ({
            Component: (await import('screens/Glp/MissingInformation')).MissingInformation
          })
        },
        {
          path: GlpPath.EligibilityNextSteps,
          lazy: async () => ({
            Component: (await import('screens/Glp/EligibilityNextSteps')).EligibilityNextSteps
          })
        },
        {
          path: GlpPath.ScheduleCall,
          lazy: async () => ({ Component: (await import('screens/Glp/ScheduleCall')).ScheduleCall })
        },
        {
          path: GlpPath.CallScheduled,
          lazy: async () => ({
            Component: (await import('screens/Glp/CallScheduled')).CallScheduled
          })
        },
        {
          path: GlpPath.CallRescheduledCanceled,
          lazy: async () => ({
            Component: (await import('screens/Glp/CallRescheduledCanceled')).CallRescheduledCanceled
          })
        },
        {
          path: GlpPath.AllChats,
          lazy: async () => ({ Component: (await import('screens/Glp/AllChats')).AllChats })
        },
        {
          path: GlpPath.Chat,
          lazy: async () => ({ Component: (await import('screens/Glp/Chat')).Chat })
        },
        {
          path: GlpPath.Account,
          lazy: async () => ({ Component: (await import('screens/Glp/Account')).Account })
        },
        {
          path: GlpPath.Dashboard,
          lazy: async () => ({ Component: (await import('screens/Glp/Dashboard')).Dashboard })
        }
      ]
    }
  ]
}
