import { FC } from 'react'
import { AnimatedPage } from 'components/Animated'
import { getPaidVideoCallFlow } from 'screens/PaidVideoCalls/types'

export const PaidCallFlowStep: FC<any> = ({ stepIndex }) => {
  const currentStep = getPaidVideoCallFlow()[stepIndex]
  const StepComponent = currentStep.routeComponent

  return (
    <AnimatedPage>
      <StepComponent />
    </AnimatedPage>
  )
}
