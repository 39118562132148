import { PrivateRoute } from 'components'
import { SecureDetailsLayout } from 'components/PageLayout/SecureDetailsLayout'
import { PartnerPath, LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

export const partnerRoutes: RouteObject = {
  path: LocationPaths.Partner,
  element: <SecureDetailsLayout />,
  children: [
    {
      index: true,
      lazy: async () => ({ Component: (await import('screens/Partner/Redirect')).Redirect })
    },
    {
      path: PartnerPath.SignUp,
      lazy: async () => ({
        Component: (await import('screens/Partner/SignUp')).SignUp
      })
    },
    {
      path: PartnerPath.SignIn,
      lazy: async () => ({
        Component: (await import('screens/Partner/SignIn')).SignIn
      })
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: PartnerPath.CodeRedemption,
          lazy: async () => ({
            Component: (await import('screens/Partner/CodeRedemption')).CodeRedemption
          })
        },
        {
          path: PartnerPath.SteloEligibility,
          lazy: async () => ({
            Component: (await import('screens/Partner/SteloEligibility')).SteloEligibility
          })
        },
        {
          path: PartnerPath.Address,
          lazy: async () => ({
            Component: (await import('screens/Partner/Address')).Address
          })
        },
        {
          path: PartnerPath.ClaimOffer,
          lazy: async () => ({
            Component: (await import('screens/Partner/ClaimOffer')).ClaimOffer
          })
        },
        {
          path: PartnerPath.ScheduleCall,
          lazy: async () => ({
            Component: (await import('screens/Partner/ScheduleCall')).ScheduleCall
          })
        },
        {
          path: PartnerPath.CompleteAccount,
          lazy: async () => ({
            Component: (await import('screens/Partner/CompleteAccount')).CompleteAccount
          })
        },
        {
          path: PartnerPath.Confirmed,
          lazy: async () => ({
            Component: (await import('screens/Partner/Confirmed')).Confirmed
          })
        },
        {
          path: PartnerPath.Ineligible,
          lazy: async () => ({
            Component: (await import('screens/Partner/Ineligible')).Ineligible
          })
        }
      ]
    }
  ]
}
