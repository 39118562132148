import { AddressState } from 'types'

export const FORCE_ALLOW_CAPTCHA_HEADER = 'Force-Allow-Captcha'
export const CAPTCHA_TOKEN_HEADER = 'Client-Captcha-Token'
export const RECAPTCHA_TIMEOUT = 110000
export const MAX_POSSIBLE_AGE = 125

export const US_STATES = [
  {
    name: 'Alaska',
    abbreviation: AddressState.Aa
  },
  {
    name: 'Alabama',
    abbreviation: AddressState.Al
  },
  {
    name: 'American Samoa',
    abbreviation: AddressState.As
  },
  {
    name: 'Arizona',
    abbreviation: AddressState.Az
  },
  {
    name: 'Arkansas',
    abbreviation: AddressState.Ar
  },
  {
    name: 'California',
    abbreviation: AddressState.Ca
  },
  {
    name: 'Colorado',
    abbreviation: AddressState.Co
  },
  {
    name: 'Connecticut',
    abbreviation: AddressState.Ct
  },
  {
    name: 'Delaware',
    abbreviation: AddressState.De
  },
  {
    name: 'District of Columbia',
    abbreviation: AddressState.Dc
  },
  {
    name: 'Federated States of Micronesia',
    abbreviation: AddressState.Fm
  },
  {
    name: 'Florida',
    abbreviation: AddressState.Fl
  },
  {
    name: 'Georgia',
    abbreviation: AddressState.Ga
  },
  {
    name: 'Guam',
    abbreviation: AddressState.Gu
  },
  {
    name: 'Hawaii',
    abbreviation: AddressState.Hi
  },
  {
    name: 'Idaho',
    abbreviation: AddressState.Id
  },
  {
    name: 'Illinois',
    abbreviation: AddressState.Il
  },
  {
    name: 'Indiana',
    abbreviation: AddressState.In
  },
  {
    name: 'Iowa',
    abbreviation: AddressState.Ia
  },
  {
    name: 'Kansas',
    abbreviation: AddressState.Ks
  },
  {
    name: 'Kentucky',
    abbreviation: AddressState.Ky
  },
  {
    name: 'Louisiana',
    abbreviation: AddressState.La
  },
  {
    name: 'Maine',
    abbreviation: AddressState.Me
  },
  {
    name: 'Marshall Islands',
    abbreviation: AddressState.Mh
  },
  {
    name: 'Maryland',
    abbreviation: AddressState.Md
  },
  {
    name: 'Massachusetts',
    abbreviation: AddressState.Ma
  },
  {
    name: 'Michigan',
    abbreviation: AddressState.Mi
  },
  {
    name: 'Minnesota',
    abbreviation: AddressState.Mn
  },
  {
    name: 'Mississippi',
    abbreviation: AddressState.Ms
  },
  {
    name: 'Missouri',
    abbreviation: AddressState.Mo
  },
  {
    name: 'Montana',
    abbreviation: AddressState.Mt
  },
  {
    name: 'Nebraska',
    abbreviation: AddressState.Ne
  },
  {
    name: 'Nevada',
    abbreviation: AddressState.Nv
  },
  {
    name: 'New Hampshire',
    abbreviation: AddressState.Nh
  },
  {
    name: 'New Jersey',
    abbreviation: AddressState.Nj
  },
  {
    name: 'New Mexico',
    abbreviation: AddressState.Nm
  },
  {
    name: 'New York',
    abbreviation: AddressState.Ny
  },
  {
    name: 'North Carolina',
    abbreviation: AddressState.Nc
  },
  {
    name: 'North Dakota',
    abbreviation: AddressState.Nd
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: AddressState.Mp
  },
  {
    name: 'Ohio',
    abbreviation: AddressState.Oh
  },
  {
    name: 'Oklahoma',
    abbreviation: AddressState.Ok
  },
  {
    name: 'Oregon',
    abbreviation: AddressState.Or
  },
  {
    name: 'Palau',
    abbreviation: AddressState.Pw
  },
  {
    name: 'Pennsylvania',
    abbreviation: AddressState.Pa
  },
  {
    name: 'Puerto Rico',
    abbreviation: AddressState.Pr
  },
  {
    name: 'Rhode Island',
    abbreviation: AddressState.Ri
  },
  {
    name: 'South Carolina',
    abbreviation: AddressState.Sc
  },
  {
    name: 'South Dakota',
    abbreviation: AddressState.Sd
  },
  {
    name: 'Tennessee',
    abbreviation: AddressState.Tn
  },
  {
    name: 'Texas',
    abbreviation: AddressState.Tx
  },
  {
    name: 'Utah',
    abbreviation: AddressState.Ut
  },
  {
    name: 'Vermont',
    abbreviation: AddressState.Vt
  },
  {
    name: 'Virgin Islands',
    abbreviation: AddressState.Vi
  },
  {
    name: 'Virginia',
    abbreviation: AddressState.Va
  },
  {
    name: 'Washington',
    abbreviation: AddressState.Wa
  },
  {
    name: 'West Virginia',
    abbreviation: AddressState.Wv
  },
  {
    name: 'Wisconsin',
    abbreviation: AddressState.Wi
  },
  {
    name: 'Wyoming',
    abbreviation: AddressState.Wy
  },
  {
    name: 'Armed Forces Middle East',
    abbreviation: AddressState.Ae
  },
  {
    name: 'Armed Forces Americas (except Canada)',
    abbreviation: AddressState.Aa
  },
  {
    name: 'Armed Forces Pacific',
    abbreviation: AddressState.Ap
  }
]
