import { createContext, useContext } from 'react'
import { CurrentUser } from 'customTypes'

const defaultValues = {
  stepIndex: -1,
  navigateToNextStep: (_user: CurrentUser) => {},
  navigateToPreviousStep: () => {},
  registerCallback: (_cb: () => void) => {}
}

export const PaidVideoCallFlowContext = createContext(defaultValues)
export const usePaidVideoCallFlowContext = () => useContext(PaidVideoCallFlowContext)
