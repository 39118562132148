import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Header } from 'components/PageLayout/Header'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { FlowProgress } from 'screens/OwnSensor/FlowProgress'
import { useFindCurrentStep } from 'screens/OwnSensor/hooks/useFindCurrentStep'
import { OwnSensorFlow } from 'screens/OwnSensor/Steps'

export const Navigation = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { index: stepIndex, step } = useFindCurrentStep()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const goToPreviousStep = () => {
    if (!step.canNavigateBack) {
      return
    }

    const previousStepIndex = stepIndex - 1
    const previousStep = OwnSensorFlow[previousStepIndex]
    navigate(previousStep.location)
  }

  return (
    <Box sx={styles.box}>
      <Header hideBorder {...(step.canNavigateBack && { backButton: goToPreviousStep })} />
      <FlowProgress stepIndex={stepIndex} />
      <Outlet />
    </Box>
  )
}

const styles = {
  box: {
    backgroundColor: 'members.background'
  }
}
