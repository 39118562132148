import { SchedulingVariant } from 'screens/ScheduleCall/types'
import { HealthGoalName } from 'screens/Shared/HealthGoals/constants'
import { getObject, LocalStorageKeys, setObject } from 'utils/storage'
import { Coach, EhrAppointmentKind, EhrSlot, InsuranceBerryStreetAppointmentType } from 'types'
import { NOT_ELIGIBLE_PLANS } from './constants'

export interface ReservedAppointment {
  slot: Partial<EhrSlot>
  appointmentType: InsuranceBerryStreetAppointmentType
  pendingAppointmentId?: string | null
  appointmentKind?: EhrAppointmentKind
  timeZone: string
  coachProfile?: Coach
  coach: Coach
  schedulingVariant: SchedulingVariant
}
interface VideoCallData {
  health_goal?: HealthGoalName
  other_health_goal?: string
  secondary_health_goals?: HealthGoalName[]
  other_secondary_health_goal?: string
  selected_insurance_plan?: string
  ineligible_insurance_plan_selected?: string
  reserved_appointment?: ReservedAppointment | null
  appointment_agenda?: string | null
  slot_reserved_at?: string | null
  heard_about_us?: string
  heard_about_us_clarification?: string
  insurance_benefits_check_completed?: boolean
  // Dashboard flag used to control UI and change the behavior of the ScheduleCall screen
  coming_from_dashboard?: boolean
  rescheduling_appointment_id?: string
  dashboard_success_message_type?: 'Schedule' | 'Reschedule' | 'Cancel'
}

export const getVideoCallData = (): VideoCallData | null =>
  getObject<VideoCallData>(LocalStorageKeys.VideoCallData)

export const setVideoCallData = (videoCallData: Partial<VideoCallData>) => {
  const currentVideoCallData = getVideoCallData()

  setObject(LocalStorageKeys.VideoCallData, { ...currentVideoCallData, ...videoCallData })
}

export const ineligiblePlanSelected = () => {
  const videoCallData = getVideoCallData()

  return (
    videoCallData?.selected_insurance_plan &&
    Object.values(NOT_ELIGIBLE_PLANS).indexOf(videoCallData?.selected_insurance_plan) > -1
  )
}
