import { CurrentUser } from 'customTypes'
import { useIsFeatureEnabled } from 'utils/feature'
import { EhrAppointment, LatestUserAppointment } from 'types'
import { getVideoCallFlow, VideoCallStep } from '../types'

export const useFindStep = () => {
  const isFeatureEnabled = useIsFeatureEnabled()

  const findNextStep = (
    currentIndex: number,
    user: CurrentUser,
    latestUserAppointment: LatestUserAppointment | EhrAppointment
  ): VideoCallStep => {
    const videoCallFlow = getVideoCallFlow()
    const nextStep = videoCallFlow[currentIndex + 1]

    if (!nextStep) {
      return videoCallFlow[currentIndex]
    }

    if (nextStep.featureFlag && !isFeatureEnabled(nextStep.featureFlag)) {
      return findNextStep(currentIndex + 1, user, latestUserAppointment)
    }

    if (nextStep.completedResolver(user, latestUserAppointment)) {
      return findNextStep(currentIndex + 1, user, latestUserAppointment)
    }

    return nextStep
  }

  const findPreviousStep = (currentIndex: number) => {
    const videoCallFlow = getVideoCallFlow()
    const previousStep = videoCallFlow[currentIndex - 1]

    if (!previousStep) {
      return videoCallFlow[currentIndex]
    }

    if (previousStep.featureFlag && !isFeatureEnabled(previousStep.featureFlag)) {
      return findPreviousStep(currentIndex - 1)
    }

    return previousStep
  }

  return { findNextStep, findPreviousStep }
}
