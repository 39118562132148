import { CurrentUser } from 'customTypes'
import { isPaidVideoCallsFlow, isVideoCallsFlow } from 'utils/navigation'
import { BillingProduct, MobileAppFeature } from 'types'

export const isBookingAllowed = (user?: CurrentUser) => {
  if (!user) {
    return false
  }

  // do not allow direct booking if the user is in the paid calls flow
  const isPaidCallsFlow = isPaidVideoCallsFlow()
  if (isPaidCallsFlow) {
    return false
  }

  const isNewVideoCallsFlow = isVideoCallsFlow()

  if (!isNewVideoCallsFlow) {
    return true
  }

  const paymentMethodCollected =
    !!user.paymentMethod || user.referralPartner.schedulingWithoutPaymentMethod

  const baseConditionsSatisfied =
    paymentMethodCollected && !!user.address && !!user.ongoingCoreSubscription

  const isMedicalConsultUser =
    user.ongoingCoreSubscription?.primaryProduct.key ===
    BillingProduct.FreeSoftwareOnlyMedicalAnnualMembership

  if (isMedicalConsultUser) {
    return (
      baseConditionsSatisfied && user.features.includes(MobileAppFeature.InsuranceCoveredVideoCalls)
    )
  }

  return baseConditionsSatisfied
}
