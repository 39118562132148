import { Suspense, useEffect } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { Typography } from '@mui/material'
import { Loader } from 'components'
import LockIcon from 'components/Icons/LockIcon'
import { Header } from 'components/PageLayout/Header'
import { usePageTracking } from 'hooks/analytics'
import { Outlet, useLocation } from 'react-router-dom'
import { palette } from 'theme/theme.palette'

const Loading = () => {
  return (
    <Box style={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export const SecureDetailsLayout = () => {
  const trackPage = usePageTracking()
  const location = useLocation()

  useEffect(() => {
    trackPage(location.pathname)
  }, [location.pathname, trackPage])

  return (
    <Grid
      container
      sx={{
        flexWrap: 'nowrap',
        backgroundColor: palette.members.background
      }}
    >
      <Grid flex={1} item>
        <Stack minHeight="100dvh">
          <Box sx={styles.sticky}>
            <Header />
          </Box>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>

          <Typography sx={styles.securityNote} variant="subtitle2">
            <LockIcon sx={styles.lockIcon} />
            All details you share are secure and confidential
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

const styles = {
  securityNote: {
    color: 'brandText.light.main',
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.15,
    fontWeight: 400,
    width: '100%',
    paddingBottom: 2,
    paddingTop: 1,
    textAlign: 'center',
    marginTop: 'auto'
  },
  lockIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
    marginRight: 1
  },
  sticky: {
    backgroundColor: 'members.background',
    position: 'sticky',
    zIndex: 100,
    top: 0
  }
}
