import { Suspense, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { Box, Grid, Stack } from '@mui/material'
import { Loader } from 'components'
import { Direction } from 'components/Animated'
import { Header } from 'components/PageLayout/Header'
import { SecurityNote } from 'components/SecurityNote'
import { preloadQuery } from 'config/apollo/client'
import { useLoadStripe } from 'context/StripeElementsContext'
import { usePageTracking } from 'hooks/analytics'
import { useRecaptcha } from 'hooks/useRecaptcha'
import { useReferredByCoach } from 'hooks/useReferredByCoach'
import { LocationPaths } from 'location.types'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { VideoCallFlowContext } from 'screens/VideoCalls/context/VideoCallFlowContext'
import { FlowProgress } from 'screens/VideoCalls/FlowProgress'
import { useStepNavigation, useFindCurrentStep, useReferralPartner } from 'screens/VideoCalls/hooks'

import { useFindStep } from 'screens/VideoCalls/hooks'
import { GlobalTimer } from 'screens/VideoCalls/ReservationTimer'
import { getVideoCallData } from 'screens/VideoCalls/utils'
import { palette } from 'theme/theme.palette'
import {
  useCurrentUserQuery,
  useLatestUserAppointmentQuery,
  LatestUserAppointment,
  MobileAppFeature,
  useLatestUserEhrAppointmentQuery,
  EhrAppointment,
  EhrAppointmentCategory
} from 'types'

const Loading = () => {
  return (
    <Box style={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export const Layout = () => {
  const location = useLocation()
  const { index: stepIndex, step } = useFindCurrentStep()

  const { data: { currentUser } = {}, loading: loadingUser } = useCurrentUserQuery()
  const {
    data: { latestUserAppointment: latestUserInsuranceAppointment } = {},
    loading: loadingInsuranceAppointment
  } = useLatestUserAppointmentQuery()
  const { data: { latestUserEhrAppointment } = {}, loading: loadingEhrAppointment } =
    useLatestUserEhrAppointmentQuery({ variables: { category: EhrAppointmentCategory.Coaching } })
  const latestUserAppointment = currentUser?.migrationFromInsuranceEnabled
    ? latestUserEhrAppointment
    : latestUserInsuranceAppointment
  const loadingAppointment = currentUser?.migrationFromInsuranceEnabled
    ? loadingEhrAppointment
    : loadingInsuranceAppointment

  const { navigateToNextStep, navigateToPreviousStep, direction, setDirection } =
    useStepNavigation(stepIndex)
  const [callback, setCallback] = useState<(() => void) | null>(null)
  const videoCallData = getVideoCallData()

  const currentStep = step
  const loading = loadingUser || loadingAppointment

  const trackPage = usePageTracking()
  const navigate = useNavigate()
  const { capturePartner } = useReferralPartner()
  const { captureCoachIdFromParams } = useReferredByCoach()
  const { captureForceAllowCaptcha } = useRecaptcha()

  const loadStripe = useLoadStripe()
  const { findNextStep } = useFindStep()

  useEffect(() => {
    if (loading) {
      return
    }
    const nextStep = findNextStep(
      stepIndex,
      currentUser,
      latestUserAppointment as LatestUserAppointment | EhrAppointment
    )

    if (nextStep && nextStep.routeComponent.preload) {
      nextStep.routeComponent.preload()
    }

    if (nextStep?.requiresStripe) {
      loadStripe?.()
    }

    if (nextStep.preloadQueries) {
      nextStep.preloadQueries.forEach((query) => preloadQuery(query))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, loading])

  useEffect(() => {
    if (!currentStep) {
      Bugsnag.notify(new Error('Invalid step index'))
      navigate(LocationPaths.NotFound)
    }
  }, [currentStep, navigate])

  useEffect(() => {
    trackPage(location.pathname)
  }, [location.pathname, trackPage])

  useEffect(() => {
    const currentStepCompleted = currentStep.completedResolver(
      currentUser,
      latestUserAppointment as LatestUserAppointment | EhrAppointment
    )

    if (!loading && currentStepCompleted) {
      const nextStep = findNextStep(
        stepIndex,
        currentUser,
        latestUserAppointment as LatestUserAppointment | EhrAppointment
      )

      navigate(nextStep.location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, latestUserAppointment])

  if (stepIndex === 0) {
    capturePartner()
    captureCoachIdFromParams()
    captureForceAllowCaptcha()
  }

  const registerCallback = (callback: () => void) => {
    setCallback(() => callback)
  }

  const handlePreviousStepNavigate = () => {
    if (callback) {
      callback()
      setCallback(null)
    }
    navigateToPreviousStep()
  }

  const alreadyEligible = currentUser?.features.includes(
    MobileAppFeature.InsuranceCoveredVideoCalls
  )

  // Hide the back button for users that are already eligible (i.e. users that became eligible via a different flow, e.g. DPP).
  // Otherwise, they will be able to navigate back to steps that don't make sense for them (e.g. the insurance survey)
  //
  const backButton =
    !alreadyEligible && (currentStep.canNavigateBack || !!callback)
      ? handlePreviousStepNavigate
      : undefined

  return (
    <Grid
      container
      sx={{
        flexWrap: 'nowrap',
        backgroundColor: palette.members.background
      }}
    >
      <Grid flex={1} item>
        <Stack minHeight="100dvh">
          <VideoCallFlowContext.Provider
            value={{
              stepIndex,
              navigateToNextStep,
              navigateToPreviousStep,
              registerCallback,
              direction,
              resetBackDirection: () => {
                setDirection(Direction.Forward)
              }
            }}
          >
            <Box sx={styles.sticky}>
              <Header hideBorder backButton={backButton} />
              {!videoCallData?.coming_from_dashboard && <FlowProgress />}
            </Box>
            {!videoCallData?.coming_from_dashboard && <GlobalTimer />}
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>

            <SecurityNote />
          </VideoCallFlowContext.Provider>
          {/* </AnimatePresence> */}
        </Stack>
      </Grid>
    </Grid>
  )
}

const styles = {
  securityNote: {
    position: 'sticky',
    bottom: 0,
    color: 'brandText.light.main',
    backgroundColor: 'members.background',
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.15,
    fontWeight: 400,
    width: '100%',
    paddingBottom: 3,
    paddingTop: 1,
    textAlign: 'center',
    marginTop: 'auto'
  },
  lockIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
    marginRight: 1
  },
  loaderWrapper: {
    margin: 'auto 0'
  },
  sticky: {
    backgroundColor: 'members.background',
    position: 'sticky',
    zIndex: 100,
    top: 0
  }
}
