import { useState } from 'react'
import { Direction } from 'components/Animated/types'
import { CurrentUser } from 'customTypes'
import { useNavigate } from 'react-router-dom'
import {
  EhrAppointment,
  EhrAppointmentCategory,
  LatestUserAppointment,
  useLatestUserAppointmentQuery,
  useLatestUserEhrAppointmentQuery
} from 'types'
import { useFindStep } from '../hooks'

export const useStepNavigation = (currentStep: number) => {
  const navigate = useNavigate()
  const [direction, setDirection] = useState(Direction.Forward)

  const { findNextStep, findPreviousStep } = useFindStep()
  const { data: { latestUserAppointment: latestUserInsuranceAppointment } = {} } =
    useLatestUserAppointmentQuery()
  const { data: { latestUserEhrAppointment } = {} } = useLatestUserEhrAppointmentQuery({
    variables: { category: EhrAppointmentCategory.Coaching }
  })

  const navigateToNextStep = (user: CurrentUser) => {
    const latestUserAppointment = user?.migrationFromInsuranceEnabled
      ? latestUserEhrAppointment
      : latestUserInsuranceAppointment

    const nextStep = findNextStep(
      currentStep,
      user,
      latestUserAppointment as LatestUserAppointment | EhrAppointment
    )

    setDirection(Direction.Forward)
    navigate(nextStep.location)
  }

  const navigateToPreviousStep = () => {
    setDirection(Direction.Backward)
    const previousStep = findPreviousStep(currentStep)
    navigate(previousStep.location)
  }

  return {
    direction,
    setDirection,
    navigateToPreviousStep,
    navigateToNextStep
  }
}
