import { Suspense } from 'react'
import { ApolloProvider } from '@apollo/client'
import { Client } from '@bugsnag/js'
import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { Loader, Segment, SnackBar } from 'components'
import { Bugsnag as BugsnagProvider, GoogleTagManager } from 'components/Analytics'
import { client } from 'config/apollo/client'
import bugsnagClient from 'config/bugsnag'
import { AuthProvider, UtmParamsProvider, UnleashProvider } from 'context'
import { Elements } from 'context/StripeElementsContext'
import { ConfirmProvider } from 'material-ui-confirm'
import { Outlet } from 'react-router-dom'
import { IframeImpersonationProvider } from '../components/IframeImpersonation'
import { theme, globalStyles } from '../theme'

export const App = () => {
  return (
    <ApolloProvider client={client}>
      <BugsnagProvider client={bugsnagClient as unknown as Client}>
        <UnleashProvider>
          <UtmParamsProvider>
            <AuthProvider>
              <IframeImpersonationProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <ConfirmProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        <GlobalStyles styles={globalStyles} />
                        <Segment />
                        <GoogleTagManager />
                        <Elements>
                          <Suspense fallback={<Loader />}>
                            <Outlet />
                          </Suspense>
                        </Elements>
                        <SnackBar />
                      </LocalizationProvider>
                    </ConfirmProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </IframeImpersonationProvider>
            </AuthProvider>
          </UtmParamsProvider>
        </UnleashProvider>
      </BugsnagProvider>
    </ApolloProvider>
  )
}
