import { ReactNode } from 'react'
import { useIframeImpersonation } from 'hooks/useIframeImpersonation'

interface IframeImpersonationProviderProps {
  children: ReactNode
}

export const IframeImpersonationProvider = ({ children }: IframeImpersonationProviderProps) => {
  useIframeImpersonation()

  return children
}
