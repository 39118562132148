export default function getUserGeolocation() {
  // Geolocation is added to the code by a Cloudflare worker
  const userGeolocationCountryCode = window.userGeolocationCountryCode
  const userGeolocationRegion = window.userGeolocationRegion

  return {
    userGeolocationCountryCode,
    userGeolocationRegion
  }
}
