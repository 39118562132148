import { FC } from 'react'
import { AnimatedPage } from 'components/Animated'
import { OwnSensorFlow } from 'screens/OwnSensor/Steps'

export const OwnSensorFlowStep: FC<any> = ({ stepIndex }) => {
  const currentStep = OwnSensorFlow[stepIndex]
  const StepComponent = currentStep.routeComponent

  if (currentStep.noPageAnimation) {
    return <StepComponent />
  }

  return (
    <AnimatedPage>
      <StepComponent />
    </AnimatedPage>
  )
}
