import { Typography } from '@mui/material'
import LockIcon from 'components/Icons/LockIcon'

export const SecurityNote = () => {
  return (
    <Typography sx={styles.securityNote} variant="subtitle2" mt={4}>
      <LockIcon sx={styles.lockIcon} />
      All details you share are secure and confidential
    </Typography>
  )
}

const styles = {
  securityNote: {
    position: 'sticky',
    bottom: 0,
    color: 'brandText.light.main',
    backgroundColor: 'members.background',
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.15,
    fontWeight: 400,
    width: '100%',
    paddingBottom: 3,
    paddingTop: 1,
    textAlign: 'center',
    marginTop: 'auto'
  },
  lockIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
    marginRight: 1
  }
}
