import { Suspense, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { Box, Grid, Stack } from '@mui/material'
import { Typography } from '@mui/material'
import { Loader } from 'components'
import LockIcon from 'components/Icons/LockIcon'
import { Header } from 'components/PageLayout/Header'
import { useLoadStripe } from 'context/StripeElementsContext'
import { usePageTracking } from 'hooks/analytics'
import { LocationPaths } from 'location.types'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { PaidVideoCallFlowContext } from 'screens/PaidVideoCalls/context/PaidVideoCallFlowContext'
import { FlowProgress } from 'screens/PaidVideoCalls/FlowProgress'
import { useFindCurrentStep } from 'screens/PaidVideoCalls/hooks/useFindCurrentStep'
import { useStepNavigation } from 'screens/PaidVideoCalls/hooks/useStepNavigation'
import { palette } from 'theme/theme.palette'

const Loading = () => {
  return (
    <Box style={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export const Layout = () => {
  const location = useLocation()
  const { index: stepIndex, step: currentStep } = useFindCurrentStep()
  const { navigateToNextStep, navigateToPreviousStep } = useStepNavigation(stepIndex)
  const [callback, setCallback] = useState<(() => void) | null>(null)

  const trackPage = usePageTracking()
  const navigate = useNavigate()
  const loadStripe = useLoadStripe()

  useEffect(() => {
    if (!currentStep) {
      Bugsnag.notify(new Error('Invalid step index'))
      navigate(LocationPaths.NotFound)
    }
  }, [currentStep, navigate])

  useEffect(() => {
    trackPage(location.pathname)
  }, [location.pathname, trackPage])

  useEffect(() => {
    if (currentStep?.requiresStripe) {
      loadStripe?.()
    }
  }, [currentStep?.requiresStripe, loadStripe])

  const registerCallback = (callback: () => void) => {
    setCallback(() => callback)
  }

  const handlePreviousStepNavigate = () => {
    if (callback) {
      callback()
      setCallback(null)
    }
    if (stepIndex === 0) {
      // Go back to the normal flow
      navigate(LocationPaths.VideoCalls)
    } else {
      navigateToPreviousStep()
    }
  }

  const backButton =
    currentStep?.canNavigateBack || !!callback ? handlePreviousStepNavigate : undefined

  return (
    <Grid
      container
      sx={{
        flexWrap: 'nowrap',
        backgroundColor: palette.members.background
      }}
    >
      <Grid flex={1} item>
        <Stack minHeight="100dvh">
          <PaidVideoCallFlowContext.Provider
            value={{
              stepIndex,
              navigateToNextStep,
              navigateToPreviousStep,
              registerCallback
            }}
          >
            <Box sx={styles.sticky}>
              <Header hideBorder backButton={backButton} />
              <FlowProgress />
            </Box>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>

            {!currentStep.hideSecurityNote && (
              <Typography sx={styles.securityNote} variant="subtitle2">
                <LockIcon sx={styles.lockIcon} />
                All details you share are secure and confidential
              </Typography>
            )}
          </PaidVideoCallFlowContext.Provider>
        </Stack>
      </Grid>
    </Grid>
  )
}

const styles = {
  securityNote: {
    position: 'sticky',
    bottom: 0,
    color: 'brandText.light.main',
    backgroundColor: 'members.background',
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.15,
    fontWeight: 400,
    width: '100%',
    paddingBottom: 3,
    paddingTop: 1,
    textAlign: 'center',
    marginTop: 'auto'
  },
  lockIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
    marginRight: 1
  },
  sticky: {
    backgroundColor: 'members.background',
    position: 'sticky',
    zIndex: 100,
    top: 0
  }
}
