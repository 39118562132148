import { useCallback } from 'react'
import { SearchParams } from 'location.types'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'utils/localStorage'
import { UserReferralPartner, useReferralPartnerQuery } from 'types'

export const PARTNER_ID_KEY = 'partner'

export const useReferralPartner = () => {
  const [searchParams] = useSearchParams()

  const [partner, setPartner] = useLocalStorage(PARTNER_ID_KEY)

  const { data, loading } = useReferralPartnerQuery({
    variables: {
      partner: partner as UserReferralPartner
    },
    skip: !partner
  })

  const logoUrl = data?.referralPartner?.logoUrl

  const capturePartner = useCallback(() => {
    const partnerKey = searchParams.get(SearchParams.Partner)?.toLowerCase()
    if (!partnerKey || partner) {
      return
    }
    if (!Object.values(UserReferralPartner).includes(partnerKey as UserReferralPartner)) {
      return
    }

    setPartner(partnerKey)
  }, [partner, searchParams, setPartner])

  return {
    partner,
    capturePartner,
    loading,
    logoUrl,
    partnerData: data?.referralPartner
  }
}
