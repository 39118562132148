import { useEffect, useCallback } from 'react'
import { showSnack, useAuth } from 'context'
import { useLocalStorage } from 'utils/localStorage'
import { MobileAppFeature, useSigninUserMutation } from 'types'
import { useReferredByCoach } from './useReferredByCoach'

interface MemberDataEvent {
  type: string
  userEmail: string
  coachId: string
}

export const useIframeImpersonation = () => {
  const [adminEmail, setAdminEmail] = useLocalStorage('adminEmail')

  const { isAuthorized, currentUser, updateCurrentUser } = useAuth()
  const [signinUserMutation] = useSigninUserMutation()
  const { manuallyCaptureCoachId } = useReferredByCoach()

  const impersonateUser = useCallback(
    async (email: string) => {
      try {
        if (currentUser?.email === email) {
          // Already logged in as the desired user
          return
        }

        if (!adminEmail) {
          throw new Error('Admin email not found')
        }

        // Sign in as the impersonated user
        const { data: response } = await signinUserMutation({
          variables: {
            email: adminEmail,
            password: '',
            impersonate: email
          }
        })

        if (!response?.signinUser?.success) {
          showSnack('Failed to impersonate user', 'error')
        }

        await updateCurrentUser(response?.signinUser?.user)
        showSnack(`Impersonated user ${email} successfully`, 'success')
      } catch (error) {
        showSnack('An error occurred during impersonation', 'error')
      }
    },
    [signinUserMutation, adminEmail, updateCurrentUser, currentUser]
  )

  useEffect(() => {
    const handleMemberDataMessage = (event: MessageEvent) => {
      const data = event.data as MemberDataEvent

      if (data.type !== 'member_data' || !data.userEmail || !data.coachId) {
        return
      }

      impersonateUser(data.userEmail)
      manuallyCaptureCoachId(data.coachId)
    }

    window.addEventListener('message', handleMemberDataMessage)

    return () => {
      window.removeEventListener('message', handleMemberDataMessage)
    }
  }, [impersonateUser, manuallyCaptureCoachId])

  // Notify parent window when admin is authenticated
  useEffect(() => {
    if (isAuthorized) {
      if (currentUser?.features.includes(MobileAppFeature.AdminImpersonate)) {
        // If user has admin impersonate feature, set admin login so it is saved
        // for next time when admin wants to impersonate a member
        setAdminEmail(currentUser.email)
        window.parent.postMessage({ type: 'admin_authenticated' }, '*')
      } else if (adminEmail) {
        window.parent.postMessage({ type: 'admin_authenticated' }, '*')
      }
    }
  }, [isAuthorized, currentUser, setAdminEmail, adminEmail])
}
