import { createContext, useContext } from 'react'
import { Direction } from 'components/Animated/types'
import { CurrentUser } from 'customTypes'

const defaultValues = {
  stepIndex: -1,
  navigateToNextStep: (_user: CurrentUser) => {},
  navigateToPreviousStep: () => {},
  registerCallback: (_cb: () => void) => {},
  direction: Direction.Forward,
  resetBackDirection: () => {}
}

export const VideoCallFlowContext = createContext(defaultValues)
export const useVideoCallsFlowContext = () => useContext(VideoCallFlowContext)
