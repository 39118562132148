import { useMatches } from 'react-router-dom'
import { getPaidVideoCallFlow } from '../types'

export const useFindCurrentStep = () => {
  const matches = useMatches()
  const paidVideoCallFlow = getPaidVideoCallFlow()

  const currentStepIdx =
    matches.slice(-1)[0]?.handle?.['index' as keyof typeof matches[0]['handle']]

  if (typeof currentStepIdx !== 'number') {
    return {
      index: 0,
      step: paidVideoCallFlow[0]
    }
  }

  return {
    index: currentStepIdx,
    step: paidVideoCallFlow[currentStepIdx]
  }
}
