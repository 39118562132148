import { useState } from 'react'
import { SearchParams } from 'location.types'
import { useSearchParams } from 'react-router-dom'

const REFERRED_BY_COACH_ID = 'referredByCoachId'

export const useReferredByCoach = () => {
  const [searchParams] = useSearchParams()
  const [coachId, setCoachId] = useState<string | null>(localStorage.getItem(REFERRED_BY_COACH_ID))

  const captureCoachIdFromParams = () => {
    const id = searchParams.get(SearchParams.CoachId)
    if (!id || coachId) {
      return
    }
    localStorage.setItem(REFERRED_BY_COACH_ID, id)
    setCoachId(id)
  }

  const manuallyCaptureCoachId = (newCoachId: string) => {
    localStorage.setItem(REFERRED_BY_COACH_ID, newCoachId)
    setCoachId(newCoachId)
  }

  const resetCoachId = () => {
    localStorage.removeItem(REFERRED_BY_COACH_ID)
    setCoachId(null)
  }

  return {
    coachId,
    resetCoachId,
    captureCoachIdFromParams,
    manuallyCaptureCoachId
  }
}
