import { autocompleteClasses } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { palette } from './theme.palette'

const defaults = createTheme()

export const theme = createTheme({
  typography: {
    fontFamily: '"Inter",sans-serif',
    h1: {
      fontFamily: 'NBInternationalProReg,sans-serif'
    },
    h2: {
      fontFamily: 'NBInternationalProReg,sans-serif',
      color: palette.members.primary,
      letterSpacing: '0.01em',
      fontSize: '1.5rem'
    },
    h3: {
      fontFamily: 'NBInternationalProReg,sans-serif'
    },
    h4: {
      fontFamily: 'NBInternationalProReg,sans-serif'
    },
    h5: {
      fontFamily: 'NBInternationalProReg,sans-serif'
    },
    h6: {
      fontFamily: 'NBInternationalProReg,sans-serif'
    }
  },
  breakpoints: {
    values: {
      ...defaults.breakpoints.values,
      sm: 457,
      md: 624,
      lg: 824,
      xl: 1200
    }
  },
  palette: {
    ...palette,
    background: {
      paper: '#FFFFFF'
    },
    primary: palette.brandPrimary.base,
    secondary: palette.brandSecondary.base,
    action: {
      active: palette.brandSecondary.base.main,
      hover: palette.brandSecondary.base.light
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
          height: 54,
          borderRadius: 27,
          textTransform: 'unset',
          fontSize: 16,
          fontWeight: 600,
          fontFamily: 'Inter'
        },
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
          ':hover': {
            borderWidth: 1
          }
        }),
        sizeSmall: {
          height: 40,
          paddingHorizontal: 16,
          fontSize: 14
        }
      },
      variants: [
        {
          props: { variant: 'solid' },
          style: {
            backgroundColor: palette.members.primary,
            letterSpacing: '-0.01em',
            fontSize: '0.9375rem',
            fontWeight: 500,
            color: 'white',
            '&:hover': {
              backgroundColor: palette.members.button.hover
            },
            '&:disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)'
            },
            '.MuiButton-endIcon': {
              position: 'absolute',
              fontSize: '1rem',
              right: '1.25rem',
              '& > *:nth-of-type(1)': { fontSize: '1rem' }
            }
          }
        },
        {
          props: { variant: 'outlinedAlt' },
          style: {
            borderColor: palette.members.stroke,
            backgroundColor: 'white',
            letterSpacing: '-0.02em',
            padding: '0.5rem 1rem',
            borderStyle: 'solid',
            borderWidth: '1px',
            lineHeight: 1.4,
            fontWeight: 500,
            color: '#555555',
            '&:hover': {
              backgroundColor: palette.members.input.hover
            },
            '.MuiButton-endIcon': {
              position: 'absolute',
              fontSize: '1rem',
              right: '1.25rem',
              '& > *:nth-of-type(1)': { fontSize: '1rem' }
            }
          }
        }
      ]
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: ({ theme }) => ({ background: theme.palette.brandPrimary.light.main }),
        colorPrimary: ({ theme }) => ({ background: theme.palette.brandGrey.light })
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: '0 0 16px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.paper
        })
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.brandPrimary.base.main
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: ({ theme }) => ({
          [`& .${autocompleteClasses.option}.${autocompleteClasses.focused}`]: {
            backgroundColor: `${theme.palette.brandGrey.light}`
          }
        })
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
          title: 'h1'
        }
      },
      variants: [
        {
          props: { variant: 'label' },
          style: {
            letterSpacing: '-0.02em',
            fontSize: '0.9375rem',
            fontWeight: 500
          }
        },
        {
          props: { variant: 'body3' },
          style: {
            color: palette.members.primary,
            letterSpacing: '-0.02em',
            fontSize: '0.875rem',
            fontWeight: 400
          }
        },
        {
          props: { variant: 'subtitle3' },
          style: {
            color: palette.members.primary,
            fontSize: '0.75rem',
            lineHeight: '1.65',
            fontWeight: 400
          }
        },
        {
          props: { variant: 'title' },
          style: ({ theme }) => ({
            fontFamily: 'CanelaMedium',
            color: palette.members.primary,
            fontSize: '1.125rem',
            lineHeight: '1.3',
            fontWeight: 500,
            [theme.breakpoints.up('sm')]: {
              fontSize: '1.7rem'
            }
          })
        }
      ]
    }
  }
})
